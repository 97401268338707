import { Module } from "vuex";

const strAppFormDataStoreModule: Module<any, any> = {
  state: {
    strAppFormData: {}
  },
  mutations: {
    setStrAppFormData(state, fd) {
      state.strAppFormData = fd;
    }
  },
  actions: {
    setStrAppFormData({ commit }, fd) {
      commit('setStrAppFormData', fd);
    }
  },
  getters: {
    getStrAppFormData: (state) => state.strAppFormData
  }
}
export default strAppFormDataStoreModule;