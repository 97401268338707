import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { QualificationInstitution } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    institution: Object,
    error: Boolean,
    enableAddInstitution: Boolean,
  },
  components: {}
})
export default class InstitutionSearch extends Vue {
  private institution!: QualificationInstitution;
  private error!: boolean;
  private enableAddInstitution!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  // private keyword = this.firm?.firmName ? this.institution.firmName : '';
  private isSearching = false;
  private isOpen = false;
  private stopSearch = false;

  // private isFirmIdValid = true;
  private formDirty = false;

  private arrowCounter = -1;
  private searchDebounced: any = null;

  private institutionList: QualificationInstitution[] = [];
  private showInputField = false;
  // private firmIdList = [];

  // private firmName = '';
  // private firmId = '';

  private manualInstitution = '';
  private autoCompInstitution = '';

  @Watch('isSearching')
  private isSearchingChange() {
    this.$emit('isBusy', this.isSearching || this.isOpen);
  }

  @Watch('isOpen')
  private isOpenChange() {
    this.$emit('isBusy', this.isSearching || this.isOpen);
  }

  @Watch('autoCompInstitution')
  private onKeywordChange(): void {
    this.$emit('isBusy', true);
    if ((this.institution?.name?.length > 0 && this.institution?.id != 0) &&
      this.autoCompInstitution !== this.institution?.name) {
      this.$emit('updateInstitution', null);
    } else if (this.autoCompInstitution.trim().length > 2 && !this.stopSearch && this.formDirty) {
      setTimeout(this.searchDebounced.cancel, 0);
      setTimeout(this.searchDebounced, 0);
    }
    // if (this.institutionList.filter((x: any) => x.id == this.institution?.id).length == 1) {
    //   this.autoCompInstitution = this.institution.name;
    // } else {
    //   this.$emit('updateInstitution', null);
    //   if (this.autoCompInstitution?.trim().length > 2 && !this.stopSearch && this.formDirty) {
    //     setTimeout(this.searchDebounced.cancel, 0);
    //     setTimeout(this.searchDebounced, 0);
    //   }
    // }
  }

  // @Watch('institution.id')
  // private onInstitutionIdChanged(): void {
  //   console.log(this.institution);
  //   if (this.institution?.id === 0) {
  //     this.showInputField = true;
  //   }
  // }

  @Watch('manualInstitution')
  private onManualInstitutionChanged(): void {
    if (this.manualInstitution?.trim().length == 0) {
      this.$emit('updateInstitution', null);
    } else {
      this.$emit('updateInstitution', {
        id: 0,
        name: this.manualInstitution,
      });
    }
  }

  mounted(): void {
    document.addEventListener('click', this.handleClickOutside);
    this.searchDebounced = debounce(() => {
      this.stopSearch = false;
      if (this.autoCompInstitution.trim() !== '') {
        this.getInstitutionsList();
      }
    }, 500);

    if (this.institution?.id >= 0) {
      this.institutionList.push(this.institution);
    }

    if (this.institution.id === 0) {
      this.manualInstitution = this.institution.name;
      this.isOpen = false;
      this.formDirty = false;
      this.showInputField = true;
    } else if (this.institution == null || this.institution.id >= 0) {
      this.autoCompInstitution = this.institution.name;
      this.isOpen = false;
      this.formDirty = false;
      this.showInputField = false;
    }

  }

  beforeUnmount(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  private handleClickOutside(event: any) {
    if (this.$el !== (event.target)) {
      if (this.isOpen) {
        this.isOpen = false;
        this.arrowCounter = -1;
        this.$emit('updateInstitution', null);
      }
    }
  }

  private getInstitutionsList() {
    if (this.autoCompInstitution.length > 2) {
      this.isSearching = true;

      const body: any = {
        skip: 0,
        take: 0,
        keyword: this.autoCompInstitution.trim(),
      }
      this.apiService.CodeApi?.apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body)
        .then((res: any) => {
          this.processResult(res);
        })
        .catch((err: any) => {
          this.ai.trackException('Error getting institution list: ' + err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error getting institution list: ' + err);
          this.isSearching = false;
        })
    }

  }

  private processResult(res: any) {
    this.institutionList = res.data.data;
    this.isSearching = false;
    if (this.institutionList.length > 0) {
      this.arrowCounter = -1;
      this.isOpen = this.institutionList.length > 0;
    } else {
      this.$emit('updateInstitution', null);
      this.isOpen = false;
    }
  }

  private highlightWord(word: string) {
    try {
      const regex = new RegExp('(' + this.institution.name + ')', 'ig');
      return word.replace(regex, '<mark>$1</mark>');
    } catch (err) {
      return word;
    }
  }

  private onArrowDown() {
    if (this.institutionList.length > 0) {
      if (this.arrowCounter < this.institutionList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    this.formDirty = false;
    this.isOpen = false;
    if (this.institutionList.length > 0) {
      this.selectFirmFromSearch(
        this.institutionList[this.arrowCounter === -1 ? 0 : this.arrowCounter]
      );
    }
  }

  private selectFirmFromSearch(institution: QualificationInstitution) {
    this.isOpen = false;
    this.stopSearch = true;
    // this.firmName = firm.firmName;
    // this.firmId = firm.firmId;
    // this.institution.name = firm.firmName;
    setTimeout(() => {
      this.stopSearch = false;
    }, 500)
    const _institution: QualificationInstitution =
      { id: institution.id, name: institution.name, description: institution.description };
    this.autoCompInstitution = institution.name;
    this.$emit('updateInstitution', _institution);
    // this.keyword = '';
  }

  // private manualInput(institution: string): void {
  //   console.log(institution);
  //   this.$emit('updateInstitution', {
  //     id: 0,
  //     name: institution,
  //   });
  // }

  private addNewInstitution(): void {
    this.isOpen = false;
    this.formDirty = false;
    this.showInputField = true;
    this.$emit('updateInstitution', null);
  }

  private showSearchField(): void {
    this.isOpen = false;
    this.formDirty = false;
    this.showInputField = false;
    this.$emit('updateInstitution', null);
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

}



