
import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { PracticeDetails, RecordPractice, RecordPracticeRequest } from "@/shared/modules/typescript-api-client/models";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';

@Options({
  props: {
    practiceRecords: Object
  },
  components: {
    DatePicker,
    AddressPreview,
    LoadingIndicator,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrPracticeRecordAdditionalDetailsPopup extends Vue {
  private practiceRecords!: PracticeDetails[];

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  isVisible = false;

  title = '';
  message = '';// Main text content
  okButton = 'OK'; // Text for confirm button; leave it empty because we don't know what we're using it for
  cancelButton = 'Cancel'; // text for cancel button

  // Private variables
  private resolvePromise: any;
  private rejectPromise: any;

  private answer: any = null;
  private newRecords: RecordPractice[] = [];

  private selectedAddressId = 0;
  private selectedAddressIndex = 0;

  private isSavingData = false;

  @Watch('practiceRecords')
  private onRecordsChange() {
    this.newRecords = [];
    for (let r = 0; r < this.practiceRecords.length; r++) {
      if (this.practiceRecords[r].newPracticeRecord?.name) {
        this.newRecords.push(this.practiceRecords[r].newPracticeRecord);
      } else {
        this.newRecords.push(this.practiceRecords[r].originalPracticeRecord);
      }
      this.newRecords[r].effectiveDate = null;
    }
  }

  private open() {
    this.answer = null;
    setTimeout(() => {
      // delay add class to allow other popup to close
      document.body.classList.add('modal-open');
    }, 500);
    this.isVisible = true;
  }

  private close() {
    this.isVisible = false;
    document.body.classList.remove('modal-open');
  }

  public show(opts: any = {}) {
    this.title = opts?.title || 'CANCEL ALERT';
    this.message = opts?.message || 'Are you sure?';
    this.okButton = opts?.okButton || 'OK';
    this.cancelButton = opts?.cancelButton || 'Cancel';

    this.open();
    // Return promise so the caller can get results
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve
      this.rejectPromise = reject
    })
  }

  async _confirm() {
    if (!this.answer) {
      await this.updateRecords();
    }
    setTimeout(() => {
      this.close();
      this.resolvePromise(true);
    }, this.answer ? 0 : 1000);

  }

  _cancel() {
    this.close()
    this.resolvePromise(false)
    // Or you can throw an error
    // this.rejectPromise(new Error('User cancelled the dialogue'))
  }

  private async updateRecords() {
    this.isSavingData = true;
    for (let i = 0; i < this.practiceRecords.length; i++) {
      if (this.newRecords[i].effectiveDate) {
        this.newRecords[i].employmentStatus = { id: 999999999, name: "Ceased", description: "Creased" };
        this.newRecords[i].recordPracticeAddresses = this.setSelectedAddress(this.newRecords[i].recordPracticeAddresses);
        const _rec = {
          originalPracticeRecord: this.practiceRecords[i].originalPracticeRecord,
          newPracticeRecord: this.newRecords[i],
        }
        await this.updatePracticeDetailsPost(_rec);
      }
    }
    this.isSavingData = false;
  }

  private setSelectedAddress(addresses: any[]) {
    let foundIt = false;
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].isSelected) {
        foundIt = true;
      }
    }
    if (!foundIt) {
      addresses[0].isSelected = true;
    }
    return addresses;
  }

  private async updatePracticeDetailsPost(record: any) {
    const _newPracticeRecord = record.newPracticeRecord as RecordPractice;
    const _recordPracticeRequest: RecordPracticeRequest = _newPracticeRecord;
    const _address = this.getSelectedAddressFromArray(_recordPracticeRequest.recordPracticeAddresses);
    _recordPracticeRequest.recordPracticeAddresses.address = this.$addressCleanup(_address);
    await this.apiService.PcRenewApi?.apiV1PcRenewSavePracticeDetailsPost(_recordPracticeRequest)
      .then(() => {
        this.eventCtrl.emit('show-snackbar', 'success', 'Practice records updated');
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update practice records: ' + err);
        this.ai.trackException('Error, Unable to update practice records: ' + err);
      })
  }

  private getSelectedAddressFromArray(addresses: any[]) {
    const _selectedAddress: any[] = addresses.filter(a => {
      return a.isSelected;
    });
    return _selectedAddress[0]?.address ? _selectedAddress[0].address : addresses[0].address;
  }

  private disableAfterJulyTheFirst(date: any) {
    const lastApplicationYear = store.getters.getAppInfo.lastApplicationYear.year;
    return date > new Date(new Date(lastApplicationYear + '-07-01T23:59:59'));
  }

  get effectiveDateCount() {
    return this.newRecords.filter(r => {
      return r.effectiveDate !== null;
    }).length;
  }

  get canSubmit() {
    return this.answer || this.effectiveDateCount > 0;
  }

}

