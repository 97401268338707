import { Module } from "vuex";

const countryListStoreModule: Module<any, any> = {
  state: {
    countryList: null
  },
  mutations: {
    setCountryList(state, cl) {
      state.countryList = cl;
      window.sessionStorage.setItem('countryList', JSON.stringify(cl));
    }
  },
  actions: {
    setCountryList({ commit }, cl) {
      // console.log('setCountryList: ', cl)
      commit('setCountryList', JSON.stringify(cl));
    }
  },
  getters: {
    getCountryList: (state) => state.countryList
  }
}
export default countryListStoreModule;