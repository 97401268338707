import { Module } from "vuex";

const addressTypeStoreModule: Module<any, any> = {
  state: {
    addressTypeList: null
  },
  mutations: {
    setAddressTypeList(state, at) {
      state.addressTypeList = at;
      window.sessionStorage.setItem('addressTypeList', JSON.stringify(at));
    }
  },
  actions: {
    setAddressTypeList({ commit }, at) {
      commit('setAddressTypeList', JSON.stringify(at));
    }
  },
  getters: {
    getAddressTypeList: (state) => state.addressTypeList
  }
}
export default addressTypeStoreModule;