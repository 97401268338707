export function localUrl(): string {
  const result = window.location.href.split('/');
  return result[0] + '//' + result[2] + '/';
}

export const AZ_INSTRUMENT_KEY_PAYMENTAPP: string = process.env.VUE_APP_AZ_INSTRUMENT_KEY_PAYMENTAPP as string;
export const PUBLISHABLE_API_KEY: string = process.env.VUE_APP_PAYWAY_PUBLISHABLE_API_KEY as string;

export const TOKEN_URL: string = process.env.VUE_APP_TOKEN_URL as string;
export const API_URL: string = localUrl().slice(0, -1);
export const API_PAYMENT_URL: string = process.env.VUE_APP_API_URL as string;
export const IDLE_TIMEOUT: number = parseInt(process.env.VUE_APP_IDLE_TIMEOUT, 10);
export const TOKEN_REFRESH_TIME: number = parseInt(process.env.VUE_APP_TOKEN_REFRESH_TIME, 10);
export const SELF_URL: string = localUrl();
