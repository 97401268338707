import { Inject, Options, Vue } from "vue-property-decorator";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import ModalWindow from "@/shared/components/modal-window/index.vue";
import { AddressDetail } from "@/shared/modules/typescript-api-client/models";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import AddressComponent from "../address-component/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';

@Options({
  props: {
    editAddressType: Number,
    address: Object,
  },
  components: {
    ModalWindow,
    AddressPreview,
    AddressComponent,
    LoadingIndicator,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrAddressDetailsUpdateModal extends Vue {
  private address!: AddressDetail;
  private editAddressType!: number;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private isLoadingData = false;

  private isSubmitting = false;

  private formData: any = {};
  private formValid = false;

  mounted(): void {
    // this.preloadData();
  }

  private saveServiceAddress() {
    this.apiService.PcRenewApi?.apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(
      this.pcRenewApplicationId, this.$addressCleanup(this.address)
    )
      .then(res => {
        this.$emit('reload', this.editAddressType, true);
        this.eventCtrl.emit('show-snackbar', 'success', 'Service address updated: ');
        this.close();
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to save Service address: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Service address: ' + err);
      })
  }


  private savePostalAddress() {
    this.apiService.PcRenewApi?.apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(
      this.pcRenewApplicationId, this.$addressCleanup(this.address)
    )
      .then(res => {
        this.$emit('reload', this.editAddressType, true);
        this.eventCtrl.emit('show-snackbar', 'success', 'Postal address updated: ');
        this.close();
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to save Postal address: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Postal address: ' + err);
      })
  }

  private saveOtherAddress() {
    this.apiService.PcRenewApi?.apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(
      this.pcRenewApplicationId, this.$addressCleanup(this.address)
    )
      .then(res => {
        this.$emit('reload', this.editAddressType, true);
        this.eventCtrl.emit('show-snackbar', 'success', 'Other address updated: ');
        this.close();
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to save Postal address: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Other address: ' + err);
      })
  }

  private updateAddress(address: AddressDetail, valid: boolean) {
    // console.log('update address: ', address, valid);
    this.formValid = valid;
  }

  private update() {
    this.isSubmitting = true;
    if (this.formValid) {
      switch (this.editAddressType) {
        case 1:
          this.saveServiceAddress();
          break;
        case 2:
          this.savePostalAddress();
          break;
        case 3:
          this.saveOtherAddress();
          break;
      }
    }
  }

  private cancel() {
    this.close();
  }

  private close() {
    this.$emit('close');
  }

  get pcRenewApplicationId() {
    return store.getters.getPcrFormData.individualDetails.pcRenewApplicationId;
  }
}
