import { Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
// import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import DatePicker from 'vue-datepicker-next';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';

@Options({
  props: {
    pcrFormData: Object,
    sarAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    FileDragAndDrop,
    DatePicker,
    ConfirmDialogue
  },
  mixins: [FormatDateTimeMixin],
})
export default class SarRenewalDeclarations extends Vue {
  private sarAppFormData!: any;
  private isSectionLoading!: boolean;
  private pcrFormData!: any;

  private ai = new AppInsights;
  private isPcrApp = false;

  // @Inject({ from: 'apiService', default: new MARSApi() })
  // private apiService!: MARSApi;

  private loadingDataSet = false;

  // private uploading = false;
  // private fileList: Array<File> = [];
  // private applicationTrueCorrect = null as any;
  // private adverseAction = null as any;
  // private declarationDate = null as any;

  // private renewalDeclarationForm: any = {};

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  // @Watch('adverseAction')
  // private onAdverseActionChanged(): void {
  //   if (!this.adverseAction) {
  //     this.renewalDeclarationForm.adverseActionFiles = [];
  //   }
  //   this.syncData();
  // }

  // @Watch('applicationTrueCorrect')
  // private onApplicationTrueCorrectChanged(): void {
  //   this.syncData();
  // }

  // @Watch('declarationDate')
  // private onDeclarationDateChanged(): void {
  //   this.syncData();
  // }

  // @Watch('renewalDeclarationForm.adverseActionFiles.length')
  // private adverseActionFileListChanged(): void {
  //   this.syncData();
  // }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.isPcrApp = this.pcrFormData?.appId > 0;
    // this.preloadData();
    this.canGoNextCheck();
  }


  // private async preloadData(): Promise<void> {
  //   if (this.isPcrApp) {
  //     const recordLinkedApplicationId = this.pcrFormData?.preferenceDetail?.recordLinkedApplication?.linkedApplicationId;
  //     try {
  //       this.loadingDataSet = true;
  //       const resp: any = await Promise.all([
  //         this.apiService.PcRenewApi?.apiV1PcRenewGetRenewalDeclarationLinkedApplicationIdGet(recordLinkedApplicationId),
  //       ]);
  //       this.pcrFormData['sarRenewalDeclaration'] = resp[0].data.data;
  //       this.initSync();
  //       store.dispatch('setPcrFormData', this.pcrFormData);
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       this.canGoNextCheck();
  //       this.loadingDataSet = false;
  //     }
  //   } else {
  //     try {
  //       this.loadingDataSet = true;
  //       // const resp: any = await Promise.all([
  //       //   this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewGetRenewalDeclarationApplicationIdGet(this.onlineApplication.id)
  //       // ]);
  //       // this.sarAppFormData['sarRenewalDeclaration'] = resp[0].data.data;
  //       this.initSync();
  //       store.dispatch('setSarAppFormData', this.sarAppFormData);
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       this.canGoNextCheck();
  //       this.loadingDataSet = false;
  //     }
  //   }

  // }

  // private initSync(): void {
  //   if (this.isPcrApp) {
  //     this.renewalDeclarationForm = JSON.parse(JSON.stringify(this.pcrFormData['sarRenewalDeclaration']));
  //   } else {
  //     this.renewalDeclarationForm = JSON.parse(JSON.stringify(this.sarAppFormData['sarRenewalDeclaration']));
  //   }

  //   // if (!this.renewalDeclarationForm.adverseActionFiles) {
  //   //   this.renewalDeclarationForm.adverseActionFiles = [];
  //   // }

  //   // this.adverseAction = this.renewalDeclarationForm.renewalDeclarationFlag1 ?? null;
  //   // this.applicationTrueCorrect = this.renewalDeclarationForm.renewalDeclarationFlag2 ?? null;

  // }

  // private syncData(): void {
  //   try {
  //     if (!this.loadingDataSet) {
  //       this.renewalDeclarationForm.renewalDeclarationFlag1 = this.adverseAction;
  //       this.renewalDeclarationForm.renewalDeclarationFlag2 = this.applicationTrueCorrect;
  //       this.renewalDeclarationForm.declarationDate = this.declarationDate;
  //       if (this.isPcrApp) {
  //         this.pcrFormData['sarRenewalDeclaration'] = this.renewalDeclarationForm;
  //       }
  //       else {
  //         this.sarAppFormData['sarRenewalDeclaration'] = this.renewalDeclarationForm;
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   this.canGoNextCheck();
  // }

  // private fileListChanged(fileList: Array<File>): void {
  //   this.fileList = fileList;
  //   this.uploadAllFiles(fileList, 4);
  //   this.fileList = [];
  // }

  private isValidForm(): boolean {
    return true;
  }

  // private isAdverseActionValidForm(): boolean {
  //   if (this.adverseAction == null) {
  //     return false;
  //   } else if (this.adverseAction != null && this.adverseAction == false) {
  //     return true;
  //   } else if ((this.adverseAction != null && this.adverseAction == true) &&
  //     this.renewalDeclarationForm.adverseActionFiles.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  // private async uploadAllFiles(fileList: Array<File>, specAccFileType: number): Promise<void> {
  //   const allRequests: Array<Promise<boolean>> = [];
  //   this.uploading = true;
  //   try {
  //     if (fileList.length > 0) {
  //       fileList.forEach((x) => allRequests.push(this.uploadFile(x, specAccFileType)));
  //       await Promise.all(allRequests);
  //       this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
  //     }
  //   } catch (err: any) {
  //     this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
  //     this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
  //   } finally {
  //     this.syncData();
  //     this.uploading = false;
  //   }
  // }

  // private async uploadFile(file: File, specAccFileType: number): Promise<boolean> {
  //   const _applicationId = this.isPcrApp ? this.pcrFormData?.preferenceDetail?.recordLinkedApplication?.linkedApplicationId : this.onlineApplication.id;

  //   try {
  //     const fileStr = await this.fileToString(file);
  //     const body = {
  //       id: 0,
  //       fileName: file.name,
  //       fileExtension: file.name,
  //       fileDataUrl: fileStr,
  //       seed: 0,
  //       applicationId: _applicationId,
  //       specAccFileTypeId: specAccFileType,
  //       pcRenewLinkedApplicationFileTypeId: specAccFileType,
  //     };

  //     let resp: any;
  //     if (this.isPcrApp) {
  //       resp = await this.apiService.FileApi?.apiV1FileSavePcRenewLinkedApplicationFilePost(body);
  //     } else {
  //       resp = await this.apiService.FileApi?.apiV1FileSaveSpecAccFilePost(body);
  //     }
  //     switch (specAccFileType) {
  //       case 4:
  //         this.renewalDeclarationForm.adverseActionFiles.push({
  //           id: resp.data.data.data,
  //           fileName: file.name,
  //           fileExtension: file.name,
  //           // fileDataUrl: fileStr,
  //           applicationId: this.onlineApplication.id,
  //         })
  //         break;
  //     }

  //     return Promise.resolve(true);
  //   } catch (err) {
  //     console.log(err);
  //     return Promise.resolve(false);
  //   }
  // }

  // private fileToString(file: File): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       return resolve(reader.result as string);
  //     };

  //     reader.onabort = () => {
  //       return reject();
  //     }

  //     reader.onerror = () => {
  //       return reject();
  //     }
  //   });
  // }

  // private deleteFile(id: number) {
  //   const _fileIdToDelete = id;
  //   const _confirm: any = this.$refs.confirmDialogue;
  //   _confirm.show({
  //     title: 'Delete File',
  //     message: 'Are you sure you want to delete this file?',
  //     okButton: 'Delete',
  //     cancelButton: 'Cancel',
  //   }).then((res: any) => {
  //     if (res) {
  //       this.deleteFileFromServer(_fileIdToDelete);
  //     }
  //   })
  // }

  // private async deleteFileFromServer(id: number): Promise<void> {
  //   let files: any[] = [];
  //   files = this.renewalDeclarationForm.adverseActionFiles;
  //   try {
  //     const resp: any = await this.apiService.FileApi?.apiV1FileDeleteFileFileIdDelete(id);
  //     for (let i = 0; i < files.length; i++) {
  //       if (files[i].id === id) {
  //         files.splice(i, 1);
  //       }
  //     }
  //     this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');

  //   } catch (err) {
  //     this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
  //     this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);

  //   } finally {
  //     this.canGoNextCheck();
  //   }
  // }

  // private notToday(date: Date) {
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);
  //   return date > today || date < today;
  // }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

