import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_SnackBar = _resolveComponent("SnackBar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_SessionExpired = _resolveComponent("SessionExpired")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SnackBar, { store: _ctx.store }, null, 8, ["store"]),
    _createVNode(_component_router_view),
    (_ctx.sessionExpired)
      ? (_openBlock(), _createBlock(_component_SessionExpired, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = $event => (_ctx.sessionExpired = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}