import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import StepIndicator from "@/shared/components/step-indicator/index.vue";
import { Step } from "@/shared/components/step-indicator/step-indicator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import { Application, PcRenewFileRequest } from "@/shared/modules/typescript-api-client/models";
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import Popover from "@/shared/components/popover/index.vue";
import PcrWordingData from '@/assets/config/pcr-wording.json';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import moment from "moment";
import _ from "lodash";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading', 'update:pcrFormData'],
  components: {
    StepIndicator,
    LoadingIndicator,
    FileDragAndDrop,
    ConfirmDialogue,
    Popover,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrRenewalDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;
  private loadingDataSet = true;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  @Watch('files')
  private onFileListChanged() {
    this.isValidatedCheck(this.currentStepIndex);
  }

  @Watch('currentStepIndex')
  private onCurrentStepIndexChange() {
    this.isValidatedCheck(this.currentStepIndex);
  }

  // read wording from JSON file
  private step_1_q_1 = PcrWordingData[0]['renewals-details'].step_1_q_1;
  private step_1_a_1 = PcrWordingData[0]['renewals-details'].step_1_a_1;
  private step_1_a_2 = PcrWordingData[0]['renewals-details'].step_1_a_2;

  private step_2_q_1 = PcrWordingData[0]['renewals-details'].step_2_q_1;
  private step_2_a_1 = PcrWordingData[0]['renewals-details'].step_2_a_1;
  private step_2_a_2 = PcrWordingData[0]['renewals-details'].step_2_a_2;

  private step_3_q_1 = PcrWordingData[0]['renewals-details'].step_3_q_1;
  private step_3_a_1 = PcrWordingData[0]['renewals-details'].step_3_a_1;

  private step_3_a_2 = PcrWordingData[0]['renewals-details'].step_3_a_2;
  private step_3_a_2_c_1_title = PcrWordingData[0]['renewals-details'].step_3_a_2_c_1_title;
  private step_3_a_2_c_1 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_1.replaceAll('--[[CurrentApplicationYear]]--', this.currentApplicationYear);
  private step_3_a_2_c_1_notice = PcrWordingData[0]['renewals-details'].step_3_a_2_c_1_notice;
  private step_3_a_2_c_2_title = PcrWordingData[0]['renewals-details'].step_3_a_2_c_2_title;
  private step_3_a_2_c_2 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_2.replaceAll('--[[CurrentApplicationYear]]--', this.currentApplicationYear);
  private step_3_a_2_c_2_note = PcrWordingData[0]['renewals-details'].step_3_a_2_c_2_note;

  private step_3_a_2_c_3_note = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_note;
  private step_3_a_2_c_3 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3;
  private step_3_a_2_c_3_1 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_1;
  private step_3_a_2_c_3_2 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_2;
  private step_3_a_2_c_3_3 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_3;
  private step_3_a_2_c_3_4 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_4;
  private step_3_a_2_c_3_5 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_5;
  private step_3_a_2_c_3_6 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_6;
  private step_3_a_2_c_3_7 = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_7;
  private step_3_a_2_c_3_title = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_title;
  private step_3_a_2_c_3_footnote = PcrWordingData[0]['renewals-details'].step_3_a_2_c_3_footnote;

  private step_3_a_3 = PcrWordingData[0]['renewals-details'].step_3_a_3;
  private step_3_a_3_note = PcrWordingData[0]['renewals-details'].step_3_a_3_note.replaceAll('--[[CurrentApplicationYear]]--', this.currentApplicationYear).replaceAll('--[[LastApplicationYear]]--', this.lastApplicationYearFrom);

  private step_4_c_1 = PcrWordingData[0]['renewals-details'].step_4_c_1;
  private step_4_c_2a = PcrWordingData[0]['renewals-details'].step_4_c_2a;
  private step_4_c_2b = PcrWordingData[0]['renewals-details'].step_4_c_2b;
  private step_4_c_3 = PcrWordingData[0]['renewals-details'].step_4_c_3;
  private step_4_c_4 = PcrWordingData[0]['renewals-details'].step_4_c_4;

  private currentStepIndex = 0;
  private isValidated = false;
  private newSeed = 1;

  private steps: Step[] = [
    { name: '1', description: 'Fit and proper person', completed: false, current: true },
    { name: '2', description: 'Show cause events', completed: false, current: false },
    { name: '3', description: 'Continuing professional development', completed: false, current: false, popoverKey: 'ContinuingProfessionalDevelopment' },
    { name: '4', description: 'Declaration', completed: false, current: false },
  ]

  private uploading = false;
  private requiredFileUpload = false;
  private files: any[] = [];
  private fileList: Array<File> = [];
  private fileIdToDelete = 0;

  private radio_step1 = -1;
  private radio_step2 = -1;
  private radio_step3 = -1;
  private radio_step4 = -1;

  private check_1 = false;
  // private check_2 = false;
  // private check_3 = false;
  // private check_4 = false;
  // private check_5 = false;

  private check_s3_c1 = false;
  private check_s3_c2 = false;
  private check_s3_c3 = false;
  private check_s3_c3_1 = false;
  private check_s3_c3_2 = false;
  private check_s3_c3_3 = false;
  private check_s3_c3_4 = false;
  private check_s3_c3_5 = false;
  private check_s3_c3_6 = false;
  private check_s3_c3_7 = false;

  private textarea_1 = '';
  private textarea_2 = '';
  // private textarea_3 = '';
  // private textarea_3_check = false;

  @Watch('radio_step1')
  private onRadioStep1Changed(): void {
    if (this.radio_step1 == 0) {
      this.files = this.files.filter(x => x.PcRenewFileTypeId !== 2);
    }
  }

  @Watch('radio_step2')
  private onRadioStep2Changed(): void {
    if (this.radio_step2 == 0) {
      this.files = this.files.filter(x => x.PcRenewFileTypeId !== 3);
    }
  }

  mounted(): void {
    window.scrollTo(0, 0);
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    this.loadRenewDetails();
    this.canGoNextCheck();
  }

  private isValidatedCheck(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        this.isValidated = this.radio_step1 == 0 || (this.radio_step1 == 1 && this.fitAndProperPersonFileList.length > 0);
        break;
      case 1:
        this.isValidated = this.radio_step2 == 0 || (this.radio_step2 == 1 && this.showCauseEventsFileList.length > 0);
        break;
      case 2:
        this.isValidated = this.isStep3Valid();
        break;
      case 3:
        // this.textarea_3_check = this.textarea_3.trim() !== '';
        // this.isValidated = this.check_1 && (this.check_2 || this.check_3 && this.textarea_3_check) && this.check_4 && this.check_5;
        this.isValidated = this.check_1;
        break;
    }
    this.steps[stepIndex].completed = this.isValidated;
    this.canGoNextCheck();
  }

  private isStep3Valid(): boolean {

    let _cond1 = false;
    let _cond2 = false;
    let _cond3 = false;

    if (this.radio_step3 == 1 || this.radio_step3 == 3) {
      return true;
    } else if (this.radio_step3 == 2) {

      if ((this.check_s3_c1 && this.check_s3_c2 && (typeof this.textarea_1 == 'string' && this.textarea_1.length != 0) || !this.check_s3_c1)) {
        _cond1 = true;
      } else {
        _cond1 = false;
      }

      if ((this.check_s3_c3 && this.atLeastOneRuleSelected() &&
        (typeof this.textarea_2 == 'string' && this.textarea_2.length != 0)) || !this.check_s3_c3) {
        _cond2 = true
      } else {
        _cond2 = false;
      }

      _cond3 = !this.check_s3_c1 && !this.check_s3_c3;

      return _cond1 && _cond2 && !_cond3;
    } else {
      return false;
    }
  }

  private atLeastOneRuleSelected(): boolean {
    return this.check_s3_c3_1 ||
      this.check_s3_c3_2 ||
      this.check_s3_c3_3 ||
      this.check_s3_c3_4 ||
      this.check_s3_c3_5 ||
      this.check_s3_c3_6 ||
      this.check_s3_c3_7;
  }

  private stepBack() {
    this.steps[this.currentStepIndex].current = false;
    this.steps[this.currentStepIndex].completed = false;
    this.currentStepIndex--;
    this.steps[this.currentStepIndex].current = true;
    this.syncData();
    // this.eventCtrl.emit('canGoNext', false);
  }

  private nextStep() {
    this.currentStepIndex++;
    for (let i = 0; i < this.currentStepIndex; i++) {
      this.steps[i].completed = true;
      this.steps[i].current = false;
    }
    this.steps[this.currentStepIndex].completed = false;
    this.steps[this.currentStepIndex].current = true;
    this.syncData();
  }

  private fileListChanged(fileList: Array<File>, pcRenewFileType: number): void {
    this.fileList = fileList;
    this.uploadAllFiles(this.fileList, pcRenewFileType);
  }

  private deleteFile(id: number) {
    this.fileIdToDelete = id;
    const _confirm: any = this.$refs.confirmDialogue;
    _confirm.show({
      title: 'Delete File',
      message: 'Are you sure you want to delete this file?',
      okButton: 'Delete',
      cancelButton: 'Cancel',
    }).then((res: any) => {
      if (res) {
        this.deleteFileFromServer(this.fileIdToDelete);
      }
    })

  }

  private deleteFileFromServer(id: number) {
    this.apiService.FileApi?.apiV1FileDeleteFileFileIdDelete(id)
      .then(res => {
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].id === id) {
            this.files.splice(i, 1);
          }
        }
        this.isValidatedCheck(this.currentStepIndex);
        this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');
      })
      .catch(err => {
        this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
        this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);
      })
  }


  private async uploadAllFiles(fileList: Array<File>, pcRenewFileType: number): Promise<void> {
    const allRequests: Array<Promise<boolean>> = [];
    this.uploading = true;
    try {
      if (fileList.length > 0) {
        fileList.forEach((x) => allRequests.push(this.uploadFile(x, pcRenewFileType)));
        await Promise.all(allRequests);
        this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
      }
    } catch (err: any) {
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
    } finally {
      this.uploading = false;
    }
  }

  private async uploadFile(file: File, pcRenewFileType: number): Promise<boolean> {
    // const _id: number = parseInt(this.$route.query.investigation as string, 10);
    this.getNextSeed();
    try {
      const fileStr = await this.fileToString(file);
      const body = {
        id: 0,
        fileName: file.name,
        fileExtension: file.name,
        fileDataUrl: fileStr,
        seed: this.newSeed,
        applicationId: this.pcrApp.id,
        PcRenewFileTypeId: pcRenewFileType, // 1, name Change, 2: Fit and Proper, 3: Show Cause Events.
      };

      await this.apiService.FileApi?.apiV1FileSavePcRenewFilePost(
        body
      ).then((res: any) => {
        this.files.push({
          id: res.data.data.data,
          fileName: file.name,
          fileExtension: file.name,
          // fileDataUrl: fileStr,
          seed: this.newSeed,
          applicationId: this.pcrApp.id,
          PcRenewFileTypeId: pcRenewFileType, // 1, name Change, 2: Fit and Proper, 3: Show Cause Events.
        });
        this.isValidatedCheck(this.currentStepIndex);
      })
      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  private fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result as string);
      };

      reader.onabort = () => {
        return reject();
      }

      reader.onerror = () => {
        return reject();
      }
    });
  }

  private async loadRenewDetails(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await this.apiService.PcRenewApi?.apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(this.pcrApp.id);
      this.pcrFormData.renewDetails = resp.data.data;
      this.initSync();

      this.steps.forEach((x, i) => {
        this.steps[i].completed = false;
      });
      this.steps[0].current = true;

    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get renew details: ' + err);
      this.ai.trackException('Error, Unable to get renew details: ' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private initSync(): void {

    if (this.pcrFormData.renewDetails?.fitAndProperFlag == null) {
      this.radio_step1 = -1;
    } else if (this.pcrFormData.renewDetails?.fitAndProperFlag) {
      this.radio_step1 = 1;
    } else {
      this.radio_step1 = 0;
    }

    if (this.pcrFormData.renewDetails?.showCauseEventsFlag == null) {
      this.radio_step2 = -1;
    } else if (this.pcrFormData.renewDetails?.showCauseEventsFlag) {
      this.radio_step2 = 1;
    } else {
      this.radio_step2 = 0;
    }

    this.radio_step3 = this.pcrFormData.renewDetails?.pcRenewCpdAnswerTypeId ?? -1;
    this.check_s3_c1 = this.pcrFormData.renewDetails?.cpdQuestionFlag1;
    this.check_s3_c2 = this.pcrFormData.renewDetails?.cpdQuestionFlag2;
    this.textarea_1 = this.pcrFormData.renewDetails?.cpdNonComplianceNote ?? '';
    this.check_s3_c3 = this.pcrFormData.renewDetails?.cpdQuestionExemptionFlag;
    this.check_s3_c3_1 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag1;
    this.check_s3_c3_2 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag2;
    this.check_s3_c3_3 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag3;
    this.check_s3_c3_4 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag4;
    this.check_s3_c3_5 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag5;
    this.check_s3_c3_6 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag6;
    this.check_s3_c3_7 = this.pcrFormData.renewDetails?.cpdQuestionExemptionRuleFlag7;
    this.textarea_2 = this.pcrFormData.renewDetails?.cpdQuestionExemptionNote ?? '';
    this.check_1 = this.pcrFormData.renewDetails?.pcRenewalDeclarationFlag1;
    // this.check_2 = this.pcrFormData.renewDetails?.pcRenewalDeclarationFlag2;
    // this.check_3 = this.pcrFormData.renewDetails?.pcRenewalDeclarationFlag3;
    // this.check_4 = this.pcrFormData.renewDetails?.pcRenewalDeclarationFlag4;
    // this.check_5 = this.pcrFormData.renewDetails?.pcRenewalDeclarationFlag5;
    // this.textarea_3 = this.pcrFormData.renewDetails?.pcRenewalDeclarationNote ?? '';
    if (this.pcrFormData.renewDetails?.fitAndProperFiles?.length > 0) {
      this.files = [
        ...this.files,
        ...this.pcrFormData.renewDetails?.fitAndProperFiles.map((x: any) => {
          return {
            id: x.id,
            fileName: x.originalFileName,
            seed: x.seed,
            applicationId: this.pcrApp.id,
            PcRenewFileTypeId: 2,
          }
        })];
    } else {
      this.pcrFormData.renewDetails.fitAndProperFiles = [];
    }

    if (this.pcrFormData.renewDetails?.showCauseEventsFiles?.length > 0) {
      this.files = [
        ...this.files,
        ...this.pcrFormData.renewDetails?.showCauseEventsFiles.map((x: any) => {
          return {
            id: x.id,
            fileName: x.originalFileName,
            seed: x.seed,
            applicationId: this.pcrApp.id,
            PcRenewFileTypeId: 3,
          }
        })];
    } else {
      this.pcrFormData.renewDetails.showCauseEventsFiles = [];
    }
    // console.log(this.files);
  }

  private syncData(): void {
    if (!this.loadingDataSet) {
      this.pcrFormData.renewDetails.fitAndProperFlag = this.radio_step1 == 1;
      this.pcrFormData.renewDetails.showCauseEventsFlag = this.radio_step2 == 1;
      this.pcrFormData.renewDetails.pcRenewCpdAnswerTypeId = this.radio_step3;
      this.pcrFormData.renewDetails.cpdQuestionFlag1 = this.check_s3_c1;
      this.pcrFormData.renewDetails.cpdQuestionFlag2 = this.check_s3_c2;
      this.pcrFormData.renewDetails.cpdNonComplianceNote = this.textarea_1;
      this.pcrFormData.renewDetails.cpdQuestionExemptionFlag = this.check_s3_c3;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag1 = this.check_s3_c3_1;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag2 = this.check_s3_c3_2;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag3 = this.check_s3_c3_3;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag4 = this.check_s3_c3_4;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag5 = this.check_s3_c3_5;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag6 = this.check_s3_c3_6;
      this.pcrFormData.renewDetails.cpdQuestionExemptionRuleFlag7 = this.check_s3_c3_7;
      this.pcrFormData.renewDetails.cpdQuestionExemptionNote = this.textarea_2;
      this.pcrFormData.renewDetails.pcRenewalDeclarationFlag1 = this.check_1;
      // this.pcrFormData.renewDetails.pcRenewalDeclarationFlag2 = this.check_2;
      // this.pcrFormData.renewDetails.pcRenewalDeclarationFlag3 = this.check_3;
      // this.pcrFormData.renewDetails.pcRenewalDeclarationFlag4 = this.check_4;
      // this.pcrFormData.renewDetails.pcRenewalDeclarationFlag5 = this.check_5;
      // this.pcrFormData.renewDetails.pcRenewalDeclarationNote = this.textarea_3;
      this.pcrFormData.renewDetails.fitAndProperFiles = this.fitAndProperPersonFileList;
      this.pcrFormData.renewDetails.showCauseEventsFiles = this.showCauseEventsFileList;
    }
  }

  get fitAndProperPersonFileList(): any {
    return this.files.filter(x => x.PcRenewFileTypeId == 2);
  }

  get showCauseEventsFileList(): any {
    return this.files.filter(x => x.PcRenewFileTypeId == 3);
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  get currentApplicationYear(): string {
    const _currentAppYear = store.getters.getAppInfo.applicationYear?.effectiveStartDate;
    return moment(_currentAppYear).format('YYYY');
  }

  get lastApplicationYearFrom(): string {
    const _lastAppYear = store.getters.getAppInfo.lastApplicationYear?.effectiveStartDate;
    return moment(_lastAppYear).format('YYYY');
  }

  private canGoNextCheck() {
    this.syncData();
    this.eventCtrl.emit('canGoNext', this.steps[0].completed && this.steps[1].completed && this.steps[2].completed && this.steps[3].completed);
  }

  private getNextSeed() {
    const fileList = this.currentStepIndex == 0 ? this.fitAndProperPersonFileList : this.showCauseEventsFileList;
    this.newSeed = fileList.length > 0
      ? (_.maxBy(fileList, (f: PcRenewFileRequest) => f.seed) as PcRenewFileRequest).seed + 1
      : 1;
  }

}

