export type AuthenticationStatus = AuthenticationStatusType.Unknown | AuthenticationStatusType.NotLogin |
  AuthenticationStatusType.LoggedIn | AuthenticationStatusType.AppAccessPermitted |
  AuthenticationStatusType.AppAccessDenied;

export enum AuthenticationStatusType {
  Unknown,
  NotLogin,
  LoggedIn,
  AppAccessDenied,
  AppAccessPermitted,
}
