import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ff0bc63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm-dialog" }
const _hoisted_2 = {
  key: 0,
  class: "popup-modal"
}
const _hoisted_3 = { class: "dialog-box" }
const _hoisted_4 = { style: {"text-align":"center"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "btns right" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("i", {
                  class: "fal fa-times close",
                  onClick: _cache[0] || (_cache[0] = (...args) => (_ctx._cancel && _ctx._cancel(...args)))
                }),
                _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
                _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn btn-tertiary sml",
                    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx._cancel && _ctx._cancel(...args)))
                  }, _toDisplayString(_ctx.cancelButton), 1),
                  _createElementVNode("button", {
                    class: "btn btn-secondary sml ml-2",
                    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx._confirm && _ctx._confirm(...args)))
                  }, _toDisplayString(_ctx.okButton), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}