import { Options, Vue } from 'vue-class-component';
import { PageContent } from '@/shared/modules/typescript-api-client/models';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
@Options({
  props: {
    helpContent: Object
  },
  components: {
  },
})
export default class HelpScreen extends Vue {
  private helpContent!: PageContent;
  private isClosing = false;

  mounted(): void {
    document.body.classList.add('modal-open');
    document.addEventListener('keydown', this.onEscape);
  }

  beforeUnmount(): void {
    document.body.classList.remove('modal-open');
    document.removeEventListener('keydown', this.onEscape);
  }

  private onEscape(e: KeyboardEvent) {
    if (e.key.toLowerCase() === 'escape') {
      this.close();
    }
  }

  private close() {
    this.isClosing = true;
    setTimeout(() => {
      this.$emit('close');
    }, 500);
  }

}
