import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34607d2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "std-page-layout-main" }

export function render(_ctx, _cache) {
  const _component_PageHeader = _resolveComponent("PageHeader")
  const _component_PageFooter = _resolveComponent("PageFooter")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      showAppMenu: _ctx.showAccountControl,
      isExternal: _ctx.isExternal
    }, null, 8, ["showAppMenu", "isExternal"]),
    _renderSlot(_ctx.$slots, "body-content", {}, undefined, true),
    _createVNode(_component_PageFooter, {
      baseYear: _ctx.baseYear,
      currentYear: _ctx.currentYear,
      class: "footer"
    }, null, 8, ["baseYear", "currentYear"])
  ]))
}