import { Options, Vue } from 'vue-class-component';
// import { UserAccount } from '@/components/user/user-account';
import { RouteN, RouteName } from '@/shared/constants/route-name';
import { Prop } from 'vue-property-decorator';
import { SectionName, Section } from '@/shared/constants/section-name';
import UserDefine from '@/assets/config/user-define.json';
import store from '@/shared/store';
import { EventEmitter } from 'events';

@Options({})
export default class ApplicationMenuComponent extends Vue {

  @Prop({ required: true })
  public show!: boolean;

  @Prop({ default: null })
  private isExternal!: boolean;

  // get userAccount(): UserAccount {
  //   return this.$store.getters.getUserAccount;
  // }

  get RouteN(): RouteName {
    return RouteN;
  }

  get Section(): SectionName {
    return Section;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private newContract(type: number) {
    this.eventCtrl.emit('new-contract', type);
  }

  private goToFAQ() {
    window.open(UserDefine[0].faq_url, 'blank');
  }

  private goToAboutDCS() {
    // window.open(UserDefine[0].about_dcs_url, 'blank');
  }

  private goToContactUs() {
    window.open(UserDefine[0].contact_us_url, 'blank');
  }

}
