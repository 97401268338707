/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AmNewFileRequest } from '../models';
import { AmRenewFileRequest } from '../models';
import { BooleanResponse } from '../models';
import { FileResponse } from '../models';
import { Int32Response } from '../models';
import { PcRenewFileRequest } from '../models';
import { RecordLinkedApplicationFileRequest } from '../models';
import { SarFileRequest } from '../models';
import { StudentNewFileRequest } from '../models';
import { StudentRenewFileRequest } from '../models';
/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Delete File
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileDeleteFileFileIdDelete: async (fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling apiV1FileDeleteFileFileIdDelete.');
            }
            const localVarPath = `/api/v1/File/DeleteFile/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileDownloadStreamFileIdGet: async (fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling apiV1FileDownloadStreamFileIdGet.');
            }
            const localVarPath = `/api/v1/File/Download-stream/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileGetFileFileIdGet: async (fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling apiV1FileGetFileFileIdGet.');
            }
            const localVarPath = `/api/v1/File/GetFile/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmNew FileId
         * @param {AmNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveAmNewFilePost: async (body?: AmNewFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SaveAmNewFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew FileId
         * @param {AmRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveAmRenewFilePost: async (body?: AmRenewFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SaveAmRenewFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns PcRenew FileId
         * @param {PcRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSavePcRenewFilePost: async (body?: PcRenewFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SavePcRenewFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Linked Application File
         * @param {RecordLinkedApplicationFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSavePcRenewLinkedApplicationFilePost: async (body?: RecordLinkedApplicationFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SavePcRenewLinkedApplicationFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Spec Acc FileId
         * @param {SarFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveSpecAccFilePost: async (body?: SarFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SaveSpecAccFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns StudentNew FileId
         * @param {StudentNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveStudentNewFilePost: async (body?: StudentNewFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SaveStudentNewFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew FileId
         * @param {StudentRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveStudentRenewFilePost: async (body?: StudentRenewFileRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/File/SaveStudentRenewFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Delete File
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileDeleteFileFileIdDelete(fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileDeleteFileFileIdDelete(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileDownloadStreamFileIdGet(fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileDownloadStreamFileIdGet(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileGetFileFileIdGet(fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileGetFileFileIdGet(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmNew FileId
         * @param {AmNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSaveAmNewFilePost(body?: AmNewFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSaveAmNewFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew FileId
         * @param {AmRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSaveAmRenewFilePost(body?: AmRenewFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSaveAmRenewFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns PcRenew FileId
         * @param {PcRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSavePcRenewFilePost(body?: PcRenewFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSavePcRenewFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Linked Application File
         * @param {RecordLinkedApplicationFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSavePcRenewLinkedApplicationFilePost(body?: RecordLinkedApplicationFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSavePcRenewLinkedApplicationFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Spec Acc FileId
         * @param {SarFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSaveSpecAccFilePost(body?: SarFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSaveSpecAccFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns StudentNew FileId
         * @param {StudentNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSaveStudentNewFilePost(body?: StudentNewFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSaveStudentNewFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew FileId
         * @param {StudentRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FileSaveStudentRenewFilePost(body?: StudentRenewFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await FileApiAxiosParamCreator(configuration).apiV1FileSaveStudentRenewFilePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Delete File
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileDeleteFileFileIdDelete(fileId: number, options?: any): AxiosPromise<BooleanResponse> {
            return FileApiFp(configuration).apiV1FileDeleteFileFileIdDelete(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileDownloadStreamFileIdGet(fileId: number, options?: any): AxiosPromise<string> {
            return FileApiFp(configuration).apiV1FileDownloadStreamFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileGetFileFileIdGet(fileId: number, options?: any): AxiosPromise<FileResponse> {
            return FileApiFp(configuration).apiV1FileGetFileFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmNew FileId
         * @param {AmNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveAmNewFilePost(body?: AmNewFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSaveAmNewFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmRenew FileId
         * @param {AmRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveAmRenewFilePost(body?: AmRenewFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSaveAmRenewFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns PcRenew FileId
         * @param {PcRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSavePcRenewFilePost(body?: PcRenewFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSavePcRenewFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Linked Application File
         * @param {RecordLinkedApplicationFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSavePcRenewLinkedApplicationFilePost(body?: RecordLinkedApplicationFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSavePcRenewLinkedApplicationFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Spec Acc FileId
         * @param {SarFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveSpecAccFilePost(body?: SarFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSaveSpecAccFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns StudentNew FileId
         * @param {StudentNewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveStudentNewFilePost(body?: StudentNewFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSaveStudentNewFilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns StudentRenew FileId
         * @param {StudentRenewFileRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FileSaveStudentRenewFilePost(body?: StudentRenewFileRequest, options?: any): AxiosPromise<Int32Response> {
            return FileApiFp(configuration).apiV1FileSaveStudentRenewFilePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary External | Delete File
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileDeleteFileFileIdDelete(fileId: number, options?: any) {
        return FileApiFp(this.configuration).apiV1FileDeleteFileFileIdDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns file as stream
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileDownloadStreamFileIdGet(fileId: number, options?: any) {
        return FileApiFp(this.configuration).apiV1FileDownloadStreamFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns file
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileGetFileFileIdGet(fileId: number, options?: any) {
        return FileApiFp(this.configuration).apiV1FileGetFileFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmNew FileId
     * @param {AmNewFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSaveAmNewFilePost(body?: AmNewFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSaveAmNewFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmRenew FileId
     * @param {AmRenewFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSaveAmRenewFilePost(body?: AmRenewFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSaveAmRenewFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns PcRenew FileId
     * @param {PcRenewFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSavePcRenewFilePost(body?: PcRenewFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSavePcRenewFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Linked Application File
     * @param {RecordLinkedApplicationFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSavePcRenewLinkedApplicationFilePost(body?: RecordLinkedApplicationFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSavePcRenewLinkedApplicationFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Spec Acc FileId
     * @param {SarFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSaveSpecAccFilePost(body?: SarFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSaveSpecAccFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns StudentNew FileId
     * @param {StudentNewFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSaveStudentNewFilePost(body?: StudentNewFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSaveStudentNewFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns StudentRenew FileId
     * @param {StudentRenewFileRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FileSaveStudentRenewFilePost(body?: StudentRenewFileRequest, options?: any) {
        return FileApiFp(this.configuration).apiV1FileSaveStudentRenewFilePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
