import { Module } from "vuex";

const amnAppFormDataStoreModule: Module<any, any> = {
  state: {
    amnAppFormData: {}
  },
  mutations: {
    setAmnAppFormData(state, fd) {
      state.amnAppFormData = fd;
    }
  },
  actions: {
    setAmnAppFormData({ commit }, fd) {
      commit('setAmnAppFormData', fd);
    }
  },
  getters: {
    getAmnAppFormData: (state) => state.amnAppFormData
  }
}
export default amnAppFormDataStoreModule;