import { Options, Vue } from "vue-property-decorator";
import Popover from "../popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';

export interface Step {
  name: string,
  description: string,
  current: boolean,
  completed: boolean,
  popoverKey?: string,
}
@Options({
  props: {
    steps: Object,
    currentStepIndex: Number,
  },
  components: {
    Popover,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class StepIndicator extends Vue {
  private steps!: Step[];
  private currentStepIndex!: number;

  // get shift(): number {
  //   if (window.innerWidth < 780) {
  //     console.log(window.innerWidth);
  //     return 5;
  //   }
  //   return 0;
  // }

  get styleClass() {
    if (this.currentStepIndex !== -1) {
      return 'class-' + this.currentStepIndex;
    } else {
      return '';
    }
  }

}

