import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { SuburbStatePostcode } from "@/shared/modules/typescript-api-client/models";
import { debounce } from "lodash";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";

@Options({
  props: {
    suburbStatePostcode: Object,
    suburbError: Boolean,
  },
  components: {}
})
export default class PostcodeSearch extends Vue {
  private suburbStatePostcode!: SuburbStatePostcode;
  private suburbError!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private query = '';
  private isSearching = false;
  private isOpen = false;
  private isPostcodeSearching = false;
  private isPostcodeOpen = false;
  private isSuburbValid = true;
  private formDirty = false;

  private arrowCounter = -1;
  private searchDebounced: any = null;

  private suburbList = [];
  private postcodeList = [];
  private canSubmit = true;

  private suburbName = '';
  private postcode = '';
  private stateName = '';

  @Watch('query')
  private onKeywordChange(): void {
    setTimeout(this.searchDebounced.cancel, 0);
    setTimeout(this.searchDebounced, 0);
  }

  mounted(): void {
    this.suburbName = this.suburbStatePostcode.suburbName;
    this.postcode = this.suburbStatePostcode.postcode;
    this.stateName = this.suburbStatePostcode.stateName;
    document.addEventListener('click', this.handleClickOutside);

    this.searchDebounced = debounce(() => {
      if (this.query.trim() !== '') {
        this.getSuburbPostcodeList();
      }
    }, 500);
  }

  beforeUnmount(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  private handleClickOutside(event: any) {
    if (this.$el !== (event.target)) {
      this.isOpen = false;
      this.arrowCounter = -1;
    }
  }

  private getSuburbPostcodeList() {
    // console.log('form is dirty: ', this.formDirty);
    if (this.formDirty) {
      if (this.query.length == 4 && parseInt(this.query) > 999) {
        this.getPostcodeList();
      } else {
        this.getSuburbList();
      }
    }
  }

  private getSuburbList() {
    if (this.query.length > 2) {
      this.isSearching = true;
      const body: any = { 'keyword': this.query.trim() }
      this.apiService.CodeApi?.apiV1CodeGetSuburbStatePostcodePost(body)
        .then((res: any) => {
          this.processResult(res);
        })
        .catch((err: any) => {
          this.ai.trackException('Error getting suburb list: ' + err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error getting suburb list: ' + err.response.status);
          this.isSearching = false;
        })
    }

  }

  private getPostcodeList() {
    if (this.query.length == 4) {
      this.isSearching = true;
      const body: any = { 'keyword': this.query.trim() }
      this.apiService.CodeApi?.apiV1CodeGetSuburbStatePostcodePost(body)
        .then((res: any) => {
          this.processResult(res);
        })
        .catch((err: any) => {
          this.ai.trackException('Error getting Postcode list: ' + err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error getting Postcode List: ' + err.response.status);
          this.isSearching = false;
        })
    }
  }

  private processResult(res: any) {
    // const el = this.$refs.suburbName as HTMLElement;
    this.suburbList = [];
    this.suburbList = res.data.data;
    this.isSearching = false;
    // console.log('suburbList.length: ', this.suburbList.length);
    if (this.suburbList.length > 0) {
      this.arrowCounter = -1;
      this.isOpen = this.suburbList.length > 0;
      // el.className = 'has-success';
      // console.log('suburbList: ', this.suburbList);
    } else {
      // console.log('Not result return', this.suburbList.length);
      this.isOpen = false;
      // el.className = 'has-error';
      this.canSubmit = false;
    }
  }


  private highlightWord(word: string) {
    try {
      const regex = new RegExp('(' + this.query + ')', 'ig');
      return word.replace(regex, '<mark>$1</mark>');
    } catch (err) {
      return word;
    }
  }

  private onArrowDown() {
    if (this.suburbList.length > 0) {
      if (this.arrowCounter < this.suburbList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    } else {
      if (this.arrowCounter < this.postcodeList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    this.formDirty = false;
    this.isOpen = false;
    if (this.suburbList.length > 0) {
      this.selectSuburbFromSearch(
        this.suburbList[this.arrowCounter === -1 ? 0 : this.arrowCounter]
      );
    }
  }

  private selectSuburbFromSearch(suburb: SuburbStatePostcode) {
    this.isOpen = false;
    this.suburbName = suburb.suburbName;
    this.postcode = suburb.postcode;
    this.stateName = suburb.stateName;
    const _suburb: SuburbStatePostcode = {
      suburbId: suburb.suburbId,
      suburbName: suburb.suburbName,
      postcode: suburb.postcode,
      postCodeSuburbId: suburb.postCodeSuburbId,
      stateId: suburb.stateId,
      stateName: suburb.stateName,
      state: suburb.state,
    }
    this.$emit('updateSuburb', _suburb);
    this.query = '';
  }

}

