declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $convertToDollarValue: (v: number) => string,
  }
}

export default {
  methods: {
    $convertToDollarValue(v: number): string {
      try {
        const formatter = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
          minimumFractionDigits: 2
        });
        return formatter.format(v);
      } catch (err) {
        return '0.00';
      }
    }
  },
};
