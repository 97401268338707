import { Options, Vue } from 'vue-class-component';
import { NavStep, NavStepStatusID } from './nav-step';
import HelpScreen from '@/shared/components/help-screen/index.vue';
import UserDefine from '@/assets/config/user-define.json';
import { Watch } from 'vue-property-decorator';
import { PageContent } from '@/shared/modules/typescript-api-client/models';
import store from '@/shared/store';
import { EventEmitter } from 'events';

@Options({
  props: {
    navSteps: Array,
    navbarText: String,
    showHelpBtn: Boolean,
    showFaqBtn: Boolean,
  },
  components: {
    HelpScreen,
  }
})
export default class SwipeStepper extends Vue {
  private showHelpScreen = false;
  navSteps!: NavStep[];
  navbarText!: string;
  showHelpBtn!: boolean;
  showFaqBtn!: boolean;

  private distance = 0;

  private helpContent: PageContent = {
    title: 'Page title here',
    content: 'Help content here',
    isShow: false,
    key: '',
  }

  get section(): string {
    return this.$route.query.sec as string;
  }

  get helpContentData(): unknown {
    const _helpContentData: any = [{}];
    return _helpContentData;
  }

  private navigate(s: NavStep): void {
    const _section: string = this.$route.query.sec as string;
    if (s.status === NavStepStatusID.Done && _section !== 'confirmation') {
      const temp: string = JSON.stringify(this.$route.query);
      const tempJSON = JSON.parse(temp);
      tempJSON.sec = s.routeSecName;
      this.$router.push({ query: tempJSON });
    }
  }

  private navigateBack() {
    const index = this.currentNavStepIndex;
    if (index > 0) {
      // console.log(this.navSteps[index - 1].routeSecName);
      this.navigate(this.navSteps[index - 1]);
    }
  }

  get currentNavStepIndex(): number {
    let index = -1;
    this.navSteps.filter((z) => z.visible).forEach((x, i) => {
      if (x.status === NavStepStatusID.Current) {
        index = i;
      }
    });
    return index;
  }

  @Watch('currentNavStepIndex')
  private onNavStepsChanged() {
    setTimeout(() => {
      this.scrollToItem();
    }, 1000)

  }

  private startY: any = null;
  private startX: any = null;
  private scrollLeft: any = null;
  private scrollTop: any = null;
  private isDown = false;
  private menuInnerBox: any | null = null;

  mounted() {
    this.menuInnerBox = this.$refs.menuInnerBox as HTMLElement;
    this.menuInnerBox.addEventListener('mousedown', (e: MouseEvent) => this.mouseIsDown(e));
    this.menuInnerBox.addEventListener('mouseup', (e: MouseEvent) => this.mouseUp(e))
    this.menuInnerBox.addEventListener('mouseleave', (e: MouseEvent) => this.mouseLeave(e));
    this.menuInnerBox.addEventListener('mousemove', (e: MouseEvent) => this.mouseMove(e));
    this.eventCtrl.on('updateHelpContent', this.updateHelpContent);
  }

  beforeUnmount(): void {
    this.menuInnerBox.removeEventListener('mousedown', (e: MouseEvent) => this.mouseIsDown(e));
    this.menuInnerBox.removeEventListener('mouseup', (e: MouseEvent) => this.mouseUp(e))
    this.menuInnerBox.removeEventListener('mouseleave', (e: MouseEvent) => this.mouseLeave(e));
    this.menuInnerBox.removeEventListener('mousemove', (e: MouseEvent) => this.mouseMove(e));
    this.eventCtrl.removeListener('updateHelpContent', this.updateHelpContent);
  }

  private updateHelpContent(content: any) {
    this.helpContent = content;
    // console.log('nav bar updateHelpContent: ', this.helpContent);
  }

  private mouseIsDown(e: MouseEvent) {
    this.isDown = true;
    this.startY = e.pageY - this.menuInnerBox.offsetTop;
    this.startX = e.pageX - this.menuInnerBox.offsetLeft;
    this.scrollLeft = this.menuInnerBox.scrollLeft;
    this.scrollTop = this.menuInnerBox.scrollTop;
    this.menuInnerBox.classList.add('dragging');
  }

  private mouseUp(e: MouseEvent) {
    this.isDown = false;
    this.menuInnerBox.classList.remove('dragging');
  }

  private mouseLeave(e: MouseEvent) {
    this.isDown = false;
    this.menuInnerBox.classList.remove('dragging');
  }

  private mouseMove(e: MouseEvent) {
    if (this.isDown) {
      e.preventDefault();

      //Move vertcally
      // const y = e.pageY - container.offsetTop;
      // const walkY = y - startY;
      // container.scrollTop = scrollTop - walkY;

      //Move Horizontally
      const x = e.pageX - this.menuInnerBox.offsetLeft;
      const walkX = x - this.startX;
      this.menuInnerBox.scrollLeft = this.scrollLeft - walkX;

    }
  }

  private scrollToItem() {
    const _menuInnerBox = this.$refs.menuInnerBox as HTMLElement;
    const navItem = document.querySelector('li.current') as HTMLElement;
    _menuInnerBox.scrollTo(navItem?.offsetLeft - 50, 0);
  }

  private scroll(distance: number) {
    const _menuInnerBox = this.$refs.menuInnerBox as HTMLElement;
    for (let i = 0; i < 500; i++) {
      setTimeout(() => {
        _menuInnerBox.scrollLeft += distance / 50;
      }, 10 * i);
    }
  }

  private contactUs() {
    window.open(UserDefine[0].contact_us_url, 'blank');
  }

  private faqLink() {
    const _pathName: string = this.$route.path;
    let fqaLink = UserDefine[0].faq_url;
    if (_pathName.indexOf('/amr-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/AMR-faq';
    } else if (_pathName.indexOf('/amn-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/AMN-faq';
    } else if (_pathName.indexOf('/stn-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/legal-communities/law-students/student-members';
    } else if (_pathName.indexOf('/str-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/legal-communities/law-students/student-members';
    } else if (_pathName.indexOf('/sar-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/SAR-FAQ';
    }
    window.open(fqaLink, 'blank');
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

}

