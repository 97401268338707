import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import PcrPracticeRecordCard from "../pcr-practice-record-card/index.vue";
import PcrPracticeRecordChangeDetailModal from "../pcr-practice-record-change-detail-modal/index.vue";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AddressDetail, Application, RecordPracticeAddress } from "@/shared/modules/typescript-api-client/models";
import ConfirmDialogue from "@/shared/components/confirm-dialogue/index.vue";
// import PcrPracticeRecordAdditionalDetailsPopup from "../pcr-practice-record-additional-details-popup/index.vue";
import { PracticeDetails } from "@/shared/modules/typescript-api-client/models/practice-details";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    PcrPracticeRecordCard,
    PcrPracticeRecordChangeDetailModal,
    LoadingIndicator,
    ConfirmDialogue,
    // PcrPracticeRecordAdditionalDetailsPopup,
  }
})
export default class PcrPracticeRecord extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  private loadingDataSet = true;
  private addNew = false;
  private additionDetailsConfirmed = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  private showChangeDetailsModal = false;

  private practiceRecords: PracticeDetails[] = [];
  private popupPracticeRecords: PracticeDetails[] = [];

  private selectedPracticeRecord: any;
  private selectedRecordIndex = 0;
  private showNoChange = false;

  mounted(): void {
    this.eventCtrl.on('reloadPracticeRecord', this.loadPracticeRecord);
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    this.loadPracticeRecord();
    this.canGoNextCheck();
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('reloadPracticeRecord', this.loadPracticeRecord);
  }

  private async loadPracticeRecord(isNew?: boolean) {
    this.loadingDataSet = true;
    await this.apiService.PcRenewApi?.apiV1PcRenewGetPracticeDetailsApplicationIdGet(this.pcrApp.id)
      .then(res => {
        this.practiceRecords = this.sortPPP(res.data.data);
        this.practiceRecords.forEach((p, index) => {
          if (p.originalPracticeRecord?.employmentStatus?.id == p.newPracticeRecord?.employmentStatus?.id
            && this.selectedAddress(p.originalPracticeRecord?.recordPracticeAddresses) == this.selectedAddress(p.newPracticeRecord?.recordPracticeAddresses)
          ) {
            this.revertDetails(index, p);
          }
        })
        this.showNoChangeCheck();
        this.pcrFormData.practiceRecords = this.practiceRecords;
        this.loadingDataSet = false;
        // if (isNew) {
        //   this.additionalPracticeDetailsPopup();
        // }
      })
      .catch(err => {
        this.loadingDataSet = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get practice records: ' + err);
        this.ai.trackException('Error, Unable to get practice records: ' + err);
      })
  }

  private selectedAddress(addresses: RecordPracticeAddress[]) {
    const _a = addresses.filter(a => {
      return a.isSelected
    })
    // console.log(JSON.stringify(_a[0].address));
    return this.getAddressInline(_a[0].address);
  }

  private sortPPP(records: PracticeDetails[]) {
    const _isPpp = records.filter(r => {
      return r.originalPracticeRecord?.isPpp || r.newPracticeRecord?.isPpp;
    })

    const _notPpp = records.filter(r => {
      return !r.originalPracticeRecord?.isPpp && !r.newPracticeRecord?.isPpp;
    })
    return [..._isPpp, ..._notPpp];
  }

  private addPractice() {
    const _newP =
    {
      "originalPracticeRecord": null,
      "newPracticeRecord": {
        pcRenewApplicationId: this.pcRenewApplicationId,
        isPrincipalPlace: false,
        name: '',
        employmentStatus: {},
        businessPhone: '',
        businessFax: '',
        recordPracticeAddresses: [
          { address: {} }
        ]
      }
    };

    this.changeDetails(this.practiceRecords.length, _newP, true);
  }

  get pcRenewApplicationId() {
    return this.pcrFormData.individualDetails.pcRenewApplicationId;
  }

  private changeDetails(cardIndex: number, p: any, addNew: boolean) {
    // console.log('Change details', cardIndex, JSON.stringify(p), addNew);
    this.selectedPracticeRecord = p;
    this.selectedRecordIndex = cardIndex;
    this.addNew = addNew;
    this.showChangeDetailsModal = true;
  }

  private revertDetails(cardIndex: number, p: any) {
    this.apiService.PcRenewApi?.apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(
      this.pcRenewApplicationId, // pcRenewApplicationId
      this.practiceRecords[cardIndex].newPracticeRecord.id // recordPracticeId
    )
      .then(() => {
        if (this.practiceRecords[cardIndex].originalPracticeRecord?.name) {
          this.practiceRecords[cardIndex].newPracticeRecord = null;
        } else {
          this.practiceRecords.splice(cardIndex, 1);
        }
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to revert practice records: ' + err);
        this.ai.trackException('Error, Unable to revert practice records: ' + err);
      })
    this.showNoChangeCheck();
  }

  private additionalPracticeDetailsPopup() {
    const _nonCeased = this.practiceRecords.filter(pr => {
      const _isCeased = pr.newPracticeRecord && pr.newPracticeRecord.employmentStatus?.id == 999999999;
      return pr.originalPracticeRecord !== null && !_isCeased;
    })
    if (_nonCeased.length > 0) {
      this.popupPracticeRecords = this.practiceRecords.filter(pr => {
        return pr.originalPracticeRecord !== null;
      });
      const _confirm: any = this.$refs.additionalDetailsPopUp;
      _confirm.show({
        title: 'Additional Practice Details',
        message: 'You have added new practice details, are you engaged in legal practice for more than one Law Practice / Employer / Entity?',
        okButton: 'Save',
        cancelButton: 'Cancel',
      }).then((res: any) => {
        if (res) {
          this.loadPracticeRecord(false);
          this.additionDetailsConfirmed = true;
        }
      })
    } else {
      this.additionDetailsConfirmed = true;
    }

  }

  private closeModal(p: any) {
    this.showChangeDetailsModal = false;
  }

  private update(p: any, isNew: boolean) {
    this.loadPracticeRecord(isNew);
  }

  private showNoChangeCheck(): boolean {
    setTimeout(() => {
      this.showNoChange = false;
      this.practiceRecords.forEach(p => {
        if (this.practiceRecords.length > 1 && p.newPracticeRecord !== null) {
          this.showNoChange = true;
        }
      })
    }, 1000)
    return this.showNoChange;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', true);
  }

  get lastApplicationYear(): number {
    return store.getters.getAppInfo.lastApplicationYear.year;
  }

  private getAddressInline(add: AddressDetail) {
    let address: AddressDetail = add;
    const countryName: string = address.countryName ? address.countryName : '';
    let addressEncoded = '';
    address = JSON.parse(JSON.stringify(address).replace(/:null/gi, ':""'));  // replace all null value
    if (countryName !== '' && countryName.toLowerCase() == 'australia') {
      switch (address['addressTypeId']) {
        case 1: // Street
          addressEncoded = address['buildingName'] + ' ';
          addressEncoded += address['buildingTypeName'] + ' ' + address['buildingNumber'] + ' ' + address['level'] + ', ';
          addressEncoded += address['streetNumber'] + ' ' + address['streetName'] + ' ' + address['streetTypeName'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 2: // PO Box
          addressEncoded = address['deliveryTypeName'] + ' ' + address['poBox'] + ',';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 3: // DX address
          addressEncoded = 'DX ' + address['dxNumber'] + ',';
          addressEncoded += address['dxExchangeTypeName'];
          break;
      }
    } else if (countryName !== '') {
      addressEncoded = address['foreignAddress'] + ', ' + address['countryName'];
    } else {
      addressEncoded = JSON.stringify(address).replaceAll('"', '');
    }
    return addressEncoded.replace(',', '').trim();
  }

}

