import { createStore } from 'vuex';
import snackBarStoreModule from '@/shared/store/snack-bar-store-module';
import eventControllerModule from '@/shared/store/event-controller-store-module';
import userAccountStoreModule from '@/shared/store/user-account-store-module';
import appInfoStoreModule from '@/shared/store/app-info-store-module';
import appRenewStoreModule from '@/shared/store/app-renew-store-module';
import pcrFormDataStoreModule from '@/shared/store/pcr-formdata-store-module';
import popOverStoreModule from '@/shared/store/popover-store-module';
import countryListStoreModule from '@/shared/store/countrylist-store-module';
import dxExchangeTypeListStoreModule from '@/shared/store/dx-exchangelist-store-module';
import smAppFormDataStoreModule from './sm-app-formdata-store-module';
import onlineApplicationStoreModule from './online-application-store-module';
import addressTypeStoreModule from './address-type-store-module';
import sarAppFormDataStoreModule from './sar-app-formdata-store-module';
import amnAppFormDataStoreModule from './amn-app-formdata-store-module';
import amrAppFormDataStoreModule from './amr-app-formdata-store-module';
import stnAppFormDataStoreModule from './stn-app-formdata-store-module';
import strAppFormDataStoreModule from './str-app-formdata-store-module';

export default createStore({
  modules: {
    snackBarStoreModule,
    eventControllerModule,
    userAccountStoreModule,
    appInfoStoreModule,
    appRenewStoreModule,
    pcrFormDataStoreModule,
    popOverStoreModule,
    countryListStoreModule,
    dxExchangeTypeListStoreModule,
    smAppFormDataStoreModule,
    onlineApplicationStoreModule,
    addressTypeStoreModule,
    sarAppFormDataStoreModule,
    stnAppFormDataStoreModule,
    strAppFormDataStoreModule,
    amnAppFormDataStoreModule,
    amrAppFormDataStoreModule
  }
});
