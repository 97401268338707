import { Inject, Options, Vue } from "vue-property-decorator";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { Application } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  components: {
    Popover,
    LoadingIndicator
  },
  mixins: [FormatDateTimeMixin]
})
export default class PcNorPaymentDetails extends Vue {

  private pcrFormData!: any;
  private isSectionLoading!: boolean;
  private ai = new AppInsights;
  private loadingDataSet = true;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;


  mounted(): void {
    this.canGoNextCheck();
    this.getPaymentDetails();
  }

  private async getPaymentDetails(): Promise<void> {
    try {
      console.log(store.getters);
      this.loadingDataSet = true;
      const resp: any = await this.apiService.PcNonRenewApi?.apiV1PcNonRenewGetPaymentDetailsApplicationIdGet(this.pcrApp.id);
      this.pcrFormData.paymentDetails = resp.data.data;
      this.canGoNextCheck();
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get payment details: ' + err);
      this.ai.trackException('Error, Unable to get payment details: ' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }


  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

  private getPayableItem(itemName: string): any {
    try {
      const _rtn = this.pcrFormData.paymentDetails.lineItems.filter(
        (x: any) => x.name.toLocaleLowerCase() == itemName.toLocaleLowerCase()
      );
  
      if (_rtn.length > 0) {
        return _rtn[0];
      } else {
        throw new Error('Invalid Item');
      }
  
    } catch {
      return {
        name: itemName,
        amountGstExcluded: 0,
        gst: 0,
        total: 0,
        lsAmountGstExcluded: 0,
        lsFeeName: '',
        lsGst: 0,
        lsTotal: 0,        
        localOW: true
      }
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.getPayableItem('Associate Membership Fee').total > 0);
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }
  get getTotal(): number {
    return this.getPayableItem('Associate Membership Fee').total;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }


}

