import { Module } from "vuex";

const pcrFormDataStoreModule: Module<any, any> = {
  state: {
    pcrFormData: {}
  },
  mutations: {
    setPcrFormData(state, fd) {
      state.pcrFormData = fd;
    }
  },
  actions: {
    setPcrFormData({ commit }, fd) {
      commit('setPcrFormData', fd);
    }
  },
  getters: {
    getPcrFormData: (state) => state.pcrFormData
  }
}
export default pcrFormDataStoreModule;