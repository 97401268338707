import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
// import { AuthorizedUser } from "@/shared/models/authorized-user";
import Popover from "@/shared/components/popover/index.vue";
// import { PopoverView } from "@/shared/components/popover/popover";
import { AddressDetail, AmRenewIndividualDetail, Application, IndividualMembershipDetails } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import AddressPreview from "@/shared/components/address-preview/index.vue";
import AmrMembershipDetailsUpdateModal from "../amr-membership-details-update-modal/index.vue";


@Options({
  props: {
    amrAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    AddressPreview,
    AmrMembershipDetailsUpdateModal,
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmrMembershipDetails extends Vue {
  private amrAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;
  private declaration1 = null;
  private declaration2 = null;
  private individualDetails: AmRenewIndividualDetail = {};
  private individualMemberShipDetails: IndividualMembershipDetails = {};
  private membershipDetails: Application = {};

  private contactPhoneInfoMissing = false;
  private addressMissing = false;
  private showUpdateDetailsModal = false;

  private isRenew = null;
  private origNames = {};

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  @Watch('individualDetails.isCurrentDetailCorrect')
  private radioButtonChange() {
    this.eventCtrl.emit('canGoNext', this.individualDetails.isCurrentDetailCorrect);
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    this.eventCtrl.emit('canGoNext', false);
    const _origName = this.amrAppFormData.origNames;
    this.origNames = _origName;
    window.scrollTo(0, 0);
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    try {
      const _isRenewing = this.amrAppFormData.individualDetails?.amRenewApplicationId > 0;
      this.loadingDataSet = true;
      const resp: any[] = await Promise.all([
        this.apiService.AmRenewApi?.apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(this.onlineApplication.id, !_isRenewing),
        this.apiService.IndividualApi?.apiV1IndividualGetIndividualMembershipDetailsGet(),
        this.apiService.ApplicationApi?.apiV1ApplicationGetApplicationByIdApplicationIdGet(this.onlineApplication.id),
      ]);
      this.individualDetails = resp[0].data.data;
      this.individualMemberShipDetails = resp[1].data.data;
      this.membershipDetails = resp[2].data.data;
      if (!_isRenewing) {
        this.origNames = {
          firstName: this.individualDetails.firstName,
          otherName: this.individualDetails.otherName,
          surname: this.individualDetails.surname,
        };
      }
      this.individualDetails.workPhone = this.individualDetails.workPhone.phoneNumber ? this.individualDetails.workPhone :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.businessFax = this.individualDetails.businessFax.phoneNumber ? this.individualDetails.businessFax :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.mobilePhone = this.individualDetails.mobilePhone.phoneNumber ? this.individualDetails.mobilePhone :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.businessPhone = this.individualDetails.businessPhone.phoneNumber ? this.individualDetails.businessPhone :
        { phoneNumber: '', phoneAreaCode: null };

      // if (this.amrAppFormData.tempEmail) {
      //   this.individualDetails.communicationEmail.email = this.amrAppFormData.tempEmail;
      // }
      if (this.individualDetails.mobilePhone?.phoneNumber || this.individualDetails.businessPhone?.phoneNumber || this.individualDetails.workPhone?.phoneNumber) {
        this.contactPhoneInfoMissing = false;
      } else {
        this.contactPhoneInfoMissing = true;
      }
      this.addressMissing = this.individualDetails.postalAddress == null;
      this.amrAppFormData.appId = this.onlineApplication.id;
      this.amrAppFormData.origNames = this.origNames;
      this.amrAppFormData.individualDetails = this.individualDetails;
      store.dispatch('setAmrAppFormData', this.amrAppFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.individualDetails?.isCurrentDetailCorrect);
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get membershipPeriod(): string {
    if (this.membershipDetails?.effectiveStartDate && this.membershipDetails?.effectiveEndDate) {
      return '1 Jul ' + this.membershipDetails?.effectiveStartDate.slice(0, 4) + ' - ' + '30 Jun ' + this.membershipDetails?.effectiveEndDate.slice(0, 4)
    } else {
      return ''
    }
  }

  get nameCheck() {
    const _firstNameCheck = this.individualDetails?.firstName?.trim() == this.amrAppFormData.origNames?.firstName?.trim();
    const _otherNameCheck = this.individualDetails?.otherName?.trim() == this.amrAppFormData.origNames?.otherName?.trim();
    const _surnameCheck = this.individualDetails?.surname?.trim() == this.amrAppFormData.origNames?.surname?.trim();
    return !(_firstNameCheck && _otherNameCheck && _surnameCheck);
  }

  private updateCurrentDetails() {
    this.showUpdateDetailsModal = true;
  }

  private reloadCurrentDetails() {
    this.apiService.AmRenewApi?.apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(
      this.onlineApplication.id, false
    ).then(res => {
      this.individualDetails = res.data.data;
      this.individualDetails.isCurrentDetailCorrect = null;
      this.contactPhoneInfoMissing = false;
      this.addressMissing = false;

      this.individualDetails.workPhone = this.individualDetails.workPhone.phoneNumber ? this.individualDetails.workPhone :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.businessFax = this.individualDetails.businessFax.phoneNumber ? this.individualDetails.businessFax :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.mobilePhone = this.individualDetails.mobilePhone.phoneNumber ? this.individualDetails.mobilePhone :
        { phoneNumber: '', phoneAreaCode: null };
      this.individualDetails.businessPhone = this.individualDetails.businessPhone.phoneNumber ? this.individualDetails.businessPhone :
        { phoneNumber: '', phoneAreaCode: null };

      this.amrAppFormData.individualDetails = this.individualDetails;
    })
      .catch(err => {
        this.ai.trackException('Error, Unable to reload user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to reload user details');
      });
  }

}

