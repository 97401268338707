import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c95e4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popover"
}

export function render(_ctx, _cache) {
  const _directive_tippy = _resolveDirective("tippy")

  return (_ctx.preview || _ctx.popover && (_ctx.popover.isShow && _ctx.popover.content?.length > 0))
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _withDirectives(_createElementVNode("i", {
          class: _normalizeClass(_ctx.iconClass || 'fa fa-question-circle'),
          style: {"font-size":"20px"}
        }, null, 2), [
          [_directive_tippy, {content: '\<strong>' + _ctx.popover.title+ '\</strong>' + '\<br>' + _ctx.popover.content, interactive: true}]
        ])
      ]))
    : _createCommentVNode("", true)
}