import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { Application, SmIndividualDetail } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
// import SmApplicationWording from '@/assets/config/sm-application-wording.json';

@Options({
  props: {
    smAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover
  },
  mixins: [FormatDateTimeMixin],
})
export default class SmApplicationDetails extends Vue {
  private smAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  // private declaration_check1_text = SmApplicationWording[0]["application-details"]["declaration_check_1"];
  // private declaration_check2_text = SmApplicationWording[0]["application-details"]["declaration_check_2"];

  private individualDetails: SmIndividualDetail = {};

  private loadingDataSet = false;
  // private declaration1 = null;
  // private declaration2 = null;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }


  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      await Promise.all([
        this.getSmIndividualDetails()
      ]);
      store.dispatch('setSmAppFormData', this.smAppFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private async getSmIndividualDetails(): Promise<boolean> {
    try {
      const _isRenewing = !!this.smAppFormData.individualDetails;
      const resp: any = await this.apiService.SmApi?.apiV1SmGetApplicationDetailsApplicationIdIsNewApplicationGet(
        this.onlineApplication.id, !_isRenewing
      );
      this.individualDetails = resp.data.data;
      this.smAppFormData['smIndividualDetails'] = this.individualDetails;
      // this.initSync();
      this.canGoNextCheck();
      return Promise.resolve(true);
    } catch (err: any) {
      // if (!this.smAppFormData?.smIndividualDetails) {
      //   this.smAppFormData['smIndividualDetails']['declaration1'] = null;
      //   this.smAppFormData['smIndividualDetails']['declaration2'] = null;
      // }
      if (err.response?.data && err.response.data?.errorCode == 10001) {
        this.eventCtrl.emit('show-snackbar', 'info', 'Concurrent application detected');
      } else {
        this.ai.trackException('Error, Unable to get user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get user details, redirect to Portal home');
      }
      // this.$router.push({ name: RouteN.Mars.Home.n });
      return Promise.reject(false);
    }
  }

  // private initSync(): void {
  //   this.declaration1 = this.smAppFormData.smIndividualDetails.declaration1;
  //   this.declaration2 = this.smAppFormData.smIndividualDetails.declaration2;
  // }

  // private syncData(): void {
  //   try {
  //     // if (!this.loadingDataSet) {
  //     //   this.smAppFormData.smIndividualDetails.declaration1 = this.declaration1;
  //     //   this.smAppFormData.smIndividualDetails.declaration2 = this.declaration2;
  //     // }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   this.canGoNextCheck();
  // }


  private isValidateForm(): boolean {
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidateForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

