import { EventEmitter } from "events";
import { Module } from "vuex";

const eventControllerStoreModule: Module<any, any> = {
  state: {
    eventController: new EventEmitter()
  },
  getters: {
    getEventController: (state) => state.eventController
  }
}
export default eventControllerStoreModule;