import { Module } from "vuex";

const stnAppFormDataStoreModule: Module<any, any> = {
  state: {
    stnAppFormData: {}
  },
  mutations: {
    setStnAppFormData(state, fd) {
      state.stnAppFormData = fd;
    }
  },
  actions: {
    setStnAppFormData({ commit }, fd) {
      commit('setStnAppFormData', fd);
    }
  },
  getters: {
    getStnAppFormData: (state) => state.stnAppFormData
  }
}
export default stnAppFormDataStoreModule;