import { Module } from "vuex";

const smAppFormDataStoreModule: Module<any, any> = {
  state: {
    smAppFormData: {}
  },
  mutations: {
    setSmAppFormData(state, fd) {
      state.smAppFormData = fd;
    }
  },
  actions: {
    setSmAppFormData({ commit }, fd) {
      commit('setSmAppFormData', fd);
    }
  },
  getters: {
    getSmAppFormData: (state) => state.smAppFormData
  }
}
export default smAppFormDataStoreModule;