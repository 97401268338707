import { Options, Vue, Watch } from "vue-property-decorator";
import LoadingIndicator from "@/shared/components/loading-indicator/loading-indicator";
import DatePicker from 'vue-datepicker-next';
import { Application, StudentRenewRecordDeclarationRequest } from "@/shared/modules/typescript-api-client/models";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { AppInsights } from "@/mars-app/appInsights";

@Options({
  props: {
    strAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    DatePicker,
  }
})
export default class StrDeclaration extends Vue {
  private strAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  private loadingDataSet = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.strAppFormData.strDeclarations = {};
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.updateStrDeclaration();
  }

  private updateStrDeclaration() {
    const _strDeclaration: StudentRenewRecordDeclarationRequest = {
      id: 0,
      studentRenewApplicationId: this.strAppFormData.studentRecord?.studentRenewApplicationId,
    }
    this.strAppFormData.strDeclarations = _strDeclaration;
    this.canGoNextCheck();
  }

  private isValidForm(): boolean {
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}


