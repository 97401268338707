export class User {
  constructor(
    public externalId?: string | null,
    public email?: string | null,
    public firstName?: string | null,
    public lastName?: string | null,
    public title?: string | null,
    public userName?: string | null,
    public lawId?: string | null,
    public isMember?: boolean | null,
    public isPcHolder?: boolean | null,
    public securityGroups?: null,
    public roles?: []
  ) { }
}