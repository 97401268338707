import { AuthorizedUser } from '@/shared/models/authorized-user';
import { AxiosInstance, AxiosResponse } from 'axios';
import globalAxios from 'axios';
import * as HttpStatus from 'http-status-codes';
import { AuthenticationStatus, AuthenticationStatusType } from './authentication-status';
import { SELF_URL } from '@/shared/config/config';
import { Url } from '@/shared/config/url'

export default class AuthenticationService {

  private options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  constructor(
    public authedUser: AuthorizedUser = new AuthorizedUser(),
    public axios: AxiosInstance = globalAxios
  ) { }

  public async checkAuthentication(): Promise<AuthenticationStatus> {
    let tokenResp: AxiosResponse;

    try {
      tokenResp = await this.axios.get(SELF_URL + Url.Token);
      try {
        this.authedUser.token.tokenStr = tokenResp.data.token;
        this.authedUser.token.clientId = tokenResp.data.clientId;
        this.authedUser.token.userId = tokenResp.data.userId;
        this.authedUser.user.email = tokenResp.data.user.email;
        this.authedUser.user.externalId = tokenResp.data.user.externalId;
        this.authedUser.user.firstName = tokenResp.data.user.firstName;
        this.authedUser.user.lastName = tokenResp.data.user.lastName;
        this.authedUser.user.lawId = tokenResp.data.user.lawId;
        this.authedUser.user.userName = tokenResp.data.user.lawId;
        this.authedUser.user.title = tokenResp.data.user.title;
        this.authedUser.user.isMember = tokenResp.data.user.isMember;
        this.authedUser.user.isPcHolder = tokenResp.data.user.isPcHolder;
        this.authedUser.session.issued = Math.round(new Date(tokenResp.data['issued']).getTime() / 1000);
        this.authedUser.session.expires = Math.round(new Date(tokenResp.data['expires']).getTime() / 1000);
        this.authedUser.user.roles = tokenResp.data.user.roles;

        if (tokenResp.data.token) {
          this.authedUser.authenticationStatus = AuthenticationStatusType.LoggedIn;
          return Promise.resolve(AuthenticationStatusType.LoggedIn);
        } else {
          this.authedUser.authenticationStatus = AuthenticationStatusType.Unknown;
          return Promise.reject(AuthenticationStatusType.Unknown);
        }
      } catch (err) {
        this.authedUser.authenticationStatus = AuthenticationStatusType.Unknown;
        return Promise.reject(AuthenticationStatusType.Unknown);
      }

    } catch (err: any) {
      if (err.response.status === HttpStatus.UNAUTHORIZED ||
        err.response.status === HttpStatus.FORBIDDEN) {
        this.authedUser.authenticationStatus = AuthenticationStatusType.NotLogin;
        return Promise.reject(AuthenticationStatusType.NotLogin);
      } else {
        this.authedUser.authenticationStatus = AuthenticationStatusType.Unknown;
        return Promise.reject(AuthenticationStatusType.Unknown);
      }
    }
  }
}
