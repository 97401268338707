/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanResponse } from '../models';
import { PageContainerIEnumerableResponse } from '../models';
import { PageContentIEnumerableResponse } from '../models';
import { PageContentRequest } from '../models';
import { PageContentStatusRequest } from '../models';
import { PageIEnumerableResponse } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Internal Content Admin | Returns Page Containers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPageContainersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/GetPageContainers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Page Contents by pageId
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPageContentsPageIdGet: async (pageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            if (pageId === null || pageId === undefined) {
                throw new RequiredError('pageId','Required parameter pageId was null or undefined when calling apiV1AdminGetPageContentsPageIdGet.');
            }
            const localVarPath = `/api/v1/Admin/GetPageContents/{pageId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Pages by pageContainerId
         * @param {number} pageContainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPagesByPageContainerIdPageContainerIdGet: async (pageContainerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageContainerId' is not null or undefined
            if (pageContainerId === null || pageContainerId === undefined) {
                throw new RequiredError('pageContainerId','Required parameter pageContainerId was null or undefined when calling apiV1AdminGetPagesByPageContainerIdPageContainerIdGet.');
            }
            const localVarPath = `/api/v1/Admin/GetPagesByPageContainerId/{pageContainerId}`
                .replace(`{${"pageContainerId"}}`, encodeURIComponent(String(pageContainerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content
         * @param {PageContentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUpdatePageContentPost: async (body?: PageContentRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/UpdatePageContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content Status
         * @param {PageContentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUpdatePageContentStatusPost: async (body?: PageContentStatusRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/UpdatePageContentStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Internal Content Admin | Returns Page Containers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGetPageContainersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageContainerIEnumerableResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).apiV1AdminGetPageContainersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Page Contents by pageId
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGetPageContentsPageIdGet(pageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageContentIEnumerableResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).apiV1AdminGetPageContentsPageIdGet(pageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Pages by pageContainerId
         * @param {number} pageContainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIEnumerableResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content
         * @param {PageContentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUpdatePageContentPost(body?: PageContentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).apiV1AdminUpdatePageContentPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content Status
         * @param {PageContentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUpdatePageContentStatusPost(body?: PageContentStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).apiV1AdminUpdatePageContentStatusPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Internal Content Admin | Returns Page Containers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPageContainersGet(options?: any): AxiosPromise<PageContainerIEnumerableResponse> {
            return AdminApiFp(configuration).apiV1AdminGetPageContainersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Page Contents by pageId
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPageContentsPageIdGet(pageId: number, options?: any): AxiosPromise<PageContentIEnumerableResponse> {
            return AdminApiFp(configuration).apiV1AdminGetPageContentsPageIdGet(pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Content Admin | Returns Pages by pageContainerId
         * @param {number} pageContainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId: number, options?: any): AxiosPromise<PageIEnumerableResponse> {
            return AdminApiFp(configuration).apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content
         * @param {PageContentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUpdatePageContentPost(body?: PageContentRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AdminApiFp(configuration).apiV1AdminUpdatePageContentPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Content Admin | Update Page Content Status
         * @param {PageContentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUpdatePageContentStatusPost(body?: PageContentStatusRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AdminApiFp(configuration).apiV1AdminUpdatePageContentStatusPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Internal Content Admin | Returns Page Containers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminGetPageContainersGet(options?: any) {
        return AdminApiFp(this.configuration).apiV1AdminGetPageContainersGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Internal Content Admin | Returns Page Contents by pageId
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminGetPageContentsPageIdGet(pageId: number, options?: any) {
        return AdminApiFp(this.configuration).apiV1AdminGetPageContentsPageIdGet(pageId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Internal Content Admin | Returns Pages by pageContainerId
     * @param {number} pageContainerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId: number, options?: any) {
        return AdminApiFp(this.configuration).apiV1AdminGetPagesByPageContainerIdPageContainerIdGet(pageContainerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Internal Content Admin | Update Page Content
     * @param {PageContentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminUpdatePageContentPost(body?: PageContentRequest, options?: any) {
        return AdminApiFp(this.configuration).apiV1AdminUpdatePageContentPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Internal Content Admin | Update Page Content Status
     * @param {PageContentStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminUpdatePageContentStatusPost(body?: PageContentStatusRequest, options?: any) {
        return AdminApiFp(this.configuration).apiV1AdminUpdatePageContentStatusPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
