import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import SwipeStepper from "@/shared/components/swipe-stepper/index.vue";
import LoadingSpinner from "@/shared/components/loading-spinner/index.vue";
import { RouteN } from "@/shared/constants/route-name";
import store from "@/shared/store";
import { EventEmitter } from "events";
import PcrCurrentDetails from "../pcr-current-details/index.vue";
import PcrPracticeRecord from "../pcr-practice-record/index.vue";
import { NavStep, NavStepStatusID, Section, Step } from "@/shared/components/header-stepper/nav-step";
import PcrAddressDetails from "../pcr-address-details/index.vue";
import PcrConfirmation from "../pcr-confirmation/index.vue";
import PcrPaymentDetails from "../pcr-payment-details/index.vue";
import PcrPreferences from "../pcr-preferences/index.vue";
import PcrRenewalDetails from "../pcr-renewal-details/index.vue";
import PcrStatutoryRegister from "../pcr-statutory-register/index.vue";
import PcrAdditionalDetails from "../pcr-additional-details/index.vue";
import PcrPracticeRecordPppPopup from "../pcr-practice-record-ppp-popup/index.vue";
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import { Application, PageContent, PracticeDetails } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import PaymentPopup from "../payment-popup/index.vue";
import { PaymentTransaction } from "@/shared/models/payment-transaction";
import { PAYMENTAPP_URL } from "@/shared/config/config";
import PcrPracticeRecordAdditionalDetailsPopup from "../pcr-practice-record-additional-details-popup/index.vue";
import SarApplicationDeclarations from "../sar-app/sar-application-declarations/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import PcrSpecialistAccreditation from "../pcr-specialist-accreditation/index.vue";
import PcrRenewalDeclarations from "../pcr-renewal-declarations/index.vue";

@Options({
  components: {
    LoadingSpinner,
    LoadingIndicator,
    SwipeStepper,
    PcrCurrentDetails,
    PcrPracticeRecord,
    PcrAddressDetails,
    PcrRenewalDetails,
    PcrStatutoryRegister,
    PcrAdditionalDetails,
    PcrPreferences,
    PcrSpecialistAccreditation,
    SarApplicationDeclarations,
    PcrRenewalDeclarations,
    PcrPaymentDetails,
    PcrConfirmation,
    PcrPracticeRecordPppPopup,
    PcrPracticeRecordAdditionalDetailsPopup,
    PaymentPopup,
  },
  mixins: [FormatDateTimeMixin],
  beforeRouteEnter(to: any, from: any, next: any) {
    const pcrApp = store.getters.getAppRenew;
    if (!pcrApp.id) {
      // console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    next(); // don't check for now;
  }
})
export default class Pcr extends Vue {
  private ai = new AppInsights;

  @Watch('$route')
  public OnRouteChange(val: any, oldVal: any): void {
    const _stored = store.getters.getPcrFormData;
    window.scrollTo(0, 0);
    if (oldVal.fullPath.indexOf('confirmation') > -1 || _stored == null) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    this.setCurrentStep();
  }

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private sectionName = '';
  get $Section() {
    return Section;
  }

  private backButtonVisible = true;
  private canGoNext = false;

  private backButtonText = 'Cancel Application';
  // private nextButtonText = 'Next';

  private practiceRecords: PracticeDetails[] = [];
  private popupPracticeRecords: PracticeDetails[] = [];

  private helpContent: PageContent = {};

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private navSteps = [
    new NavStep(Step.details, Section.details), // 0
    new NavStep(Step.record, Section.record),   // 1
    new NavStep(Step.address, Section.address), // 2
    new NavStep(Step.renewal, Section.renewal), // 3
    new NavStep(Step.register, Section.register),       // 4
    new NavStep(Step.additional, Section.additional),   // 5
    new NavStep(Step.preferences, Section.preferences), // 6
    new NavStep(Step.specialistAccreditation, Section.specialistAccreditation), // 7
    new NavStep(Step.applicationDeclarations, Section.applicationDeclarations), // 8 
    new NavStep(Step.renewalDeclarations, Section.renewalDeclarations),         // 9
    new NavStep(Step.payment, Section.payment),           // 10
    new NavStep(Step.confirmation, Section.confirmation), // 11
  ];

  private isLoadingData = false;
  private isSectionLoading = false;
  private isSaving = false;

  private pcrFormData: any = {};
  private showPaymentPopup = false;
  private disablePaymentPopupCloseBut = false;
  private paymentTransaction: PaymentTransaction = new PaymentTransaction;
  private validPaymentPage = false;

  private ping: any;
  private sessionId = new Date().getTime() + '';
  private paymentAppName = 'pcr-payment';

  mounted(): void {
    document.body.classList.remove('modal-open');
    const _appRenew = store.getters.getAppRenew;
    if (!_appRenew.id) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Invalid Page entry, please choose an option from Portal home');
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    window.scrollTo(0, 0);
    this.setCurrentStep();

    this.eventCtrl.on('canGoNext', this.updateCanGoNext);
    this.eventCtrl.on('navigateTo', this.navigateTo);
    this.eventCtrl.on('cardSubmitting', this.cardSubmitting);
    // this.eventCtrl.on('pc-payment-confirmed', this.paymentConfirmed);
    // this.eventCtrl.on('remove-pcr-payment-window', this.removePaymentWindow);
    window.addEventListener('message', this.receivedNotification);

    const _section: any = this.$route.query.sec || '';
    this.sectionName = _section;
    this.updateStepsUI();
  }

  beforeUnmount(): void {
    // this.eventCtrl.removeListener('canSubmit', () => { this.canGoNext = false });
    this.eventCtrl.removeListener('canGoNext', this.updateCanGoNext);
    this.eventCtrl.removeListener('navigateTo', this.navigateTo);
    this.eventCtrl.removeListener('cardSubmitting', this.cardSubmitting);
    // this.eventCtrl.removeListener('pc-payment-confirmed', this.paymentConfirmed);
    // this.eventCtrl.removeListener('remove-pcr-payment-window', this.removePaymentWindow);
    window.removeEventListener('message', this.receivedNotification);
    // clearInterval(this.ping);
  }

  private cardSubmitting(isCardSubmitting: boolean): void {
    this.isSaving = isCardSubmitting;
  }

  private navigateTo(stepIndex: number): void {
    this.navigate(this.navSteps[stepIndex]);
  }

  private receivedNotification(event: any): void {

    const action = event?.data?.action;
    const validOrigin = event?.origin == PAYMENTAPP_URL;
    const validSessionId = event?.data?.sessionId == this.sessionId;
    const validPaymentApp = this.paymentAppName == event?.data?.paymentAppName;

    // console.log('Received Notifications -- MARSApp');
    // console.log('action: ', action);
    // console.log('payment url: ', event?.origin, PAYMENTAPP_URL);
    // console.log('sessionId: ', event?.data?.sessionId, this.sessionId);
    // console.log('paymentAppName', event?.data?.paymentAppName, this.paymentAppName);
    // console.log('failed verification: ', !validOrigin, !validSessionId, !validPaymentApp);
    if (!validOrigin || !validSessionId || !validPaymentApp) {
      return;
    }

    this.processNotification(action, event?.data?.data);
  }

  private processNotification(request: string, dataO: any = null): void {
    switch (request) {
      case 'cancel-payment':
        this.eventCtrl.emit('cancel-pcr-payment');
        break;
      case 'payment-processed':
        this.paymentConfirmed();
        break;
      case 'parent-close-state':
        // console.log(dataO);
        this.disablePaymentPopupCloseBut = dataO.disabled;
        break;
      case 'close-payment-form':
        this.showPaymentPopup = false;
        this.validPaymentPage = false;
        this.disablePaymentPopupCloseBut = false;
        break;
      case 'payment-form-isReady':
        this.validPaymentPage = true;
        break;
    }
  }

  private updateCanGoNext(val: boolean): void {
    if (val) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
  }

  private setCurrentStep(): void {
    const _section: string = this.$route.query.sec as string;
    if (_section !== Section.details) {
      this.backButtonText = 'Back';
    } else {
      this.backButtonText = 'Cancel application'
    }
    // console.log('setCurrentStep: ', this.pcrFormData?.paymentDetails?.isSpecAccWithPracticeEmployer, _section);
    // if (this.pcrFormData.paymentDetails?.isSpecAccWithPracticeEmployer && _section == Section.payment) {
    //   this.nextButtonText = 'Submit';
    // } else {
    //   this.nextButtonText = 'Next';
    // }
    this.backButtonVisible = true;

    switch (_section) {
      case Section.details:
        this.setSteps(0);
        break;
      case Section.record:
        this.setSteps(1);
        break;
      case Section.address:
        this.setSteps(2);
        break;
      case Section.renewal:
        this.setSteps(3);
        break;
      case Section.register:
        this.setSteps(4);
        break;
      case Section.additional:
        this.setSteps(5);
        break;
      case Section.preferences:
        this.setSteps(6);
        break;
      case Section.specialistAccreditation:
        this.setSteps(7);
        break;
      case Section.applicationDeclarations:
        this.setSteps(8);
        break;
      case Section.renewalDeclarations:
        this.setSteps(9);
        break;
      case Section.payment:
        this.setSteps(10);
        break;
      case Section.confirmation:
        this.setSteps(11);
        this.backButtonVisible = false;
        break;
      default:
        break;
    }
  }

  get nextButtonText() {
    const _section: string = this.$route.query.sec as string;
    if (this.pcrFormData.paymentDetails?.isSpecAccWithPracticeEmployer && _section == Section.payment) {
      return 'Submit';
    } else {
      return 'Next';
    }
  }

  get savingText() {
    const _section: string = this.$route.query.sec as string;
    if (this.pcrFormData.paymentDetails?.isSpecAccWithPracticeEmployer && _section == Section.payment) {
      return 'Processing... Do not refresh or click any button on your browser.';
    } else {
      return 'Saving...';
    }
  }

  private setSteps(n: number) {
    this.updateHelpContent(n);
    for (let i = 0; i < this.navSteps.length; i++) {
      this.navSteps[i].status = NavStepStatusID.NotDone
    }
    for (let i = 0; i < n; i++) {
      this.navSteps[i].status = NavStepStatusID.Done
    }
    this.navSteps[n].status = NavStepStatusID.Current
    // the confirmation page
    const _confirmation = this.navSteps.length - 1;
    if (n === _confirmation) {
      for (let i = 0; i < _confirmation; i++) {
        this.navSteps[i].status = NavStepStatusID.Unknown
      }
    } else {
      // save the current step
      // window.sessionStorage.setItem('contractData', JSON.stringify(this.contractData));
    }
  }

  private updateHelpContent(n: number) {
    switch (n) {
      case 0: // Section.details
        this.helpContent = this.$getPopover('Help', 2);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 1: // Section.record
        this.helpContent = this.$getPopover('Help', 3);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 2: // Section.address
        this.helpContent = this.$getPopover('Help', 4);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 3: // Section.renewal
        this.helpContent = this.$getPopover('Help', 5);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 4: // Section.other
        this.helpContent = this.$getPopover('Help', 6);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 5: // Section.additional
        this.helpContent = this.$getPopover('Help', 15);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 6: // Section.preferences
        this.helpContent = this.$getPopover('Help', 16);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 7: // Section.specialistAccreditation
        this.helpContent = this.$getPopover('Help', 51);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 8: // Section.applicationDeclarations
        this.helpContent = this.$getPopover('Help', 17);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 9: // Section.renewalDeclarations
        this.helpContent = this.$getPopover('Help', 18);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 10:
        this.helpContent = this.$getPopover('Help', 7);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 11:
        this.helpContent = this.$getPopover('Help', 19);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      default:
        // this.helpContent = '';
        break;
    }
  }

  private goBack() {
    const _section: string = this.$route.query.sec as string;
    switch (_section) {
      case Section.details:
        this.eventCtrl.emit('cancel-pc-renew');
        // this.$router.push({ name: RouteN.Mars.PcRenewOverview.n, query: { appId: this.$route.query.appId } });
        break;
      case Section.record:
        this.navigate(this.navSteps[0]);
        break;
      case Section.address:
        this.navigate(this.navSteps[1]);
        break;
      case Section.renewal:
        this.navigate(this.navSteps[2]);
        break;
      case Section.register:
        this.navigate(this.navSteps[3]);
        break;
      case Section.additional:
        this.navigate(this.navSteps[4]);
        break;
      case Section.preferences:
        this.navigate(this.navSteps[5]);
        break;
      case Section.specialistAccreditation:
        this.navigate(this.navSteps[6]);
        break;
      case Section.applicationDeclarations:
        this.navigate(this.navSteps[7]);
        break;
      case Section.renewalDeclarations:
        if (this.specAccRenewalFlag) {
          this.navigate(this.navSteps[8]);
        } else if (this.isSarStatusNew) {
          this.navigate(this.navSteps[7]);
        } else {
          this.navigate(this.navSteps[6]);
        }
        break;
      case Section.payment:
        this.navigate(this.navSteps[9]);
        break;
      case Section.confirmation:

        this.eventCtrl.emit('cancel-purchase');
        break;
      default:
        break;
    }
  }

  private navigate(s: NavStep): void {
    const temp: string = JSON.stringify(this.$route.query);
    const tempJSON = JSON.parse(temp);
    tempJSON.sec = s.routeSecName;
    this.$router.push({ query: tempJSON });
  }

  private goNext() {
    store.dispatch('setPcrFormData', this.pcrFormData);
    const _section = this.$route.query.sec as string;
    switch (_section) {
      case Section.details:
        this.saveIndividualDetails(1);
        // this.navigate(this.navSteps[1]);
        break;
      case Section.record:
        this.additionalPracticeDetailsPopup();
        break;
      case Section.address:
        this.saveAddress();
        // this.navigate(this.navSteps[3]);
        break;
      case Section.renewal:
        this.savePCRenewalDetails();
        break;
      case Section.register:
        this.saveStatutoryRegister();
        // this.navigate(this.navSteps[5]);
        break;
      case Section.additional:
        this.saveAdditionalDetails();
        break;
      case Section.preferences:
        this.savePreferences();
        // this.navigate(this.navSteps[7]);
        break;
      case Section.specialistAccreditation:
        this.saveSpecialistAccreditation();
        // this.navigate(this.navSteps[8]);
        break;
      case Section.applicationDeclarations:
        this.saveApplicationDeclarations();
        // this.navigate(this.navSteps[8 or 9]);
        break;
      case Section.renewalDeclarations:
        this.saveRenewalDeclarations();
        // this.navigate(this.navSteps[9]);
        break;
      case Section.payment:
        if (this.pcrFormData?.paymentDetails?.isSpecAccWithPracticeEmployer) {
          this.savePcPaymentDetailsInternal();
        } else {
          this.savePcPaymentDetails();
        }
        // this.navigate(this.navSteps[10]);
        break;
      case Section.confirmation:
        this.openSurveyWindow();
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }

  }

  private openSurveyWindow() {
    const _url = "https://www.lawsociety.com.au/pcrsurvey";
    window.open(_url, '_blank');
  }

  private saveAddress() {
    this.isSaving = true;
    const _body = this.pcrFormData.pcRenewAddressDetails;
    this.apiService.PcRenewApi?.apiV1PcRenewSaveAddressDetailsPost(_body)
      .then(() => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'Address details updated');
        this.navigate(this.navSteps[3]);
      })
      .catch(err => {
        this.isSaving = false;
        this.ai.trackException('Error, Unable to update address details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update address details: ' + err);
      })
  }

  private saveStatutoryRegister() {
    this.isSaving = true;
    this.apiService.PcRenewApi?.apiV1PcRenewSaveStatutoryRegisterDetailsPost(this.pcrFormData.statutoryRegisterDetails)
      .then(() => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'Statutory Register updated');
        this.navigate(this.navSteps[5]);
      })
      .catch(err => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update Statutory Register: ' + err);
        this.ai.trackException('Error, Unable to update Statutory Register: ' + err);
      })
  }

  private savePreferences() {
    this.isSaving = true;
    this.apiService.PcRenewApi?.apiV1PcRenewSavePreferenceDetailsPost(this.pcrFormData.preferenceDetail)
      .then(() => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'User preference updated');
        if (this.isSarStatusNew) {
          this.navigate(this.navSteps[7]);
        } else {
          this.navigate(this.navSteps[9]);
        }

      })
      .catch(err => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update user preference: ' + err);
        this.ai.trackException('Error, Unable to update user preference: ' + err);
      })
  }

  private saveSpecialistAccreditation() {
    this.isSaving = true;
    this.apiService.PcRenewApi?.apiV1PcRenewSaveSarRecordLinkedApplicationPost(this.pcrFormData.sarRecordLinkedApplication)
      .then(() => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'Specialist Accreditation updated');
        if (this.specAccRenewalFlag) {
          this.navigate(this.navSteps[8]);
        } else {
          this.navigate(this.navSteps[9]);
        }
      })
      .catch(err => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update Specialist Accreditation: ' + err);
        this.ai.trackException('Error, Unable to update Specialist Accreditation: ' + err);
      })
  }

  private saveIndividualDetails(step: number) {
    this.isSaving = true;
    this.apiService.PcRenewApi?.apiV1PcRenewUpdatePersonalDetailPost(this.pcrFormData.individualDetails)
      .then(() => {
        this.isSaving = false;
        this.navigate(this.navSteps[step]);
        // this.eventCtrl.emit('show-snackbar', 'success', 'Current details updated');
      })
      .catch((err: any) => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update current details: ' + err);
        this.ai.trackException('Error, Unable to update current details: ' + err);
      })
  }

  private async confirmPPP() {
    if (this.pcrFormData.practiceRecords.length > 0) {
      // const lastApplicationYear = store.getters.getAppInfo.lastApplicationYear.year;
      const _isCeased = this.pcrFormData.practiceRecords.filter((pr: PracticeDetails) => {
        const _realCessationStatus = pr.newPracticeRecord?.isCeased;
        return pr.newPracticeRecord && _realCessationStatus;
      });

      const _notCeased = this.pcrFormData.practiceRecords.filter((pr: PracticeDetails) => {
        return pr.newPracticeRecord?.name ? !pr.newPracticeRecord.isCeased : !pr.originalPracticeRecord.isCeased;
      });

      // console.log( _isCeased.length, _notCeased.length, _notCeased[0].newPracticeRecord?.id, _notCeased[0].originalPracticeRecord?.id);

      if (this.pcrFormData.practiceRecords.length == 1 && this.pcrFormData.practiceRecords[0]?.originalPracticeRecord?.isCeased) {
        // console.log('case 1');
        this.navigate(this.navSteps[2]);
      } else if (this.pcrFormData.practiceRecords.length == 1 && this.pcrFormData.practiceRecords[0]?.newPracticeRecord?.isCeased) {
        // console.log('case 2');
        this.navigate(this.navSteps[2]);
      } else if (this.pcrFormData.practiceRecords.length == 1 && this.pcrFormData.practiceRecords[0].newPracticeRecord && !this.pcrFormData.practiceRecords[0]?.newPracticeRecord?.isCeased) {
        // console.log('case 3');
        this.updatePPPandSkip(this.pcrFormData.practiceRecords[0].newPracticeRecord?.id);
      } else if (this.pcrFormData.practiceRecords.length == 1 && this.pcrFormData.practiceRecords[0].originalPracticeRecord && !this.pcrFormData.practiceRecords[0]?.originalPracticeRecord?.isCeased) {
        // console.log('case 4');
        this.updatePPPandSkip(this.pcrFormData.practiceRecords[0].originalPracticeRecord.id);
      } else if (_notCeased.length == 1 && _notCeased[0].originalPracticeRecord) {
        // console.log('case 5');
        const _recordPracticeId = _notCeased[0].newPracticeRecord?.id || _notCeased[0].originalPracticeRecord?.id;
        this.updatePPPandSkip(_recordPracticeId);
      } else if (this.pcrFormData.practiceRecords.length - _isCeased.length == 1) {
        // console.log('case 6' );
        if (_notCeased.length == 1) { // set the only 1 as PPP by default
          const _recordPracticeId = _notCeased[0].newPracticeRecord?.id || _notCeased[0].originalPracticeRecord?.id;
          this.updatePPPandSkip(_recordPracticeId);
        }
      } else {
        // console.log('case 7');
        if (this.pcrFormData.practiceRecords.length - _isCeased.length > 0) {
          this.practiceRecords = JSON.parse(JSON.stringify(this.pcrFormData.practiceRecords));
          const _confirm: any = this.$refs.pppPopup;
          _confirm.show({
            title: 'Select Principal Place of Practice',
            message: 'We need to know what your Principal Place of Practice will be as 1 July ' + this.lastApplicationYear,
            okButton: 'Next',
            cancelButton: 'Cancel',
          })
            .then((res: any) => {
              if (res) {
                this.savePPP();
              } else {
                // enable PPP selection
                // this.pcrFormData.practiceRecords = this.practiceRecords;
              }
            })
        } else {
          this.navigate(this.navSteps[2]);
        }
      }
    } else {
      this.navigate(this.navSteps[2]);
    }
  }

  private async updatePPPandSkip(recordPracticeId: number) {
    await this.apiService.PcRenewApi?.apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(
      this.pcRenewApplicationId, // pcRenewApplicationId
      recordPracticeId        // recordPracticeId
    )
      .then(() => {
        this.eventCtrl.emit('show-snackbar', 'success', 'PPP record updated');
        this.navigate(this.navSteps[2]);
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update PPP record: ' + err);
        this.ai.trackException('Error, Unable to update PPP record: ' + err);
      })
  }

  private savePPP() {
    this.saveIndividualDetails(2);
    // this.navigate(this.navSteps[2]);
  }

  private async savePCRenewalDetails(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.PcRenewApi?.apiV1PcRenewSavePcRenewalDetailsPost(this.pcrFormData.renewDetails)
      this.eventCtrl.emit('show-snackbar', 'success', 'Renew details saved');
      this.isSaving = false;
      this.navigate(this.navSteps[4]);
    } catch (err) {
      this.isSaving = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save renew details: ' + err);
      this.ai.trackException('Error, Unable to update user preference: ' + err);
    }

  }

  private async saveAdditionalDetails(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.PcRenewApi?.apiV1PcRenewSaveAdditionalDetailsPost(this.pcrFormData.additionalDetails)
      this.eventCtrl.emit('show-snackbar', 'success', 'Additional details saved');
      this.isSaving = false;
      this.navigate(this.navSteps[6]);
    } catch (err) {
      this.isSaving = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Additional details: ' + err);
      this.ai.trackException('Error, Unable to save Additional details: ' + err);
    }
  }

  private async savePcPaymentDetailsInternal() {
    this.eventCtrl.emit('apply-cc-payment');
  }

  private async savePcPaymentDetails(): Promise<void> {
    this.isSaving = true;
    try {
      let totalCost = 0;
      this.pcrFormData.paymentDetails.lineItems.forEach((x: any) => {
        totalCost += x.total;
      });

      if (totalCost == 0) {
        this.pcrFormData.paymentDetails.noMembership = !!this.pcrFormData.paymentDetails.isLifeMember;
        await this.apiService.PcRenewApi?.apiV1PcRenewSavePaymentDetailsWithZeroFeePost({
          applicationId: this.pcrFormData.paymentDetails.applicationId,
          lineItems: this.pcrFormData.paymentDetails.lineItems,
          noMembership: this.pcrFormData.noMembership
        });
        this.eventCtrl.emit('show-snackbar', 'success', 'Payment details saved');
        this.navigate(this.navSteps[11]);
      } else {
        const resp: any = await this.apiService.PcRenewApi?.apiV1PcRenewSavePaymentDetailsPost(this.pcrFormData.paymentDetails);
        this.paymentTransaction.transactionKey = resp.data.data.transactionKey;
        this.paymentTransaction.secureKey = resp.data.data.secureKey;
        this.eventCtrl.emit('show-snackbar', 'success', 'Payment details saved');
        this.showPaymentPopup = true;
      }
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Payment details: ' + err);
      this.ai.trackException('Error, Unable to save Payment details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private removePaymentWindow(): void {
    this.showPaymentPopup = false;
  }


  private paymentConfirmed() {
    this.showPaymentPopup = false;
    this.navigate(this.navSteps[11]);
  }

  get appId(): number {
    return store.getters.getAppRenew.id;
  }

  get pcRenewApplicationId() {
    return store.getters.getPcrFormData.individualDetails.pcRenewApplicationId;
  }

  get isSarStatusNew(): boolean {
    return this.pcrFormData?.individualDetails?.isSarStatusNew;
  }

  get lastApplicationYear(): number {
    return store.getters.getAppInfo.lastApplicationYear.year;
  }

  // private additionDetailsConfirmed = false;

  private additionalPracticeDetailsPopup() {
    const _nonCeased = this.pcrFormData.practiceRecords.filter((pr: { newPracticeRecord: { employmentStatus: { id: number; }; }; originalPracticeRecord: null; }) => {
      const _isCeased = pr.newPracticeRecord && pr.newPracticeRecord.employmentStatus?.id == 999999999;
      return pr.originalPracticeRecord !== null && !_isCeased;
    })

    const _newlyAdded = this.pcrFormData.practiceRecords.filter((pr: { newPracticeRecord: { employmentStatus: { id: number; }; }; originalPracticeRecord: null; }) => {
      const _isCeased = pr.newPracticeRecord && pr.newPracticeRecord.employmentStatus?.id == 999999999;
      return pr.originalPracticeRecord == null && !_isCeased;
    })
    if (_nonCeased.length > 0 && _newlyAdded.length > 0) {
      this.popupPracticeRecords = this.pcrFormData.practiceRecords.filter((pr: { originalPracticeRecord: null; }) => {
        return pr.originalPracticeRecord !== null;
      });
      const _confirm: any = this.$refs.additionalDetailsPopUp;
      _confirm.show({
        title: 'Additional Practice Details',
        message: 'You have added new practice details, are you engaged in legal practice for more than one Law Practice / Employer / Entity?',
        okButton: 'Save',
        cancelButton: 'Cancel',
      }).then((res: any) => {
        if (res) {
          // this.loadPracticeRecord(false);
          // update the top screen.
          this.eventCtrl.emit('reloadPracticeRecord');
          // this.additionDetailsConfirmed = true;
          this.reloadPracticeRecord();
        }
      })
    } else {
      // this.additionDetailsConfirmed = true;
      this.confirmPPP();
    }

  }

  private async reloadPracticeRecord(isNew?: boolean) {
    await this.apiService.PcRenewApi?.apiV1PcRenewGetPracticeDetailsApplicationIdGet(this.pcrApp.id)
      .then(res => {
        this.practiceRecords = this.sortPPP(res.data.data);
        // this.practiceRecords.forEach((p, index) => {
        //   if (p.originalPracticeRecord?.employmentStatus?.id == p.newPracticeRecord?.employmentStatus?.id
        //     && this.selectedAddress(p.originalPracticeRecord?.recordPracticeAddresses) == this.selectedAddress(p.newPracticeRecord?.recordPracticeAddresses)
        //   ) {
        //     this.revertDetails(index, p);
        //   }
        // })
        this.pcrFormData.practiceRecords = this.practiceRecords;
        this.confirmPPP();
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to reload practice records: ' + err);
        this.ai.trackException('Error, Unable to reload practice records: ' + err);
      })
  }

  private sortPPP(records: PracticeDetails[]) {
    const _isPpp = records.filter(r => {
      return r.originalPracticeRecord?.isPpp || r.newPracticeRecord?.isPpp;
    })

    const _notPpp = records.filter(r => {
      return !r.originalPracticeRecord?.isPpp && !r.newPracticeRecord?.isPpp;
    })
    return [..._isPpp, ..._notPpp];
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag1')
  private onSpecAccRenewalFlagChange(): void {
    this.updateStepsUI();
  }

  @Watch('pcrFormData.individualDetails.isSarStatusNew')
  private onIsSarStatusNewChange(): void {
    this.updateStepsUI();
  }

  get specAccRenewalFlag() {
    return this.pcrFormData?.sarRecordLinkedApplication?.renewalFlag1;
  }

  private updateStepsUI(): void {
    if (this.isSarStatusNew) {
      this.navSteps[7].visible = true;
      if (this.specAccRenewalFlag) {
        this.navSteps[8].visible = true;
      } else {
        this.navSteps[8].visible = false;
      }
    } else {
      this.navSteps[7].visible = false;
      this.navSteps[8].visible = false;
    }
  }

  private async saveApplicationDeclarations() {
    this.isSaving = true;
    try {
      await this.apiService.PcRenewApi?.apiV1PcRenewSaveSarApplicationDeclarationPost(this.pcrFormData.sarDeclaration)
      this.eventCtrl.emit('show-snackbar', 'success', 'Application declarations saved');
      this.isSaving = false;
      this.navigate(this.navSteps[9]);

    } catch (err) {
      this.isSaving = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Application declarations: ' + err);
      this.ai.trackException('Error, Unable to save Application declarations: ' + err);
    }
  }

  private async saveRenewalDeclarations() {
    this.isSaving = true;
    try {
      await this.apiService.PcRenewApi?.apiV1PcRenewSaveRecordDeclarationPost({ id: this.pcrFormData.recordDeclarationApplicationId.id, pcRenewApplicationId: this.pcrFormData.individualDetails?.pcRenewApplicationId })
      this.eventCtrl.emit('show-snackbar', 'success', 'Renewal declarations saved');
      this.isSaving = false;
      this.navigate(this.navSteps[10]);
    } catch (err) {
      this.isSaving = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Renewal declarations: ' + err);
      this.ai.trackException('Error, Unable to save Renewal declarations: ' + err);
    }
  }

  get currentSection(): string {
    return this.$route.query.sec as string;
  }

}

