import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import SwipeStepper from "@/shared/components/swipe-stepper/index.vue";
import LoadingSpinner from "@/shared/components/loading-spinner/index.vue";
import { RouteN } from "@/shared/constants/route-name";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { NavStep, NavStepStatusID, Section, Step } from "@/shared/components/header-stepper/nav-step";
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Application, PageContent } from "@/shared/modules/typescript-api-client/models";
// import { PopoverView } from "@/shared/components/popover/popover";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import StnEligibility from "../stn-eligibility/index.vue";
import StnApplicantDetails from "../stn-applicant-details/index.vue";
import StnPreferences from "../stn-preferences/index.vue";
import StnDeclaration from "../stn-declaration/index.vue";
import StnConfirmation from "../stn-confirmation/index.vue";

@Options({
  components: {
    LoadingSpinner,
    SwipeStepper,
    StnEligibility,
    StnApplicantDetails,
    StnPreferences,
    StnDeclaration,
    StnConfirmation,
    ConfirmDialogue,
    LoadingIndicator,
  },
  mixins: [FormatDateTimeMixin],
  beforeRouteEnter(to: any, from: any, next: any) {
    const onlineApplication = store.getters.getOnlineApplication;
    if (!onlineApplication.id) {
      console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  }
})
export default class StnApplication extends Vue {
  private ai = new AppInsights;

  @Watch('$route')
  public OnRouteChange(val: any, oldVal: any): void {
    const _stored = store.getters.getStnAppFormData;
    window.scrollTo(0, 0);
    if (oldVal.fullPath.indexOf('confirmation') > -1 || _stored == null) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    this.setCurrentStep();
  }

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private sectionName = '';

  private backButtonVisible = true;
  private canGoNext = false;

  private backButtonText = 'Back';
  private nextButtonText = 'Next';

  private navSteps = [
    new NavStep(Step.eligibility, Section.eligibility),
    new NavStep(Step.applicantDetails, Section.applicantDetails),
    new NavStep(Step.preferences, Section.preferences),
    new NavStep(Step.declarations, Section.declarations),
    new NavStep(Step.confirmation, Section.confirmation),
  ];

  private isLoadingData = false;
  private isSectionLoading = false;

  private stnAppFormData: any = {};

  private sessionId = new Date().getTime() + '';

  private isSaving = false;
  private popovers: any[] = [];
  private popoverKeys: string[] = [];

  private allPageContents: PageContent = {};
  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private checkingConcurrency = false;

  private helpContent: PageContent = {};

  mounted(): void {
    document.body.classList.remove('modal-open');
    const _onlineApp: Application = store.getters.getOnlineApplication;
    const _section: any = this.$route.query.sec || '';
    this.sectionName = _section;

    if (!_onlineApp.id) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Invalid Page entry, please choose an option from Portal home');
      this.$router.push({ name: RouteN.Mars.Home.n });
    } else {
      window.scrollTo(0, 0);
      this.setCurrentStep();

      this.eventCtrl.on('canGoNext', this.updateCanGoNext);
      // window.addEventListener('message', this.receivedNotification);

      this.checkingConcurrency = true;
      this.checkConcurrentApplication(this.onlineApplication);
      this.loadPageContents();
    }
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('canGoNext', this.updateCanGoNext);
    // window.removeEventListener('message', this.receivedNotification);
  }

  private updateCanGoNext(val: boolean): void {
    if (val) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
  }

  private setCurrentStep(): void {
    const _section: string = this.$route.query.sec as string;
    // if (_section !== Section.details) {
    //   this.backButtonText = 'Back';
    // } else {
    //   this.backButtonText = 'Cancel application'
    // }

    if (!_section) {
      this.backButtonText = 'Back';
      this.nextButtonText = 'Next';
    }

    switch (_section) {
      case Section.eligibility:
        this.setSteps(0);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.applicantDetails:
        this.setSteps(1);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.preferences:
        this.setSteps(2);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.declarations:
        this.setSteps(3);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.confirmation:
        this.setSteps(4);
        this.backButtonVisible = false;
        this.nextButtonText = 'Close';
        break;
      default:
        break;
    }
  }

  private setSteps(n: number) {
    this.updateHelpContent(n);
    for (let i = 0; i < this.navSteps.length; i++) {
      this.navSteps[i].status = NavStepStatusID.NotDone
    }
    for (let i = 0; i < n; i++) {
      this.navSteps[i].status = NavStepStatusID.Done
    }
    this.navSteps[n].status = NavStepStatusID.Current
    // the confirmation page
    if (n === 5) {
      for (let i = 0; i < 5; i++) {
        this.navSteps[i].status = NavStepStatusID.Unknown
      }
    } else {
      // save the current step
      // window.sessionStorage.setItem('contractData', JSON.stringify(this.contractData));
    }
  }

  private updateHelpContent(n: number) {
    switch (n) {
      case 0:
        this.helpContent = this.$getPopover('Help', 43);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 1:
        this.helpContent = this.$getPopover('Help', 13);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 2:
        this.helpContent = this.$getPopover('Help', 44);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 3:
        this.helpContent = this.$getPopover('Help', 45);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 4:
        this.helpContent = this.$getPopover('Help', 46);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      default:
        // this.helpContent = '';
        break;
    }
  }

  private goBack() {
    const _section: string = this.$route.query.sec as string;

    // if (this.isOverviewPage) {
    //   this.$router.push({ name: RouteN.Mars.Home.n});
    // }

    switch (_section) {
      case Section.eligibility:
        this.eventCtrl.emit('cancel-stn-application');
        break;
      case Section.applicantDetails:
        this.navigate(this.navSteps[0]);
        break;
      case Section.preferences:
        this.navigate(this.navSteps[1]);
        break;
      case Section.declaration:
        this.navigate(this.navSteps[2]);
        break;
      case Section.confirmation:
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }
  }

  private navigate(s: NavStep): void {
    const temp: string = JSON.stringify(this.$route.query);
    const tempJSON = JSON.parse(temp);
    tempJSON.sec = s.routeSecName;
    this.$router.push({ query: tempJSON });
  }

  private goNext() {
    store.dispatch('setStnAppFormData', this.stnAppFormData);
    const _section = this.$route.query.sec as string;

    // if (this.isOverviewPage) {
    //   this.checkConcurrentApplication();
    // }

    switch (_section) {
      case Section.eligibility:
        this.saveEligibility();
        break;
      case Section.applicantDetails:
        this.saveApplicantDetails();
        break;
      case Section.preferences:
        this.saveStnPreferenceDetailData();
        break;
      case Section.declaration:
        this.saveStnDeclarationsData()
        break;
      case Section.confirmation:
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }

  }

  private checkConcurrentApplication(app: Application) {
    this.apiService.StudentNewApi?.apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(app.id, true)
      .then(res => {
        const _stnAppFormData = {
          id: app.id,
          origNames: {}
        }
        store.dispatch('setStnAppFormData', _stnAppFormData);
        this.$router.push({ name: RouteN.Mars.StnApplication.n, query: { appId: app.id, sec: Section.eligibility } });
      })
      .catch(err => {
        if (err.response?.status == 400 && err.response.data?.errorCode == 10001) {
          this.confirmCancelConcurrentApp();
        }
      }).finally(() => {
        this.checkingConcurrency = false;
      });

  }

  private async saveEligibility(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.StudentNewApi?.apiV1StudentNewSaveStudentNewRecordEligibilityPost(this.stnAppFormData.studentRecord);
      this.eventCtrl.emit('show-snackbar', 'success', 'Eligibility details saved');
      this.navigate(this.navSteps[1]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Application details: ' + err);
      this.ai.trackException('Error, Unable to save Eligibility details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveApplicantDetails(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.StudentNewApi?.apiV1StudentNewUpdateStudentNewApplicationDetailPost(this.stnAppFormData.individualDetails);
      this.eventCtrl.emit('show-snackbar', 'success', 'Applicant details saved');
      this.navigate(this.navSteps[2]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Application details: ' + err);
      this.ai.trackException('Error, Unable to save Applicant details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }


  private async saveStnPreferenceDetailData() {
    this.isSaving = true;
    try {
      this.isSaving = true;
      await this.apiService.StudentNewApi?.apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(this.stnAppFormData.preferenceDetail);
      this.eventCtrl.emit('show-snackbar', 'success', 'User preference updated');
      this.navigate(this.navSteps[3]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update user preference: ' + err);
      this.ai.trackException('Error, Unable to update user preference: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveStnDeclarationsData() {
    this.isSaving = true;
    await this.apiService.StudentNewApi?.apiV1StudentNewSaveStudentNewRecordDeclarationPost(this.stnAppFormData.stnDeclarations)
      .then(() => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'User declaration saved');
        this.navigate(this.navSteps[4]);
      })
      .catch(err => {
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save user declaration: ' + err);
        this.ai.trackException('Error, Unable to save user declaration: ' + err);
      })
  }

  private getPopOverKeys(): void {
    const _keys = [];
    for (let p = 0; p < this.popoverKeys.length; p++) {
      if (this.popoverKeys[p + 1]) {
        const _stringEndAt = this.popoverKeys[p + 1].indexOf('}}--');
        _keys.push(this.popoverKeys[p + 1].substring(0, _stringEndAt));
      }
    }
    this.popoverKeys = _keys;
  }

  // private getPopover(pKey: string): PopoverView {
  //   const _popOver = this.popovers.filter(p => {
  //     return p.popoverKey == pKey;
  //   })[0];
  //   return _popOver;
  // }

  private async loadPageContents() {
    // const _appId = parseInt(this.$route.query.appId as string);
    try {
      this.loadingDataSet = true;
      this.errorLoadingDataSet = false;
      const resp: any[] = await Promise.all([
        this.apiService.PageApi?.apiV1PageGetPagesGet(),
        this.apiService.CodeApi?.apiV1CodeGetContentTypesGet(),
        this.apiService.PageApi?.apiV1PageGetAllPageContentsGet(),
      ]);

      for (let p = 0; p < resp[2].data.data.length; p++) {
        // push all page contents to store including popovers and help pages
        this.popovers.push({
          key: resp[2].data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp[2].data.data[p].content as string,
          isShow: resp[2].data.data[p].isShow as boolean,
          title: resp[2].data.data[p].title as string,
          pageId: resp[2].data.data[p].pageId as number,
        })
      }
      this.getPopOverKeys();
      store.dispatch('setPopovers', this.popovers);
    } catch (err) {
      this.errorLoadingDataSet = true;
      this.ai.trackException('Error, Unable to load page contents: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private confirmCancelConcurrentApp() {
    this.loadingDataSet = true;
    const _confirm: any = this.$refs.confirmCancelCurrentDialogue;
    _confirm.show({
      title: 'You are already logged in elsewhere',
      message: 'You can only be logged in on one browser at a time. If you wish to <b>continue here</b>, please select "Continue" if not, select "Cancel". <p/><div style="color: red">Please note that, if you wish to continue on this browser, you will be restarting your application. Do you still wish to continue your application here? </div>',
      okButton: 'Continue',
      cancelButton: 'Cancel',
    }).then(async (res: any) => {
      if (res) {
        this.clearSessionAndStart();
      }
      else {
        this.$router.push({ name: RouteN.Mars.Home.n });
      }
    })
  }

  private async clearSessionAndStart(): Promise<void> {
    try {
      this.loadingDataSet = true;
      await this.apiService.StudentNewApi?.apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(this.onlineApplication.id);
      this.loadingDataSet = false;
      this.navigate(this.navSteps[0]);

    } catch {
      this.loadingDataSet = false;

    }
  }

  // get isOverviewPage(): boolean {
  //   return !this.$route.query.sec && this.$route.name == RouteN.Mars.SmApplication.n;
  // }

  get $Section() {
    return Section;
  }

  get navStepsData(): NavStep[] {
    return this.navSteps;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get currentSection(): string {
    return this.$route.query.sec as string;
  }

}

