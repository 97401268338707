import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
// import { AuthorizedUser } from "@/shared/models/authorized-user";
// import Popover from "@/shared/components/popover/index.vue";
// import { PopoverView } from "@/shared/components/popover/popover";
import { Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import { orderBy } from "lodash";

@Options({
  props: {
    amrAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    // Popover
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmrPreferences extends Vue {
  private amrAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;

  private emailPreferencesList: any[] = [];
  private areaOfInterestsList: any[] = [];

  private errorLoadingDataSet = false;

  private option_SAR = null;

  private recordAreaOfInterests: any[] = [];
  private recordEmailPreferences: any[] = [];

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted(): void {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.getCheckBoxesList();
    this.canGoNextCheck();
  }

  private async getCheckBoxesList() {
    this.loadingDataSet = true;
    this.errorLoadingDataSet = false;
    this.apiService.AmRenewApi?.apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(this.onlineApplication.id)
      .then(res => {
        this.loadingDataSet = false;
        this.amrAppFormData.preferenceDetail = res.data.data;
      })
      .catch(err => {
        this.errorLoadingDataSet = true;
        this.loadingDataSet = false;
        this.ai.trackException('Error, Unable to load page contents: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents, ' + err);
      })
  }

  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      // const resp: any = await Promise.all([]);
      store.dispatch('setAmrAppFormData', this.amrAppFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private initSync(): void {
    console.log('InitSync');
  }

  private syncData(): void {
    try {
      if (!this.loadingDataSet) {
        console.log('syncData');
      }
    } catch (err) {
      console.log(err);
    }
    this.canGoNextCheck();
  }


  private isValidForm(): boolean {
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get sortedPreferences() {
    return orderBy(this.amrAppFormData.preferenceDetail?.recordEmailPreferences, ['emailPreference.sortOrder'], 'asc');
  }

  get amRenewApplicationId() {
    return this.amrAppFormData.individualDetails?.amRenewApplicationId;
  }
  

}

