import { Module } from "vuex";
import { AuthorizedUser } from "../models/authorized-user";

const userAccountStoreModule: Module<any, any> = {
  state: {
    authorizedUser: new AuthorizedUser()
  },
  mutations: {
    setUserAccount(state, authorizedUser: AuthorizedUser) {
      state.authorizedUser = authorizedUser;
    }
  },
  actions: {
    setUserAccount({ commit }, authorizedUser: AuthorizedUser) {
      commit('setAuthorizedUser', authorizedUser);
    }
  },
  getters: {
    getUserAccount: (state) => state.authorizedUser
  }
}
export default userAccountStoreModule;