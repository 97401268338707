import { Options, Vue } from "vue-property-decorator";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FirmSearch from "@/shared/components/firmSearch/index.vue";
import { FirmNameSearch } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    categoryDetail: Object,
  },
  components: {
    LoadingIndicator,
    FirmSearch,
  },
})
export default class AmnAssociateMCategoryThree extends Vue {
  private categoryDetail!: any;

  private firm: FirmNameSearch = {};
  private isAddNewFirm = false;

  mounted(): void {
      this.firm = { firmId: this.categoryDetail.firmId, firmName: this.categoryDetail.firmName };
  }

  private updateFirm(firm: FirmNameSearch) {
    this.firm = firm;
    this.categoryDetail.firmId = firm.firmId;
    this.categoryDetail.firmName = firm.firmName;
    this.categoryDetail.jurisdiction = this.firm.firmId === 0 ? this.categoryDetail.jurisdiction : '';
  }

  private isBusy(isBusy: boolean) {
    this.$emit('isBusy', isBusy);
  }

  private addNewFirm(isAddNew: boolean) {
    this.isAddNewFirm = isAddNew;
  }

}

