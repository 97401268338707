import { RouteN } from '@/shared/constants/route-name';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../components/home/index.vue';
import Logout from '@/shared/components/logout/index.vue';
import AutoLogin from '../components/auto-login/index.vue';
import MarsMain from '../components/mars-main/index.vue';
import AccessError from '../components/access-error/index.vue';
import PcrOverview from '../components/pcr-overview/index.vue';
import Pcr from '../components/pcr/index.vue';
import PcNoR from '../components/pc-nor/index.vue';
import SmApplication from '../components/sm-app/sm-application/index.vue';
import SarApplication from '../components/sar-app/sar-application/index.vue';
import AmnApplication from '../components/amn-app/amn-application/index.vue';
import StnApplication from '../components/stn-app/stn-application/index.vue';
import AmrApplication from '../components/amr-app/amr-application/index.vue';
import StrApplication from '../components/str-app/str-application/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: RouteN.Mars.LoginPage.p,
    name: RouteN.Mars.LoginPage.n,
    component: AutoLogin,
  },
  {
    path: RouteN.Mars.LogoutPage.p,
    name: RouteN.Mars.LogoutPage.n,
    component: Logout,
  },
  {
    path: RouteN.Mars.AccessError.p,
    name: RouteN.Mars.AccessError.n,
    component: AccessError,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: RouteN.Mars.Home.n
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: AccessError,
  },
  {
    path: RouteN.Mars.Main.p,
    meta: { requiresAuth: true },
    redirect: { name: RouteN.Mars.Home.n },
    name: RouteN.Mars.Main.n,
    component: MarsMain,
    children: [
      {
        path: RouteN.Mars.Home.p,
        name: RouteN.Mars.Home.n,
        component: Home
      },
      {
        path: RouteN.Mars.PcRenewOverview.p,
        name: RouteN.Mars.PcRenewOverview.n,
        component: PcrOverview
      },
      {
        path: RouteN.Mars.PcRenew.p,
        name: RouteN.Mars.PcRenew.n,
        component: Pcr
      },
      {
        path: RouteN.Mars.PcNoRenew.p,
        name: RouteN.Mars.PcNoRenew.n,
        component: PcNoR
      },
      {
        path: RouteN.Mars.SmApplication.p,
        name: RouteN.Mars.SmApplication.n,
        component: SmApplication
      },
      {
        path: RouteN.Mars.SarApplication.p,
        name: RouteN.Mars.SarApplication.n,
        component: SarApplication
      },
      {
        path: RouteN.Mars.AmnApplication.p,
        name: RouteN.Mars.AmnApplication.n,
        component: AmnApplication
      },
      {
        path: RouteN.Mars.StnApplication.p,
        name: RouteN.Mars.StnApplication.n,
        component: StnApplication
      },
      {
        path: RouteN.Mars.StrApplication.p,
        name: RouteN.Mars.StrApplication.n,
        component: StrApplication
      },
      {
        path: RouteN.Mars.AmrApplication.p,
        name: RouteN.Mars.AmrApplication.n,
        component: AmrApplication
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
