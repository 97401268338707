import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import Popover from "@/shared/components/popover/index.vue";
import { EventEmitter } from "events";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import PcrAddressDetailsUpdateModal from "../pcr-address-details-update-modal/index.vue";
import { AddressDetail, PcRenewAddressDetails, PcRenewAddressType, RecordAddress } from "@/shared/modules/typescript-api-client/models";
import store from "@/shared/store";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { orderBy } from "lodash";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    Popover,
    PcrAddressDetailsUpdateModal,
    LoadingIndicator,
  },
  mixins: [
    FormatDateTimeMixin
  ]
})
export default class PcrAddressDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('isLoadingData')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.isLoadingData);
  }

  private showUpdateModal = false;
  private editAddressType = 0; // 1 - Service; 2 - Postal

  private serviceAddressOption = 10;
  private postalAddressOption = 10;

  private pcRenewAddressDetails: PcRenewAddressDetails = {};

  private addressToEdit: AddressDetail = {};
  private addressTypes: PcRenewAddressType[] = [];
  private isLoadingData = false;

  @Watch('serviceAddressOption')
  private onServiceAddressOptionChange() {
    this.setServiceAddress();
  }

  @Watch('postalAddressOption')
  private onPostalAddressOptionChange() {
    this.setPostalAddress();
  }

  mounted() {
    this.getAddresses();
  }

  private async getAddresses(isReload?: boolean) {
    this.isLoadingData = true;
    await this.apiService.CodeApi?.apiV1CodeGetPcRenewAddressTypesGet()
      .then(res => {
        this.addressTypes = res.data.data;
        store.dispatch('setAddressTypeList', this.addressTypes);
      }).catch(err => {
        this.ai.trackException('Error, Unable to load address types: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load address types' + err);
      });

    await this.apiService.PcRenewApi?.apiV1PcRenewGetAddressDetailsApplicationIdGet(this.appId)
      .then(res => {
        this.pcRenewAddressDetails = res.data.data;
        this.pcRenewAddressDetails.serviceAddresses = orderBy(this.pcRenewAddressDetails.serviceAddresses, ['pcRenewAddressTypeId'], 'asc');
        this.pcRenewAddressDetails.postalAddresses = orderBy(this.pcRenewAddressDetails.postalAddresses, ['pcRenewAddressTypeId'], 'asc');
        if (isReload) {
          this.setServiceAddress();
          this.setPostalAddress();
        } else {
          this.getSelectedAddresses();
        }
        this.canGoNextCheck();
        this.isLoadingData = false;
      })
      .catch(err => {
        this.isLoadingData = false;
        this.ai.trackException('Error, Unable to load address details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load address details' + err);
      })

  }

  private setServiceAddress() {
    this.pcRenewAddressDetails.serviceAddresses.forEach((a: { isSelected: boolean; }) => {
      a.isSelected = false;
    });
    if (this.serviceAddressOption < this.pcRenewAddressDetails.serviceAddresses.length) {
      if (this.pcRenewAddressDetails.serviceAddresses[this.serviceAddressOption].address) {
        this.pcRenewAddressDetails.serviceAddresses[this.serviceAddressOption].isSelected = true;
      } else {
        this.serviceAddressOption = 10;
      }
    }
    this.canGoNextCheck();
  }

  private setPostalAddress() {
    this.pcRenewAddressDetails.postalAddresses.forEach((a: { isSelected: boolean; }) => {
      a.isSelected = false;
    });
    if (this.postalAddressOption < this.pcRenewAddressDetails.postalAddresses.length) {
      if (this.pcRenewAddressDetails.postalAddresses[this.postalAddressOption].address) {
        this.pcRenewAddressDetails.postalAddresses[this.postalAddressOption].isSelected = true;
      } else {
        this.postalAddressOption = 10;
      }
    }
    this.canGoNextCheck();
  }

  private openEdit(type: number, index: number) {
    this.editAddressType = type;
    this.addressToEdit = this.editAddressType == 1 ? { ... this.pcRenewAddressDetails.serviceAddresses[index]?.address } : { ... this.pcRenewAddressDetails.postalAddresses[index]?.address };
    this.addressToEdit = this.initAddress(this.addressToEdit);
    this.showUpdateModal = true;
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.lsAddressId = a.lsAddressId || 0;
    a.firmAddMapId = a.firmAddMapId || 0;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }

  private canGoNextCheck() {
    this.pcrFormData.pcRenewAddressDetails = this.pcRenewAddressDetails;
    this.eventCtrl.emit('canGoNext', this.serviceAddressOption < 10 && this.postalAddressOption < 10);
  }

  private getSelectedAddresses() {
    for (let i = 0; i < this.pcRenewAddressDetails.serviceAddresses.length; i++) {
      if (this.pcRenewAddressDetails.serviceAddresses[i].isSelected) {
        this.serviceAddressOption = i;
      }
    }
    for (let i = 0; i < this.pcRenewAddressDetails.postalAddresses.length; i++) {
      if (this.pcRenewAddressDetails.postalAddresses[i].isSelected) {
        this.postalAddressOption = i;
      }
    }
  }

  private reload(editAddressType: number, isRevertible: boolean) {
    if (editAddressType == 1) {
      this.pcrFormData.isRevertibleService = isRevertible;
    } else {
      this.pcrFormData.isRevertiblePostal = isRevertible;
    }
    this.getSelectedAddresses();
    this.getAddresses(true);
  }

  get appId(): number {
    return this.pcrFormData.appId;
  }

  get pcRenewApplicationId(): number {
    return this.pcrFormData.individualDetails.pcRenewApplicationId;
  }

  private getAddressLabel(id: number): string {
    return this.addressTypes.filter(a => {
      return a.id == id;
    })[0].description;
  }

  private getAddressDisplay(a: RecordAddress): string {
    const _label = this.getAddressLabel(a.pcRenewAddressTypeId);
    let _address = this.getAddressInline(a.address);

    if (_address == '' && a.pcRenewAddressTypeId == 2 || _address == '' && a.pcRenewAddressTypeId == 5) {
      _address = 'PPP does not exist';
    } else if (a.address?.deliveryTypeId == 8 && a.pcRenewAddressTypeId == 2) {
      _address = 'Address for Service must be a street address (practice street is not disclosed)';
    }
    if (_address.length == 0) {
      _address = '(Address is blank. Select the <b>Edit</b> button to add new address)';
      if (a.pcRenewAddressTypeId == 3) {
        this.pcrFormData.isServiceAddressBlank = true;
      }
      if (a.pcRenewAddressTypeId == 6) {
        this.pcrFormData.isPostalAddressBlank = true;
      }
    }
    return _label + ' - ' + _address;
  }

  private getAddressInline(add: AddressDetail) {
    if (add == null) {
      return '';
    }
    let address: AddressDetail = add;
    const countryName: string = address.countryName ? address.countryName : '';
    let addressEncoded = '';
    address = JSON.parse(JSON.stringify(address).replace(/:null/gi, ':""'));  // replace all null value
    if (countryName !== '' && countryName.toLowerCase() == 'australia') {
      switch (address['addressTypeId']) {
        case 1: // Street
          addressEncoded = address['buildingName'] + ' ';
          addressEncoded += address['buildingTypeName'] + ' ' + address['buildingNumber'] + ' ' + address['level'] + ', ';
          addressEncoded += address['streetNumber'] + ' ' + address['streetName'] + ' ' + address['streetTypeName'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 2: // PO Box
          addressEncoded = address['deliveryTypeName'] + ' ' + address['poBox'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 3: // DX address
          addressEncoded = 'DX ' + address['dxNumber'] + ', ';
          addressEncoded += address['dxExchangeTypeName'];
          break;
      }
    } else if (countryName !== '') {
      addressEncoded = address['foreignAddress'] + ', ' + address['countryName'];
    } else {
      addressEncoded = JSON.stringify(address).replaceAll('"', '');
    }
    return addressEncoded.replace(',', '').trim();
  }

  private revertAddress(type: number, index: number) {
    const addressDetailId = type == 1 ? this.pcRenewAddressDetails.serviceAddresses[index]?.address.id : this.pcRenewAddressDetails.postalAddresses[index]?.address.id;
    this.apiService.PcRenewApi?.apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(this.pcRenewApplicationId, addressDetailId)
      .then(() => {
        this.reload(type, false);
        this.eventCtrl.emit('show-snackbar', 'success', 'Address details reverted');
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to revert address details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to revert address details' + err);
      })
  }

}

