import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    modalOpened: Boolean,
  }
})
export default class AlertBox extends Vue {
  private modalOpened!: boolean;

  mounted() {
    if (!this.modalOpened) {
      document.body.classList.add('modal-open');
    }

  }

  beforeUnmount() {
    if (!this.modalOpened) {
      document.body.classList.remove('modal-open');
    }
  }

}
