import { Inject, Options, Vue } from "vue-property-decorator";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import ModalWindow from "@/shared/components/modal-window/index.vue";
import { AddressDetail, AmRenewPersonalDetail, Application, PcRenewFileRequest, SuburbStatePostcode } from "@/shared/modules/typescript-api-client/models";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import _ from "lodash";
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import PostcodeSearch from "@/shared/components/postcodeSearch/index.vue";
import AlertBox from "@/shared/components/alert-box/index.vue";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import AddressComponent from "../../address-component/index.vue";
import { PopoverView } from "@/shared/components/popover/popover";

@Options({
  props: {
    currentDetails: Object,
    origNames: Object,
  },
  components: {
    FileDragAndDrop,
    LoadingIndicator,
    PostcodeSearch,
    AlertBox,
    Popover,
    ConfirmDialogue,
    AddressComponent,
    AddressPreview,
    ModalWindow,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})

export default class AmrMembershipDetailsUpdateModal extends Vue {

  private currentDetails!: AmRenewPersonalDetail;
  private origNames!: any;

  private isClosing = false;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private titles: any[] = [];
  private buildingTypes: any[] = [];
  private streetTypes: any[] = [];
  private buildingType: any = { id: 0, name: '' };
  private streetType: any = { id: 0, description: '' };

  private pcRenewFileTypes: any[] = [];
  private fileIdToDelete = 0;

  private popover: PopoverView = {
    key:'',
    content: '<p><strong>Example:</strong></p><p>.headings {<br>font-family: var(--font-headings);<br>}</p><p>.body-text {<br>font-family: var(--font-body-text);<br>}</p>',
    isShow: false,
    title: 'Title',
  };

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private isLoadingData = true;

  private requiredFileUpload = false;

  private formData: AmRenewPersonalDetail = {};

  private fileList: Array<File> = [];
  private uploading = false;
  private dateReceived: Date = null as any;
  private showDeleteAlert = false;

  private isSubmitting = false;
  private suburbStatePostcode: SuburbStatePostcode = {};

  // mandatory fields
  private formFirstName = true;
  private formSurname = true;
  private formFileUpload = true;
  private formPhoneNumber = true;
  private formEmail = true;
  private isEmailDuplicated = false;
  private addressCheck = true;
  private isUpdating = false;
  private newSeed = 1;

  mounted(): void {
    document.body.classList.add('modal-open');
    this.preloadData();
    this.formData = JSON.parse(JSON.stringify(this.currentDetails));
    this.formData.postalAddress = this.formData.postalAddress ? this.initAddress(this.formData.postalAddress) : this.initAddress({});
    if (!this.formData.files) {
      this.formData.files = [];
    }
    this.streetType = {
      id: this.formData.postalAddress?.streetTypeId ?? 0,
      name: this.formData.postalAddress?.streetTypeName ?? '',
    }
    this.buildingType = {
      id: this.formData.postalAddress?.buildingTypeId ?? 0,
      name: this.formData.postalAddress?.buildingTypeName ?? '',
    }

    this.suburbStatePostcode = {
      suburbId: this.formData.postalAddress?.suburbId,
      suburbName: this.formData.postalAddress?.suburbName,
      postcode: this.formData.postalAddress?.postcode,
      stateId: this.formData.postalAddress?.stateId,
      stateName: this.formData.postalAddress?.stateName,
    }
    this.nameCheck();
  }

  beforeUnmount(): void {
    document.body.classList.remove('modal-open');
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.lsAddressId = a.lsAddressId || 0;
    a.stateId = a.stateId || 0;
    a.firmAddMapId = a.firmAddMapId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }

  private async preloadData() {
    try {
      // const _memberId: any = parseInt(this.$route.query.memberId as string, 10) || '';
      this.isLoadingData = true;
      const resp: any[] = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetTitlesGet(),
        this.apiService.CodeApi?.apiV1CodeGetPcRenewFileTypesGet(),
      ]);
      this.titles = resp[0].data.data;
      this.pcRenewFileTypes = resp[1].data.data;
    } catch (err) {
      this.isLoadingData = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to preload data: ' + err);
      this.ai.trackException('Error, Unable to preload data: ' + err);
    } finally {
      this.isLoadingData = false;
    }
  }

  private cancel() {
    // if (this.isDirty) {
    //   const _confirm: any = this.$refs.confirmDialogue;
    //   _confirm.show({
    //     title: 'Cancel Update Current Details',
    //     message: 'By cancelling, changes to the current screen will be lost.<br> Are you sure you want to cancel?',
    //     okButton: 'Yes',
    //     cancelButton: 'No',
    //   }).then((res: any) => {
    //     if (res) {
    //       this.close();
    //     }
    //   })
    // } else {
    this.close();
    // }
  }

  private close() {
    this.isClosing = true;
    setTimeout(() => {
      this.$emit('close');
    }, 500);
  }

  private updateAddress(address: AddressDetail, valid: boolean) {
    this.addressCheck = valid;
  }

  private async update() {
    this.isSubmitting = true;
    this.isUpdating = true;
    this.formData.isCurrentDetailCorrect = true;
    if (this.formValidated()) {
      this.formData.postalAddress = this.$addressCleanup(this.formData.postalAddress);
      await this.apiService.AmRenewApi?.apiV1AmRenewUpdatePersonalDetailPost(this.formData)
        .then(() => {
          this.isUpdating = false;
          this.eventCtrl.emit('show-snackbar', 'success', 'Current details updated');
          this.$emit('reloadCurrentDetails');
          this.close();
        })
        .catch(err => {
          this.isUpdating = false;
          if (err.response.data.errorCode == 30001) {
            this.isEmailDuplicated = true;
            this.eventCtrl.emit('show-snackbar', 'error', 'Email address is already registered');
            this.ai.trackException('Error, Unable to update current details: Email address is already registered');
          } else {
            this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update current details: ' + err);
            this.ai.trackException('Error, Unable to update current details: ' + err);
          }
        })
    } else {
      this.isUpdating = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Please check all required fields');
    }

  }

  private formValidated(): boolean {
    this.formFirstName = this.formData.firstName.trim() !== '';
    this.formSurname = this.formData.surname.trim() !== '';
    this.formFileUpload = true;
    if (this.requiredFileUpload) {
      this.formFileUpload = this.formData.files.length > 0;
    }
    this.formPhoneNumber = this.formData.mobilePhone.phoneNumber?.trim() !== ''
      || this.formData.businessPhone.phoneNumber?.trim() !== '' || this.formData.workPhone?.phoneNumber?.trim() !== '';

    this.formEmail = this.emailCheck();
    return this.formSurname && this.formFileUpload && this.addressCheck && this.formPhoneNumber && this.formEmail;
  }

  private fileListChanged(fileList: Array<File>): void {
    this.fileList = fileList;
    this.uploadAllFiles(this.fileList);
  }

  private deleteFile(id: number) {
    this.fileIdToDelete = id;
    const _confirm: any = this.$refs.confirmDialogue;
    _confirm.show({
      title: 'Delete File',
      message: 'Are you sure you want to delete this file?',
      okButton: 'Delete',
      cancelButton: 'Cancel',
    }).then((res: any) => {
      if (res) {
        this.deleteFileFromServer(this.fileIdToDelete);
      }
    })

  }

  private buildingTypeChange(bt: any) {
    this.formData.postalAddress.buildingTypeId = bt.id;
    this.formData.postalAddress.buildingTypeName = bt.name;
  }

  private streetTypeChange(st: any) {
    this.formData.postalAddress.streetTypeId = st.id;
    this.formData.postalAddress.streetTypeName = st.name;
  }

  private async deleteFileFromServer(id: number) {
    await this.apiService.FileApi?.apiV1FileDeleteFileFileIdDelete(id)
      .then(() => {
        for (let i = 0; i < this.formData.files.length; i++) {
          if (this.formData.files[i].id === id) {
            this.formData.files.splice(i, 1);
          }
        }
        this.nameCheck();
        this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');
      })
      .catch(err => {
        this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
        this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);
      })
  }

  private upload() {
    this.uploadAllFiles(this.fileList);
  }

  private async uploadAllFiles(fileList: Array<File>): Promise<void> {
    const allRequests: Array<Promise<boolean>> = [];
    this.uploading = true;
    try {
      if (fileList.length > 0) {
        fileList.forEach((x) => allRequests.push(this.uploadFile(x)));
        await Promise.all(allRequests);
        this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
      }
    } catch (err: any) {
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
    } finally {
      this.uploading = false;
    }
  }

  private async uploadFile(file: File): Promise<boolean> {
    // const _id: number = parseInt(this.$route.query.investigation as string, 10);
    this.getNextSeed();
    try {
      const fileStr = await this.fileToString(file);
      const body = {
        id: 0,
        fileName: file.name,
        fileExtension: file.name,
        fileDataUrl: fileStr,
        seed: this.newSeed,
        applicationId: this.amrApp.id,
        amRenewFileTypeId: 1, // 1, name Change, 2: Fit and Proper, 3: Show Cause Events.
      };

      await this.apiService.FileApi?.apiV1FileSaveAmRenewFilePost(
        body
      ).then(res => {
        this.formData.files.push({
          id: res.data.data.data,
          fileName: file.name,
          fileExtension: file.name,
          // fileDataUrl: fileStr,
          seed: this.newSeed,
          applicationId: this.amrApp.id,
          amRenewFileTypeId: 1, // 1, name Change, 2: Fit and Proper, 3: Show Cause Events.
        })
      })
      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  private fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result as string);
      };

      reader.onabort = () => {
        return reject();
      }

      reader.onerror = () => {
        return reject();
      }
    });
  }

  private nameCheck() {
    const _firstNameCheck = this.formData.firstName?.trim() == this.origNames.firstName?.trim();
    const _otherNameCheck = this.formData.otherName?.trim() == this.origNames.otherName?.trim();
    const _surnameCheck = this.formData.surname?.trim() == this.origNames.surname?.trim();
    this.requiredFileUpload = !(_firstNameCheck && _otherNameCheck && _surnameCheck);
  }

  private emailCheck(): boolean {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(this.formData.communicationEmail?.email);
  }

  private updateSuburb(s: SuburbStatePostcode) {
    this.formData.postalAddress.suburbId = s.suburbId;
    this.formData.postalAddress.suburbName = s.suburbName;
    this.formData.postalAddress.postcode = s.postcode;
    this.formData.postalAddress.stateId = s.stateId;
    this.formData.postalAddress.stateName = s.stateName;
  }

  get amrApp(): Application {
    return store.getters.getAppRenew;
  }

  get isDirty(): boolean {
    return JSON.stringify(this.currentDetails) !== JSON.stringify(this.formData);
  }

  private getNextSeed() {
    this.newSeed = this.formData.files?.length > 0
      ? (_.maxBy(this.formData.files, (f: PcRenewFileRequest) => f.seed) as PcRenewFileRequest).seed + 1
      : 1;
  }

}



