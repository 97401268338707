import { Options, Vue } from 'vue-class-component';
import PageHeader from '../page-header/page-header/index.vue';
import PageFooter from '../page-footer/index.vue';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

@Options({
  components: {
    PageHeader,
    PageFooter
  }
})
export default class StdPageLayout extends Vue {
  @Prop({ default: false })
  private showAccountControl!: boolean;

  @Prop({ default: null })
  private isExternal!: boolean;

  private baseDate: string = new Date().toUTCString();
  
  private baseYear = '2022';
  private currentYear = moment(this.baseDate).format('YYYY');
}


