import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { AmNewRecordCategoryDetailJurisdiction, Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import DatePicker from 'vue-datepicker-next';

@Options({
  props: {
    categoryDetail: Object,
    isSectionLoading: Boolean,
    australianJurisdictionsList: Array,
    uploading: Boolean,
  },
  components: {
    LoadingIndicator,
    Popover,
    FileDragAndDrop,
    DatePicker
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmnAssociateMCategoryOne extends Vue {
  private categoryDetail!: any;
  private isSectionLoading!: boolean;
  private australianJurisdictionsList!: any;
  private uploading!: boolean;

  private jurisdictionList: Array<AmNewRecordCategoryDetailJurisdiction> = [];


  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  // 1 - admittedInAustraliaFiles
  // 2 - foreignLawyerFiles
  // 3 - 
  // 4 - qualificationInstitutionFiles
  private selectedOption = null as any;

  private fileList: Array<File> = [];

  private australianJurisdiction: any = {};


  mounted(): void {
    if (this.categoryDetail?.amNewRecordCategoryDetailJurisdictions?.length > 0) {

      this.jurisdictionList = JSON.parse(JSON.stringify(this.categoryDetail.amNewRecordCategoryDetailJurisdictions));
      this.jurisdictionList.forEach((x: AmNewRecordCategoryDetailJurisdiction, i) => {
        this.jurisdictionList[i].admissionDate = new Date(x.admissionDate);
      });
      this.updateData();
    } else {
      this.jurisdictionList.push({
        id: 0,
        admissionDate: null,
        australianJurisdiction: null,
      });
    }
  }

  updateData(): void {
    this.categoryDetail.amNewRecordCategoryDetailJurisdictions = this.jurisdictionList;
    this.$emit('formChanged', {
      selectedOption: 1,
      fileList: this.fileList,
    });
    this.fileList = [];
  }

  private fileListChanged(fileList: Array<File>): void {
    this.fileList = fileList;
    this.$emit('formChanged', {
      selectedOption: 1,
      fileList: this.fileList,
    });
    this.fileList = [];
  }

  private deleteFile(fileId?: number): void {
    this.$emit('deleteFile', fileId);
  }

  private addJurisdictionRow(): void {
    if (this.allowAddJurisdiction) {
      this.jurisdictionList.unshift({
        id: 0,
        admissionDate: null,
        australianJurisdiction: null
      });
      this.updateData();
    }
  }

  private deleteJurisdictionRow(index: number): void {
    if (this.jurisdictionList.length != 1) {
      this.jurisdictionList.splice(index, 1);
      this.updateData();
    }
  }

  private offJurisdiction(selectedVal: any, itemToChk: any): boolean {
    if(selectedVal) {
      return this.jurisdictionList.filter(
        x => itemToChk.id == x?.australianJurisdiction?.id && selectedVal.id !== x?.australianJurisdiction?.id
      ).length > 0;
    } else {
      return this.jurisdictionList.filter(x => itemToChk.id == x?.australianJurisdiction?.id).length > 0;
    }
  }

  get allowAddJurisdiction(): boolean {
    return this.jurisdictionList?.length < this.australianJurisdictionsList.length &&
    !(this.jurisdictionList.filter((jurisdiction: any) => !jurisdiction.admissionDate || !jurisdiction.australianJurisdiction).length > 0);
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

