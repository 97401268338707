import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import InstitutionSearch from "@/shared/components/institutionSearch/index.vue";
import { QualificationInstitution } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    categoryDetail: Object,
    uploading: Boolean,
  },
  components: {
    LoadingIndicator,
    FileDragAndDrop,
    InstitutionSearch,
  },
  mixins: [FormatDateTimeMixin],
})
export default class  AmnAssociateMCategoryFour extends Vue {
  private categoryDetail!: any;
  private uploading!: boolean;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  // 1 - admittedInAustraliaFiles
  // 2 - foreignLawyerFiles
  // 3 - 
  // 4 - qualificationInstitutionFiles
  private selectedOption = null as any;

  private fileList: Array<File> = [];

  private fileListChanged(fileList: Array<File>): void {
    this.fileList = fileList;
    this.$emit('formChanged', {
      selectedOption: 4,
      fileList: this.fileList,
    });
  }

  private deleteFile(fileId?: number) {
    this.$emit('deleteFile', fileId);
  }

  private updateInstitution(institution: QualificationInstitution) {
    this.categoryDetail.qualificationInstitution = institution;
  }

  // private isBusy(isBusy: boolean) {
  //   this.$emit('isBusy', isBusy);
  // }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  // get onlineApplication(): Application {
  //   return store.getters.getOnlineApplication;
  // }

}

