/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AmNewApplicationRequest } from '../models';
import { AmNewIndividualDetailRequest } from '../models';
import { AmNewIndividualDetailResponse } from '../models';
import { AmNewPreferenceDetailRequest } from '../models';
import { AmNewPreferenceDetailResponse } from '../models';
import { AmNewRecordCategoryDetailRequest } from '../models';
import { AmNewRecordCategoryDetailResponse } from '../models';
import { AmNewRecordCurrentStatusRequest } from '../models';
import { AmNewRecordCurrentStatusResponse } from '../models';
import { AmNewRecordDeclarationRequest } from '../models';
import { AmNewRecordDeclarationResponse } from '../models';
import { BooleanResponse } from '../models';
import { PaymentDetailsRequest } from '../models';
import { PaymentDetailsResponse } from '../models';
import { TransactionResponse } from '../models';
/**
 * AmNewApi - axios parameter creator
 * @export
 */
export const AmNewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewCancelAmNewApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewCancelAmNewApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/CancelAmNewApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/CheckAmNewConcurrentSession/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet: async (applicationId: number, isNewApplication: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            // verify required parameter 'isNewApplication' is not null or undefined
            if (isNewApplication === null || isNewApplication === undefined) {
                throw new RequiredError('isNewApplication','Required parameter isNewApplication was null or undefined when calling apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewIndividualDetails/{applicationId}/{isNewApplication}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"isNewApplication"}}`, encodeURIComponent(String(isNewApplication)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewPaymentDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewPreferenceDetailData/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Category Detail
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewRecordCategoryDetail/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Current Status
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewRecordCurrentStatus/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmNew/GetAmNewRecordDeclaration/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewPreferenceDetailDataPost: async (body?: AmNewPreferenceDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/SaveAmNewPreferenceDetailData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Category Detail
         * @param {AmNewRecordCategoryDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordCategoryDetailPost: async (body?: AmNewRecordCategoryDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/SaveAmNewRecordCategoryDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Current Status
         * @param {AmNewRecordCurrentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordCurrentStatusPost: async (body?: AmNewRecordCurrentStatusRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/SaveAmNewRecordCurrentStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Declaration
         * @param {AmNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordDeclarationPost: async (body?: AmNewRecordDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/SaveAmNewRecordDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSavePaymentDetailsPost: async (body?: PaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/SavePaymentDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update AmNewApplication Detail
         * @param {AmNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewUpdateAmNewApplicationDetailPost: async (body?: AmNewApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/UpdateAmNewApplicationDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {AmNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewUpdateAmNewIndividualDetailPost: async (body?: AmNewIndividualDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmNew/UpdateAmNewIndividualDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmNewApi - functional programming interface
 * @export
 */
export const AmNewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewIndividualDetailResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetailsResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewPreferenceDetailResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Category Detail
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewRecordCategoryDetailResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Current Status
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewRecordCurrentStatusResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmNew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewRecordDeclarationResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewSaveAmNewPreferenceDetailDataPost(body?: AmNewPreferenceDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewSaveAmNewPreferenceDetailDataPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Category Detail
         * @param {AmNewRecordCategoryDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewSaveAmNewRecordCategoryDetailPost(body?: AmNewRecordCategoryDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewSaveAmNewRecordCategoryDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Current Status
         * @param {AmNewRecordCurrentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewSaveAmNewRecordCurrentStatusPost(body?: AmNewRecordCurrentStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewSaveAmNewRecordCurrentStatusPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save AmNew Record Declaration
         * @param {AmNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewSaveAmNewRecordDeclarationPost(body?: AmNewRecordDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewSaveAmNewRecordDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewSavePaymentDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update AmNewApplication Detail
         * @param {AmNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewUpdateAmNewApplicationDetailPost(body?: AmNewApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewUpdateAmNewApplicationDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {AmNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmNewUpdateAmNewIndividualDetailPost(body?: AmNewIndividualDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmNewApiAxiosParamCreator(configuration).apiV1AmNewUpdateAmNewIndividualDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AmNewApi - factory interface
 * @export
 */
export const AmNewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmNew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): AxiosPromise<AmNewIndividualDetailResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PaymentDetailsResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmNewPreferenceDetailResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmNew Record Category Detail
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmNewRecordCategoryDetailResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmNew Record Current Status
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmNewRecordCurrentStatusResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmNew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmNewRecordDeclarationResponse> {
            return AmNewApiFp(configuration).apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewPreferenceDetailDataPost(body?: AmNewPreferenceDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewSaveAmNewPreferenceDetailDataPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save AmNew Record Category Detail
         * @param {AmNewRecordCategoryDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordCategoryDetailPost(body?: AmNewRecordCategoryDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewSaveAmNewRecordCategoryDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save AmNew Record Current Status
         * @param {AmNewRecordCurrentStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordCurrentStatusPost(body?: AmNewRecordCurrentStatusRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewSaveAmNewRecordCurrentStatusPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save AmNew Record Declaration
         * @param {AmNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSaveAmNewRecordDeclarationPost(body?: AmNewRecordDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewSaveAmNewRecordDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any): AxiosPromise<TransactionResponse> {
            return AmNewApiFp(configuration).apiV1AmNewSavePaymentDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update AmNewApplication Detail
         * @param {AmNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewUpdateAmNewApplicationDetailPost(body?: AmNewApplicationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewUpdateAmNewApplicationDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {AmNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmNewUpdateAmNewIndividualDetailPost(body?: AmNewIndividualDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmNewApiFp(configuration).apiV1AmNewUpdateAmNewIndividualDetailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmNewApi - object-oriented interface
 * @export
 * @class AmNewApi
 * @extends {BaseAPI}
 */
export class AmNewApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Cancelled AmNewApplication
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewCancelAmNewApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmNew Concurrent Session
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewCheckAmNewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Individual Details
     * @param {number} applicationId 
     * @param {boolean} isNewApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Payment Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Preference Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmNew Record Category Detail
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmNew Record Current Status
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmNew Record Declaration
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewGetAmNewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Preference Details
     * @param {AmNewPreferenceDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewSaveAmNewPreferenceDetailDataPost(body?: AmNewPreferenceDetailRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewSaveAmNewPreferenceDetailDataPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save AmNew Record Category Detail
     * @param {AmNewRecordCategoryDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewSaveAmNewRecordCategoryDetailPost(body?: AmNewRecordCategoryDetailRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewSaveAmNewRecordCategoryDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save AmNew Record Current Status
     * @param {AmNewRecordCurrentStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewSaveAmNewRecordCurrentStatusPost(body?: AmNewRecordCurrentStatusRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewSaveAmNewRecordCurrentStatusPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save AmNew Record Declaration
     * @param {AmNewRecordDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewSaveAmNewRecordDeclarationPost(body?: AmNewRecordDeclarationRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewSaveAmNewRecordDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details
     * @param {PaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewSavePaymentDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update AmNewApplication Detail
     * @param {AmNewApplicationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewUpdateAmNewApplicationDetailPost(body?: AmNewApplicationRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewUpdateAmNewApplicationDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Individual Detail
     * @param {AmNewIndividualDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmNewApi
     */
    public apiV1AmNewUpdateAmNewIndividualDetailPost(body?: AmNewIndividualDetailRequest, options?: any) {
        return AmNewApiFp(this.configuration).apiV1AmNewUpdateAmNewIndividualDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
