import { Inject, Options, Vue } from "vue-property-decorator";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { Application, LineItem } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { orderBy } from "lodash";

@Options({
  props: {
    sarAppFormData: Object,
    isSectionLoading: Boolean,
  },
  components: {
    Popover,
    LoadingIndicator
  },
  mixins: [FormatDateTimeMixin]
})
export default class SarPaymentDetails extends Vue {

  private sarAppFormData!: any;
  private isSectionLoading!: boolean;
  private ai = new AppInsights;
  private loadingDataSet = true;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;


  mounted(): void {
    this.canGoNextCheck();
    this.getPaymentDetails();
  }

  private async getPaymentDetails(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewGetPaymentDetailsApplicationIdGet(this.onlineApplication.id);
      this.sarAppFormData.paymentDetails = resp.data.data;
      this.canGoNextCheck();
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get payment details: ' + err);
      this.ai.trackException('Error, Unable to get payment details: ' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }


  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

  private getPayableItem(itemName: string): any {
    try {
      const _rtn = this.sarAppFormData.paymentDetails.lineItems.filter(
        (x: any) => x.name.toLocaleLowerCase() == itemName.toLocaleLowerCase()
      );

      if (_rtn.length > 0) {
        return _rtn[0];
      } else {
        throw new Error('Invalid Item');
      }

    } catch {
      return {
        name: itemName,
        amountGstExcluded: 0,
        gst: 0,
        total: 0,
        lsAmountGstExcluded: 0,
        lsFeeName: '',
        lsGst: 0,
        lsTotal: 0
      }
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', true);
  }

  get item1Name(): string {
    return 'Criminal Law';
  }

  get lineItems(): LineItem[] {
    return orderBy(this.sarAppFormData.paymentDetails.lineItems, ['order'], 'asc') || []
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }
  get getTotal(): number {

    let total = 0;
    this.lineItems.forEach((x) => {
      total += x.total;
    });
    return total;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }


}

