import { Options, Vue } from "vue-property-decorator";
import { AddressDetail } from "@/shared/modules/typescript-api-client/models";


@Options({
  props: {
    address: Object,
    noTitle: Boolean,
    style: String,
  },
  components: {}
})
export default class AddressPreview extends Vue {
  private address!: AddressDetail;
  private noTitle!: boolean;
  private style!: string;

  private addressEncoded = '';

  get getAddressEncoded() {
    let address: AddressDetail = this.address;
    const countryName: string = address.countryName ? address.countryName : '';
    let addressEncoded = '';
    address = JSON.parse(JSON.stringify(address).replace(/:null/gi, ':""'));  // replace all null value
    if (countryName !== '' && countryName.toLowerCase() == 'australia') {
      const comma = address['suburbName'] && address['suburbName'].length > 0 ? ', ' : ' ';
      switch (address['addressTypeId']) {
        case 1: // Street
          addressEncoded = this.trimLine(address['buildingName']) + '\n';
          addressEncoded += this.trimLine(address['buildingTypeName'] + ' ' + address['buildingNumber'] + ' ' + address['level']) + '\n';
          addressEncoded += this.trimLine(address['streetNumber'] + ' ' + address['streetName'] + ' ' + address['streetTypeName']) + '\n';
          addressEncoded += this.trimLine(address['suburbName'] + comma + address['stateName'] + ' ' + address['postcode']);
          break;
        case 2: // PO Box
          addressEncoded = address['deliveryTypeName'] + ' ' + address['poBox'] + '\n';
          addressEncoded += address['suburbName'] + comma + address['stateName'] + ' ' + address['postcode'];
          break;
        case 3: // DX address
          addressEncoded = 'DX ' + address['dxNumber'] + '\n';
          addressEncoded += address['dxExchangeTypeName'] + '\n';
          break;
      }
    } else if (countryName !== '') {
      addressEncoded = address['foreignAddress'] + '\n' + address['countryName'];
    } else {
      // addressEncoded = JSON.stringify(address).replaceAll('"','');
    }
    return addressEncoded.replaceAll('undefined', '').trim();
  }

  private trimLine(txt: string) {
    if (txt) {
      return txt.trim();
    } else {
      return '';
    }
  }

}

