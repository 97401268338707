import { API_URL, TOKEN_REFRESH_TIME } from "@/shared/config/config";
import { RouteN } from "@/shared/constants/route-name";
import { AuthorizedUser } from "@/shared/models/authorized-user";
import AuthenticationService from "@/shared/modules/authentication/authentication.service";
import { MARSApi } from "@/shared/modules/mars-api";
import store from "@/shared/store";
import axios, { AxiosInstance } from "axios";
import { EventEmitter } from "events";
import { Vue } from "vue-class-component";
import { Provide, Options } from 'vue-property-decorator';
import * as HttpStatus from 'http-status-codes';

import SnackBar from "../shared/components/snackbar/index.vue";
import SessionExpired from "./components/session-expired/index.vue";

@Options<App>({
  components: {
    SnackBar,
    SessionExpired,
  }
})
export default class App extends Vue {

  private apiAxios?: AxiosInstance;
  private authApiAxios?: AxiosInstance;
  private gettingToken?: boolean;
  private authService?: AuthenticationService;
  private authorizedUser: AuthorizedUser = new AuthorizedUser;

  private sessionExpired = false;

  @Provide({ to: 'apiService', reactive: true })
  private apiService: MARSApi = new MARSApi();

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  created(): void {
    this.apiAxios = axios.create();
    this.authApiAxios = axios.create();

    this.authorizedUser = store.getters.getUserAccount;
    this.authService = new AuthenticationService(this.authorizedUser, this.authApiAxios);
    this.gettingToken = false;
    // this.authService?.checkAuthentication();
    this.apiAxios.interceptors.request.use(async (config) => {

      const refreshToken = (this.authorizedUser.session.issued + TOKEN_REFRESH_TIME)
        < Math.round(new Date().getTime() / 1000) && !this.gettingToken;

      while (this.gettingToken) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      try {

        if (refreshToken) {
          this.gettingToken = true;
          this.authorizedUser.token.tokenStr = '';
          await this.authService?.checkAuthentication();
          this.gettingToken = false;
        }
        if (config.headers) {
          config.headers.Authorization = 'bearer ' + this.authorizedUser.token.tokenStr;
        }
        return Promise.resolve(config);
      } catch (err) {
        this.gettingToken = false;
        return Promise.reject(config);
      }

    });

    this.apiAxios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      this.sessionExpired = false;
      if (error.response?.status == 400 && error.response.data?.errorCode == 10001) {
        // handle by concurrent handler
      } else if (error.response?.status == 400 && error.response.data?.errorCode == 20001) {
        // console.log('*** Session Expired');
        this.sessionExpired = true;
      } else {
        this.eventCtrl.emit('show-snackbar', 'error', 'Network error: ' + error.message);
      }
      if (error.response?.status === HttpStatus.UNAUTHORIZED || error.response?.status === HttpStatus.FORBIDDEN) {
        // document.location.href = RouteN.DCSI.LoginPage.p + '?redirect=' + this.$route.fullPath;
        this.$router.replace({ name: RouteN.NoAccess.n });
      }
      return Promise.reject(error);
    });

    this.apiService = new MARSApi(undefined, API_URL, this.apiAxios);
  }

  get store(): any {
    return store;
  }

}
