import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AmNewCategory, AmNewRecordCategoryDetail, Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import AmnAssociateMCategoryOne from "./amn-associate-m-category-one/index.vue";
import AmnAssociateMCategoryTwo from "./amn-associate-m-category-two/index.vue";
import AmnAssociateMCategoryThree from "./amn-associate-m-category-three/index.vue";
import AmnAssociateMCategoryFour from "./amn-associate-m-category-four/index.vue";
import _ from "lodash";
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';

@Options({
  props: {
    amnAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    AmnAssociateMCategoryOne,
    AmnAssociateMCategoryTwo,
    AmnAssociateMCategoryThree,
    AmnAssociateMCategoryFour,
    ConfirmDialogue,
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmnAssociateMCategory extends Vue {
  private amnAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;

  private categoryList: AmNewCategory[] = [];
  private currentSelection = -1;
  private australianJurisdictionsList: any = {};
  private admissionPlaceList: any = {};

  private newSeed = 1;
  private uploading = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  @Watch('currentSelection')
  private selectionChange() {
    this.initData();
  }

  @Watch('amnAppFormData.categoryDetail.admissionDate')
  private admissionDateChanged() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.australianJurisdiction')
  private australianJurisdictionChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.admissionPlace')
  private admissionPlaceChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.foreignJurisdiction')
  private foreignJurisdictionChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.firmName')
  private firmNameChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.jurisdiction')
  private jurisdictionChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.jobTitle')
  private jobTitleChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.legalQualification')
  private legalQualificationChange() {
    this.canGoNextCheck();
  }

  @Watch('amnAppFormData.categoryDetail.qualificationInstitution')
  private qualificationInstitutionChange() {
    console.log('watch', this.amnAppFormData?.categoryDetail?.qualificationInstitution);
    this.canGoNextCheck();
  }


  mounted() {
    this.eventCtrl.emit('canGoNext', false);
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetAmNewCategoriesGet(),
        this.apiService.AmNewApi?.apiV1AmNewGetAmNewRecordCategoryDetailApplicationIdGet(this.onlineApplication.id),
        this.apiService.CodeApi?.apiV1CodeGetAustralianJurisdictionsGet(),
        this.apiService.CodeApi?.apiV1CodeGetAdmissionPlacesGet(),
      ]);
      this.categoryList = resp[0].data;
      this.amnAppFormData.categoryDetail = resp[1].data.data;
      this.australianJurisdictionsList = resp[2].data.data;
      this.admissionPlaceList = resp[3].data.data;
      this.currentSelection = this.amnAppFormData?.categoryDetail?.amNewCategoryId || -1;
      this.initData();
      store.dispatch('setAmnAppFormData', this.amnAppFormData);
    } catch (err) {
      this.ai.trackException('Error, Unable to load category details: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load category details: ' + err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private initData() {
    let categoryDetail: AmNewRecordCategoryDetail = {};

    switch (this.currentSelection) {
      case 1:
        categoryDetail = {
          id: this.amnAppFormData.categoryDetail?.id || 0,
          amNewCategoryId: this.currentSelection,
          amNewApplicationId: this.amnAppFormData.individualDetails.amNewApplicationId,
          amNewRecordCategoryDetailJurisdictions: this.amnAppFormData.categoryDetail?.amNewRecordCategoryDetailJurisdictions || [],
          admittedInAustraliaFiles: this.amnAppFormData.categoryDetail?.admittedInAustraliaFiles || [],
        };
        break;
      case 2:
        categoryDetail = {
          id: this.amnAppFormData.categoryDetail?.id || 0,
          amNewCategoryId: this.currentSelection,
          amNewApplicationId: this.amnAppFormData.individualDetails.amNewApplicationId,
          admissionDate: this.amnAppFormData.categoryDetail?.foreignJurisdiction ? new Date(this.amnAppFormData.categoryDetail?.admissionDate) || null : null,
          admissionPlace: this.amnAppFormData.categoryDetail?.admissionPlace || null,
          foreignJurisdiction: this.amnAppFormData.categoryDetail?.foreignJurisdiction || '',
          foreignLawyerFiles: this.amnAppFormData.categoryDetail?.foreignLawyerFiles || [],
        };
        break;
      case 3:
        categoryDetail = {
          id: this.amnAppFormData.categoryDetail?.id || 0,
          amNewCategoryId: this.currentSelection,
          amNewApplicationId: this.amnAppFormData.individualDetails.amNewApplicationId,
          firmId: this.amnAppFormData.categoryDetail.firmId,
          firmName: this.amnAppFormData.categoryDetail.firmName || '',
          jurisdiction: this.amnAppFormData.categoryDetail?.jurisdiction || '',
          jobTitle: this.amnAppFormData.categoryDetail?.jobTitle || '',
        };
        break;
      case 4:
        categoryDetail = {
          id: this.amnAppFormData.categoryDetail?.id || 0,
          amNewCategoryId: this.currentSelection,
          amNewApplicationId: this.amnAppFormData.individualDetails.amNewApplicationId,
          legalQualification: this.amnAppFormData.categoryDetail?.legalQualification || '',
          qualificationInstitution: this.amnAppFormData.categoryDetail?.qualificationInstitution || {},
          qualificationInstitutionFiles: this.amnAppFormData.categoryDetail?.qualificationInstitutionFiles || [],
        };
        break;
    }

    this.amnAppFormData.categoryDetail = categoryDetail;
    this.$emit('update:isSectionLoading', false);
    this.canGoNextCheck();
  }

  private isFormValid(): boolean {
    const _admissionDate = this.amnAppFormData.categoryDetail.admissionDate?.toString().length > 12;
    const _validAustralianJurisdiction =
      this.amnAppFormData.categoryDetail?.amNewRecordCategoryDetailJurisdictions?.length > 0 &&
      this.amnAppFormData.categoryDetail?.amNewRecordCategoryDetailJurisdictions.filter(
        (x: any) => !x.admissionDate || !x.australianJurisdiction).length == 0;
    const _admittedInAustraliaFiles = this.amnAppFormData.categoryDetail?.admittedInAustraliaFiles?.length > 0;
    const _admissionPlaceId = this.amnAppFormData.categoryDetail?.admissionPlace?.id > 0;
    const _foreignJurisdiction = this.amnAppFormData.categoryDetail?.foreignJurisdiction && this.amnAppFormData.categoryDetail?.foreignJurisdiction?.trim() !== '';
    const _foreignLawyerFiles = this.amnAppFormData.categoryDetail?.foreignLawyerFiles?.length > 0;
    const _firmId = this.amnAppFormData.categoryDetail?.firmId >= 0;
    const _jurisdiction = this.amnAppFormData.categoryDetail?.firmId == 0 ? this.amnAppFormData.categoryDetail?.jurisdiction && this.amnAppFormData.categoryDetail?.jurisdiction?.trim() !== '' : true;
    const _firmName = this.amnAppFormData.categoryDetail?.firmName && this.amnAppFormData.categoryDetail?.firmName?.trim() !== '';
    const _jobTitle = this.amnAppFormData.categoryDetail?.jobTitle && this.amnAppFormData.categoryDetail?.jobTitle?.trim() !== '';
    const _legalQualification = this.amnAppFormData.categoryDetail?.legalQualification && this.amnAppFormData.categoryDetail?.legalQualification?.trim() !== '';
    const _qualificationInstitution = this.amnAppFormData.categoryDetail?.qualificationInstitution?.id > 0;
    const _qualificationInstitutionFiles = this.amnAppFormData.categoryDetail?.qualificationInstitutionFiles?.length > 0;

    switch (this.currentSelection) {
      case 1:
        return _validAustralianJurisdiction && _admittedInAustraliaFiles;
      case 2:
        return _admissionDate && _admissionPlaceId && _foreignJurisdiction && _foreignLawyerFiles;
      case 3:
        return _firmId && _firmName && _jobTitle && _jurisdiction;
      case 4:
        return _legalQualification && _qualificationInstitution && _qualificationInstitutionFiles;
    }
    return false;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isFormValid());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get amnApp(): Application {
    return store.getters.getAppRenew;
  }

  private fileList: File[] = [];

  private formChanged(formData: any): void {
    const category: number = formData.selectionOption;
    this.fileList = formData.fileList;
    this.uploadAllFiles(category, this.fileList);
  }

  private deleteFile(id: number) {
    const _fileIdToDelete = id;
    const _confirm: any = this.$refs.confirmDialogue;
    _confirm.show({
      title: 'Delete File',
      message: 'Are you sure you want to delete this file?',
      okButton: 'Delete',
      cancelButton: 'Cancel',
    }).then((res: any) => {
      if (res) {
        this.deleteFileFromServer(_fileIdToDelete);
      }
    })

  }

  private async deleteFileFromServer(id: number) {
    let files: any[] = [];
    switch (this.currentSelection) {
      case 1:
        files = this.amnAppFormData.categoryDetail.admittedInAustraliaFiles;
        break;
      case 2:
        files = this.amnAppFormData.categoryDetail.foreignLawyerFiles;
        break;
      case 4:
        files = this.amnAppFormData.categoryDetail.qualificationInstitutionFiles;
        break;
    }
    await this.apiService.FileApi?.apiV1FileDeleteFileFileIdDelete(id)
      .then(res => {
        console.log('File deleted: ', res);
        for (let i = 0; i < files.length; i++) {
          if (files[i].id === id) {
            files.splice(i, 1);
          }
        }
        this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');
        switch (this.currentSelection) {
          case 1:
            this.amnAppFormData.categoryDetail.admittedInAustraliaFiles = files;
            break;
          case 2:
            this.amnAppFormData.categoryDetail.foreignLawyerFiles = files;
            break;
          case 4:
            this.amnAppFormData.categoryDetail.qualificationInstitutionFiles = files;
            break;
        }
      })
      .catch(err => {
        this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
        this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);
      })
    this.canGoNextCheck();

  }

  private async uploadAllFiles(category: number, fileList: Array<File>): Promise<void> {
    const allRequests: Array<Promise<boolean>> = [];
    this.uploading = true;
    try {
      if (fileList.length > 0) {
        fileList.forEach((x) => allRequests.push(this.uploadFile(x)));
        await Promise.all(allRequests);
        this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
      }
    } catch (err: any) {
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
    } finally {
      this.uploading = false;
      this.canGoNextCheck();
    }
  }

  private async uploadFile(file: File): Promise<boolean> {
    // const _id: number = parseInt(this.$route.query.investigation as string, 10);
    this.getNextSeed();
    let _fileTypeId = 0;
    try {
      switch (this.currentSelection) {
        case 1:
          _fileTypeId = 2;
          break;
        case 2:
          _fileTypeId = 3;
          break;
        case 4:
          _fileTypeId = 4;
          break;
      }
      const fileStr = await this.fileToString(file);
      const body = {
        id: 0,
        fileName: file.name,
        fileExtension: file.name,
        fileDataUrl: fileStr,
        seed: this.newSeed,
        applicationId: this.onlineApplication.id,
        amNewFileTypeId: _fileTypeId,
      };
      switch (this.currentSelection) {
        case 1:
          await this.apiService.FileApi?.apiV1FileSaveAmNewFilePost(
            body
          ).then(res => {
            this.amnAppFormData.categoryDetail.admittedInAustraliaFiles.push({
              id: res.data.data.data,
              fileName: file.name,
              fileExtension: file.name,
              // fileDataUrl: fileStr,
              seed: this.newSeed,
              applicationId: this.amnAppFormData.id,
            })
          })
          break;
        case 2:
          await this.apiService.FileApi?.apiV1FileSaveAmNewFilePost(
            body
          ).then(res => {
            this.amnAppFormData.categoryDetail.foreignLawyerFiles.push({
              id: res.data.data.data,
              fileName: file.name,
              fileExtension: file.name,
              // fileDataUrl: fileStr,
              seed: this.newSeed,
              applicationId: this.amnAppFormData.id,
            })
          })
          break;
        case 3:
          break;
        case 4:
          await this.apiService.FileApi?.apiV1FileSaveAmNewFilePost(
            body
          ).then(res => {
            this.amnAppFormData.categoryDetail.qualificationInstitutionFiles.push({
              id: res.data.data.data,
              fileName: file.name,
              fileExtension: file.name,
              // fileDataUrl: fileStr,
              seed: this.newSeed,
              applicationId: this.amnAppFormData.id,
            })
          })
          break;
      }


      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  private fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result as string);
      };

      reader.onabort = () => {
        return reject();
      }

      reader.onerror = () => {
        return reject();
      }
    });
  }

  private getNextSeed() {
    let files = [];
    switch (this.currentSelection) {
      case 1:
        files = this.amnAppFormData.categoryDetail.admittedInAustraliaFiles;
        break;
      case 2:
        files = this.amnAppFormData.categoryDetail.foreignLawyerFiles;
        break;
      case 4:
        files = this.amnAppFormData.categoryDetail.qualificationInstitutionFiles;
        break;
    }

    this.newSeed = files.length > 0
      ? (_.maxBy(files, (f: any) => f.seed) as any).seed + 1
      : 1;
  }

  private isBusy(isBusy: boolean) {
    this.$emit('update:isSectionLoading', isBusy);
  }

}

