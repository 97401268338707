import { Module } from "vuex";

const appRenewStoreModule: Module<any, any> = {
  state: {
    appRenew: {}
  },
  mutations: {
    setAppRenew(state, app) {
      state.appRenew = app;
      // window.sessionStorage.setItem('appRenew', JSON.stringify(app));
    }
  },
  actions: {
    setAppRenew({ commit }, app) {
      commit('setAppRenew', app);
    }
  },
  getters: {
    getAppRenew: (state) => state.appRenew
  }
}
export default appRenewStoreModule;