import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import SwipeStepper from "@/shared/components/swipe-stepper/index.vue";
import LoadingSpinner from "@/shared/components/loading-spinner/index.vue";
import { RouteN } from "@/shared/constants/route-name";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { NavStep, NavStepStatusID, Section, Step } from "@/shared/components/header-stepper/nav-step";
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import PcNorCurrentDetails from "../pc-nor-current-details/index.vue";
import PcNorDeclarations from "../pc-nor-declarations/index.vue";
import PcNorConfirmation from "../pc-nor-confirmation/index.vue";
import PcNorPaymentDetails from "../pc-nor-payment-details/index.vue";
import PaymentPopup from "../payment-popup/index.vue";
import { PaymentTransaction } from "@/shared/models/payment-transaction";
import { PAYMENTAPP_URL } from "@/shared/config/config";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { PageContent, RecordNonRenewalDeclarationRequest } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';

@Options({
  components: {
    LoadingSpinner,
    SwipeStepper,
    PcNorCurrentDetails,
    PcNorDeclarations,
    PcNorConfirmation,
    PcNorPaymentDetails,
    PaymentPopup,
    LoadingIndicator,
  },
  mixins: [FormatDateTimeMixin],
  beforeRouteEnter(to: any, from: any, next: any) {
    const pcrApp = store.getters.getAppRenew;
    if (!pcrApp.id) {
      // console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  }
})
export default class PcNoR extends Vue {
  private ai = new AppInsights;

  @Watch('$route')
  public OnRouteChange(val: any, oldVal: any): void {
    const _stored = store.getters.getPcrFormData;
    window.scrollTo(0, 0);
    if (oldVal.fullPath.indexOf('confirmation') > -1 || _stored == null) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    this.setCurrentStep();
  }

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private sectionName = '';
  get $Section() {
    return Section;
  }

  private backButtonVisible = true;
  private canGoNext = false;

  private backButtonText = 'Back';
  private nextButtonText = 'Next';

  private navSteps = [
    new NavStep(Step.details, Section.details),
    new NavStep(Step.declarations, Section.declarations),
    new NavStep(Step.payment, Section.payment),
    new NavStep(Step.confirmation, Section.confirmation),
  ];

  private isLoadingData = false;
  private isSectionLoading = false;
  private isSubmitting = false;
  private isEmailDuplicated = false;

  private pcrFormData: any = {};
  private showPaymentPopup = false;
  private paymentTransaction: PaymentTransaction = new PaymentTransaction;
  private disablePaymentPopupCloseBut = false;
  private validPaymentPage = false;

  private sessionId = new Date().getTime() + '';
  private paymentAppName = 'pc-nor-payment';

  private isSaving = false;
  private helpContent: PageContent = {};

  mounted(): void {
    document.body.classList.remove('modal-open');
    // hide the payment step 
    this.navSteps[2].visible = false;
    const _appRenew = store.getters.getAppRenew;
    if (!_appRenew.id) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Invalid Page entry, please choose an option from Portal home');
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    window.scrollTo(0, 0);
    this.setCurrentStep();

    this.eventCtrl.on('canGoNext', this.updateCanGoNext);
    this.eventCtrl.on('updateStepUI', this.updateStepsUI);
    window.addEventListener('message', this.receivedNotification);
    // this.eventCtrl.on('pc-nor-payment-confirmed', this.paymentConfirmed);

    const _section: any = this.$route.query.sec || '';
    this.sectionName = _section;
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('canGoNext', this.updateCanGoNext);
    this.eventCtrl.removeListener('updateStepUI', this.updateStepsUI);
    window.removeEventListener('message', this.receivedNotification);
    // this.eventCtrl.removeListener('pc-nor-payment-confirmed', this.paymentConfirmed);
  }

  private receivedNotification(event: any): void {

    const action = event?.data?.action;
    const validOrigin = event?.origin == PAYMENTAPP_URL;
    const validSessionId = event?.data?.sessionId == this.sessionId;
    const validPaymentApp = this.paymentAppName == event?.data?.paymentAppName;

    // console.log('Received Notifications -- MARSApp');
    // console.log(action);
    // console.log(event?.origin, PAYMENTAPP_URL);
    // console.log(event?.data?.sessionId, this.sessionId);
    // console.log(event?.data?.paymentAppName, this.paymentAppName);
    // console.log('failed verification', !validOrigin, !validSessionId, !validPaymentApp);
    if (!validOrigin || !validSessionId || !validPaymentApp) {
      return;
    }

    this.processNotification(action, event?.data?.data);
  }

  private processNotification(request: string, dataO: any = null): void {
    switch (request) {
      case 'cancel-payment':
        this.eventCtrl.emit('cancel-pc-nor-payment');
        break;
      case 'payment-processed':
        this.paymentConfirmed();
        break;
      case 'parent-close-state':
        // console.log(dataO);
        this.disablePaymentPopupCloseBut = dataO.disabled;
        break;
      case 'close-payment-form':
        this.showPaymentPopup = false;
        this.validPaymentPage = false;
        this.disablePaymentPopupCloseBut = false;
        break;
      case 'payment-form-isReady':
        this.validPaymentPage = true;
        break;
    }
  }

  private updateCanGoNext(val: boolean): void {
    if (val) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
  }

  private setCurrentStep(): void {
    const _section: string = this.$route.query.sec as string;
    if (_section !== Section.details) {
      this.backButtonText = 'Back';
    } else {
      this.backButtonText = 'Cancel application'
    }
    switch (_section) {
      case Section.details:
        this.setSteps(0);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.declarations:
        this.setSteps(1);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.payment:
        this.setSteps(2);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.confirmation:
        this.setSteps(3);
        this.backButtonVisible = false;
        this.nextButtonText = 'Next';
        break;
      default:
        break;
    }
  }

  private setSteps(n: number) {
    this.updateHelpContent(n);
    for (let i = 0; i < this.navSteps.length; i++) {
      this.navSteps[i].status = NavStepStatusID.NotDone
    }
    for (let i = 0; i < n; i++) {
      this.navSteps[i].status = NavStepStatusID.Done
    }
    this.navSteps[n].status = NavStepStatusID.Current
    // the confirmation page
    const confirmationPage = this.navSteps.length - 1;
    if (n === confirmationPage) {
      for (let i = 0; i < confirmationPage; i++) {
        this.navSteps[i].status = NavStepStatusID.Unknown
      }
    } else {
      // save the current step
      // window.sessionStorage.setItem('contractData', JSON.stringify(this.contractData));
    }
  }

  private updateHelpContent(n: number) {
    switch (n) {
      case 0:
        this.helpContent = this.$getPopover('Help', 20);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 1:
        this.helpContent = this.$getPopover('Help', 52);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 2:
        this.helpContent = this.$getPopover('Help', 21);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 3:
        this.helpContent = this.$getPopover('Help', 22);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      default:
        // this.helpContent = '';
        break;
    }
  }

  private updateStepsUI(): void {
    if (this.pcrFormData?.noRenewDetails.isApplyAssociateMembership) {
      this.navSteps[2].visible = true;
    } else {
      this.navSteps[2].visible = false;
    }

  }

  private goBack() {
    const _section: string = this.$route.query.sec as string;
    switch (_section) {
      case Section.details:
        this.eventCtrl.emit('cancel-pc-no-renew');
        break;
      case Section.declarations:
        this.navigate(this.navSteps[0]);
        break;
      case Section.payment:
        this.navigate(this.navSteps[1]);
        break;
      case Section.confirmation:

        this.eventCtrl.emit('cancel-purchase');
        break;
      default:
        break;
    }
  }

  private navigate(s: NavStep): void {
    const temp: string = JSON.stringify(this.$route.query);
    const tempJSON = JSON.parse(temp);
    tempJSON.sec = s.routeSecName;
    this.$router.push({ query: tempJSON });
  }

  private goNext() {
    store.dispatch('setPcrFormData', this.pcrFormData);
    const _section = this.$route.query.sec as string;
    switch (_section) {
      case Section.details:
        this.savePCNoRenewDetails();
        break;
      case Section.declarations:
        this.saveDeclarations();
        break;
      case Section.payment:
        // this.navigate(this.navSteps[3]);
        this.savePcNoRenewPaymentDetails();
        break;
      case Section.confirmation:
        this.openSurveyWindow();
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }

  }

  private async saveDeclarations(): Promise<void> {
    this.isSubmitting = true;
    this.isSaving = true;
    const body: RecordNonRenewalDeclarationRequest = {
      pcRenewApplicationId: this.pcrFormData.individualDetails.pcRenewApplicationId,
    }
    await this.apiService.PcNonRenewApi?.apiV1PcNonRenewSaveRecordNonRenewalDeclarationPost(body)
      .then(() => {
        this.isSaving = false;
        this.isSubmitting = false;
        if (this.pcrFormData?.noRenewDetails?.isApplyAssociateMembership) {
          this.navigate(this.navSteps[2]);
        } else {
          this.navigate(this.navSteps[3]);
        }
      })
      .catch(err => {
        this.isSaving = false;
        this.isSubmitting = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save declaration: ' + err);
        this.ai.trackException('Error, Unable to to save declaration:: ' + err);
      })
  }

  private async savePCNoRenewDetails(): Promise<void> {
    this.isSubmitting = true;
    this.isSaving = true;
    this.pcrFormData.noRenewDetails.communicationEmail = this.pcrFormData.individualDetails.communicationEmail;
    await this.apiService.PcNonRenewApi?.apiV1PcNonRenewSaveRecordNonRenewalDetailsPost(this.pcrFormData.noRenewDetails)
      .then(() => {
        this.isEmailDuplicated = false;
        this.isSaving = false;
        this.eventCtrl.emit('show-snackbar', 'success', 'Current details saved');
        this.navigate(this.navSteps[1]);

      })
      .catch(err => {
        this.isSaving = false;
        if (err.response.data.errorCode == 30001) {
          this.isEmailDuplicated = true;
          this.eventCtrl.emit('show-snackbar', 'error', 'Email address is already registered');
          this.ai.trackException('Error, Unable to update current details: Email address is already registered');
        } else {
          this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update current details: ' + err);
          this.ai.trackException('Error, Unable to update user preference: ' + err);
        }
      });

  }

  private async savePcNoRenewPaymentDetails(): Promise<void> {
    this.isSaving = true;
    try {
      const resp: any = await this.apiService.PcNonRenewApi?.apiV1PcNonRenewSavePaymentDetailsPost(this.pcrFormData.paymentDetails);
      this.paymentTransaction.transactionKey = resp.data.data.transactionKey;
      this.paymentTransaction.secureKey = resp.data.data.secureKey;
      this.eventCtrl.emit('show-snackbar', 'success', 'Payment details saved');
      this.showPaymentPopup = true;
      // this.navigate(this.navSteps[8]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Payment details: ' + err);
      this.ai.trackException('Error, Unable to save Payment details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private openSurveyWindow() {
    const _url = "https://www.lawsociety.com.au/pcnrsurvey";
    window.open(_url, '_blank');
  }


  private paymentConfirmed() {
    this.showPaymentPopup = false;
    this.navigate(this.navSteps[3]);
  }

  get navStepsData(): NavStep[] {
    return this.navSteps;
  }

  get appId(): number {
    return store.getters.getAppRenew.id;
  }

  get pcRenewApplicationId() {
    return store.getters.getPcrFormData.individualDetails.pcRenewApplicationId;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get isDebugging() {
    return window.location.href.indexOf('https://localhost:44341/') == 0 && navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }

  get debugText() {
    return JSON.stringify(this.pcrFormData, undefined, 2);
  }

  get currentSection(): string {
    return this.$route.query.sec as string;
  }

}

