import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dbd892d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", {
    class: "address-preview",
    style: _normalizeStyle(_ctx.style)
  }, [
    (!_ctx.noTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Address Preview:"))
      : _createCommentVNode("", true),
    (_ctx.address)
      ? (_openBlock(), _createElementBlock("pre", _hoisted_2, _toDisplayString(_ctx.getAddressEncoded), 1))
      : _createCommentVNode("", true)
  ], 4))
}