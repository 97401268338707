import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import store from "@/shared/store";
import { EventEmitter } from "events";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import AddressPreview from "@/shared/components/address-preview/index.vue";
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import ModalWindow from "@/shared/components/modal-window/index.vue";
import { AddressDetail, Application, EmploymentStatus, FirmType, RecordPractice, RecordPracticeAddress, RecordPracticeRequest } from "@/shared/modules/typescript-api-client/models";
import { debounce, orderBy } from "lodash";
import AddressComponent from "../address-component/index.vue";

@Options({
  props: {
    selectedPracticeRecord: Object,
    pcRenewApplicationId: Number,
    addNew: Boolean,
  },
  components: {
    ModalWindow,
    LoadingIndicator,
    DatePicker,
    Popover,
    AddressPreview,
    ConfirmDialogue,
    AddressComponent,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrPracticeRecordChangeDetailModal extends Vue {
  private selectedPracticeRecord!: any;
  private pcRenewApplicationId!: number;
  private addNew!: boolean;
  private headerTitleText = '';

  private ai = new AppInsights;
  private newRecord: RecordPractice = {};
  private isFormDirty = false;
  private employmentStatusList: EmploymentStatus[] = [];
  private practiceTypeList: FirmType[] = [];
  private practiceType: FirmType = {};
  private addressList: any[] = [];
  private selectedAddressId = 0;
  private selectedAddressIndex = 0;
  private addressCheck = true;
  private isSaving = false;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private isLoadingData = false;
  private isLoadingEmploymentStatus = false;
  private isSubmitting = false;
  private dateOfChange = '';
  private isNewlyAdded = false;

  private keyword = '';
  private arrowCounter = -1;
  private searchDebounced: any = null;
  private isSearching = false;
  private stopSearch = false;
  private isOpen = false;
  private practiceList: any = [];

  private firmId = 0;
  private noSearchResult = false;
  private addNewPractice = false;

  private effectiveDate: any = null;
  private firmTypeCheck = true;
  private empStatusCheck = true;
  private validAddressDetails = true;

  private countryList = [];

  @Watch('keyword')
  private onKeywordChange(): void {
    if (this.keyword.trim().length > 2 && !this.stopSearch) {
      setTimeout(this.searchDebounced.cancel, 0);
      setTimeout(this.searchDebounced, 0);
    }
  }


  private dateError = false;

  private recordPracticeAddresses: RecordPracticeAddress[] = [];

  mounted(): void {
    this.loadPracticeTypeList();
    if (this.addNew) {
      this.headerTitleText = 'Add additional Law Practice / Employer / Entity';
      this.newRecord = { ... this.selectedPracticeRecord.newPracticeRecord };
      this.getAddressList();
      this.getAddressId();
      this.effectiveDate = null;
    } else {
      this.newRecord = { ... (this.selectedPracticeRecord.newPracticeRecord?.name ? this.selectedPracticeRecord.newPracticeRecord : this.selectedPracticeRecord.originalPracticeRecord) };
      this.effectiveDate = this.selectedPracticeRecord.newPracticeRecord?.name && !this.sameEmploymentStatus ? new Date(this.newRecord.effectiveDate) : null;
      this.recordPracticeAddresses = JSON.parse(JSON.stringify(this.newRecord.recordPracticeAddresses));
      this.recordPracticeAddresses = this.recordPracticeAddresses.map((item) => {
        return {
          id: item.id,
          isSelected: item.isSelected,
          address: item.address,
          inlineAddress: this.getAddressInline(item.address),
          businessPhone: item.businessPhone,
          businessFax: item.businessFax
        };
      });
      this.recordPracticeAddresses = orderBy(this.recordPracticeAddresses, ['inlineAddress', 'asc']);
      this.selectedAddressIndex = 0;
      this.headerTitleText = 'Update Law Practice / Employer / Entity';
      if (this.newRecord.firmId == null) {
        this.headerTitleText = 'Edit new Law Practice / Employer / Entity';
      }
      this.getAddressList();
      this.getAddressId();
      this.loadData();
    }

    this.isNewlyAdded = !this.selectedPracticeRecord.originalPracticeRecord?.name;

    this.searchDebounced = debounce(() => {
      if (this.keyword.trim() !== '') {
        this.getPracticeList();
      }
    }, 500);

  }

  private async loadPracticeTypeList() {
    await this.apiService.CodeApi?.apiV1CodeGetFirmTypesGet()
      .then(res => {
        this.practiceTypeList = (res.data.data).filter((p: { id: number, firmName: string; }) => {
          return p.id !== 4 && p.firmName !== 'F';
        });
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get practice types: ' + err);
        this.ai.trackException('Error, Unable to get practice types: ' + err);
      })
  }

  private getPracticeList() {
    this.isSearching = true;
    this.apiService.PcRenewApi?.apiV1PcRenewSearchFirmsPost({
      skip: 0,
      take: 0,
      keyword: this.keyword
    }).then(res => {
      this.isSearching = false;
      this.practiceList = res.data.data;
      if (this.practiceList.length > 0) {
        this.isOpen = true;
        this.noSearchResult = false;
      } else {
        if (!this.stopSearch) {
          this.isOpen = false;
          this.noSearchResult = true;
          this.recordPracticeAddresses = [];
          this.practiceType = {};
          this.firmId = 0;
          this.newRecord.firmId = null;
        } else {
          this.stopSearch = false;
        }
      }

    })
      .catch(err => {
        this.isSearching = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get practice result: ' + err);
        this.ai.trackException('Error, Unable to get practice result: ' + err);
      })
  }

  private async loadData(practiceType?: FirmType) {
    let _firmTypeId = this.newRecord.firmType?.id
    if (practiceType && practiceType.id > 0) {
      _firmTypeId = practiceType.id;
      this.newRecord.employmentStatus = null;
    }
    this.isLoadingEmploymentStatus = true;
    await this.apiService.CodeApi?.apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(_firmTypeId)
      .then(res => {
        this.employmentStatusList = res.data.data;
        this.employmentStatusList = orderBy(this.employmentStatusList, ['name'], 'asc');
        this.isLoadingEmploymentStatus = false;
      })
      .catch(err => {
        this.isLoadingEmploymentStatus = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get practice records: ' + err);
        this.ai.trackException('Error, Unable to get practice records: ' + err);
      })
  }

  private searchAgain() {
    this.noSearchResult = false;
    this.headerTitleText = 'Add additional Law Practice / Employer / Entity';
    this.keyword = '';
    const _searchBox = this.$refs.practiceSearch as HTMLElement;
    _searchBox.focus();
  }

  private addNewPracticeFromHere() {
    this.headerTitleText = 'Add new Law Practice / Employer / Entity';
    this.newRecord.name = this.keyword;
    this.addNewPractice = true;
    this.selectedAddressIndex = 0;
    this.recordPracticeAddresses.push(this.initAddress({}));
  }

  private cancel() {
    this.close();
  }

  private close() {
    this.$emit('close');
  }

  private update() {
    this.isSubmitting = true;
    if (this.formValidated()) {
      this.newRecord.recordPracticeAddresses = this.recordPracticeAddresses;
      const _rec = {
        originalPracticeRecord: this.selectedPracticeRecord.originalPracticeRecord,
        newPracticeRecord: this.newRecord
      }
      const _newPracticeRecord = _rec.newPracticeRecord as RecordPractice;
      const _recordPracticeRequest: RecordPracticeRequest = _newPracticeRecord;
      this.isSaving = true;
      this.apiService.PcRenewApi?.apiV1PcRenewSavePracticeDetailsPost(_recordPracticeRequest)
        .then(() => {
          this.eventCtrl.emit('show-snackbar', 'success', 'Practice records updated');
          this.$emit('update', _rec, this.addNew);
          this.isSaving = false;
          this.close();
        })
        .catch(err => {
          this.isSaving = false;
          let _msg = 'Unable to update practice records: ' + err;
          if (err.response?.status == 400 && err.response.data?.errorCode == 30002) {
            _msg = 'Error: Practice record already exists';
          }
          this.eventCtrl.emit('show-snackbar', 'error', _msg);
          this.ai.trackException('Error, Unable to update practice records: ' + err);
        })

    }
  }

  private formValidated(): boolean {
    this.empStatusCheck = this.newRecord.employmentStatus?.id > 0;
    if (this.addNewPractice) {
      this.firmTypeCheck = this.newRecord.firmType?.id > 0;
    }
    this.newRecord.effectiveDate = this.effectiveDate;
    if (!this.sameEmploymentStatus) {
      this.dateError = this.isFormDirty && this.newRecord.effectiveDate == null;
    } else {
      this.dateError = false;
    }
    this.addressCheck = this.addressList.length > 1 ? this.selectedAddressId > -1 : true;
    return !this.dateError && this.empStatusCheck && this.addressCheck && this.validAddressDetails;
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  private highlightWord(word: string) {
    try {
      const regex = new RegExp('(' + this.keyword + ')', 'ig');
      return word.replace(regex, '<mark>$1</mark>');
    } catch (err) {
      return word;
    }
  }

  private onArrowDown() {
    if (this.practiceList.length > 0) {
      if (this.arrowCounter < this.practiceList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    } else {
      if (this.arrowCounter < this.practiceList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    this.isFormDirty = false;
    this.isOpen = false;
    if (this.practiceList.length > 0) {
      this.selectPracticeFromSearch(
        this.practiceList[this.arrowCounter === -1 ? 0 : this.arrowCounter]
      );
    }
  }

  private selectPracticeFromSearch(p: any) {
    this.isOpen = false;
    this.stopSearch = true;
    this.keyword = p.firmName;
    setTimeout(() => {
      this.stopSearch = false;
    }, 500)
    this.apiService.PcRenewApi?.apiV1PcRenewGetPracticeByFirmIdFirmIdGet(p.firmId)
      .then(async res => {
        this.newRecord = res.data.data;
        this.newRecord.pcRenewApplicationId = this.pcRenewApplicationId;
        this.recordPracticeAddresses = JSON.parse(JSON.stringify(this.newRecord.recordPracticeAddresses));
        this.recordPracticeAddresses = this.recordPracticeAddresses.map((item) => {
          return {
            id: item.id,
            isSelected: item.isSelected,
            address: item.address,
            inlineAddress: this.getAddressInline(item.address),
            businessPhone: item.businessPhone,
            businessFax: item.businessFax
          };
        });
        this.recordPracticeAddresses = orderBy(this.recordPracticeAddresses, ['inlineAddress', 'asc']);
        this.selectedAddressIndex = 0;
        this.getAddressList();
        this.getAddressId();
        await this.apiService.CodeApi?.apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(this.newRecord.firmId)
          .then(res => {
            this.employmentStatusList = res.data.data;
            this.employmentStatusList = orderBy(this.employmentStatusList, ['name'], 'asc');
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to select this practice: ' + err);
        this.ai.trackException('Error, Unable to select this practice: ' + err);
      })

    // this.$emit('updateSuburb', _suburb);
    // this.keyword = '';
  }

  private getAddressId() {
    for (let i = 0; i < this.recordPracticeAddresses.length; i++) {
      if (this.recordPracticeAddresses[i].isSelected) {
        this.selectedAddressIndex = i;
        this.selectedAddressId = this.recordPracticeAddresses[i].id;
      }
    }
  }

  private setAddressId() {
    this.formCheck();
    this.recordPracticeAddresses.forEach(r => {
      r.isSelected = false;
    });
    this.recordPracticeAddresses[this.selectedAddressIndex].isSelected = true;
  }

  private getAddressList() {
    if (this.addNew && this.recordPracticeAddresses.length > 1) {
      this.setAddressId();
    }
    this.addressList = [];
    this.selectedAddressIndex = 0;
    for (let i = 0; i < this.recordPracticeAddresses.length; i++) {
      this.addressList.push({
        id: this.recordPracticeAddresses[i].id,
        address: this.getAddressInline(this.recordPracticeAddresses[i].address)
      })
    }
  }

  private getAddressInline(add: AddressDetail) {
    let address: AddressDetail = add;
    const countryName: string = address.countryName ? address.countryName : '';
    let addressEncoded = '';
    address = JSON.parse(JSON.stringify(address).replace(/:null/gi, ':""'));  // replace all null value
    if (countryName !== '' && countryName.toLowerCase() == 'australia') {
      switch (address['addressTypeId']) {
        case 1: // Street
          addressEncoded = address['buildingName'] + ' ';
          addressEncoded += address['buildingTypeName'] + ' ' + address['buildingNumber'] + ' ' + address['level'] + ', ';
          addressEncoded += address['streetNumber'] + ' ' + address['streetName'] + ' ' + address['streetTypeName'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 2: // PO Box
          addressEncoded = address['deliveryTypeName'] + ' ' + address['poBox'] + ',';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 3: // DX address
          addressEncoded = 'DX ' + address['dxNumber'] + ',';
          addressEncoded += address['dxExchangeTypeName'] + ',';
          break;
      }
    } else if (countryName !== '') {
      addressEncoded = address['foreignAddress'] + ', ' + address['countryName'];
    } else {
      addressEncoded = JSON.stringify(address).replaceAll('"', '');
    }
    return addressEncoded.replace(',', ' ').trim();
  }

  private formCheck() {
    this.isFormDirty = true;
    if (this.isSubmitting) {
      this.formValidated();
    }
  }

  private updateAddress(address: AddressDetail, validAddressDetails: boolean) {
    this.formCheck();
    this.recordPracticeAddresses = [];
    this.recordPracticeAddresses.push({ id: 0, isSelected: true, address: address });
    this.validAddressDetails = validAddressDetails;
  }

  private disableAfterJulyTheFirst(date: any) {
    const lastApplicationYear = store.getters.getAppInfo.lastApplicationYear.year;
    return date > new Date(new Date(lastApplicationYear + '-07-01T23:59:59'));
  }

  get sameEmploymentStatus(): boolean {
    return this.selectedPracticeRecord.originalPracticeRecord?.employmentStatus?.id == this.newRecord?.employmentStatus?.id;
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.firmAddMapId = a.firmAddMapId || 0;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }

  get previewAddress(): AddressDetail {
    return this.recordPracticeAddresses[this.selectedAddressIndex]?.address;
  }

  private getSelectedPhoneNumber(isFax: boolean): string {
    const practiceAddress = this.recordPracticeAddresses[this.selectedAddressIndex];
    const phoneNumber = isFax ? practiceAddress.businessFax : practiceAddress.businessPhone
    return phoneNumber?.phoneNumber ? phoneNumber.phoneAreaCode + ' ' + phoneNumber.phoneNumber : '';
  }

}

