/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanResponse } from '../models';
import { StudentNewApplicationRequest } from '../models';
import { StudentNewIndividualDetailRequest } from '../models';
import { StudentNewIndividualDetailResponse } from '../models';
import { StudentNewPreferenceDetailRequest } from '../models';
import { StudentNewPreferenceDetailResponse } from '../models';
import { StudentNewRecordDeclarationRequest } from '../models';
import { StudentNewRecordDeclarationResponse } from '../models';
import { StudentNewRecordEligibilityRequest } from '../models';
import { StudentNewRecordEligibilityResponse } from '../models';
/**
 * StudentNewApi - axios parameter creator
 * @export
 */
export const StudentNewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewCancelStudentNewApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewCancelStudentNewApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentNew/CancelStudentNewApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Student New Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentNew/CheckStudentNewConcurrentSession/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentNew/GetStudentNewPreferenceDetailData/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Student New Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentNew/GetStudentNewRecordDeclaration/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Student New Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet: async (applicationId: number, isNewApplication: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet.');
            }
            // verify required parameter 'isNewApplication' is not null or undefined
            if (isNewApplication === null || isNewApplication === undefined) {
                throw new RequiredError('isNewApplication','Required parameter isNewApplication was null or undefined when calling apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet.');
            }
            const localVarPath = `/api/v1/StudentNew/GetStudentNewRecordEligibility/{applicationId}/{isNewApplication}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"isNewApplication"}}`, encodeURIComponent(String(isNewApplication)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentNew/GetStudentNewStudentIndividualDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewPreferenceDetailDataPost: async (body?: StudentNewPreferenceDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentNew/SaveStudentNewPreferenceDetailData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save StudentNew Record Declaration
         * @param {StudentNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewRecordDeclarationPost: async (body?: StudentNewRecordDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentNew/SaveStudentNewRecordDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Student New Record Eligibility
         * @param {StudentNewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewRecordEligibilityPost: async (body?: StudentNewRecordEligibilityRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentNew/SaveStudentNewRecordEligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update StudentNewApplication Detail
         * @param {StudentNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewUpdateStudentNewApplicationDetailPost: async (body?: StudentNewApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentNew/UpdateStudentNewApplicationDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewUpdateStudentNewIndividualDetailPost: async (body?: StudentNewIndividualDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentNew/UpdateStudentNewIndividualDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentNewApi - functional programming interface
 * @export
 */
export const StudentNewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Student New Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentNewPreferenceDetailResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Student New Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentNewRecordDeclarationResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Student New Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentNewRecordEligibilityResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentNewIndividualDetailResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body?: StudentNewPreferenceDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save StudentNew Record Declaration
         * @param {StudentNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewSaveStudentNewRecordDeclarationPost(body?: StudentNewRecordDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewSaveStudentNewRecordDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Student New Record Eligibility
         * @param {StudentNewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewSaveStudentNewRecordEligibilityPost(body?: StudentNewRecordEligibilityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewSaveStudentNewRecordEligibilityPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update StudentNewApplication Detail
         * @param {StudentNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewUpdateStudentNewApplicationDetailPost(body?: StudentNewApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewUpdateStudentNewApplicationDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentNewUpdateStudentNewIndividualDetailPost(body?: StudentNewIndividualDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentNewApiAxiosParamCreator(configuration).apiV1StudentNewUpdateStudentNewIndividualDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentNewApi - factory interface
 * @export
 */
export const StudentNewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentNewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Student New Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentNewPreferenceDetailResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Student New Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentNewRecordDeclarationResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Student New Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): AxiosPromise<StudentNewRecordEligibilityResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentNewIndividualDetailResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentNewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body?: StudentNewPreferenceDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save StudentNew Record Declaration
         * @param {StudentNewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewRecordDeclarationPost(body?: StudentNewRecordDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewSaveStudentNewRecordDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Student New Record Eligibility
         * @param {StudentNewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewSaveStudentNewRecordEligibilityPost(body?: StudentNewRecordEligibilityRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewSaveStudentNewRecordEligibilityPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update StudentNewApplication Detail
         * @param {StudentNewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewUpdateStudentNewApplicationDetailPost(body?: StudentNewApplicationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewUpdateStudentNewApplicationDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentNewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentNewUpdateStudentNewIndividualDetailPost(body?: StudentNewIndividualDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentNewApiFp(configuration).apiV1StudentNewUpdateStudentNewIndividualDetailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentNewApi - object-oriented interface
 * @export
 * @class StudentNewApi
 * @extends {BaseAPI}
 */
export class StudentNewApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Cancelled StudentNewApplication
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId: number, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Student New Concurrent Session
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId: number, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewCheckStudentNewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Preference Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewGetStudentNewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Student New Record Declaration
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId: number, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewGetStudentNewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Student New Record Eligibility
     * @param {number} applicationId 
     * @param {boolean} isNewApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewGetStudentNewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Individual Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewGetStudentNewStudentIndividualDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Preference Details
     * @param {StudentNewPreferenceDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body?: StudentNewPreferenceDetailRequest, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewSaveStudentNewPreferenceDetailDataPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save StudentNew Record Declaration
     * @param {StudentNewRecordDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewSaveStudentNewRecordDeclarationPost(body?: StudentNewRecordDeclarationRequest, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewSaveStudentNewRecordDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Student New Record Eligibility
     * @param {StudentNewRecordEligibilityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewSaveStudentNewRecordEligibilityPost(body?: StudentNewRecordEligibilityRequest, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewSaveStudentNewRecordEligibilityPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update StudentNewApplication Detail
     * @param {StudentNewApplicationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewUpdateStudentNewApplicationDetailPost(body?: StudentNewApplicationRequest, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewUpdateStudentNewApplicationDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Individual Detail
     * @param {StudentNewIndividualDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentNewApi
     */
    public apiV1StudentNewUpdateStudentNewIndividualDetailPost(body?: StudentNewIndividualDetailRequest, options?: any) {
        return StudentNewApiFp(this.configuration).apiV1StudentNewUpdateStudentNewIndividualDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
