import { Module } from "vuex";
import { Application } from "../modules/typescript-api-client/models";

const onlineApplicationStoreModule: Module<any, any> = {
  state: {
    onlineApplication: <Application> {}
  },
  mutations: {
    setOnlineApplication(state, app: Application) {
      state.onlineApplication = app;
    }
  },
  actions: {
    setOnlineApplication({ commit }, app: Application) {
      commit('setOnlineApplication', app);
    }
  },
  getters: {
    getOnlineApplication: (state) => state.onlineApplication
  }
}
export default onlineApplicationStoreModule;