import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { RecordAdditionalDetails } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";


@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
  }
})
export default class PcrAdditionalDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private loadingDataSet = false;

  private additionalDetails: RecordAdditionalDetails = {};

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }
  
  mounted() {
    this.loadAdditionalDetails();
  }

  private async loadAdditionalDetails() {
    this.loadingDataSet = true;
    await this.apiService.PcRenewApi?.apiV1PcRenewGetAdditionalDetailsApplicationIdGet(this.appId)
      .then(res => {
        this.additionalDetails = res.data.data;
        this.pcrFormData.additionalDetails = this.additionalDetails;
        this.loadingDataSet = false;
        this.canGoNextCheck();
      })
      .catch(err => {
        this.canGoNextCheck();
        this.loadingDataSet = false;
        this.ai.trackException('Error, Unable to load Additional details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load Additional details' + err);
      })
  }

  private canGoNextCheck() {
    // const _jobTitleCheck = this.additionalDetails.pppJobTitle && this.additionalDetails.pppJobTitle.trim() !== '';
    // const _options = this.additionalDetails.pcRequiredFlag !== null;
    // this.eventCtrl.emit('canGoNext', _jobTitleCheck && _options);
    this.eventCtrl.emit('canGoNext', true);
  }

  get appId(): number {
    return this.pcrFormData.appId;
  }

  get pcRenewApplicationId(): number {
    return this.pcrFormData.individualDetails.pcRenewApplicationId;
  }

}

