import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import UserDefine from '@/assets/config/user-define.json';
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import HelpScreen from "@/shared/components/help-screen/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Application } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    strAppFormData: Object,
    isSectionLoading: Boolean,
  },
  components: {
    Popover,
    HelpScreen,
    LoadingIndicator,
  },
  mixins: [
    [FormatDateTimeMixin],
  ]
})
export default class StrEligibility extends Vue {
  private ai = new AppInsights;

  private strAppFormData!: any;
  private isSectionLoading!: boolean;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private showHelpScreen = false;
  private isRenew = null;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private studentRecord: any = {};
  private isStudying = false;


  

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }


  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      await Promise.all([
        this.getStudentApplication()
      ]);
      store.dispatch('setStrAppFormData', this.strAppFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private async getStudentApplication(): Promise<boolean> {
    try {
      const _isRenewing = !!this.strAppFormData.studentRecord;
      const resp: any = await this.apiService.StudentRenewApi?.apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(
        this.onlineApplication.id, !_isRenewing
      );
      this.studentRecord = resp.data.data;
      this.strAppFormData['studentRecord'] = this.studentRecord;
      this.initSync();
      this.canGoNextCheck();
      return Promise.resolve(true);
    } catch (err: any) {
      if (err.response?.data && err.response.data?.errorCode == 10001) {
        this.eventCtrl.emit('show-snackbar', 'info', 'Concurrent application detected');
      } else {
        this.ai.trackException('Error, Unable to get user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get user details, redirect to Portal home');
      }
      // this.$router.push({ name: RouteN.Mars.Home.n });
      return Promise.reject(false);
    }
  }

  private initSync(): void {
    this.isStudying = this.studentRecord.eligibilityFlag1;
  }

  private syncData(): void {
    try {
      if (!this.loadingDataSet) {
        this.studentRecord.eligibilityFlag1 = this.isStudying;
      }
    } catch (err) {
      console.log(err);
    }
    this.canGoNextCheck();
  }

  private isValidateForm(): boolean {
    return !!this.isStudying;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidateForm());
  }

  private exitApplication(): void {
    this.eventCtrl.emit('cancel-str-fromlink');
  }


  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }
}