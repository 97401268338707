/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { LogEntryBusiness } from '../models';
import { ObjectIEnumerableResponse } from '../models';
import { ObjectResponse } from '../models';
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemAuthorizedPingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/AuthorizedPing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All | Returns 401 Unauthorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetAuthError401Get: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/GetAuthError401`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All | Returns 403 Forbidden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetAuthError403Get: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/GetAuthError403`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/GetBaseDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateUtcGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/GetBaseDateUtc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateWebJobGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/GetBaseDateWebJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} seq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetGeneratePdfExampleSeqGet: async (seq: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seq' is not null or undefined
            if (seq === null || seq === undefined) {
                throw new RequiredError('seq','Required parameter seq was null or undefined when calling apiV1SystemGetGeneratePdfExampleSeqGet.');
            }
            const localVarPath = `/api/v1/System/GetGeneratePdfExample/{seq}`
                .replace(`{${"seq"}}`, encodeURIComponent(String(seq)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/PingLsApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiPolarisGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/PingLsApiPolaris`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiSchemeApiGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/PingLsApiSchemeApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize | Put logs into system
         * @param {LogEntryBusiness} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemSaveLogEntryBusinessPost: async (body?: LogEntryBusiness, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/System/SaveLogEntryBusiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API User Only | Set ConfigCurrentDate
         * @param {string} dateTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemSetConfigCurrentDateDateTimeStringGet: async (dateTimeString: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateTimeString' is not null or undefined
            if (dateTimeString === null || dateTimeString === undefined) {
                throw new RequiredError('dateTimeString','Required parameter dateTimeString was null or undefined when calling apiV1SystemSetConfigCurrentDateDateTimeStringGet.');
            }
            const localVarPath = `/api/v1/System/SetConfigCurrentDate/{dateTimeString}`
                .replace(`{${"dateTimeString"}}`, encodeURIComponent(String(dateTimeString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemAuthorizedPingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemAuthorizedPingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary All | Returns 401 Unauthorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetAuthError401Get(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResponse>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetAuthError401Get(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary All | Returns 403 Forbidden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetAuthError403Get(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectIEnumerableResponse>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetAuthError403Get(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetBaseDateGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetBaseDateGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetBaseDateUtcGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetBaseDateUtcGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetBaseDateWebJobGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetBaseDateWebJobGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} seq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemGetGeneratePdfExampleSeqGet(seq: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemGetGeneratePdfExampleSeqGet(seq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemPingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemPingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemPingLsApiGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemPingLsApiGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemPingLsApiPolarisGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemPingLsApiPolarisGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemPingLsApiSchemeApiGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemPingLsApiSchemeApiGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authorize | Put logs into system
         * @param {LogEntryBusiness} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemSaveLogEntryBusinessPost(body?: LogEntryBusiness, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemSaveLogEntryBusinessPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary API User Only | Set ConfigCurrentDate
         * @param {string} dateTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Authorize | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemAuthorizedPingGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemAuthorizedPingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All | Returns 401 Unauthorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetAuthError401Get(options?: any): AxiosPromise<ObjectResponse> {
            return SystemApiFp(configuration).apiV1SystemGetAuthError401Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All | Returns 403 Forbidden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetAuthError403Get(options?: any): AxiosPromise<ObjectIEnumerableResponse> {
            return SystemApiFp(configuration).apiV1SystemGetAuthError403Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemGetBaseDateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateUtcGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemGetBaseDateUtcGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetBaseDateWebJobGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemGetBaseDateWebJobGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns file as stream
         * @param {number} seq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemGetGeneratePdfExampleSeqGet(seq: number, options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).apiV1SystemGetGeneratePdfExampleSeqGet(seq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemPingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemPingLsApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiPolarisGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemPingLsApiPolarisGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow Anonymous | Returns current date time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemPingLsApiSchemeApiGet(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).apiV1SystemPingLsApiSchemeApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize | Put logs into system
         * @param {LogEntryBusiness} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemSaveLogEntryBusinessPost(body?: LogEntryBusiness, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).apiV1SystemSaveLogEntryBusinessPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API User Only | Set ConfigCurrentDate
         * @param {string} dateTimeString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Authorize | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemAuthorizedPingGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemAuthorizedPingGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary All | Returns 401 Unauthorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetAuthError401Get(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetAuthError401Get(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary All | Returns 403 Forbidden
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetAuthError403Get(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetAuthError403Get(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetBaseDateGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetBaseDateGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetBaseDateUtcGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetBaseDateUtcGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetBaseDateWebJobGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetBaseDateWebJobGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns file as stream
     * @param {number} seq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemGetGeneratePdfExampleSeqGet(seq: number, options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemGetGeneratePdfExampleSeqGet(seq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemPingGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemPingGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemPingLsApiGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemPingLsApiGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemPingLsApiPolarisGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemPingLsApiPolarisGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Allow Anonymous | Returns current date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemPingLsApiSchemeApiGet(options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemPingLsApiSchemeApiGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Authorize | Put logs into system
     * @param {LogEntryBusiness} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemSaveLogEntryBusinessPost(body?: LogEntryBusiness, options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemSaveLogEntryBusinessPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary API User Only | Set ConfigCurrentDate
     * @param {string} dateTimeString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString: string, options?: any) {
        return SystemApiFp(this.configuration).apiV1SystemSetConfigCurrentDateDateTimeStringGet(dateTimeString, options).then((request) => request(this.axios, this.basePath));
    }
}
