import { Module } from "vuex";

const appInfoStoreModule: Module<any, any> = {
  state: {
    appInfo: {}
  },
  mutations: {
    setAppInfo(state, appInfo) {
      state.appInfo = appInfo;
      window.sessionStorage.setItem('appInfo', JSON.stringify(appInfo));
    }
  },
  actions: {
    setAppInfo({ commit }, appInfo) {
      commit('setAppInfo', appInfo);
    }
  },
  getters: {
    getAppInfo: (state) => state.appInfo
  }
}
export default appInfoStoreModule;