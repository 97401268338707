import { Inject, Options, Vue } from "vue-property-decorator";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
  },
  mixins: [],
})
export default class PcrRenewalDeclarations extends Vue {
  private isSectionLoading!: boolean;
  private pcrFormData!: any;

  private ai = new AppInsights;
  private loadingDataSet = true;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }
  
  mounted(): void {
    this.getApplicationId();
  }

  private getApplicationId(): void {
    this.apiService.PcRenewApi?.apiV1PcRenewGetRecordDeclarationApplicationIdGet(this.pcrFormData.appId)
      .then((res: any) => {
        this.pcrFormData.recordDeclarationApplicationId = res.data.data || { id: 0 };
        this.eventCtrl.emit('canGoNext', true);
      })
      .catch((err: any) => {
        this.ai.trackException('Error, Unable to load declaration data : ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load declaration data');
        this.eventCtrl.emit('canGoNext', false);
      })
  }

}

