/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationIEnumerableResponse } from '../models';
import { ApplicationResponse } from '../models';
import { FileResponse } from '../models';
import { PractisingCertificateIEnumerableResponse } from '../models';
/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Application By applicationId
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetApplicationByIdApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1ApplicationGetApplicationByIdApplicationIdGet.');
            }
            const localVarPath = `/api/v1/Application/GetApplicationById/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetMyApplicationsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Application/GetMyApplications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Practising Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetMyPractisingCertificateGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Application/GetMyPractisingCertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} pcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetPractisingCertificateFilePcIdGet: async (pcId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcId' is not null or undefined
            if (pcId === null || pcId === undefined) {
                throw new RequiredError('pcId','Required parameter pcId was null or undefined when calling apiV1ApplicationGetPractisingCertificateFilePcIdGet.');
            }
            const localVarPath = `/api/v1/Application/GetPractisingCertificateFile/{pcId}`
                .replace(`{${"pcId"}}`, encodeURIComponent(String(pcId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Application By applicationId
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationGetMyApplicationsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationIEnumerableResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).apiV1ApplicationGetMyApplicationsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Practising Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationGetMyPractisingCertificateGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PractisingCertificateIEnumerableResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).apiV1ApplicationGetMyPractisingCertificateGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} pcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Application By applicationId
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId: number, options?: any): AxiosPromise<ApplicationResponse> {
            return ApplicationApiFp(configuration).apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetMyApplicationsGet(options?: any): AxiosPromise<ApplicationIEnumerableResponse> {
            return ApplicationApiFp(configuration).apiV1ApplicationGetMyApplicationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Practising Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetMyPractisingCertificateGet(options?: any): AxiosPromise<PractisingCertificateIEnumerableResponse> {
            return ApplicationApiFp(configuration).apiV1ApplicationGetMyPractisingCertificateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns file
         * @param {number} pcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId: number, options?: any): AxiosPromise<FileResponse> {
            return ApplicationApiFp(configuration).apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Application By applicationId
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId: number, options?: any) {
        return ApplicationApiFp(this.configuration).apiV1ApplicationGetApplicationByIdApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Applications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiV1ApplicationGetMyApplicationsGet(options?: any) {
        return ApplicationApiFp(this.configuration).apiV1ApplicationGetMyApplicationsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Practising Certificates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiV1ApplicationGetMyPractisingCertificateGet(options?: any) {
        return ApplicationApiFp(this.configuration).apiV1ApplicationGetMyPractisingCertificateGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns file
     * @param {number} pcId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId: number, options?: any) {
        return ApplicationApiFp(this.configuration).apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId, options).then((request) => request(this.axios, this.basePath));
    }
}
