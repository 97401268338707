export class Session {

  constructor(
    public issued: number = 0,
    public expires: number = 0,
  ) {}

  public hasExpired(): boolean {
    return Math.round(new Date().getTime() / 1000) >= this.expires;
  }
}
