import { Options, Vue } from "vue-property-decorator";
import { RouteN } from "@/shared/constants/route-name";
import AlertBox from "@/shared/components/alert-box/index.vue";

@Options({
  components: {
    AlertBox,
  }
})
export default class SessionExpired extends Vue {
  private showAlert = true;

  mounted(): void {
    setTimeout(() => {
      this.goHome();
    }, 10000)
  }

  private goHome() {
    this.$emit('close');
    this.$router.push({ name: RouteN.Mars.Home.n });
  }

}

