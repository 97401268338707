
import { Options, Vue } from 'vue-class-component';
import StdPageLayout from '@/shared/components/std-page-layout/index.vue';

@Options({
  components: {
    StdPageLayout
  }
})
export default class AccessError extends Vue {
}
