import { AppInsights } from "@/mars-app/appInsights";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { MARSApi } from "@/shared/modules/mars-api";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { orderBy } from "lodash";
import { Inject, Options, Vue, Watch } from "vue-property-decorator";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
  }
})
export default class PcrPreferences extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private emailPreferencesList: any[] = [];
  private areaOfInterestsList: any[] = [];

  private loadingDataSet = false;
  private errorLoadingDataSet = false;

  private option_SAR = null;

  private recordAreaOfInterests: any[] = [];
  private recordEmailPreferences: any[] = [];
  private recordLinkedApplication: any = null;
  // private specAccRenewalFlag: any = null;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  // @Watch('specAccRenewalFlag')
  // private onSpecAccRenewalFlagChange() {
  //   if (this.recordLinkedApplication && this.specAccRenewalFlag !== null) {
  //     this.pcrFormData.preferenceDetail.recordLinkedApplication.specAccRenewalFlag = this.specAccRenewalFlag;
  //     this.canGoNextCheck();
  //   }
  // }

  mounted(): void {
    this.getCheckBoxesList();
    this.canGoNextCheck();
  }

  private async getCheckBoxesList() {
    this.loadingDataSet = true;
    this.errorLoadingDataSet = false;
    this.apiService.PcRenewApi?.apiV1PcRenewGetPreferenceDetailsApplicationIdGet(this.appId)
      .then(res => {
        this.loadingDataSet = false;
        this.pcrFormData.preferenceDetail = res.data.data;
        this.recordLinkedApplication = res.data.data.recordLinkedApplication;
        // if (this.recordLinkedApplication) {
        //   this.specAccRenewalFlag = this.recordLinkedApplication?.specAccRenewalFlag;
        // } else {
        //   this.specAccRenewalFlag = false;
        // }
        this.canGoNextCheck();
      })
      .catch(err => {
        this.errorLoadingDataSet = true;
        this.loadingDataSet = false;
        this.ai.trackException('Error, Unable to load page contents: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents' + err);
      })
  }

  get sortedPreferences() {
    return orderBy(this.pcrFormData.preferenceDetail?.recordEmailPreferences, ['emailPreference.sortOrder'], 'asc');
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', true);
  }

  get appId() {
    return this.pcrFormData.appId;
  }

}

