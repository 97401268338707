import { Vue } from 'vue-class-component';
import { EventEmitter } from 'events';
import store from '@/shared/store';
/**
 * Snackbar
 * 
 * @export
 * @class Snackbar
 * @extends {Vue}
 * 
  //  snackbar usage: 
  // import { Options, Vue } from "vue-class-component";
  // import { Component } from "vue-property-decorator";
  // import SnackBar from "../shared/components/snackbar/index.vue";
  // import { EventEmitter } from 'events';
  // import store from '@/store';


  // @Options({
  //   components: {
  //     SnackBar
  //   }
  // })


  // get eventCtrl(): EventEmitter {
  //   return store.getters.getEventController;
  // }

  // someFunction() {
  //   this.eventCtrl.emit('show-snackbar', 'error', 'File upload failed');
  // }

  //   error
  //   background-color #f44336
  //   warning
  //   background-color #ff9800
  //   info
  //   background-color #2196f3
  //   success
  //   background-color #4caf50
 */
export default class Snackbar extends Vue {
  showSnackbar = false;
  message = '';
  statusType = '';
  timeoutID = 0;
  stayOnTiming = 10000;
  snackbar: any;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  show(statusT: string, message: string) {
    this.statusType = statusT;
    this.stayOnTiming = this.statusType == 'error' ? 7200 * 1000 : 10000;
    this.message = message.replace('AxiosError:', '');
    this.showSnackbar = true;
    setTimeout(() => { this.snackbar = this.$refs.snackbar; }, 0);
    this.timeoutID = setTimeout(() => { this.showSnackbar = false; }, this.stayOnTiming);
  }

  close(): void {
    this.showSnackbar = false;
  }

  mounted() {
    this.eventCtrl.on('show-snackbar', this.show);
  }

  beforeUnmount() {
    this.eventCtrl.removeListener('show-snackbar', this.show);
  }

}
