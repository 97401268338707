import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AmNewRecordDeclarationRequest, Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import DatePicker from 'vue-datepicker-next';

@Options({
  props: {
    amnAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    DatePicker,
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmnDeclarations extends Vue {
  private amnAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.amnAppFormData.amrDeclarations = {};
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.updateAmrDeclaration();
  }

  private updateAmrDeclaration() {
    const _amnDeclaration: AmNewRecordDeclarationRequest = {
      id: 0,
      amNewApplicationId: this.amnAppFormData.individualDetails?.amNewApplicationId,
    }
    this.amnAppFormData.amnDeclarations = _amnDeclaration;
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  private isValidForm(): boolean {
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

