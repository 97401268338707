/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddressDetail } from '../models';
import { BooleanResponse } from '../models';
import { FirmsIEnumerableResponse } from '../models';
import { PcRenewAddressDetailsRequest } from '../models';
import { PcRenewAddressDetailsResponse } from '../models';
import { PcRenewApplicationResponse } from '../models';
import { PcRenewIndividualDetailResponse } from '../models';
import { PcRenewPaymentDetailsRequest } from '../models';
import { PcRenewPaymentDetailsResponse } from '../models';
import { PcRenewPersonalDetail } from '../models';
import { PracticeDetailsIEnumerableResponse } from '../models';
import { PreferenceDetailRequest } from '../models';
import { PreferenceDetailResponse } from '../models';
import { RecordAdditionalDetailsRequest } from '../models';
import { RecordAdditionalDetailsResponse } from '../models';
import { RecordDeclarationRequest } from '../models';
import { RecordDeclarationResponse } from '../models';
import { RecordPracticeAdditionalDetails } from '../models';
import { RecordPracticeRequest } from '../models';
import { RecordPracticeResponse } from '../models';
import { RecordRenewalDetailRequest } from '../models';
import { RecordRenewalDetailResponse } from '../models';
import { Request } from '../models';
import { SarRecordLinkedApplicationDeclarationRequest } from '../models';
import { SarRecordLinkedApplicationDeclarationResponse } from '../models';
import { SarRecordLinkedApplicationRequest } from '../models';
import { SarRecordLinkedApplicationResponse } from '../models';
import { StatutoryRegisterDetailsRequest } from '../models';
import { StatutoryRegisterDetailsResponse } from '../models';
import { TransactionResponse } from '../models';
/**
 * PcRenewApi - axios parameter creator
 * @export
 */
export const PcRenewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled PcRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewCancelPcRenewApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewCancelPcRenewApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/CancelPcRenewApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns PcRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/CheckPcRenewConcurrentSession/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Delete New Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete: async (pcRenewApplicationId: number, recordPracticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete.');
            }
            // verify required parameter 'recordPracticeId' is not null or undefined
            if (recordPracticeId === null || recordPracticeId === undefined) {
                throw new RequiredError('recordPracticeId','Required parameter recordPracticeId was null or undefined when calling apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete.');
            }
            const localVarPath = `/api/v1/PcRenew/DeleteNewPracticeDetails/{pcRenewApplicationId}/{recordPracticeId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)))
                .replace(`{${"recordPracticeId"}}`, encodeURIComponent(String(recordPracticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Additional Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetAdditionalDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetAdditionalDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetAdditionalDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Address Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetAddressDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetAddressDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetAddressDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Application Information
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetApplicationInfoApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetApplicationInfoApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetApplicationInfo/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet: async (applicationId: number, isNewApplication: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            // verify required parameter 'isNewApplication' is not null or undefined
            if (isNewApplication === null || isNewApplication === undefined) {
                throw new RequiredError('isNewApplication','Required parameter isNewApplication was null or undefined when calling apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetIndividualDetails/{applicationId}/{isNewApplication}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"isNewApplication"}}`, encodeURIComponent(String(isNewApplication)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPaymentDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetPaymentDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetPaymentDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get PcRenewalDetails
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPcRenewalDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetPcRenewalDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetPcRenewalDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Practice By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPracticeByFirmIdFirmIdGet: async (firmId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            if (firmId === null || firmId === undefined) {
                throw new RequiredError('firmId','Required parameter firmId was null or undefined when calling apiV1PcRenewGetPracticeByFirmIdFirmIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetPracticeByFirmId/{firmId}`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Practice Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPracticeDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetPracticeDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetPracticeDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPreferenceDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetPreferenceDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetPreferenceDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetRecordDeclarationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetRecordDeclarationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetRecordDeclaration/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Application Declaration
         * @param {number} recordLinkedApplicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet: async (recordLinkedApplicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordLinkedApplicationId' is not null or undefined
            if (recordLinkedApplicationId === null || recordLinkedApplicationId === undefined) {
                throw new RequiredError('recordLinkedApplicationId','Required parameter recordLinkedApplicationId was null or undefined when calling apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetSarApplicationDeclaration/{recordLinkedApplicationId}`
                .replace(`{${"recordLinkedApplicationId"}}`, encodeURIComponent(String(recordLinkedApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Record Linked Application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetSarRecordLinkedApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Statutory Register Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/PcRenew/GetStatutoryRegisterDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Revert Record Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut: async (pcRenewApplicationId: number, addressDetailId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut.');
            }
            // verify required parameter 'addressDetailId' is not null or undefined
            if (addressDetailId === null || addressDetailId === undefined) {
                throw new RequiredError('addressDetailId','Required parameter addressDetailId was null or undefined when calling apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut.');
            }
            const localVarPath = `/api/v1/PcRenew/RevertRecordAddress/{pcRenewApplicationId}/{addressDetailId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)))
                .replace(`{${"addressDetailId"}}`, encodeURIComponent(String(addressDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Revert Statutory Register Other Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut: async (pcRenewApplicationId: number, addressDetailId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut.');
            }
            // verify required parameter 'addressDetailId' is not null or undefined
            if (addressDetailId === null || addressDetailId === undefined) {
                throw new RequiredError('addressDetailId','Required parameter addressDetailId was null or undefined when calling apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut.');
            }
            const localVarPath = `/api/v1/PcRenew/RevertStatutoryRegisterOtherAddress/{pcRenewApplicationId}/{addressDetailId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)))
                .replace(`{${"addressDetailId"}}`, encodeURIComponent(String(addressDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Additional Details
         * @param {RecordAdditionalDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveAdditionalDetailsPost: async (body?: RecordAdditionalDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveAdditionalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Address Details
         * @param {PcRenewAddressDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveAddressDetailsPost: async (body?: PcRenewAddressDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveAddressDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost: async (body?: PcRenewPaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePaymentDetailsForPracticeEmployer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsPost: async (body?: PcRenewPaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePaymentDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details With Zero Fee
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsWithZeroFeePost: async (body?: PcRenewPaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePaymentDetailsWithZeroFee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save PcRenewal Details data
         * @param {RecordRenewalDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePcRenewalDetailsPost: async (body?: RecordRenewalDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePcRenewalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost: async (pcRenewApplicationId: number, body?: AddressDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost.');
            }
            const localVarPath = `/api/v1/PcRenew/SavePostalAddress/{pcRenewApplicationId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Practice Details
         * @param {RecordPracticeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePracticeDetailsPost: async (body?: RecordPracticeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePracticeDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {PreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePreferenceDetailsPost: async (body?: PreferenceDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SavePreferenceDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Record Declaration
         * @param {RecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveRecordDeclarationPost: async (body?: RecordDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveRecordDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Application Declaration
         * @param {SarRecordLinkedApplicationDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveSarApplicationDeclarationPost: async (body?: SarRecordLinkedApplicationDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveSarApplicationDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save overview details
         * @param {SarRecordLinkedApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveSarRecordLinkedApplicationPost: async (body?: SarRecordLinkedApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveSarRecordLinkedApplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Service Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost: async (pcRenewApplicationId: number, body?: AddressDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost.');
            }
            const localVarPath = `/api/v1/PcRenew/SaveServiceAddress/{pcRenewApplicationId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Statutory Register Details
         * @param {StatutoryRegisterDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveStatutoryRegisterDetailsPost: async (body?: StatutoryRegisterDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SaveStatutoryRegisterDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost: async (pcRenewApplicationId: number, body?: AddressDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost.');
            }
            const localVarPath = `/api/v1/PcRenew/SaveStatutoryRegisterOtherAddress/{pcRenewApplicationId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSearchFirmsPost: async (body?: Request, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/SearchFirms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Additional Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {Array&lt;RecordPracticeAdditionalDetails&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost: async (pcRenewApplicationId: number, body?: Array<RecordPracticeAdditionalDetails>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost.');
            }
            const localVarPath = `/api/v1/PcRenew/UpdateAdditionalPracticeDetails/{pcRenewApplicationId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {PcRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdatePersonalDetailPost: async (body?: PcRenewPersonalDetail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PcRenew/UpdatePersonalDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Principal Place Practice
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut: async (pcRenewApplicationId: number, recordPracticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pcRenewApplicationId' is not null or undefined
            if (pcRenewApplicationId === null || pcRenewApplicationId === undefined) {
                throw new RequiredError('pcRenewApplicationId','Required parameter pcRenewApplicationId was null or undefined when calling apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut.');
            }
            // verify required parameter 'recordPracticeId' is not null or undefined
            if (recordPracticeId === null || recordPracticeId === undefined) {
                throw new RequiredError('recordPracticeId','Required parameter recordPracticeId was null or undefined when calling apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut.');
            }
            const localVarPath = `/api/v1/PcRenew/UpdatePrincipalPlacePractice/{pcRenewApplicationId}/{recordPracticeId}`
                .replace(`{${"pcRenewApplicationId"}}`, encodeURIComponent(String(pcRenewApplicationId)))
                .replace(`{${"recordPracticeId"}}`, encodeURIComponent(String(recordPracticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PcRenewApi - functional programming interface
 * @export
 */
export const PcRenewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled PcRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns PcRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Delete New Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId: number, recordPracticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId, recordPracticeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Additional Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordAdditionalDetailsResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Address Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewAddressDetailsResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Application Information
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewApplicationResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewIndividualDetailResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewPaymentDetailsResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get PcRenewalDetails
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordRenewalDetailResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Practice By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordPracticeResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Practice Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDetailsIEnumerableResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferenceDetailResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordDeclarationResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Application Declaration
         * @param {number} recordLinkedApplicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SarRecordLinkedApplicationDeclarationResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Record Linked Application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SarRecordLinkedApplicationResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Statutory Register Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutoryRegisterDetailsResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Revert Record Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Revert Statutory Register Other Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Additional Details
         * @param {RecordAdditionalDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveAdditionalDetailsPost(body?: RecordAdditionalDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveAdditionalDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Address Details
         * @param {PcRenewAddressDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveAddressDetailsPost(body?: PcRenewAddressDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveAddressDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body?: PcRenewPaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePaymentDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details With Zero Fee
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body?: PcRenewPaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save PcRenewal Details data
         * @param {RecordRenewalDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePcRenewalDetailsPost(body?: RecordRenewalDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePcRenewalDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Practice Details
         * @param {RecordPracticeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePracticeDetailsPost(body?: RecordPracticeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePracticeDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {PreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSavePreferenceDetailsPost(body?: PreferenceDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSavePreferenceDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Record Declaration
         * @param {RecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveRecordDeclarationPost(body?: RecordDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveRecordDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Application Declaration
         * @param {SarRecordLinkedApplicationDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveSarApplicationDeclarationPost(body?: SarRecordLinkedApplicationDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveSarApplicationDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save overview details
         * @param {SarRecordLinkedApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveSarRecordLinkedApplicationPost(body?: SarRecordLinkedApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveSarRecordLinkedApplicationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Service Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Statutory Register Details
         * @param {StatutoryRegisterDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveStatutoryRegisterDetailsPost(body?: StatutoryRegisterDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveStatutoryRegisterDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewSearchFirmsPost(body?: Request, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmsIEnumerableResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewSearchFirmsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Additional Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {Array&lt;RecordPracticeAdditionalDetails&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: Array<RecordPracticeAdditionalDetails>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {PcRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewUpdatePersonalDetailPost(body?: PcRenewPersonalDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewUpdatePersonalDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Principal Place Practice
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId: number, recordPracticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId, recordPracticeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PcRenewApi - factory interface
 * @export
 */
export const PcRenewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled PcRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns PcRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Delete New Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId: number, recordPracticeId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId, recordPracticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Additional Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<RecordAdditionalDetailsResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Address Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PcRenewAddressDetailsResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Application Information
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PcRenewApplicationResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): AxiosPromise<PcRenewIndividualDetailResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PcRenewPaymentDetailsResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get PcRenewalDetails
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<RecordRenewalDetailResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Practice By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId: number, options?: any): AxiosPromise<RecordPracticeResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Practice Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PracticeDetailsIEnumerableResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PreferenceDetailResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<RecordDeclarationResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Application Declaration
         * @param {number} recordLinkedApplicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId: number, options?: any): AxiosPromise<SarRecordLinkedApplicationDeclarationResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Record Linked Application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<SarRecordLinkedApplicationResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Statutory Register Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StatutoryRegisterDetailsResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Revert Record Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Revert Statutory Register Other Address
         * @param {number} pcRenewApplicationId 
         * @param {number} addressDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Additional Details
         * @param {RecordAdditionalDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveAdditionalDetailsPost(body?: RecordAdditionalDetailsRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveAdditionalDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Address Details
         * @param {PcRenewAddressDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveAddressDetailsPost(body?: PcRenewAddressDetailsRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveAddressDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body?: PcRenewPaymentDetailsRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any): AxiosPromise<TransactionResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePaymentDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details With Zero Fee
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body?: PcRenewPaymentDetailsRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save PcRenewal Details data
         * @param {RecordRenewalDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePcRenewalDetailsPost(body?: RecordRenewalDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePcRenewalDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Practice Details
         * @param {RecordPracticeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePracticeDetailsPost(body?: RecordPracticeRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePracticeDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {PreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSavePreferenceDetailsPost(body?: PreferenceDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSavePreferenceDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Record Declaration
         * @param {RecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveRecordDeclarationPost(body?: RecordDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveRecordDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Application Declaration
         * @param {SarRecordLinkedApplicationDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveSarApplicationDeclarationPost(body?: SarRecordLinkedApplicationDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveSarApplicationDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save overview details
         * @param {SarRecordLinkedApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveSarRecordLinkedApplicationPost(body?: SarRecordLinkedApplicationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveSarRecordLinkedApplicationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Service Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Statutory Register Details
         * @param {StatutoryRegisterDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveStatutoryRegisterDetailsPost(body?: StatutoryRegisterDetailsRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveStatutoryRegisterDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Postal Address
         * @param {number} pcRenewApplicationId 
         * @param {AddressDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewSearchFirmsPost(body?: Request, options?: any): AxiosPromise<FirmsIEnumerableResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewSearchFirmsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Additional Practice Details
         * @param {number} pcRenewApplicationId 
         * @param {Array&lt;RecordPracticeAdditionalDetails&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: Array<RecordPracticeAdditionalDetails>, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {PcRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdatePersonalDetailPost(body?: PcRenewPersonalDetail, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewUpdatePersonalDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Principal Place Practice
         * @param {number} pcRenewApplicationId 
         * @param {number} recordPracticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId: number, recordPracticeId: number, options?: any): AxiosPromise<BooleanResponse> {
            return PcRenewApiFp(configuration).apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId, recordPracticeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PcRenewApi - object-oriented interface
 * @export
 * @class PcRenewApi
 * @extends {BaseAPI}
 */
export class PcRenewApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Cancelled PcRenewApplication
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns PcRenew Concurrent Session
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Delete New Practice Details
     * @param {number} pcRenewApplicationId 
     * @param {number} recordPracticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId: number, recordPracticeId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewDeleteNewPracticeDetailsPcRenewApplicationIdRecordPracticeIdDelete(pcRenewApplicationId, recordPracticeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Additional Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetAdditionalDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Address Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetAddressDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Application Information
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetApplicationInfoApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Individual Details
     * @param {number} applicationId 
     * @param {boolean} isNewApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Payment Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get PcRenewalDetails
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetPcRenewalDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Practice By FirmId
     * @param {number} firmId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetPracticeByFirmIdFirmIdGet(firmId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Practice Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetPracticeDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Preference Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetPreferenceDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Record Declaration
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Application Declaration
     * @param {number} recordLinkedApplicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordLinkedApplicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Record Linked Application
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Statutory Register Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Revert Record Address
     * @param {number} pcRenewApplicationId 
     * @param {number} addressDetailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewRevertRecordAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Revert Statutory Register Other Address
     * @param {number} pcRenewApplicationId 
     * @param {number} addressDetailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId: number, addressDetailId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(pcRenewApplicationId, addressDetailId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Additional Details
     * @param {RecordAdditionalDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveAdditionalDetailsPost(body?: RecordAdditionalDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveAdditionalDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Address Details
     * @param {PcRenewAddressDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveAddressDetailsPost(body?: PcRenewAddressDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveAddressDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details
     * @param {PcRenewPaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body?: PcRenewPaymentDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details
     * @param {PcRenewPaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePaymentDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details With Zero Fee
     * @param {PcRenewPaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body?: PcRenewPaymentDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePaymentDetailsWithZeroFeePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save PcRenewal Details data
     * @param {RecordRenewalDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePcRenewalDetailsPost(body?: RecordRenewalDetailRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePcRenewalDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Postal Address
     * @param {number} pcRenewApplicationId 
     * @param {AddressDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePostalAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Practice Details
     * @param {RecordPracticeRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePracticeDetailsPost(body?: RecordPracticeRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePracticeDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Preference Details
     * @param {PreferenceDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSavePreferenceDetailsPost(body?: PreferenceDetailRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSavePreferenceDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Record Declaration
     * @param {RecordDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveRecordDeclarationPost(body?: RecordDeclarationRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveRecordDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Application Declaration
     * @param {SarRecordLinkedApplicationDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveSarApplicationDeclarationPost(body?: SarRecordLinkedApplicationDeclarationRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveSarApplicationDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save overview details
     * @param {SarRecordLinkedApplicationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveSarRecordLinkedApplicationPost(body?: SarRecordLinkedApplicationRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveSarRecordLinkedApplicationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Service Address
     * @param {number} pcRenewApplicationId 
     * @param {AddressDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveServiceAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Statutory Register Details
     * @param {StatutoryRegisterDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveStatutoryRegisterDetailsPost(body?: StatutoryRegisterDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveStatutoryRegisterDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Postal Address
     * @param {number} pcRenewApplicationId 
     * @param {AddressDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: AddressDetail, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSaveStatutoryRegisterOtherAddressPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Search Firms
     * @param {Request} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewSearchFirmsPost(body?: Request, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewSearchFirmsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Additional Practice Details
     * @param {number} pcRenewApplicationId 
     * @param {Array&lt;RecordPracticeAdditionalDetails&gt;} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId: number, body?: Array<RecordPracticeAdditionalDetails>, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewUpdateAdditionalPracticeDetailsPcRenewApplicationIdPost(pcRenewApplicationId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Personal Detail
     * @param {PcRenewPersonalDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewUpdatePersonalDetailPost(body?: PcRenewPersonalDetail, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewUpdatePersonalDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Principal Place Practice
     * @param {number} pcRenewApplicationId 
     * @param {number} recordPracticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId: number, recordPracticeId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(pcRenewApplicationId, recordPracticeId, options).then((request) => request(this.axios, this.basePath));
    }
}
