import {Options, Vue} from "vue-property-decorator";
import store from "@/shared/store";
import { EventEmitter } from "events";


@Options({
  components: {}
})
export default class PcNorDeclarations extends Vue {
  
  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  mounted(): void {
    this.eventCtrl.emit('canGoNext', true);

  }
}

