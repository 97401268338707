import { Options, Vue } from "vue-property-decorator";

@Options({
  props: {
    modalOpened: Boolean,
  },
  components: {}
})
export default class ConfirmDialog extends Vue {
  private modalOpened!: boolean;

  isVisible = false;

  title = '';
  message = '';// Main text content
  okButton = 'OK'; // Text for confirm button; leave it empty because we don't know what we're using it for
  cancelButton = 'Cancel'; // text for cancel button

  // Private variables
  private resolvePromise: any;
  private rejectPromise: any;

  private open() {
    this.isVisible = true;
    if (!this.modalOpened) {
      document.body.classList.add('modal-open');
    }
  }

  private close() {
    this.isVisible = false;
    if (!this.modalOpened) {
      document.body.classList.remove('modal-open');
    }
  }

  public show(opts: any = {}) {
    this.title = opts?.title || 'CANCEL ALERT';
    this.message = opts?.message || 'Are you sure?';
    this.okButton = opts?.okButton || 'OK';
    this.cancelButton = opts?.cancelButton || 'Cancel';

    this.open();
    // Return promise so the caller can get results
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve
      this.rejectPromise = reject
    })
  }

  _confirm() {
    this.close()
    this.resolvePromise(true)
  }

  _cancel() {
    this.close()
    this.resolvePromise(false)
    // Or you can throw an error
    // this.rejectPromise(new Error('User cancelled the dialogue'))
  }

}

