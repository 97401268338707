import { Module } from "vuex";

const sarAppFormDataStoreModule: Module<any, any> = {
  state: {
    sarAppFormData: {}
  },
  mutations: {
    setSarAppFormData(state, fd) {
      state.sarAppFormData = fd;
    }
  },
  actions: {
    setSarAppFormData({ commit }, fd) {
      commit('setSarAppFormData', fd);
    }
  },
  getters: {
    getSarAppFormData: (state) => state.sarAppFormData
  }
}
export default sarAppFormDataStoreModule;