import { Options, Vue } from "vue-property-decorator";

export interface PopoverView {
  title: string;
  content: string;
  key: string;
  isShow: boolean;
}

@Options({
  props: {
    popover: Object,
    iconClass: String,
    preview: Boolean,
  },
  components: {
    Popover,
  },
})
/* 
* usage: <Popover :popover="$getPopover('popoverKeyName', pageId)"></Popover>
* usage: <Popover :popover="$getPopover('popoverKeyName', pageId)" :iconClass="'fa fa-question-circle'"></Popover>
*/
export default class Popover extends Vue {
  private popover!: PopoverView;
  private iconClass?: string;
  private preview?: boolean;

}
