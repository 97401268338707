import { Inject, Options, Vue } from "vue-property-decorator";
import UserDefine from '@/assets/config/user-define.json';
import AmnApplicationWording from '@/assets/config/amn-application-wording.json';
// import { RouteN } from "@/shared/constants/route-name";
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import HelpScreen from "@/shared/components/help-screen/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { PageContent } from "@/shared/modules/typescript-api-client/models";

@Options({
  components: {
    Popover,
    HelpScreen,
    LoadingIndicator,
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmnOverview extends Vue {
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private showHelpScreen = false;
  private isRenew = null;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private popovers: any[] = [];

  private htmlContents_1 = '';

  private htmlContents: string[] = [];

  private helpContent: PageContent = {};

  private contactUs() {
    window.open(UserDefine[0].contact_us_url, '_blank');
  }

  mounted(): void {
    window.scrollTo(0, 0);
    this.loadPageContents();
    this.eventCtrl.emit('canGoNext', true);
    this.helpContent = this.$getPopover('Help', 36);
  }

  private async loadPageContents() {
    this.loadingDataSet = false;
    this.htmlContents_1 = AmnApplicationWording[0]["overview"]["htmlContents"];
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private faqClicked() {
    window.open(UserDefine[0].faq_amn_url, 'blank');
  }

}

