import { Inject, Options, Vue } from "vue-property-decorator";
import UserDefine from '@/assets/config/user-define.json';
import SmApplicationWording from '@/assets/config/sm-application-wording.json';
// import { RouteN } from "@/shared/constants/route-name";
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import HelpScreen from "@/shared/components/help-screen/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Application, PageContent } from "@/shared/modules/typescript-api-client/models";

@Options({
  components: {
    Popover,
    HelpScreen,
    LoadingIndicator,
  },
  mixins: [FormatDateTimeMixin],
})
export default class SmOverview extends Vue {
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private showHelpScreen = false;
  private isRenew = null;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private popovers: any[] = [];

  private htmlContents_1 = '';

  private htmlContents: string[] = [];

  private helpContent: PageContent = {};

  private contactUs() {
    window.open(UserDefine[0].contact_us_url, '_blank');
  }

  mounted(): void {
    window.scrollTo(0, 0);
    this.loadPageContents();
    setTimeout(() => {
      this.eventCtrl.emit('canGoNext', true);
      this.helpContent = this.$getPopover('Help', 23);
    }, 500);
  }

  private async loadPageContents() {
    console.log('Load Contents');
    this.loadingDataSet = false;
    this.htmlContents_1 = SmApplicationWording[0]["overview"]["htmlContents"].replaceAll('--[[ApplicationYearName]]--', this.onlineApplication.applicationYearName);
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

}

