import { Options, Prop, Vue } from "vue-property-decorator";
import { Application, PractisingCertificate } from "@/shared/modules/typescript-api-client/models";
import AlertBoxTypeTwo from "@/shared/components/alert-box-type-two/index.vue";

@Options({
  components: {
    AlertBoxTypeTwo
  }
})
export default class AppCard extends Vue {
  @Prop()
  private applications!: Application[];

  @Prop()
  private practisingCertificate!: PractisingCertificate[];

  @Prop()
  private downloading!: boolean;

  private showSARMessage = false;

  // *** ApplicationTypes: = [
  //     {
  //       "id": 1,
  //       "name": "Practising Certificate and Solicitor Membership",
  //       "description": "Practising Certificate and Solicitor Membership"
  //     },
  //     {
  //       "id": 2,
  //       "name": "Solicitor Membership",
  //       "description": "Solicitor Membership"
  //     },
  //     {
  //       "id": 3,
  //       "name": "SpecAccRenewals",
  //       "description": "Specialist Accreditation Renewals"
  //     },
  //     {
  //       "id": 4,
  //       "name": "Associate New",
  //       "description": "Associate Membership New"
  //     },
  //     {
  //       "id": 5,
  //       "name": "Associate Renewals",
  //       "description": "Associate Membership Renewals"
  //     },
  //     {
  //       "id": 6,
  //       "name": "Student Membership New",
  //       "description": "Student Membership"
  //     },
  //     {
  //       "id": 7,
  //       "name": "Student Membership Renew",
  //       "description": "Student Membership"
  //     }
  //   ],

  get myRenewals(): Application[] {
    return this.applications.filter(a => {
      return a.applicationClass.id == 2; // My Renewals
    });
  }

  get myApplications(): Application[] {
    return this.applications.filter(a => {
      return a.applicationClass.id == 1; // My Applications
    });
  }

  private renew(app: Application): void {
    if (app.applicationStatus?.id == 5) {
      this.showSARMessage = true;
    } else {
      this.$emit('apply', app);
    }
  }

  private apply(app: Application): void {
    this.$emit('apply', app);
  }

  private download(pcId: number): void {
    this.$emit('download', pcId);
  }

}

