import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import UserDefine from '@/assets/config/user-define.json';
import SarApplicationWording from '@/assets/config/sar-application-wording.json';
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import HelpScreen from "@/shared/components/help-screen/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Application, NonRenewalReason, PageContent, SarRecordLeaveOfAbsenceRequest, SarRecordNonRenewalDetailRequest } from "@/shared/modules/typescript-api-client/models";
import DatePicker from 'vue-datepicker-next';

@Options({
  props: {
    sarAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['sarAppFormData'],
  components: {
    Popover,
    HelpScreen,
    LoadingIndicator,
    DatePicker,
  },
  mixins: [FormatDateTimeMixin],
})
export default class SarOverview extends Vue {
  private sarAppFormData!: any;
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private showHelpScreen = false;
  private isRenew = null;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private popovers: any[] = [];

  private htmlContents_1 = '';

  private renewingType = null as any;
  private noRenewApplyType = null as any;
  
  private leaveOfAbsenceText = '';

  private helpContent: PageContent = {};
  private nonRenewalReasons: NonRenewalReason[] = [];
  private selectedReason: NonRenewalReason = null as any;
  // private leaveStartDate: Date | null = null;
  // private leaveReturnDate: Date | null = null;
  private dateRangeSelected: [Date | null, Date | null] = [null, null];
  private reasonSeekingLeave = '';
  
  private contactUs(): void {
    window.open(UserDefine[0].contact_us_url, '_blank');
  }

  private sarFAQ(): void {
    window.open(UserDefine[0].faq_sar_url, '_blank');
  }

  @Watch('renewingType')
  private onRenewingTypeChanged(): void {
    this.sarAppFormData['sarOverview']['decision'] = this.renewingType;
    if (this.renewingType == 0) {
      this.noRenewApplyType = null as any;
      this.selectedReason = null as any;
      this.dateRangeSelected = [null, null];
      this.reasonSeekingLeave = null as any;
    }
    this.$emit('sarAppFormData', this.sarAppFormData);
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  @Watch('noRenewApplyType')
  private onNoRenewApplyTypeChanged(): void {
    this.sarAppFormData['sarOverview']['nonRenewType'] = this.noRenewApplyType;
    if (this.noRenewApplyType) {
      this.selectedReason = null as any;
    } else if (!this.noRenewApplyType && this.noRenewApplyType !== null) {
      this.dateRangeSelected = [null, null];
      this.reasonSeekingLeave = null as any;
    }
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  @Watch('selectedReason')
  private onSelectedReasonChanged(): void {
    const data: SarRecordNonRenewalDetailRequest = {
      applicationId: this.onlineApplication.id,
      isNewApplication:	true,
      nonRenewalReasonId: this.selectedReason?.id ?? null,
    } 
    this.sarAppFormData['sarOverview']['nonRenewalDetails'] = data;
    this.$emit('sarAppFormData', this.sarAppFormData);
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  @Watch('dateRangeSelected')
  private onLeaveStartDateChanged(): void {
    const data: SarRecordLeaveOfAbsenceRequest = {
      applicationId: this.onlineApplication.id,
      leaveStartDate: this.dateRangeSelected[0] ?? null,
      leaveReturnDate: this.dateRangeSelected[1] ?? null,
      reasons: this.reasonSeekingLeave,
    }
    this.sarAppFormData['sarOverview']['leaveOfAbsence'] = data;
    this.$emit('sarAppFormData', this.sarAppFormData);
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  // @Watch('leaveStartDate')
  // private onLeaveStartDateChanged(): void {
  //   const data: SarRecordLeaveOfAbsenceRequest = {
  //     applicationId: this.onlineApplication.id,
  //     leaveStartDate: this.leaveStartDate,
  //     leaveReturnDate: this.leaveReturnDate,
  //     reasons: this.reasonSeekingLeave,
  //   }
  //   this.sarAppFormData['sarOverview']['leaveOfAbsence'] = data;
  //   this.$emit('sarAppFormData', this.sarAppFormData);
  //   this.eventCtrl.emit('canGoNext', this.isValidForm());
  // }

  // @Watch('leaveReturnDate')
  // private onLeaveReturnDateChanged(): void {
  //   const data: SarRecordLeaveOfAbsenceRequest = {
  //     applicationId: this.onlineApplication.id,
  //     leaveStartDate: this.leaveStartDate,
  //     leaveReturnDate: this.leaveReturnDate,
  //     reasons: this.reasonSeekingLeave,
  //   }
  //   this.sarAppFormData['sarOverview']['leaveOfAbsence'] = data;
  //   this.$emit('sarAppFormData', this.sarAppFormData);
  //   this.eventCtrl.emit('canGoNext', this.isValidForm());
  // }

  @Watch('reasonSeekingLeave')
  private onReasonSeekingLeaveChanged(): void {
    const data: SarRecordLeaveOfAbsenceRequest = {
      applicationId: this.onlineApplication.id,
      leaveStartDate: this.dateRangeSelected[0] ?? null,
      leaveReturnDate: this.dateRangeSelected[1] ?? null,
      reasons: this.reasonSeekingLeave,
    }
    this.sarAppFormData['sarOverview']['leaveOfAbsence'] = data;
    this.$emit('sarAppFormData', this.sarAppFormData);
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  created(): void {
    this.sarAppFormData['sarOverview'] = {
      decision: null,
      nonRenewType: null,
      leaveOfAbsence: null,
    };
    this.$emit('sarAppFormData', this.sarAppFormData);
  }

  mounted(): void {
    window.scrollTo(0, 0);
    this.loadPageContents();
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetSpecAccNonRenewalReasonsGet()
      ]);
      this.nonRenewalReasons = resp[0].data.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private async loadPageContents() {
    this.loadingDataSet = false;
    this.htmlContents_1 = SarApplicationWording[0].overview.htmlContents.replaceAll('--[[ApplicationYearName]]--', this.onlineApplication.applicationYearName);
    this.leaveOfAbsenceText = SarApplicationWording[0].overview.leaveOfAbsence;
    this.helpContent = this.$getPopover('Help', 30);
  }

  private isValidForm(): boolean {
    if (this.renewingType == 0) {
      return true;
    } else if (this.renewingType == 1) {
      if (this.noRenewApplyType == true) {
        return this.isValidLeaveOfAbsenceForm();
      } else if (this.noRenewApplyType == false && this.noRenewApplyType != null) {
        return this.isValidNonRenewalForm();
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private isValidLeaveOfAbsenceForm(): boolean {
    return this.dateRangeSelected[0] != null && this.dateRangeSelected[1] != null &&
      this.reasonSeekingLeave != null && this.reasonSeekingLeave?.trim()?.length > 0;
  }

  private isValidNonRenewalForm(): boolean {
    return this.selectedReason != null;
  }

  // private initData(): void {
  // }

  // private syncData(): void {

  // }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

