export const paywayStyle = {
  'body': {
    // 'padding': '1em 0',
    // 'font-size': '16px',
  },
  '.payway-card': {
    // background: 'none',
    // border: 'none',
    // 'border-radius': '5px',
    margin: '0 0',
    // padding: '0 0',
  },
  'label, legend' : {
    // 'position': 'absolute',
    // 'font-weight': 'bold !important',
  },
  'input, select': {
    // height: '40px',
    // 'border-bottom': '2px solid grey !important',
  },
  'input:focus, select:focus': {
    // 'outline': '2px solid rgba(240,100,35,0.8) !important',
    // 'box-shadow': '0 0 0 4px rgba(240,100,35,0.8) !important',
    'z-index' : '100'
  },
  '#cardholderName' : {
    // 'width': '80vw !important',
    // 'height': '40px !important',
    // 'border-bottom': '2px solid grey',
    // 'line-height': 'normal',
  },
  'label.payway-creditcard-expirationseparator': {
    // display: 'none',
    // 'line-height': '50px'
  },

}
