import { Inject, Options, Vue } from "vue-property-decorator";
import UserDefine from '@/assets/config/user-define.json';
import PcrWordingData from '@/assets/config/pcr-wording.json';
import { RouteN } from "@/shared/constants/route-name";
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { PopoverView } from "@/shared/components/popover/popover";
import HelpScreen from "@/shared/components/help-screen/index.vue";
import { Application, PcRenewIndividualDetail, PageContent, PcRenewApplication } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";

@Options({
  components: {
    Popover,
    HelpScreen,
    ConfirmDialogue,
    LoadingIndicator,
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    const pcrApp = store.getters.getAppRenew;
    if (!pcrApp.id) {
      console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  },
  mixins: [FormatDateTimeMixin],
})
export default class PcrOverview extends Vue {
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private showHelpScreen = false;
  private isRenew = null;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private popovers: any[] = [];

  private applicationYearName = '';
  private lastApplicationYear = '';
  private lastApplicationYearName = '';

  private htmlContents_1 = '';

  private htmlContents: string[] = [];
  private popoverKeys: string[] = [];

  private allPageContents: PageContent = {};
  private applicationInfo: PcRenewApplication = {};

  private pcrFormData: any = {};
  private individualDetails: PcRenewIndividualDetail = {};
  private origNames = {};

  private helpContent: PageContent = {};

  private contactUs() {
    window.open(UserDefine[0].contact_us_url, 'blank');
  }

  private faqClicked() {
    window.open(UserDefine[0].faq_pcr_url, 'blank');
  }

  mounted(): void {
    window.scrollTo(0, 0);
    this.loadPageContents();
  }

  private getPopOverKeys() {
    const _keys = [];
    for (let p = 0; p < this.popoverKeys.length; p++) {
      if (this.popoverKeys[p + 1]) {
        const _stringEndAt = this.popoverKeys[p + 1].indexOf('}}--');
        _keys.push(this.popoverKeys[p + 1].substring(0, _stringEndAt));
      }
    }
    this.popoverKeys = _keys;
  }

  private getPopover(pKey: string): PopoverView {
    const _popOver = this.popovers.filter(p => {
      return p.popoverKey == pKey;
    })[0];
    return _popOver;
  }

  private async loadPageContents() {
    const _appId = parseInt(this.$route.query.appId as string);
    try {
      this.loadingDataSet = true;
      this.errorLoadingDataSet = false;
      const resp: any[] = await Promise.all([
        this.apiService.PageApi?.apiV1PageGetPagesGet(),
        this.apiService.CodeApi?.apiV1CodeGetContentTypesGet(),
        this.apiService.PageApi?.apiV1PageGetAllPageContentsGet(),
        this.apiService.PcRenewApi?.apiV1PcRenewGetApplicationInfoApplicationIdGet(_appId),
      ]);

      for (let p = 0; p < resp[2].data.data.length; p++) {
        this.popovers.push({
          key: resp[2].data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp[2].data.data[p].content as string,
          isShow: resp[2].data.data[p].isShow as boolean,
          title: resp[2].data.data[p].title as string,
          pageId: resp[2].data.data[p].pageId as number,
        })
      }
      store.dispatch('setAppInfo', resp[3].data.data);
      this.applicationYearName = resp[3].data.data.applicationYear?.name;
      this.lastApplicationYear = resp[3].data.data.lastApplicationYear?.year;
      this.lastApplicationYearName = resp[3].data.data.lastApplicationYear?.name;
      this.htmlContents_1 = PcrWordingData[0].overview.htmlContents.replaceAll('--[[ApplicationYearName]]--', this.applicationYearName);
      this.htmlContents_1 = this.htmlContents_1.replaceAll('--[[LastApplicationYear]]--', this.lastApplicationYear);
      this.htmlContents_1 = this.htmlContents_1.replaceAll('--[[LastApplicationYearName]]--', this.lastApplicationYearName);

      this.htmlContents = this.htmlContents_1.split(/--{{popover:.*\}}--/gm);
      this.popoverKeys = this.htmlContents_1.split(/--{{popover:/gm);
      this.getPopOverKeys();
      store.dispatch('setPopovers', this.popovers);
      this.helpContent = this.$getPopover('Help', 1);
    } catch (err) {
      this.errorLoadingDataSet = true;
      this.ai.trackException('Error, Unable to load page contents: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private backHome() {
    this.$router.push({ name: RouteN.Mars.Home.n });
  }

  private goNext() {
    const _pcrFormData = {
      id: 0,
    }
    store.dispatch('setPcrFormData', _pcrFormData);
    if (this.isRenew !== null) {
      this.checkConcurrentUsage(this.isRenew);
      // } else {
      // this.$router.push({ name: RouteN.Mars.PcNoRenew.n, query: { sec: 'current-details', appId: this.$route.query.appId } });
    }

  }

  private async checkConcurrentUsage(isRenew: boolean) {
    if (isRenew) {
      try {
        this.loadingDataSet = true;
        this.errorLoadingDataSet = false;
        await Promise.all([
          this.apiService.PcRenewApi?.apiV1PcRenewCheckPcRenewConcurrentSessionApplicationIdGet(this.pcrApp.id)
        ]);
        this.$router.push({ name: RouteN.Mars.PcRenew.n, query: { sec: 'current-details', appId: this.$route.query.appId } });
      } catch (err: any) {
        this.errorLoadingDataSet = true;
        // console.log(err.response?.status, err.response?.data.errorCode);
        if (err.response?.status == 400 && err.response.data?.errorCode == 10001) {
          this.confirmCancelConcurrentApp(isRenew);
        }
      } finally {
        this.loadingDataSet = false;
      }
    } else {
      this.loadingDataSet = true;
      await this.apiService.PcNonRenewApi?.apiV1PcNonRenewCheckPcRenewConcurrentSessionApplicationIdGet(this.pcrApp.id)
        .then(() => {
          this.$router.push({ name: RouteN.Mars.PcNoRenew.n, query: { sec: 'current-details', appId: this.$route.query.appId } });
        })
        .catch(err => {
          this.loadingDataSet = false;
          if (err.response?.status == 400 && err.response.data?.errorCode == 10001) {
            this.confirmCancelConcurrentApp(isRenew);
          }
        });

    }

  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  private confirmCancelConcurrentApp(isRenew: boolean) {
    this.loadingDataSet = true;
    // const _appId = parseInt(this.$route.query.appId as string);
    const _confirm: any = this.$refs.confirmCancelCurrentDialogue;
    _confirm.show({
      title: 'You are already logged in elsewhere',
      message: 'You can only be logged in on one browser at a time. If you wish to <b>continue here</b>, please select "Continue" if not, select "Cancel". <p/><div style="color: red">Please note that, if you wish to continue on this browser, you will be restarting your application. Do you still wish to continue your application here? </div>',
      okButton: 'Continue',
      cancelButton: 'Cancel',
    }).then(async (res: any) => {
      if (res) {
        this.clearSessionAndStart(isRenew);
      }
      else {
        this.$router.push({ name: RouteN.Mars.Home.n });
      }
    })
  }

  private clearSessionAndStart(isRenew: boolean) {
    this.loadingDataSet = true;
    this.apiService.PcRenewApi?.apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(this.pcrApp.id)
      .then(() => {
        setTimeout(() => {
          this.loadingDataSet = false;
          if (isRenew) {
            this.$router.push({ name: RouteN.Mars.PcRenew.n, query: { sec: 'current-details', appId: this.$route.query.appId } });
          } else {
            this.$router.push({ name: RouteN.Mars.PcNoRenew.n, query: { sec: 'current-details', appId: this.$route.query.appId } });
          }
        }, 2000)
      })
      .catch(() => {
        this.loadingDataSet = false;
        console.log('Unable to clear session');
      });
  }

}

