import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class LoadingIndicator extends Vue {

  @Prop({default: true})
  public showLoadingText!: boolean;

  @Prop({default: 'Loading'})
  public loadingText!: string;
}
