import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';

@Options({
  props: {
    sarAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover
  },
  mixins: [FormatDateTimeMixin],
})
export default class SarCurrentDetails extends Vue {
  private sarAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;

  private currentDetails: any = {};

  private detailsChanged = null as any;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  @Watch('detailsChanged')
  private ondetailsChangedOptionChanged(): void {
    this.syncData();
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }


  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([this.getRecordCurrentDetails()]);
      store.dispatch('setSarAppFormData', this.sarAppFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private async getRecordCurrentDetails(): Promise<boolean> {
    try {
      const _isRenewing = !!this.sarAppFormData.currentDetails;
      const resp: any = await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewGetRecordCurrentDetailsApplicationIdIsNewApplicationGet(
        this.onlineApplication.id, !_isRenewing
      );
      this.currentDetails = resp.data.data;
      this.sarAppFormData['currentDetails'] = this.currentDetails;
      this.initSync();
      this.canGoNextCheck();
      return Promise.resolve(true);
    } catch (err: any) {
      console.log(err);
      if (err.response?.data && err.response.data?.errorCode == 10001) {
        this.eventCtrl.emit('show-snackbar', 'info', 'Concurrent application detected');
      } else {
        this.ai.trackException('Error, Unable to get user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get user details, redirect to Portal home');
      }
      return Promise.reject(false);
    }
  }

  private initSync(): void {
    this.detailsChanged = this.sarAppFormData.currentDetails.sarApplication.isCurrentDetailsChanged ?? null;
  }

  private syncData(): void {
    try {
      if (!this.loadingDataSet) {
        this.sarAppFormData.currentDetails.sarApplication.isCurrentDetailsChanged = this.detailsChanged;
      }
    } catch (err) {
      console.log(err);
    }
    this.canGoNextCheck();
  }


  private isValidForm(): boolean {
    return this.detailsChanged != null;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

