import { PAYMENTAPP_URL, SELF_URL } from '@/shared/config/config';
import { PaymentTransaction } from '@/shared/models/payment-transaction';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import { EventEmitter } from 'events';
import store from '@/shared/store';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';

@Options({
  components: {
    ConfirmDialogue,
    LoadingIndicator
  },
})
export default class PaymentPopup extends Vue {

  @Ref('paymentApp') readonly paymentAppIframe!: any;

  @Prop({ default: false })
  private modalOpened!: boolean;

  @Prop({ default: '' })
  private paymentAppName!: string;

  @Prop({ default: {} })
  private paymentTransaction!: PaymentTransaction;

  @Prop({ default: '' })
  private cancelEventName!: string;

  @Prop({ default: false })
  private disableCloseButton!: boolean;

  @Prop({ default: '' })
  private sessionId!: string;

  @Prop({ default: false })
  private validPaymentPage!: boolean;

  private targetOrigin = PAYMENTAPP_URL;

  private loadingIFrame = true;
  private iFrameLoaded = false;

  mounted() {
    if (!this.modalOpened) {
      document.body.classList.add('modal-open');
    }

    this.paymentAppIframe.onload = () => {
      this.iFrameLoaded = true;
      this.loadingIFrame = false;
    }

    this.eventCtrl.on(this.cancelEventName, this.confirmAlert);
  }

  beforeUnmount() {
    if (!this.modalOpened) {
      document.body.classList.remove('modal-open');
    }
    this.eventCtrl.removeListener(this.cancelEventName, this.confirmAlert);
  }

  private paymentAppSRC(): string {
    const host = PAYMENTAPP_URL;
    const tK = this.paymentTransaction.transactionKey;
    const sK = this.paymentTransaction.secureKey;
    const tO = SELF_URL;
    const pApname = this.paymentAppName;
    const src = host + '?' +
                'tK=' + encodeURIComponent(tK) + '&' +
                'sK=' + encodeURIComponent(sK) + '&' +
                'tO=' + encodeURIComponent(tO) + '&' +
                'pApname=' + encodeURIComponent(pApname) + '&' +
                'sSid=' + encodeURIComponent(this.sessionId);
    return src;
  }

  private confirmAlert() {
    if (this.disableCloseButton) {
      return;
    }
    const _confirm: any = this.$refs.confirmCancelPaymentDialogue;
    _confirm.show({
      title: 'Cancel Payment',
      message: 'Are you sure you want to cancel your payment?',
      okButton: 'Yes, cancel payment',
      cancelButton: 'No, do not cancel',
    }).then((res: any) => {
      if (res) {
        if (this.iFrameLoaded && this.validPaymentPage) {
          this.paymentAppIframe.contentWindow.postMessage({
            sessionId: this.sessionId,
            paymentAppName: this.paymentAppName,
            action: 'proceed-cancel-payment'
          }, this.targetOrigin);
        } else {
          this.$emit('close');
        }
      }
    })
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }
}
