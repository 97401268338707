import { EncryptStorage } from "encrypt-storage";

export const ENCRYPT_STORAGE = new EncryptStorage('secret-key:7ey4hu7fyf=--', {
    prefix: '@mars',
    storageType: 'sessionStorage',
  });

export function localUrl(): string {
  const result = window.location.href.split('/');
  return result[0] + '//' + result[2];
}

export const AZ_INSTRUMENT_KEY_INT: string = process.env.VUE_APP_AZ_INSTRUMENT_KEY_INT as string;
export const AZ_INSTRUMENT_KEY_EXT: string = process.env.VUE_APP_AZ_INSTRUMENT_KEY_EXT as string;
export const UPDATE_EMAIL_LINK: string = process.env.VUE_APP_UPDATE_EMAIL_LINK as string;

export const TOKEN_URL: string = process.env.VUE_APP_TOKEN_URL as string;
export const API_URL: string = process.env.VUE_APP_API_URL as string;
export const IDLE_TIMEOUT: number = parseInt(process.env.VUE_APP_IDLE_TIMEOUT, 10);
export const TOKEN_REFRESH_TIME: number = parseInt(process.env.VUE_APP_TOKEN_REFRESH_TIME, 10);
export const SELF_URL: string = localUrl();

export const PAYMENTAPP_URL: string = process.env.VUE_APP_PAYMENTAPP_URL as string;
export const SOURCE_BRANCH: string = process.env.VUE_APP_SOURCE_BRANCH as string;
