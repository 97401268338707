import { Inject, Options, Vue } from "vue-property-decorator";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { MARSApi } from "@/shared/modules/mars-api";
import { AppInsights } from "@/mars-app/appInsights";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { Application } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { orderBy } from "lodash";

@Options({
  props: {
    amrAppFormData: Object,
    isSectionLoading: Boolean,
  },
  components: {
    Popover,
    LoadingIndicator
  },
  mixins: [FormatDateTimeMixin]
})
export default class AmrPaymentDetails extends Vue {

  private amrAppFormData!: any;
  private isSectionLoading!: boolean;
  private ai = new AppInsights;
  private loadingDataSet = true;
  private lineItems: any[] = [];

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;


  mounted(): void {
    this.canGoNextCheck();
    this.getPaymentDetails();
  }

  private async getPaymentDetails(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await this.apiService.AmRenewApi?.apiV1AmRenewGetPaymentDetailsApplicationIdGet(this.onlineApplication.id);
      this.amrAppFormData.paymentDetails = resp.data.data;
      this.lineItems = orderBy(this.amrAppFormData.paymentDetails.lineItems, ['order'], ['asc']);
      this.canGoNextCheck();
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get payment details: ' + err);
      this.ai.trackException('Error, Unable to get payment details: ' + err);
    } finally {
      this.loadingDataSet = false;
    }
    this.loadingDataSet = false;
  }


  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

  private getPayableItem(itemName: string): any {
    try {
      const _rtn = this.amrAppFormData.paymentDetails.lineItems.filter(
        (x: any) => x.name.toLocaleLowerCase() == itemName.toLocaleLowerCase()
      );

      if (_rtn.length > 0) {
        return _rtn[0];
      } else {
        throw new Error('Invalid Item');
      }

    } catch {
      return {
        name: itemName,
        amountGstExcluded: 0,
        gst: 0,
        total: 0,
        lsAmountGstExcluded: 0,
        lsFeeName: '',
        lsGst: 0,
        lsTotal: 0
      }
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', true);
  }

  get itemMembershipName(): string {
    return 'ASSOCIATE MEMBERSHIP ' + this.onlineApplication.applicationYearName;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get getTotal(): number {
    const items = this.amrAppFormData.paymentDetails.lineItems;
    let _total = 0;
    for (let i = 0; i < items.length; i++) {
      _total += this.getPayableItem(items[i].name).total;
    }
    return _total;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

}

