
import { Vue } from 'vue-class-component';
import { Ref, Prop } from 'vue-property-decorator';

export default class FileDragAndDrop extends Vue {

  @Prop({ default: null }) fileInput!: Array<File>;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) uploading!: boolean;
  @Prop({ default: null }) numFileLimit!: number;
  @Prop({ default: 20 }) fileSizeLimit!: number;

  @Ref('fileSelector') readonly fileSelector!: HTMLElement;

  id: string = this.$guid();
  dragAndDropCapable = false;
  selectedFiles: Array<File> = [];
  error = false;
  errorMsg: Array<string> = [];

  private acceptedFileTypesOnInput = ".PDF, .DOC, .DOCX, .XLS, .XLSX, .RFT, .JPG, .PNG";
  private acceptedFileMineTypeArray = [
    'application/pdf', 
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/jpg', 'image/jpeg', 
    'image/png'
  ];

  mounted(): void {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
  }

  private droppedFiles(e: any) {
    e.preventDefault();
    if (!this.disabled) {
      e.currentTarget.classList.remove('drag-over');
      this.selectedFiles = Object.values(e.dataTransfer.files);
      this.checkSelectedFiles(this.selectedFiles);
      this.error = this.errorMsg.length > 0;
      if (!this.error) {
        this.$emit('file-input-changed', this.selectedFiles);
        // this.selectedFiles = [];
      }
    }
  }

  private dragover(e: any) {
    e.preventDefault();
    if (!this.disabled) {
      e.currentTarget.classList.add('drag-over');
    }
  }

  private dragleave(e: any) {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
  }

  private selectFile(e: any) {
    e.target.blur();
    const elm: HTMLElement = this.fileSelector as HTMLElement;
    elm.click();
  }

  private tryAgain(): void {
    this.error = false;
    this.selectedFiles = [];
    this.$emit('file-input-changed', this.selectedFiles);
  }

  private onFileSelected(event: any) {
    if (!this.disabled) {
      this.selectedFiles = Object.values(event.target.files);
      this.checkSelectedFiles(this.selectedFiles);
      this.error = this.errorMsg.length > 0;
      if (!this.error) {
        this.$emit('file-input-changed', this.selectedFiles);
        // this.selectedFiles = [];
        const elm: any = this.fileSelector;
        elm.value = null;
      }
    }
  }

  checkSelectedFiles(fileList: Array<File>): void {
    this.errorMsg = [];
    if (this.numFileLimit && fileList.length > this.numFileLimit) {
      this.errorMsg.push('Too many files selected');
    }

    if (this.hasOverSizedFile(fileList, this.fileSizeLimit)) {
      this.errorMsg.push('Document(s) are too large to upload');
    }

    if (this.hasProhibitedFileType(fileList, this.acceptedFileMineTypeArray)) {
      this.errorMsg.push('File types allowed to upload: PDF, DOC, DOCX, XLS, XLSX, RFT, JPG, PNG');
    }
  }

  private hasOverSizedFile(fileList: Array<File>, sizeLimit: number): boolean {
    return [...fileList].filter(x => (x.size / 1024000) > sizeLimit).length > 0;
  }

  private hasProhibitedFileType(fileList: Array<File>, allowedFileType: Array<string>): boolean {
    return [...fileList].filter(x => !allowedFileType.includes(x.type)).length > 0;
  }

  public $guid() {
    const S4 = () => {
      // tslint:disable-next-line: no-bitwise
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    // then to call it, plus stitch in '4' in the third group
    // tslint:disable-next-line: max-line-length
    return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
  }

  private determineDragAndDropCapable() {

    const div: any = document.createElement('div');

    return (('draggable' in div)
      || ('ondragstart' in div && 'ondrop' in div))
      && 'FormData' in window
      && 'FileReader' in window;
  }

}
