import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import UserDefine from '@/assets/config/user-define.json';

@Options({})
export default class PageFooter extends Vue {

  @Prop({ default: null })
  private baseYear!: string;

  @Prop({ default: null })
  private currentYear!: string;

  get termsUrl(): string {
    return UserDefine[0].terms_url;
  }
  get privacyUrl(): string {
    return UserDefine[0].privacy_url;
  }

  get copyrightYearRange(): string {
    if (this.baseYear == null) {
      return moment().format('YYYY');
    } else if (this.baseYear == this.currentYear){
      return this.baseYear;
    } else {
      return this.baseYear + ' - ' + this.currentYear;
    }
  }

  private logoClick() {
    window.open(UserDefine[0].lnsw_url, 'blank');
  }

}
