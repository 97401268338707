import { Options, Vue } from "vue-class-component";
import { Authentication } from '@/shared/modules/authentication/authentication';
import { AuthorizedUser } from '@/shared/models/authorized-user';
import { SELF_URL } from '@/shared/config/config';
import { Url } from '@/shared/config/url';
import store from '@/shared/store';

@Options({})
export default class Logout extends Vue {

  private authentication: Authentication = new Authentication(
    this.userAccount);

  created() {
    this.authentication.logout(this.logoutCallBack);
  }

  private logoutCallBack(): void {
    setTimeout( () => { document.location.href = SELF_URL + Url.Logout; }, 250);
  }

  get userAccount(): AuthorizedUser {
    return store.getters.getUserAccount;
  }
}
