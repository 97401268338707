import { Options, Vue } from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import { RouteN } from '@/shared/constants/route-name';
import AccountInfoPanel from '@/shared/components/page-header/account-info-panel/index.vue';
import AlertBox from '@/shared/components/alert-box/index.vue';
import { EventEmitter } from 'events';
import store from '@/shared/store';
import { AppInsights } from '@/mars-app/appInsights';
import { MARSApi } from '@/shared/modules/mars-api';
import { API_URL, SOURCE_BRANCH } from "@/shared/config/config";

@Options({
  components: {
    AccountInfoPanel,
    AlertBox,
  }
})
export default class PageHeader extends Vue {
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  @Prop({ default: true })
  private showAppMenu!: boolean;

  @Prop({ default: null })
  private isExternal!: boolean;

  private showCancelAlertBox = false;
  private alertBoxTitle = '';
  private alertBoxBody = 'Are you sure you want to cancel?';

  private appName = '';

  private sourceBranch = SOURCE_BRANCH;
  private apiURL = API_URL;
  public apiBranch = '';

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('$route.name')
  private onRouteChanged(): void {
    this.setAppName();
  }

  mounted(): void {
    // this.eventCtrl.on('cancel-adjustment', this.closeAdjustment);
    // this.eventCtrl.on('cancel-update', this.cancelUpdate);
    this.eventCtrl.on('cancel-pc-renew', this.cancelApplication);
    this.eventCtrl.on('cancel-pc-no-renew', this.cancelApplication);
    this.eventCtrl.on('cancel-sm-application', this.cancelApplication);
    this.eventCtrl.on('cancel-sar-application', this.cancelApplication);
    this.eventCtrl.on('cancel-sanr-application', this.cancelApplication);
    this.eventCtrl.on('cancel-amn-application', this.cancelApplication);
    this.eventCtrl.on('cancel-amr-application', this.cancelApplication);
    this.eventCtrl.on('cancel-stn-application', this.cancelApplication);
    this.eventCtrl.on('cancel-str-application', this.cancelApplication);
    this.eventCtrl.on('cancel-stn-fromlink', this.cancelSTApplication);
    this.eventCtrl.on('cancel-str-fromlink', this.cancelSTApplication);
    this.setAppName();
    if (this.env.length > 0) {
      setTimeout(() => {
        this.apiService.SystemApi?.apiV1SystemPingGet()
          .then((res: any) => {
            this.apiBranch = res.data.split('|')[0];
          })
          .catch(err => {
            console.log('error getting branch info: ', err);
          })
      }, 10000);
    }
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('cancel-pc-renew', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-pc-no-renew', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-sm-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-sar-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-sanr-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-amn-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-amr-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-stn-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-str-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-amr-application', this.cancelApplication);
    this.eventCtrl.removeListener('cancel-stn-fromlink', this.cancelSTApplication);
    this.eventCtrl.removeListener('cancel-str-fromlink', this.cancelSTApplication);
  }

  private setAppName() {
    const _pathName: string = this.$route.path;
    if (this.isExternal == null) {
      this.appName = '';
    } else if (this.isExternal) {  
      if (_pathName.indexOf('/pcr') == 0) {
        this.appName = 'Practising Certificate renewal';
      } else if (_pathName.indexOf('/pc-nor') == 0) {
        this.appName = 'Practising Certificate non-renewal';
      } else if (_pathName.indexOf('/sm-application') == 0) {
        this.appName = 'Solicitor Member';
      } else if (_pathName.indexOf('/sar-application') == 0) {
        this.appName = 'Specialist Accreditation renewal';
      } else if (_pathName.indexOf('/sa-nr-application') == 0) {
        this.appName = 'Specialist Accreditation non-renewal';
      } else if (_pathName.indexOf('/amn-application') == 0) {
        this.appName = 'Associate Membership';
      } else if (_pathName.indexOf('/stn-application') == 0) {
        this.appName = 'Student Membership';
      } else if (_pathName.indexOf('/str-application') == 0) {
        this.appName = 'Student Membership renewal';
      } else if (_pathName.indexOf('/amr-application') == 0) {
        this.appName = 'Associate Membership Renewal';
      } else {
        this.appName = '';
      }
    } else if (!this.isExternal){
      this.appName = 'MARS Admin Console';
    }
  }

  private cancelApplication(): void {
    this.alertBoxTitle = "CANCEL APPLICATION";
    this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
    if (this.isConfirmationPage) {
      this.exitApplication();
    } else {
      this.showCancelAlertBox = true;
    }
  }

  private cancelSTApplication(): void {
    this.alertBoxTitle = "CANCEL APPLICATION";
    this.alertBoxBody = 'By selecting the "Associate Membership Application" link, you will be directed back to the MY LAW SOCIETY portal, and your current application will be cancelled. Once on the portal, locate the Associate Membership card and click the \'Apply Now\' button.<br /><br />Do you wish to continue?';
    if (this.isConfirmationPage) {
      this.exitApplication();
    } else {
      this.showCancelAlertBox = true;
    }
  }

  // private cancelPcRenew() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  // private cancelPcNoRenew() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  // private cancelSmApplication() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  // private cancelSarApplication() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  // private cancelAmnApplication() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  // private cancelAmrApplication() {
  //   this.alertBoxTitle = "CANCEL APPLICATION";
  //   this.alertBoxBody = 'By cancelling the application all progress will be lost. Are you sure you want to cancel?';
  //   if (this.isConfirmationPage) {
  //     this.exitApplication();
  //   } else {
  //     this.showCancelAlertBox = true;
  //   }
  // }

  private async exitApplication(): Promise<void> {

    try {
      this.showCancelAlertBox = false;
      // clear all application data
      const _appId = store.getters.getAppRenew.id;
      if (this.$route.name == RouteN.Mars.PcRenew.n || this.$route.name == RouteN.Mars.PcRenewOverview.n) {
        await this.apiService.PcRenewApi?.apiV1PcRenewCancelPcRenewApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.PcNoRenew.n) {
        await this.apiService.PcNonRenewApi?.apiV1PcNonRenewCancelPcRenewApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.SmApplication.n) {
        await this.apiService.SmApi?.apiV1SmCancelSmApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.SarApplication.n) {
        await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewCancelApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.SaNrApplication.n) {
        await this.apiService.SpecAccNonRenewApi?.apiV1SpecAccNonRenewCancelApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.AmnApplication.n) {
        await this.apiService.AmNewApi?.apiV1AmNewCancelAmNewApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.StnApplication.n) {
        await this.apiService.StudentNewApi?.apiV1StudentNewCancelStudentNewApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.StrApplication.n) {
        await this.apiService.StudentRenewApi?.apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(_appId);
      } else if (this.$route.name == RouteN.Mars.AmrApplication.n) {
        await this.apiService.AmRenewApi?.apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(_appId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      window.sessionStorage.clear();
      store.dispatch('setPcrFormData', {});
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
  }

  get isConfirmationPage(): boolean {
    const section: string = this.$route.query.sec as string || '';
    return section.indexOf('confirmation') > -1;
  }

  get env(): string {
    const local = window.location.href.indexOf('localhost') > 0 ? 'DEV' : '';
    const dev = window.location.href.indexOf('-dev') > 0 ? 'DEV' : '';
    const sit = window.location.href.indexOf('-sit') > 0 ? 'SIT' : '';
    return local + dev + sit;
  }

  get isSameBranchName(): boolean {
    return this.sourceBranch == this.apiBranch;
  }

}
