export enum NavStepStatusID {
  Unknown = 'unknown',
  NotDone = 'not-done',
  Done = 'done',
  Current = 'current',
}
export class NavStep {
  constructor(
    public name: string = '',
    public routeSecName: string = '',
    public visible: boolean = true,
    public status: NavStepStatus = NavStepStatusID.NotDone,
  ) {
  }
}

export type NavStepStatus = NavStepStatusID.Unknown | NavStepStatusID.NotDone |
  NavStepStatusID.Done | NavStepStatusID.Current;
export interface StepperName {
  applicationDetails: string;
  applicantDetails: string;
  eligibility: string;
  membershipDetails: string;
  currentDetails: string;
  applicationDeclarations: string;
  renewalDeclarations: string;
  associateMembershipCategory: string;
  currentStatus: string;
  declaration: string;
  declarations: string;
  details: string;
  record: string;
  address: string;
  renewal: string;
  register: string;
  additional: string;
  preferences: string;
  specialistAccreditation: string;
  payment: string;
  confirmation: string;
}

export const Step: StepperName = {
  applicationDetails: 'Application Details',
  applicantDetails: 'Applicant Details',
  eligibility: 'Eligibility',
  membershipDetails: 'Membership Details',
  currentDetails: 'Current Details',
  applicationDeclarations: 'Application Declarations',
  renewalDeclarations: 'Renewal Declarations',
  associateMembershipCategory: 'Associate Membership Category',
  currentStatus: 'Current Status',
  declaration: 'Declaration',
  declarations: 'Declarations',
  details: 'Current Details',
  record: 'Practice Record',
  address: 'Address Details',
  renewal: 'Renewal Details',
  register: 'Other Details',
  additional: 'Additional Details',
  preferences: 'Preferences',
  specialistAccreditation: 'Specialist Accreditation',
  payment: 'Payment Details',
  confirmation: 'Confirmation',

}

export interface SectionName {
  applicationDetails: string;
  applicantDetails: string;
  eligibility: string;
  membershipDetails: string;
  currentDetails: string;
  applicationDeclarations: string;
  renewalDeclarations: string;
  associateMembershipCategory: string;
  currentStatus: string;
  declaration: string;
  declarations: string;
  details: string;
  record: string;
  address: string;
  renewal: string;
  register: string;
  additional: string;
  preferences: string;
  specialistAccreditation: string;
  payment: string;
  confirmation: string;
}

export const Section: SectionName = {
  applicationDetails: 'application-details',
  applicantDetails: 'applicant-details',
  eligibility: 'eligibility',
  membershipDetails: 'membership-details',
  currentDetails: 'current-details',
  applicationDeclarations: 'application-declarations',
  renewalDeclarations: 'renewal-declarations',
  associateMembershipCategory: 'associate-membership-category',
  currentStatus: 'current-status',
  declaration: 'declaration',
  declarations: 'declaration',
  details: 'current-details',
  record: 'practice-record',
  address: 'address-details',
  renewal: 'renewal-details',
  register: 'statutory-register',
  additional: 'additional-details',
  preferences: 'preferences',
  specialistAccreditation: 'specialist-accreditation',
  payment: 'payment-details',
  confirmation: 'confirmation',
}
