import { User } from '@/shared/models/user'
import { Token } from '@/shared/models/token'
import { AuthenticationStatus, AuthenticationStatusType } from '@/shared/modules/authentication/authentication-status'
import { Session } from './session'

export class AuthorizedUser {
  constructor(
    public user: User = new User(),
    public token: Token = new Token(),
    public session: Session = new Session(),
    public authenticationStatus: AuthenticationStatus = AuthenticationStatusType.Unknown
  ) { }
}