import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e3ee90e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pcr-practice-record-ppp-popup" }
const _hoisted_2 = {
  key: 0,
  class: "popup-modal"
}
const _hoisted_3 = { class: "dialog-box" }
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { style: {"display":"inline"} }
const _hoisted_6 = { class: "practice-cards row mt-3" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "btns right" }
const _hoisted_9 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_Popover = _resolveComponent("Popover")
  const _component_PcrPracticeRecordPppCard = _resolveComponent("PcrPracticeRecordPppCard")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("i", {
                  class: "fal fa-times close",
                  onClick: _cache[0] || (_cache[0] = (...args) => (_ctx._cancel && _ctx._cancel(...args)))
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.title), 1),
                  _createTextVNode(),
                  _createVNode(_component_Popover, {
                    popover: _ctx.$getPopover('SelectPPP', 3)
                  }, null, 8, ["popover"]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.message), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newRecords, (p, index) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_PcrPracticeRecordPppCard, {
                        newRecord: _ctx.newRecords[index],
                        "onUpdate:newRecord": $event => ((_ctx.newRecords[index]) = $event),
                        index: index,
                        onPppSelected: _ctx.pppSelected
                      }, null, 8, ["newRecord", "onUpdate:newRecord", "index", "onPppSelected"])
                    ]))
                  }), 256))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    class: "btn btn-tertiary",
                    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx._cancel && _ctx._cancel(...args)))
                  }, _toDisplayString(_ctx.cancelButton), 1),
                  _createElementVNode("button", {
                    class: "btn btn-secondary ml-2",
                    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx._confirm && _ctx._confirm(...args))),
                    disabled: !_ctx.pppCheck
                  }, _toDisplayString(_ctx.okButton), 9, _hoisted_9)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}