import store from "@/shared/store";
import {Options, Vue} from "vue-property-decorator";

@Options({
  components: {}
})
export default class AmrConfirmation extends Vue {

  mounted(): void {
    window.sessionStorage.removeItem('amrAppFormData');
    store.dispatch('setAppRenew', null);
  }
  
}

