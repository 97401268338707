import { Session } from './session';
import { IDLE_TIMEOUT } from '@/shared/config/config';

export class IDLESessionManager {

  public checkInterval = 1000;
  private workerID = -1;
  private session: Session = new Session();
  private timeoutCallBack: () => void;

  constructor(
  ) {
    this.session = new Session(
      Math.round(new Date().getTime() / 1000),
      Math.round(new Date().getTime() / 1000) + IDLE_TIMEOUT
    );
    this.timeoutCallBack = null as any;
    this.setup();
  }

  public run(cb: () => void): void {
    this.timeoutCallBack = cb;
    this.workerID = setInterval(() => {
      this.doCheckSession();
    }, this.checkInterval);
  }

  public stop(): void {
    clearInterval(this.workerID);
    window.removeEventListener('mousemove', this.resetTimer, false);
    window.removeEventListener('mousedown', this.resetTimer, false);
    window.removeEventListener('keypress', this.resetTimer, false);
    window.removeEventListener('DOMMouseScroll', this.resetTimer, false);
    window.removeEventListener('mousewheel', this.resetTimer, false);
    window.removeEventListener('touchmove', this.resetTimer, false);
    window.removeEventListener('MSPointerMove', this.resetTimer, false);
  }

  public resetTimer: () => void = () => {
    if (Math.round(new Date().getTime() / 1000) - this.session.issued >= 5) {
      this.doCheckSession(); // do another check;
      this.session.issued = Math.round(new Date().getTime() / 1000);
      this.session.expires = Math.round(new Date().getTime() / 1000) + IDLE_TIMEOUT;
    }
  }

  private doCheckSession(): void {
    if (this.session.hasExpired()) {
      this.timeoutCallBack();
      this.stop();
    }
  }

  private setup(): void {
    window.addEventListener('mousemove', this.resetTimer, false);
    window.addEventListener('mousedown', this.resetTimer, false);
    window.addEventListener('keypress', this.resetTimer, false);
    window.addEventListener('DOMMouseScroll', this.resetTimer, false);
    window.addEventListener('mousewheel', this.resetTimer, false);
    window.addEventListener('touchmove', this.resetTimer, false);
    window.addEventListener('MSPointerMove', this.resetTimer, false);
  }

}
