export interface SectionName {
  Details: string;
  Review: string;
  Payment: string;
  Prepayment: string;
  Confirmation: string;

  Add: string;
  Update: string;

}

export const Section: SectionName = {
  Details: 'details',
  Review: 'review',
  Payment: 'payment',
  Prepayment: 'prepayment',
  Confirmation: 'confirmation',
  Add: 'add',
  Update: 'update',

};
