import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { FirmNameSearch } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    firm: Object,
    label: String,
  },
  components: {}
})
export default class FirmSearch extends Vue {
  private firm!: FirmNameSearch;
  private label!: string;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  // private keyword = this.firm?.firmName ? this.firm.firmName : '';
  private isSearching = false;
  private isOpen = false;
  private stopSearch = false;

  private formDirty = false;

  private arrowCounter = -1;
  private searchDebounced: any = null;

  private noSearchResult = false;
  private addNewFirm = false;

  private firmList = [];

  private newFirmName = '';

  @Watch('isSearching')
  private isSearchingChange() {
    this.$emit('isBusy', this.isSearching || this.isOpen);
  }

  @Watch('isOpen')
  private isOpenChange() {
    this.$emit('isBusy', this.isSearching || this.isOpen);
  }

  @Watch('firm.firmName')
  private onKeywordChange(): void {
    if (!this.addNewFirm) {
      this.$emit('isBusy', this.isSearching || this.isOpen);
      if (this.firm.firmName?.trim().length > 2 && !this.stopSearch && this.formDirty) {
        setTimeout(this.searchDebounced.cancel, 0);
        setTimeout(this.searchDebounced, 0);
      }
    }
  }

  @Watch('newFirmName')
  private onNewFirmNameChange(): void {
    if (this.newFirmName?.length > 0) {
      this.$emit('updateFirm', {
        firmId: this.firm.firmId,
        firmName: this.newFirmName,
      });
    }
    this.$emit('isBusy', false);
  }

  mounted(): void {
    document.addEventListener('click', this.handleClickOutside);
    this.searchDebounced = debounce(() => {
      this.stopSearch = false;
      if (this.firm.firmName.trim() !== '') {
        this.getFirmList();
      }
    }, 500);
    this.$nextTick(() => {
      this.addNewFirm = this.firm.firmId === 0;
      this.newFirmName = this.firm.firmName;
    });
  }

  beforeUnmount(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  private handleClickOutside(event: any) {
    if (this.$el !== (event.target)) {
      if (this.isOpen) {
        this.isOpen = false;
        this.arrowCounter = -1;
        this.$emit('updateFirm', {
          firmId: null,
          firmName: '',
        });
      }
    }
  }

  private getFirmList() {
    if (this.firm.firmName.length > 2) {
      this.isSearching = true;

      const body: any = {
        skip: 0,
        take: 0,
        keyword: this.firm.firmName.trim(),
      }
      this.apiService.CodeApi?.apiV1CodeSearchFirmsPost(body)
        .then((res: any) => {
          this.processResult(res);
        })
        .catch((err: any) => {
          this.ai.trackException('Error getting firm list: ' + err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error getting firm list: ' + err);
          this.isSearching = false;
        })
    }

  }

  private processResult(res: any) {
    this.firmList = res.data.data;
    this.isSearching = false;
    if (this.firmList.length > 0) {
      this.arrowCounter = -1;
      this.isOpen = this.firmList.length > 0;
      this.noSearchResult = false;
    } else {
      this.$emit('updateFirm', {
        firmId: null,
        firmName: '',
      });
      this.isOpen = false;
      this.noSearchResult = true;
      const el = this.$refs['firmSearch'] as HTMLElement;
      setTimeout(() => {
        el.focus(), 500
      })
    }
  }

  private highlightWord(word: string) {
    try {
      const regex = new RegExp('(' + this.firm.firmName + ')', 'ig');
      return word.replace(regex, '<mark>$1</mark>');
    } catch (err) {
      return word;
    }
  }

  private onArrowDown() {
    if (this.firmList.length > 0) {
      if (this.arrowCounter < this.firmList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    this.formDirty = false;
    this.isOpen = false;
    if (this.firmList.length > 0) {
      this.selectFirmFromSearch(
        this.firmList[this.arrowCounter === -1 ? 0 : this.arrowCounter]
      );
    }
  }

  private selectFirmFromSearch(firm: any) {
    this.isOpen = false;
    this.stopSearch = true;
    // this.firmName = firm.firmName;
    // this.firmId = firm.firmId;
    // this.firm.firmName = firm.firmName;
    setTimeout(() => {
      this.stopSearch = false;
    }, 500)
    const _firm: FirmNameSearch =
      { firmId: firm.firmId, firmName: firm.firmName };
    this.$emit('updateFirm', _firm);
    // this.keyword = '';
  }

  private addNewFirmFromHere() {
    this.addNewFirm = true;
    this.newFirmName = '';
    this.firm.firmId = 0;
    this.$emit('updateFirm', {
      firmId: this.firm.firmId,
      firmName: this.newFirmName,
    });
    const el = this.$refs['addNewFirmName'] as HTMLElement;
    setTimeout(() => {
      el.focus(), 500
    })
  }

  private searchAgain() {
    this.addNewFirm = false;
    this.$emit('updateFirm', {
      firmId: null,
      firmName: '',
    });
    const el = this.$refs['firmSearch'] as HTMLElement;
    setTimeout(() => {
      el.focus(), 500
    })
  }

}

