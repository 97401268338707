import { createApp } from 'vue'
import App from './app.vue'
// import '../registerServiceWorker'
import router from './router'
import store from '@/shared/store'
import { AppInsights } from './appInsights'
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css' // optional for styling

const appInsights = new AppInsights;

appInsights.loadAppInsights()
appInsights.trackPageView()

createApp(App).use(store).use(router)
.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  component: 'tippy', // => <tippy/>
  componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
  defaultProps: {
    placement: 'auto-end',
    allowHTML: true,
  }, // => Global default options * see all props
}).mount('#app')
