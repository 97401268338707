import { Options, Vue } from "vue-property-decorator";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import FormatDateTimeMixin from "@/shared/mixins/format-datetime";

@Options({
  props: {
    newRecord: Object,
    index: Number,
  },
  components: {
    AddressPreview,
  },
  mixins: [FormatDateTimeMixin],
})
export default class PcrPracticeRecordPppCard extends Vue {
  private newRecord!: any;
  private index!: number;

  private pppClicked(i: number) {
    this.$emit('pppSelected', this.index);
  }

  private getSelectedAddressFromArray(addresses: any[]) {
    const _selectedAddress: any[] = addresses.filter(a => {
      return a.isSelected;
    });
    return _selectedAddress[0]?.address ? _selectedAddress[0].address : addresses[0].address;
  }

}

