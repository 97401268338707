import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AddressDetail, Country, SuburbStatePostcode } from "@/shared/modules/typescript-api-client/models";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import PostcodeSearch from "@/shared/components/postcodeSearch/index.vue";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";


@Options({
  props: {
    address: Object,
    showAddressType: Boolean,
    disableCountrySelection: Boolean,
    isSubmitting: Boolean,
    overseaAddressLabel: {
      type: String,
      default: 'Address for service'
    },
  },
  components: {
    PostcodeSearch,
    LoadingIndicator,
  }
})
export default class AddressComponent extends Vue {
  private address!: AddressDetail;
  private isSubmitting!: boolean;
  private showAddressType?: boolean;
  private validAddressDetails!: boolean;
  private disableCountrySelection?: boolean;
  private overseaAddressLabel?: string;
  

  private newAddress: AddressDetail = {};

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private countryList: Country[] = [];

  // private addressTypeId = 1;
  private buildingTypes = [];
  private streetTypes = [];

  private postcodeList = [];
  private deliveryTypeList = [];
  private dxExchangeTypeList = [];

  private country: any = {};
  private buildingType: any = '';
  private streetType: any = '';
  private deliveryType: any = '';
  private dxExchangeType: any = '';

  private isLoadingData = false;
  private suburbStatePostcode = {};

  private streetNumberCheck = true;
  private streetNameCheck = true;
  private streetTypeCheck = true;
  private suburbCheck = true;
  private countryCheck = true;
  private foreignAddressCheck = true;
  private deliveryTypeCheck = true;
  private poBoxCheck = true;
  private dxExchangeTypeCheck = true;
  private dxNumberCheck = true;

  @Watch('address')
  private onAddressChange() {
    // this.newAddress = JSON.parse(JSON.stringify(this.address));
  }

  @Watch('newAddress.addressTypeId')
  private onRadioOptionChange() {
    if (this.newAddress.addressTypeId == 1) {
      this.deliveryTypeCheck = true;
      this.poBoxCheck = true;
      this.dxExchangeTypeCheck = true;
      this.dxNumberCheck = true;
    }
    if (this.newAddress.addressTypeId == 2) {
      this.streetNumberCheck = true;
      this.streetNameCheck = true;
      this.streetTypeCheck = true;
      this.dxExchangeTypeCheck = true;
      this.dxNumberCheck = true;
    }
    if (this.newAddress.addressTypeId == 3) {
      this.streetNumberCheck = true;
      this.streetNameCheck = true;
      this.streetTypeCheck = true;
      this.deliveryTypeCheck = true;
      this.poBoxCheck = true;
      this.suburbCheck = true;
    }
    this.onNewAddressChange();
  }

  @Watch('newAddress.foreignAddress')
  private foreignAddressChange() {
    this.onNewAddressChange();
  }

  private onNewAddressChange() {
    this.countryCheck = this.newAddress.countryId && this.newAddress.countryId > 0;
    if (this.newAddress.countryName !== 'AUSTRALIA') {
      this.foreignAddressCheck = this.newAddress.foreignAddress !== '';
      this.$emit('updateAddress', this.newAddress, this.foreignAddressCheck);
    } else {
      this.foreignAddressCheck = true;
      if (this.newAddress.addressTypeId === undefined) {
        this.newAddress.addressTypeId = 1;
      }
      if (this.newAddress.addressTypeId == 1) {
        this.streetNumberCheck = this.newAddress.streetNumber && this.newAddress.streetNumber.trim() !== '';
        this.streetNameCheck = this.newAddress.streetName && this.newAddress.streetName.trim() !== '';
        // this.streetTypeCheck = this.newAddress.streetTypeId !== null && this.newAddress.streetTypeId > 0;
        this.suburbCheck = this.newAddress.suburbId && this.newAddress.suburbId > 0;
      }
      if (this.newAddress.addressTypeId == 2) {
        this.deliveryTypeCheck = this.newAddress.deliveryTypeId !== null && this.newAddress.deliveryTypeId > 0;
        this.poBoxCheck = this.newAddress.poBox && this.newAddress.poBox.trim() !== '';
        this.suburbCheck = this.newAddress.suburbId && this.newAddress.suburbId > 0;
      }
      if (this.newAddress.addressTypeId == 3) {
        this.dxExchangeTypeCheck = this.newAddress.dxExchangeTypeId !== null && this.newAddress.dxExchangeTypeId > 0;
        this.dxNumberCheck = this.newAddress.dxNumber && this.newAddress.dxNumber.trim() !== '';
      }

      const _validAddressDetails = this.countryCheck && this.streetNumberCheck && this.streetNameCheck
        && this.streetTypeCheck && this.suburbCheck
        && this.deliveryTypeCheck && this.poBoxCheck && this.dxExchangeTypeCheck && this.dxNumberCheck;

      this.$emit('updateAddress', this.newAddress, _validAddressDetails);
    }
  }

  mounted(): void {
    this.preLoadData();
  }

  private async preLoadData() {
    const _countryList = store.getters.getCountryList;
    this.countryList = _countryList == null ? null : JSON.parse(_countryList);

    const dxExchangeTypeList = store.getters.getDxExchangeTypeList;
    this.dxExchangeTypeList = dxExchangeTypeList == null ? null : JSON.parse(dxExchangeTypeList);

    this.isLoadingData = true;
    this.$emit('readyState', !this.isLoadingData);

    if (this.countryList == null) {
      await this.apiService.CodeApi?.apiV1CodeGetCountriesGet()
        .then((res: any) => {
          this.countryList = res.data.data;
          store.dispatch('setCountryList', this.countryList);
        })
        .catch((err: any) => {
          // console.log(err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error getting country list: ' + err.response?.status);
          this.ai.trackException('Error getting country list: ' + err);
        })
    }

    if (this.dxExchangeTypeList == null) {
      await this.apiService.CodeApi?.apiV1CodeGetDxExchangeListGet()
        .then((res: any) => {
          this.dxExchangeTypeList = res.data.data;
          store.dispatch('setDxExchangeTypeList', this.dxExchangeTypeList);
        })
        .catch((err: any) => {
          // console.log(err);
          this.eventCtrl.emit('show-snackbar', 'error', 'Error DX Exchange List: ' + err.response.status);
          this.ai.trackException('Error getting DX Exchange list: ' + err);
        })
    }

    try {
      const resp: any[] = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetBuildingTypesGet(),
        this.apiService.CodeApi?.apiV1CodeGetStreetTypesGet(),
        this.apiService.CodeApi?.apiV1CodeGetDeliveryTypesGet(),
      ]);
      this.buildingTypes = resp[0].data.data;
      this.streetTypes = resp[1].data.data;
      this.deliveryTypeList = resp[2].data.data;

      this.isLoadingData = false;
      if (this.address?.countryId > 0) {
        this.newAddress = this.address;
        this.country = { id: this.newAddress.countryId, name: this.newAddress.countryName };
      }
      this.suburbStatePostcode = {
        suburbId: this.newAddress.suburbId,
        suburbName: this.newAddress.suburbName,
        postcode: this.newAddress.postcode,
        stateId: this.newAddress.stateId,
        stateName: this.newAddress.stateName,
        state: this.newAddress.state,
        postCodeSuburbId: this.newAddress.postCodeSuburbId,
      }
      this.streetType = {
        id: this.newAddress?.streetTypeId ?? 0,
        name: this.newAddress?.streetTypeName ?? '',
      }
      this.buildingType = {
        id: this.newAddress?.buildingTypeId ?? 0,
        name: this.newAddress?.buildingTypeName ?? '',
      }

      if (this.newAddress.deliveryTypeId && this.newAddress.deliveryTypeId !== 0) {
        this.deliveryType = { id: this.newAddress.deliveryTypeId, name: this.newAddress.deliveryTypeName };
      }

      if (this.newAddress.dxExchangeTypeId && this.newAddress.dxExchangeTypeId !== 0) {
        this.dxExchangeType = { id: this.newAddress.dxExchangeTypeId, name: this.newAddress.dxExchangeTypeName };
      }

    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to preload data: ' + err);
      this.ai.trackException('Error, Unable to preload data: ' + err);
    } finally {
      this.isLoadingData = false;
      this.$emit('readyState', !this.isLoadingData);
    }
  }

  private setCountry() {
    this.newAddress.countryId = this.country.id;
    this.newAddress.countryName = this.country.name;
    if (this.newAddress.countryName !== 'AUSTRALIA') {
      this.newAddress.foreignAddress = '';
    }
    this.onNewAddressChange();
  }

  private buildingTypeChange(bt: any) {
    this.newAddress.buildingTypeId = bt.id;
    this.newAddress.buildingTypeName = bt.name;
    this.onNewAddressChange();
  }

  private streetTypeChange(st: any) {
    this.newAddress.streetTypeId = st.id;
    this.newAddress.streetTypeName = st.name;
    this.onNewAddressChange();
  }

  private setDeliveryType(dt: any) {
    this.newAddress.deliveryTypeId = dt.id || '';
    this.newAddress.deliveryTypeName = dt.name || '';
    this.onNewAddressChange();
  }

  private setDxExchangeType(dx: any) {
    this.newAddress.dxExchangeTypeId = dx.id || '';
    this.newAddress.dxExchangeTypeName = dx.name || '';
    this.onNewAddressChange();
  }

  private updateSuburb(s: SuburbStatePostcode) {
    this.newAddress.suburbId = s.suburbId;
    this.newAddress.suburbName = s.suburbName;
    this.newAddress.postcode = s.postcode;
    this.newAddress.stateId = s.stateId;
    this.newAddress.stateName = s.stateName;
    this.newAddress.postCodeSuburbId = s.postCodeSuburbId;
    this.newAddress.state = s.state;
    this.onNewAddressChange();
  }

  get suburbError() {
    return !this.suburbCheck && this.isSubmitting;
  }

}

