import { Options, Vue } from 'vue-class-component';
import { NavStep, NavStepStatusID } from './nav-step';
import HelpScreen from '@/shared/components/help-screen/index.vue';
import UserDefine from '@/assets/config/user-define.json';
// import HelpContentData from '@/assets/config/help-text.json';
// import { RouteN } from "@/shared/constants/route-name";

@Options({
  props: {
    navSteps: Array,
    navbarText: String,
    showHelpBtn: Boolean,
    showFaqBtn: Boolean,
  },
  components: {
    HelpScreen,
    // HelpContent,
  }
})
export default class HeaderStepper extends Vue {
  private showHelpScreen = false;
  navSteps!: NavStep[];
  navbarText!: string;
  showHelpBtn!: boolean;
  showFaqBtn!: boolean;

  get section(): string {
    return this.$route.query.sec as string;
  }

  get helpContentData(): unknown {
    const _helpContentData: any = [{}];
    return _helpContentData;
  }

  private navigate(s: NavStep): void {
    const _section: string = this.$route.query.sec as string;
    if (s.status === NavStepStatusID.Done && _section !== 'confirmation') {
      const temp: string = JSON.stringify(this.$route.query);
      const tempJSON = JSON.parse(temp);
      tempJSON.sec = s.routeSecName;
      this.$router.push({ query: tempJSON });
    }
  }

  private navigateBack() {
    const index = this.currentNavStepIndex();
    if (index > 0) {
      // console.log(this.navSteps[index - 1].routeSecName);
      this.navigate(this.navSteps[index - 1]);
    }
  }

  private currentNavStepIndex(): number {
    let index = -1;
    this.navSteps.filter((z) => z.visible).forEach((x, i) => {
      if (x.status === NavStepStatusID.Current) {
        index = i;
      }
    });
    return index;
  }

  private contactUs() {
    window.open(UserDefine[0].contact_us_url, 'blank');
  }

  private faqLink() {
    const _pathName: string = this.$route.path;
    let fqaLink = UserDefine[0].faq_url;
    if (_pathName.indexOf('/amr-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/AMR-faq';
    } else if (_pathName.indexOf('/amn-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/AMN-faq';
    } else if (_pathName.indexOf('/stn-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/STN-faq';
    } else if (_pathName.indexOf('/str-application') == 0) {
      fqaLink = 'https://www.lawsociety.com.au/STR-faq';
    }
    window.open(fqaLink, 'blank');
  }


}

