import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import DatePicker from 'vue-datepicker-next';

@Options({
  props: {
    categoryDetail: Object,
    isSectionLoading: Boolean,
    admissionPlaceList: Array,
    uploading: Boolean,
  },
  components: {
    LoadingIndicator,
    Popover,
    FileDragAndDrop,
    DatePicker
  },
  mixins: [FormatDateTimeMixin],
})
export default class  AmnAssociateMCategoryTwo extends Vue {
  private categoryDetail!: any;
  private isSectionLoading!: boolean;
  private admissionPlaceList!: any;
  private uploading!: boolean;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  // 1 - admittedInAustraliaFiles
  // 2 - foreignLawyerFiles
  // 3 - 
  // 4 - qualificationInstitutionFiles
  private selectedOption = 2;

  private fileList: Array<File> = [];

  @Watch('selectedOption')
  private onSelectedOptionChanged(): void {
    this.$emit('formChanged', {
      selectedOption: this.selectedOption,
      fileList: this.fileList
    });

    this.$emit('validForm', this.isFormValid());
  }

  private fileListChanged(fileList: Array<File>): void {
    this.fileList = fileList;
    this.$emit('formChanged', {
      selectedOption: this.selectedOption,
      fileList: this.fileList,
    });
  }

  private deleteFile(fileId?: number) {
    this.$emit('deleteFile', fileId);
  }

  private isFormValid(): boolean {
    if (this.selectedOption == 0) {
      return true;
    } else if (this.selectedOption == 1 && this.fileList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

