
import { Inject, Options, Vue } from "vue-property-decorator";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { Application } from "@/shared/modules/typescript-api-client/models";
import { filter } from "lodash";

@Options({
  props: {
    amnAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
  }
})
export default class AmnCurrentStatus extends Vue {
  private amnAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  private loadingDataSet = false;
  private currentStatusList = [];
  private currentStatus: any = {};
  private otherStatus = '';

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  mounted() {
    this.eventCtrl.emit('canGoNext', false);
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetCurrentStatusesGet(),
        this.apiService.AmNewApi?.apiV1AmNewGetAmNewRecordCurrentStatusApplicationIdGet(this.onlineApplication.id),
      ]);
      this.currentStatusList = this.sortStatus(resp[0].data.data);
      this.amnAppFormData.currentStatus = resp[1].data.data || { id: 0, amNewApplicationId: this.amnAppFormData.individualDetails.amNewApplicationId, currentStatus: {} };
      this.currentStatus = resp[1].data.data?.currentStatus;
      this.otherStatus = resp[1].data.data?.description || '';
      store.dispatch('setAmnAppFormData', this.amnAppFormData);
    } catch (err) {
      this.ai.trackException('Error, Unable to load current status: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load current status: ' + err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private sortStatus(statuses: any[]): [] {
    const list = statuses;
    const _otherStatus = filter(list, { name: 'Other (Please Specify)' })[0];
    list.sort((a, b) => a.name === _otherStatus.name ? 1 : b.name === _otherStatus.name ? -1 : a.name.localeCompare(b.name));
    return list as [];
  }

  private currentStatusChange() {
    this.amnAppFormData.currentStatus.currentStatus = this.currentStatus;
    this.amnAppFormData.currentStatus.description = this.currentStatus?.id == 15 ? this.otherStatus : '';
    this.canGoNextCheck();
  }

  private isValidForm(): boolean {
    if (this.currentStatus?.id == 15) {
      return this.otherStatus.trim() !== '';
    }
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

