export interface Url {
  Token: string;
  Login: string;
  Logout: string;
}

export const Url: Url = {
  Token: '/token',
  Login: '/login',
  Logout: '/logout'
};
