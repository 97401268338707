import { Inject, Options, Prop, Vue, Watch } from "vue-property-decorator";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import Popover from "@/shared/components/popover/index.vue";
import { Application, SarRecordApplicationDeclaration } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";

@Options({
  components: {
    LoadingIndicator,
    Popover,
    FileDragAndDrop
  },
  mixins: [FormatDateTimeMixin],
})
export default class  AppDeclarationTwo extends Vue {

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  @Prop({ default: false})
  private uploading!: boolean;

  @Prop()
  private declarationForm!: SarRecordApplicationDeclaration;

  private selectedOption = null as any;
  private fileList: Array<File> = [];

  // @Watch('selectedOption')
  // private onSelectedOptionChanged(): void {
  //   this.$emit('formChanged', {
  //     selectedOption: this.selectedOption,
  //     fileList: this.fileList
  //   });
  //   this.$emit('validForm', this.isFormValid());
  // }

  @Watch('declarationForm.interstateMembershipFiles.length')
  private smFileListChanged(): void {
    this.$emit('validForm', this.isFormValid());
  }

  mounted(): void {
    this.$nextTick(() => {
      this.$emit('validForm', this.isFormValid());
    });
  }

  private fileListChanged(fileList: Array<File>): void {
    this.fileList = fileList;
    this.$emit('formChanged', {
      // selectedOption: this.selectedOption,
      fileList: this.fileList
    });
    this.$emit('validForm', this.isFormValid());
    this.fileList = [];
  }

  private deleteFile(fileId?: number) {
    this.$emit('deleteFile', fileId);
  }

  private isFormValid(): boolean {
    // if (this.selectedOption == 0) {
    //   return true;
    // } else if (this.selectedOption == 1 && this.declarationForm?.interstateMembershipFiles?.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }

    return this.declarationForm?.interstateMembershipFiles?.length > 0;
  }

  // private updateOptions(): void {
  //   this.selectedOption = this.declarationForm.isNswSmHolderFlag ? 0 : this.declarationForm.isInterstateSmHolderFlag ? 1 : this.declarationForm.isNoSmHolderFlag ? 2 : null as any;
  // }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

