import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AZ_INSTRUMENT_KEY_DESKTOPAPP } from './config/config'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: AZ_INSTRUMENT_KEY_DESKTOPAPP,
    enableAutoRouteTracking: true,
    /* ...Other Configuration Options... */
    // https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
  }
});

// https://github.com/microsoft/ApplicationInsights-JS#readme
export class AppInsights {

  public loadAppInsights() {
    appInsights.loadAppInsights();
  }

  public trackPageView() {
    appInsights.trackPageView();
  }

  public trackEvent(eventString: string) {
    appInsights.trackEvent({ name: eventString })
  }

  // public trackPageViewPerformance() {
  //   appInsights.trackPageViewPerformance({name : 'somepage', url: 'some url'});
  // }

  public trackException(err: string) {
    appInsights.trackException({ exception: new Error(err) });
  }

  public trackTrace(msg: string) {
    appInsights.trackTrace({ message: msg });
  }

  public trackMetric(n: string, avg: number) {
    appInsights.trackMetric({ name: n, average: avg });
  }


  // public trackDependencyData(absUrl: string, resCode: number, method: string, id: string) {
  //   appInsights.trackDependencyData({ absoluteUrl: absUrl, responseCode: resCode, method: method, id: id });
  // }

  public startTrackPage(pageName: string) {
    appInsights.startTrackPage(pageName);
  }


  public stopTrackPage(pageName: string, url: string, props: any) {
    // appInsights.stopTrackPage("pageName", null, { customProp1: "some value" });
    appInsights.stopTrackPage(pageName, url, props);
  }

  public startTrackEvent(et: any) {
    appInsights.startTrackEvent(et);
  }


  // public stopTrackEvent(et: any, url: string, props: any) {
  //   // appInsights.stopTrackEvent("event", null, { customProp1: "some value" });
  // }


  public flush() {
    appInsights.flush();
  }


}
