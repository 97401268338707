
import { Options, Vue } from "vue-property-decorator";

@Options({
  props: {
    listItems: Array,
    currentIndex: Number,
    isValid: Boolean,
  },
  components: {}
})
export default class DropdownSelection extends Vue {
  private listItems!: any[];
  private currentIndex!: number;
  private isValid!: boolean;

  private dropdownOpen = false;
  private listIndex = 0;

  mounted() {
    if (this.currentIndex + 1 < this.listItems.length) {
      this.listIndex = this.currentIndex;
    }
  }

  get inputDisplay() {
    if (this.currentIndex == 999999999) {
      return '--- Please Select --- ';
    } else {
      return this.listItems[this.currentIndex].htmlText;
    }
  }

  private toggleOpen() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  private clicked(i: number) {
    this.$emit('itemSelected', i);
    this.dropdownOpen = false;
  }


}

