import { Inject, Options, Ref, Vue, Watch } from "vue-property-decorator";
import ErrorIndicator from "@/shared/components/error-indicator/index.vue";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import CurrencyFormatMixin from "@/shared/mixins/number-formatting";
import { PaymentType } from "@/shared/modules/typescript-api-client/models";
import { MARSApi } from "@/shared/modules/mars-api";
import { orderBy } from "lodash";
import CreditCardPayment from "./credit-card-payment/index.vue";
import PracticeEmployeeForm from "./practice-employee-form/index.vue";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AppInsights } from "@/mars-app/appInsights";

@Options({
  props: {
    pcrFormData: Object,
    paymentTypes: Object,
    paymentItems: Object,
  },

  components: {
    LoadingIndicator,
    ErrorIndicator,
    PracticeEmployeeForm,
    CreditCardPayment,
  },
  mixins: [CurrencyFormatMixin]
})
export default class PaymentBox extends Vue {
  private pcrFormData!: any;
  private paymentTypes!: any;
  private paymentItems!: any;

  private paymentToken = '';
  private isCreditCardValid = false;

  // @Ref('creditCardForm') readonly creditCardForm!: any;

  @Watch('selectedPaymentMethod')
  private onPaymentMethodChanged(): void {
    for (let i = 0; i < this.paymentTypes.length; i++) {
      if (this.paymentTypes[i].id === this.selectedPaymentMethod.id) {
        this.paymentTypes[i].isSelected = true;
      } else {
        this.paymentTypes[i].isSelected = false;
      }
    }
  }

  @Watch('isCreditCardValid')
  private onCreditCardValidChanged(): void {
    this.$emit('isValidCard', this.isCreditCardValid)
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private paymentMethod = null as any;
  private selectedPaymentMethod: PaymentType = null as any;
  private practiceEmployerAmount = 0;
  private otherAmount = 0;

  private delay = 3000;

  private processingPayment = false;
  private errorProcessingPayment = false;
  private paymentProcessed = false;

  private cancellingPayment = false;
  private errorCancellingPayment = false;
  private cancelPaymentSucceeded = false;

  private loadingTransaction = false;
  private errorLoadingTransaction = false;
  private transactionLoaded = false;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  mounted(): void {
    this.eventCtrl.on('apply-cc-payment', this.applyPaymentRequested);
    this.getTransactionDetails();
  }

  beforeUnmount() {
    this.eventCtrl.removeListener('apply-cc-payment', this.applyPaymentRequested);
  }

  private async getTransactionDetails(): Promise<void> {
    try {
      this.loadingTransaction = true;
      this.errorLoadingTransaction = false;
      this.paymentMethod = orderBy(this.paymentTypes, ['order'], ['asc']);
      this.practiceEmployerAmount = this.pcrFormData.paymentDetails?.practiceEmployerAmount;
      this.otherAmount = this.pcrFormData.paymentDetails?.otherAmount;
    } catch {
      this.errorLoadingTransaction = true;
      this.ai.trackException('error loading transaction');
    } finally {
      // this.sendNotification('parent-close-state', { disabled: false });
      this.loadingTransaction = false;
    }
  }

  private applyPaymentRequested(): void {
    this.eventCtrl.emit('process-payment-application', this.applyPayment);
  }

  private isLoadingData = false;

  private async applyPayment() {
    this.isLoadingData = true;
    this.eventCtrl.emit('cardSubmitting', true);
    this.pcrFormData.paymentDetails.singleUseTokenId = this.paymentToken;
    await this.apiService.PcRenewApi?.apiV1PcRenewSavePaymentDetailsForPracticeEmployerPost(
      this.pcrFormData.paymentDetails
    )
      .then(res => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.eventCtrl.emit('navigateTo', 11); // confirmation page
      })
      .catch(err => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.errorProcessPayment(err);
      });
  }

  private errorProcessPayment(err: any) {
    if (err.response?.data && err.response.data?.errorCode == 1) {
      this.eventCtrl.emit('show-snackbar', 'error', 'An unexpected error occurred while preparing your contract. Please email dcs@lawsociety.com.au with the error code "0104". Do not resubmit payment.');
      this.ai.trackException('Error on process payment: ' + JSON.stringify(err.response));
    } else {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to process payment: ' + err.message);
      this.ai.trackException('Error on process payment: ' + JSON.stringify(err));
    }
  }

  // private updateIsCreditCardValid(status: boolean) {
  //   this.$emit('isValidCard', status);
  // }

  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

}
