import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import ApplicationMenu from '@/shared/components/page-header/application-menu/index.vue';
import { RouteN } from '@/shared/constants/route-name';
import { AuthorizedUser } from '@/shared/models/authorized-user';
import store from '@/shared/store';
import { EventEmitter } from 'events';
import { AuthenticationStatusType } from '@/shared/modules/authentication/authentication-status';
// import { _strEndsWithPoly } from '@microsoft/applicationinsights-core-js/types/JavaScriptSDK/HelperFuncs';

@Options<AccountInfoPanel>({
  components: {
    ApplicationMenu
  }
})
export default class AccountInfoPanel extends Vue {


  @Prop({ default: null })
  private isExternal!: boolean;

  @Ref('dropDownMenu')
  private dropDownMenuUI!: HTMLElement;

  public showMenu = false;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  created(): void {
    window.addEventListener('click', this.clickedElement);
  }

  beforeUnmount(): void {
    window.removeEventListener('click', this.clickedElement);
  }

  private clickedElement(e: MouseEvent): void | boolean {
    const el: HTMLElement = this.dropDownMenuUI;
    const elBut: HTMLElement = this.dropDownMenuUI;
    const target: HTMLElement = e.target as HTMLElement;
    if (el !== target && elBut !== target) {
      this.showMenu = false;
    }
  }

  private onMenuClicked() {
    if (!this.isExternal) {
      switch (this.$route.name) {
        default:
          this.showMenu = !this.showMenu;
          break;
      }
    } else {
      switch (this.$route.name) {
        case RouteN.Mars.Home.n:
          this.showMenu = !this.showMenu;
          break;
        case RouteN.Mars.PcRenew.n:
        case RouteN.Mars.PcNoRenew.n:
        case RouteN.Mars.SmApplication.n:
        case RouteN.Mars.SarApplication.n:
        case RouteN.Mars.SaNrApplication.n:
        case RouteN.Mars.StnApplication.n:
        case RouteN.Mars.StrApplication.n:
        case RouteN.Mars.AmnApplication.n:
        case RouteN.Mars.AmrApplication.n:
          if (this.isConfirmationPage) {
            this.showMenu = !this.showMenu;
          } else if(!this.$route.query.sec) {
            this.$router.push({ name: RouteN.Mars.Home.n });
          } else {
            this.cancelApplicationClick();
          }
          break;
        default:
          this.$router.push({ name: RouteN.Mars.Home.n });
          break;
      }
    }


  }

  get infoTextRow0(): string {
    try {
      return this.userAccount?.user?.firstName + ' ' + this.userAccount?.user?.lastName;
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  get infoTextRow1(): string {
    try {
      if (this.isExternal) {
        return 'LawID ' + this.userAccount?.user?.lawId;
      } else {
        return '';
      }
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  get menuButtonText(): string {
    if (this.isExternal) {
      switch (this.$route.name) {
        case RouteN.Mars.Home.n:
          return 'Menu';
        case RouteN.Mars.PcRenew.n:
        case RouteN.Mars.PcNoRenew.n:
        case RouteN.Mars.SmApplication.n:
        case RouteN.Mars.SarApplication.n:
        case RouteN.Mars.SaNrApplication.n:
        case RouteN.Mars.StnApplication.n:
        case RouteN.Mars.StrApplication.n:
        case RouteN.Mars.AmnApplication.n:
        case RouteN.Mars.AmrApplication.n:
          if (this.isConfirmationPage) {
            return 'Menu';
          } else if (!this.$route.query.sec){
            return 'Close';
          } else {
            return 'Cancel Application';
          }
        default:
          return 'Close';
      }
    }
    else {
      switch (this.$route.name) {
        default:
          return 'Menu';
      }
    }

  }


  get menuIcon(): string {
    if (this.isExternal) {
      switch (this.$route.name) {
        case RouteN.Mars.Home.n:
          return this.showMenu ? 'fa-times' : 'fa-bars';
        case RouteN.Mars.PcNoRenew.n:
        case RouteN.Mars.PcRenew.n:
        case RouteN.Mars.SmApplication.n:
        case RouteN.Mars.SarApplication.n:
        case RouteN.Mars.SaNrApplication.n:
        case RouteN.Mars.StnApplication.n:
        case RouteN.Mars.StrApplication.n:
        case RouteN.Mars.AmnApplication.n:
        case RouteN.Mars.AmrApplication.n:
          if (this.isConfirmationPage) {
            return this.showMenu ? 'fa-times' : 'fa-bars';
          } else {
            return 'fa-times';
          }
        default:
          return 'fa-times';
      }
    }
    else {
      switch (this.$route.name) {
          default:
            return this.showMenu ? 'fa-times' : 'fa-bars';
      }
    }
  }

  get selectionTypeClassName(): string {
    // switch (this.$route.name) {
    // case RouteN.Mars.Committee.n:
    //   return 'committee';
    // case RouteN.Mars.Member.n:
    //   return 'committee-member';
    //   default:
    // return '';
    // }
    return '';
  }

  get isConfirmationPage(): boolean {
    const section: string = this.$route.query.sec as string || '';
    return section.indexOf('confirmation') > -1;
  }

  get isUpdating(): boolean {
    const section: string = this.$route.query.sec as string || '';
    return section.indexOf('update') == 0;
  }

  get userAccount(): AuthorizedUser {
    return store.getters.getUserAccount;
  }

  get appAccessPermitted(): boolean {
    return this.userAccount.authenticationStatus === AuthenticationStatusType.AppAccessPermitted ||
      this.userAccount.authenticationStatus === AuthenticationStatusType.LoggedIn;
  }

  private cancelApplicationClick() {
    if (this.$route.name == RouteN.Mars.PcRenew.n || this.$route.name == RouteN.Mars.PcRenewOverview.n) {
      this.eventCtrl.emit('cancel-pc-renew');
    } else if (this.$route.name == RouteN.Mars.PcNoRenew.n) {
      this.eventCtrl.emit('cancel-pc-no-renew');
    } else if (this.$route.name == RouteN.Mars.SmApplication.n) {
      this.eventCtrl.emit('cancel-sm-application');
    } else if (this.$route.name == RouteN.Mars.SarApplication.n) {
      this.eventCtrl.emit('cancel-sar-application');
    } else if (this.$route.name == RouteN.Mars.SaNrApplication.n) {
      this.eventCtrl.emit('cancel-sanr-application');
    } else if (this.$route.name == RouteN.Mars.StnApplication.n) {
      this.eventCtrl.emit('cancel-stn-application');
    } else if (this.$route.name == RouteN.Mars.StrApplication.n) {
      this.eventCtrl.emit('cancel-str-application'); 
    } else if (this.$route.name == RouteN.Mars.AmnApplication.n) {
      this.eventCtrl.emit('cancel-amn-application');
    } else if (this.$route.name == RouteN.Mars.AmrApplication.n) {
      this.eventCtrl.emit('cancel-amr-application');
    }
  }

}
