/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanResponse } from '../models';
import { TransactionRequest } from '../models';
import { TransactionStatusResponse } from '../models';
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Payment API | Update a transaction status (Callback from the Payment API)
         * @param {TransactionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentCallbackTransactionPost: async (body?: TransactionRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Payment/CallbackTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Transaction Status
         * @param {string} transactionKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentCheckPaymentStatusTransactionKeyPost: async (transactionKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionKey' is not null or undefined
            if (transactionKey === null || transactionKey === undefined) {
                throw new RequiredError('transactionKey','Required parameter transactionKey was null or undefined when calling apiV1PaymentCheckPaymentStatusTransactionKeyPost.');
            }
            const localVarPath = `/api/v1/Payment/CheckPaymentStatus/{transactionKey}`
                .replace(`{${"transactionKey"}}`, encodeURIComponent(String(transactionKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Payment API | Update a transaction status (Callback from the Payment API)
         * @param {TransactionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentCallbackTransactionPost(body?: TransactionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).apiV1PaymentCallbackTransactionPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Transaction Status
         * @param {string} transactionKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionStatusResponse>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Payment API | Update a transaction status (Callback from the Payment API)
         * @param {TransactionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentCallbackTransactionPost(body?: TransactionRequest, options?: any): AxiosPromise<BooleanResponse> {
            return PaymentApiFp(configuration).apiV1PaymentCallbackTransactionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Transaction Status
         * @param {string} transactionKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey: string, options?: any): AxiosPromise<TransactionStatusResponse> {
            return PaymentApiFp(configuration).apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Payment API | Update a transaction status (Callback from the Payment API)
     * @param {TransactionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiV1PaymentCallbackTransactionPost(body?: TransactionRequest, options?: any) {
        return PaymentApiFp(this.configuration).apiV1PaymentCallbackTransactionPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Transaction Status
     * @param {string} transactionKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey: string, options?: any) {
        return PaymentApiFp(this.configuration).apiV1PaymentCheckPaymentStatusTransactionKeyPost(transactionKey, options).then((request) => request(this.axios, this.basePath));
    }
}
