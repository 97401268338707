import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { UPDATE_EMAIL_LINK } from '@/shared/config/config';
// import { AuthorizedUser } from "@/shared/models/authorized-user";
import Popover from "@/shared/components/popover/index.vue";
// import { PopoverView } from "@/shared/components/popover/popover";
import { AddressDetail, Application, PcRenewIndividualDetail, NonRenewalReason } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import { RouteN } from "@/shared/constants/route-name";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import DatePicker from 'vue-datepicker-next';
import PcNorAddressDetailsUpdateModal from "../pc-nor-address-details-update-modal/index.vue";
import PcNorWordingData from '@/assets/config/pc-nor-wording.json';

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
    isSubmitting: Boolean,
    isEmailDuplicated: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    PcNorAddressDetailsUpdateModal,
    AddressPreview,
    DatePicker
  },
  mixins: [FormatDateTimeMixin],
})
export default class PcNorCurrentDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;
  private isSubmitting!: boolean;
  private isEmailDuplicated!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private heading1 = PcNorWordingData[0]["current-details"]["heading1"].replaceAll('--[[ApplicationYearName]]--', this.appInfo.applicationYear?.name);
  private emailUpdateInfo = (PcNorWordingData[0]["current-details"]["email-update-info"]).replace('--[[UpdateEmailLink]]--', UPDATE_EMAIL_LINK);
  private declaration_check1_text = PcNorWordingData[0]["current-details"]["declaration_check_1"];
  private declaration_check2_text = PcNorWordingData[0]["current-details"]["declaration_check_2"];

  private individualDetails: PcRenewIndividualDetail = {};
  private noRenewalReasons: NonRenewalReason[] = [];
  private editAddressType = 2;

  private loadingDataSet = false;

  private noRenewalReason = null;
  private effectiveEndDate: any = null;
  private principalInAPractice = null;
  private practiceClosed = null;
  private whoHasTheFile = '';
  private practiceSuccessor = null;
  private successorName = '';
  private applyAssociateMember = null;
  private declaration1 = null;
  private declaration2 = null;

  private editPostalAddress: AddressDetail = {};

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }


  private showUpdateModal = false;

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.preloadData();
  }


  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetNonRenewalReasonGet(),
        this.getIndividualDetails()
      ]);
      await this.getNoRenewalDetails();
      this.noRenewalReasons = resp[0].data.data;
      store.dispatch('setPcrFormData', this.pcrFormData);
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private async getIndividualDetails(): Promise<boolean> {
    try {
      const _isRenewing = this.pcrFormData.individualDetails?.pcRenewApplicationId > 0;
      const resp: any = await this.apiService.PcNonRenewApi?.apiV1PcNonRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(this.pcrApp.id, !_isRenewing);
      this.individualDetails = resp.data.data;
      if (this.pcrFormData.tempEmail) {
        this.individualDetails.communicationEmail.email = this.pcrFormData.tempEmail;
      }
      this.pcrFormData['individualDetails'] = this.individualDetails;
      this.canGoNextCheck();
      return Promise.resolve(true);
    } catch (err: any) {
      if (err.response?.data && err.response.data?.errorCode == 10001) {
        this.eventCtrl.emit('show-snackbar', 'info', 'Concurrent application detected');
      } else {
        this.ai.trackException('Error, Unable to get user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get user details, redirect to Portal home');
      }
      this.$router.push({ name: RouteN.Mars.Home.n });
      return Promise.reject(false);
    }
  }

  private async getNoRenewalDetails(): Promise<boolean> {
    try {
      const resp: any = await this.apiService.PcNonRenewApi?.apiV1PcNonRenewGetPcRenewNonRenewalDetailsApplicationIdGet(this.pcrApp.id);
      this.pcrFormData.noRenewDetails = resp.data.data;
      this.initSync();
      this.canGoNextCheck();
      return Promise.resolve(true);
    } catch (err) {
      if (!this.pcrFormData?.noRenewDetails) {
        this.pcrFormData['noRenewDetails'] = {};
        this.pcrFormData['noRenewDetails']['nonRenewalReason'] = null;
        this.pcrFormData['noRenewDetails']['effectiveEndDate'] = '';
        this.pcrFormData['noRenewDetails']['isPracticePrincipal'] = null;
        this.pcrFormData['noRenewDetails']['isPracticeClosed'] = null;
        this.pcrFormData['noRenewDetails']['whoHasTheFiles'] = '';
        this.pcrFormData['noRenewDetails']['isPracticeSuccessor'] = null;
        this.pcrFormData['noRenewDetails']['successorName'] = '';
        this.pcrFormData['noRenewDetails']['isApplyAssociateMembership'] = null;
        //this.pcrFormData['noRenewDetails']['declaration1'] = null;
        //this.pcrFormData['noRenewDetails']['declaration2'] = null;
      }
      return Promise.reject(false);
    }
  }

  private initSync(): void {
    this.noRenewalReason = this.pcrFormData.noRenewDetails.nonRenewalReason;
    this.effectiveEndDate = this.pcrFormData.noRenewDetails.effectiveEndDate ? new Date(this.pcrFormData.noRenewDetails.effectiveEndDate) : null;
    this.principalInAPractice = this.pcrFormData.noRenewDetails.isPracticePrincipal;
    this.practiceClosed = this.pcrFormData.noRenewDetails.isPracticeClosed;
    this.whoHasTheFile = this.pcrFormData.noRenewDetails.whoHasTheFiles;
    this.practiceSuccessor = this.pcrFormData.noRenewDetails.isPracticeSuccessor;
    this.successorName = this.pcrFormData.noRenewDetails.successorName;
    this.applyAssociateMember = this.pcrFormData.noRenewDetails.isApplyAssociateMembership;
    this.declaration1 = this.pcrFormData.noRenewDetails.declaration1;
    this.declaration2 = this.pcrFormData.noRenewDetails.declaration2;
    this.eventCtrl.emit('updateStepUI');
  }

  private syncData(): void {
    try {
      if (!this.loadingDataSet) {
        this.pcrFormData.noRenewDetails.nonRenewalReason = this.noRenewalReason;
        this.pcrFormData.noRenewDetails.effectiveEndDate = this.effectiveEndDate;
        this.pcrFormData.noRenewDetails.isPracticePrincipal = this.principalInAPractice;
        this.pcrFormData.noRenewDetails.isPracticeClosed = !this.principalInAPractice ? false : this.practiceClosed;
        this.pcrFormData.noRenewDetails.whoHasTheFiles = !this.principalInAPractice || !this.practiceClosed ? null : this.whoHasTheFile;
        this.pcrFormData.noRenewDetails.isPracticeSuccessor = !this.principalInAPractice || !this.practiceClosed ? null : this.practiceSuccessor;
        this.pcrFormData.noRenewDetails.successorName = !this.principalInAPractice || !this.practiceClosed || !this.practiceSuccessor ? null : this.successorName;
        this.pcrFormData.noRenewDetails.isApplyAssociateMembership = this.applyAssociateMember;
        this.pcrFormData.noRenewDetails.declaration1 = this.declaration1;
        this.pcrFormData.noRenewDetails.declaration2 = this.declaration2;
        this.pcrFormData.noRenewDetails.communicationEmail = this.individualDetails.communicationEmail;
        if (this.pcrFormData.tempEmail) {
          this.pcrFormData.noRenewDetails.communicationEmail.email = this.pcrFormData.tempEmail;
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.eventCtrl.emit('updateStepUI');
    this.canGoNextCheck();
  }


  private isValidateForm(): boolean {
    let _cond1 = false;
    let _cond2 = false;
    let _cond3 = false;

    if (this.noRenewalReason && this.effectiveEndDate) {
      _cond1 = true;
    } else {
      _cond1 = false;
    }

    _cond2 = this.principleInPracticeIsValid();

    if ((this.applyAssociateMember && !!this.individualDetails?.postalAddress) ||
      (this.applyAssociateMember != null && !this.applyAssociateMember)
    ) {
      _cond3 = true;
    } else {
      _cond3 = false;
    }

    return _cond1 && _cond2 && (this.pcrFormData?.individualDetails?.isLifeMember || _cond3) && this.emailCheck();
  }

  private principleInPracticeIsValid(): boolean {
    if (this.principalInAPractice != null) {
      if (this.principalInAPractice) {
        return this.practiceClosedIsValid();
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private practiceClosedIsValid(): boolean {
    if (this.practiceClosed != null) {
      if (this.practiceClosed) {
        return typeof this.whoHasTheFile == 'string' && this.whoHasTheFile?.length > 0 && this.successorPracticeIsValid();
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private successorPracticeIsValid(): boolean {
    if (this.practiceSuccessor != null) {
      if (this.practiceSuccessor) {
        return typeof this.successorName == 'string' && this.successorName?.length > 0;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidateForm());
  }

  private reload() {
    this.getIndividualDetails();
  }

  private showPostalAddressUpdateModal() {
    if (this.individualDetails?.postalAddress) {
      this.editPostalAddress = { ... this.individualDetails.postalAddress };
    } else {
      this.editPostalAddress = { ... this.initAddress(this.editPostalAddress) };
    }
    this.showUpdateModal = true;
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.firmAddMapId = a.firmAddMapId || 0;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }


  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  get appInfo(): any {
    return store.getters.getAppInfo;
  }

  private emailCheck(): boolean {
    const isBlank = this.individualDetails.communicationEmail?.email?.trim() == '';
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !isBlank && emailRegex.test(this.individualDetails.communicationEmail?.email);
  }

  private saveEmailAsTemp() {
    this.pcrFormData.tempEmail = this.individualDetails.communicationEmail?.email?.trim();
    this.canGoNextCheck();
  }

  private firstOfJulyTo30thOfJune(date: any) {
    const lastApplicationYear = store.getters.getAppInfo.lastApplicationYear.year;
    return new Date(new Date((lastApplicationYear - 1) + '-06-30T23:59:59')) > date || date > new Date(new Date(lastApplicationYear + '-06-30T23:59:59'));
  }

}

