import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
// import { AuthorizedUser } from "@/shared/models/authorized-user";
import Popover from "@/shared/components/popover/index.vue";
import { Application, SarRecordApplicationDeclaration } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import StepIndicator from "@/shared/components/step-indicator/index.vue";
import { Step } from "@/shared/components/step-indicator/step-indicator";
import AppDeclarationOne from "./app-declaration-one/index.vue";
import AppDeclarationTwo from "./app-declaration-two/index.vue";
import AppDeclarationThree from "./app-declaration-three/index.vue";
import AppDeclarationFour from "./app-declaration-four/index.vue";
import AppDeclarationFive from "./app-declaration-five/index.vue";
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';

@Options({
  props: {
    pcrFormData: Object,
    sarAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    StepIndicator,
    AppDeclarationOne,
    AppDeclarationTwo,
    AppDeclarationThree,
    AppDeclarationFour,
    AppDeclarationFive,
    ConfirmDialogue
  },
  mixins: [FormatDateTimeMixin],
})
export default class SarApplicationDeclarations extends Vue {
  private sarAppFormData!: any;
  private isSectionLoading!: boolean;
  private pcrFormData!: any;

  private isPcrApp = false;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;
  private uploading = false;

  private currentStepIndex = 0;
  private isPCHolder = null;


  private steps: Step[] = [];

  private stepsNSWOnly: Step[] = [
    { name: '1', description: 'Application Declaration 1', completed: false, current: true },
    { name: '2', description: 'Application Declaration 2', completed: false, current: false },
    { name: '3', description: 'Application Declaration 3', completed: false, current: false }
  ];

  private stepsInterstate: Step[] = [
    { name: '1', description: 'Application Declaration 1', completed: false, current: true },
    { name: '2', description: 'Application Declaration 2', completed: false, current: false },
    { name: '3', description: 'Application Declaration 3', completed: false, current: false },
    { name: '4', description: 'Application Declaration 4', completed: false, current: false },
    { name: '5', description: 'Application Declaration 5', completed: false, current: false }
  ];


  private declarationFormValid1 = false;
  private declarationFormValid2 = false;
  private declarationFormValid3 = false;
  private declarationFormValid4 = false;
  private declarationFormValid5 = false;

  private declarationForm: SarRecordApplicationDeclaration = {};


  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.isPcrApp = this.pcrFormData?.appId > 0;
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    if (this.isPcrApp) {
      const recordId = this.pcrFormData?.sarRecordLinkedApplication?.id;
      try {
        this.loadingDataSet = true;
        const resp: any = await Promise.all([
          this.apiService.PcRenewApi?.apiV1PcRenewGetSarApplicationDeclarationRecordLinkedApplicationIdGet(recordId),
          this.apiService.CodeApi?.apiV1CodeGetPcRenewFileTypesGet()
        ]);
        this.pcrFormData['sarDeclaration'] = resp[0].data.data;
        this.isPCHolder = true as any;
        this.steps = this.stepsNSWOnly;
        this.initSync();
        store.dispatch('setPcrFormData', this.pcrFormData);
      } catch (err) {
        console.log(err);
      } finally {
        this.canGoNextCheck();
        this.loadingDataSet = false;
      }
    } else {
      try {
        this.loadingDataSet = true;
        const resp: any = await Promise.all([
          this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewGetApplicationDeclarationApplicationIdGet(this.onlineApplication.id),
          this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewCheckPcHolderGet(this.onlineApplication.id),
          this.apiService.CodeApi?.apiV1CodeGetSpecAccFileTypesGet()
        ]);
        this.sarAppFormData['sarDeclaration'] = resp[0].data.data;
        this.isPCHolder = resp[1].data.data;
        this.sarAppFormData.sarDeclaration.isNswPcHolder = this.isPCHolder;
        this.steps = this.isPCHolder ? this.stepsNSWOnly : this.stepsInterstate;
        this.initSync();
        store.dispatch('setSarAppFormData', this.sarAppFormData);
      } catch (err) {
        console.log(err);
      } finally {
        this.canGoNextCheck();
        this.loadingDataSet = false;
      }
    }
  }

  private updateInterstateHolderDeclaration1(data: any): void {
    this.sarAppFormData.sarDeclaration.isInterstatePcHolder = data.fileList?.length > 0;
    this.uploadAllFiles(data.fileList, 1);
    this.syncData();
  }

  private updateInterstateHolderDeclaration2(data: any): void {
    this.sarAppFormData.sarDeclaration.isInterstateSmHolder = data.fileList?.length > 0;
    this.uploadAllFiles(data.fileList, 2);
    this.syncData();
  }

  private updateBothHolderDeclaration1(data: any): void {

    this.declarationForm.involvement1 = data.selectedOption == true ? true : null;
    if (this.declarationForm.involvement2 != (data.selectedOption == false ? true : null)) {
      this.declarationForm.exemptionReasonFiles = [];
    }
    this.declarationForm.involvement2 = data.selectedOption == false ? true : null;

    this.declarationForm.involvement21 = data.selectedOption2 == true ? true : null;
    if (this.declarationForm.involvement22 != (data.selectedOption2 == false ? true : null)) {
      this.declarationForm.exemptionReasonFiles = [];
    }
    this.declarationForm.involvement22 = data.selectedOption2 == false ? true : null;

    this.declarationForm.involvement211 = data.selectedOption3 == true ? true : null;
    this.declarationForm.involvement212 = data.selectedOption3 == false ? true : null;
    const _fileTypeId = this.isPcrApp ? 7 : 3;
    this.uploadAllFiles(data.fileList, _fileTypeId);
    this.syncData();
  }

  private updateBothHolderDeclaration2(data: any): void {
    this.declarationForm.completedCpdPoints1 = data.selectedOption == true ? true : null;
    if (this.declarationForm.completedCpdPoints2 != (data.selectedOption == false ? true : null)) {
      this.declarationForm.extensionStatementFiles = [];
    }
    this.declarationForm.completedCpdPoints2 = data.selectedOption == false ? true : null;

    this.declarationForm.completedCpdPoints21 = data.selectedOption2 == true ? true : null;
    if (this.declarationForm.completedCpdPoints22 != (data.selectedOption2 == false ? true : null)) {
      this.declarationForm.extensionStatementFiles = [];
    }
    this.declarationForm.completedCpdPoints22 = data.selectedOption2 == false ? true : null;

    const _fileTypeId = this.isPcrApp ? 8 : 4;
    this.uploadAllFiles(data.fileList, _fileTypeId);
    this.syncData();
  }

  private updateBothHolderDeclaration3(data: any): void {

    this.declarationForm.adverseAction1 = data.selectedOption == true ? true : null;
    if (this.declarationForm.adverseAction2 != (data.selectedOption == false ? true : null)) {
      this.declarationForm.adverseActionFiles = [];
    }
    this.declarationForm.adverseAction2 = data.selectedOption == false ? true : null;

    const _fileTypeId = this.isPcrApp ? 9 : 5;
    this.uploadAllFiles(data.fileList, _fileTypeId);
    this.syncData();
  }

  // private updateDeclaration1(data: any): void {
  //   this.declarationForm.isNswPcHolderFlag = data.selectedOption == 0 ? true : null;
  //   if (this.declarationForm.isInterstatePcHolderFlag != (data.selectedOption == 1 ? true : null)) {
  //     this.declarationForm.interstatePCFiles = [];
  //   }
  //   this.declarationForm.isInterstatePcHolderFlag = data.selectedOption == 1 ? true : null;
  //   this.declarationForm.isNoPcHolderFlag = data.selectedOption == 2 ? true : null;
  //   this.uploadAllFiles(data.fileList, 1);
  //   this.syncData();
  // }

  // private updateDeclaration2(data: any): void {
  //   this.declarationForm.isNswSmHolderFlag = data.selectedOption == 0 ? true : null;
  //   if (this.declarationForm.isInterstateSmHolderFlag != (data.selectedOption == 1 ? true : null)) {
  //     this.declarationForm.interstateMembershipFiles = [];
  //   }
  //   this.declarationForm.isInterstateSmHolderFlag = data.selectedOption == 1 ? true : null;
  //   this.declarationForm.isNoSmHolderFlag = data.selectedOption == 2 ? true : null;
  //   this.uploadAllFiles(data.fileList, 2);
  //   this.syncData();
  // }

  // private updateDeclaration3(data: any): void {
  //   this.declarationForm.nswGovtOrSoleSolicitorDirectorFlag = data.selectedOption == true ? true : null;
  //   this.declarationForm.eligibleForDiscountFlag = data.selectedOption == false ? true : null;
  //   this.syncData();
  // }

  // private updateDeclaration4(data: any): void {
  //   this.declarationForm.applicationDeclaration4Flag1 = data.selectedOption == true ? true : null;
  //   if (this.declarationForm.applicationDeclaration4Flag2 != (data.selectedOption == false ? true : null)) {
  //     this.declarationForm.exemptionReasonFiles = [];
  //   }
  //   this.declarationForm.applicationDeclaration4Flag2 = data.selectedOption == false ? true : null;

  //   this.declarationForm.applicationDeclaration4Flag21 = data.selectedOption2 == true ? true : null;
  //   if (this.declarationForm.applicationDeclaration4Flag22 != (data.selectedOption2 == false ? true : null)) {
  //     this.declarationForm.exemptionReasonFiles = [];
  //   }
  //   this.declarationForm.applicationDeclaration4Flag22 = data.selectedOption2 == false ? true : null;

  //   this.declarationForm.applicationDeclaration4Flag211 = data.selectedOption3 == true ? true : null;
  //   this.declarationForm.applicationDeclaration4Flag212 = data.selectedOption3 == false ? true : null;

  //   this.uploadAllFiles(data.fileList, 3);
  //   this.syncData();
  // }

  // private updateDeclaration5(data: any): void {
  //   this.declarationForm.applicationDeclaration5Flag1 = data.selectedOption == true ? true : null;
  //   if (this.declarationForm.applicationDeclaration5Flag2 != (data.selectedOption == false ? true : null)) {
  //     this.declarationForm.extensionStatementFiles = [];
  //   }
  //   this.declarationForm.applicationDeclaration5Flag2 = data.selectedOption == false ? true : null;

  //   this.declarationForm.applicationDeclaration5Flag21 = data.selectedOption2 == true ? true : null;
  //   if (this.declarationForm.applicationDeclaration5Flag22 != (data.selectedOption2 == false ? true : null)) {
  //     this.declarationForm.extensionStatementFiles = [];
  //   }
  //   this.declarationForm.applicationDeclaration5Flag22 = data.selectedOption2 == false ? true : null;

  //   this.uploadAllFiles(data.fileList, 4);
  //   this.syncData();
  // }

  private initSync(): void {
    if (this.isPcrApp) {
      this.declarationForm = JSON.parse(JSON.stringify(this.pcrFormData['sarDeclaration']));
    } else {
      this.declarationForm = JSON.parse(JSON.stringify(this.sarAppFormData['sarDeclaration']));
    }

    if (!this.declarationForm.interstatePCFiles) {
      this.declarationForm.interstatePCFiles = [];
    }

    if (!this.declarationForm.interstateMembershipFiles) {
      this.declarationForm.interstateMembershipFiles = [];
    }

    if (!this.declarationForm.exemptionReasonFiles) {
      this.declarationForm.exemptionReasonFiles = [];
    }

    if (!this.declarationForm.extensionStatementFiles) {
      this.declarationForm.extensionStatementFiles = [];
    }

    if (!this.declarationForm.adverseActionFiles) {
      this.declarationForm.adverseActionFiles = [];
    }
  }

  private syncData(): void {
    try {
      if (!this.loadingDataSet) {
        if (this.isPcrApp) {
          this.pcrFormData['sarDeclaration'] = this.declarationForm;
        }
        else {
          this.sarAppFormData['sarDeclaration'] = this.declarationForm;
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.canGoNextCheck();
  }

  private stepBack(): void {
    this.steps[this.currentStepIndex].current = false;
    this.steps[this.currentStepIndex].completed = false;
    this.currentStepIndex--;
    this.steps[this.currentStepIndex].current = true;
    this.syncData();
    // this.eventCtrl.emit('canGoNext', false);
  }

  private nextStep(): void {
    this.currentStepIndex++;
    for (let i = 0; i < this.currentStepIndex; i++) {
      this.steps[i].completed = true;
      this.steps[i].current = false;
    }
    this.steps[this.currentStepIndex].completed = false;
    this.steps[this.currentStepIndex].current = true;
    this.syncData();
  }


  private isValidForm(): boolean {
    if (this.isPcrApp && this.declarationForm?.involvement22) {
      return true;
    }
    if (this.isPcrApp && this.declarationForm?.completedCpdPoints22) {
      return true;
    }

    if (this.isPCHolder) {
      return this.declarationFormValid1 && this.declarationFormValid2 &&
        this.declarationFormValid3;
    } else if (!this.isPCHolder && this.isPCHolder != null) {
      return this.declarationFormValid1 && this.declarationFormValid2 &&
        this.declarationFormValid3 && this.declarationFormValid4 && this.declarationFormValid5;
    } else {
      return false;
    }
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  private async uploadAllFiles(fileList: Array<File>, fileTypeId: number): Promise<void> {
    const allRequests: Array<Promise<boolean>> = [];
    this.uploading = true;
    try {
      if (fileList.length > 0) {
        fileList.forEach((x) => allRequests.push(this.uploadFile(x, fileTypeId)));
        await Promise.all(allRequests);
        this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
      }
    } catch (err: any) {
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
    } finally {
      this.uploading = false;
    }
  }

  private async uploadFile(file: File, fileTypeId: number): Promise<boolean> {
    const _applicationId = this.isPcrApp ? this.pcrFormData?.sarRecordLinkedApplication?.linkedApplicationId : this.onlineApplication.id;

    try {
      const fileStr = await this.fileToString(file);
      const body = {
        id: 0,
        fileName: file.name,
        fileExtension: file.name,
        fileDataUrl: fileStr,
        seed: 0,
        applicationId: _applicationId,
        specAccFileTypeId: fileTypeId,
        pcRenewLinkedApplicationFileTypeId: fileTypeId,
      };

      let resp: any;
      if (this.isPcrApp) {
        resp = await this.apiService.FileApi?.apiV1FileSavePcRenewLinkedApplicationFilePost(body);
      } else {
        resp = await this.apiService.FileApi?.apiV1FileSaveSpecAccFilePost(body);
      }

      switch (fileTypeId) {
        case 1:
          this.declarationForm.interstatePCFiles.push({
            id: resp.data.data.data,
            fileName: file.name,
            fileExtension: file.name,
            // fileDataUrl: fileStr,
            applicationId: this.onlineApplication.id,
          })
          break;
        case 2:
          this.declarationForm.interstateMembershipFiles.push({
            id: resp.data.data.data,
            fileName: file.name,
            fileExtension: file.name,
            // fileDataUrl: fileStr,
            applicationId: this.onlineApplication.id,
          })
          break;
        case 3:
        case 7:
          this.declarationForm.exemptionReasonFiles.push({
            id: resp.data.data.data,
            fileName: file.name,
            fileExtension: file.name,
            // fileDataUrl: fileStr,
            applicationId: this.onlineApplication.id,
          })
          break;
        case 4:
        case 8:
          this.declarationForm.extensionStatementFiles.push({
            id: resp.data.data.data,
            fileName: file.name,
            fileExtension: file.name,
            // fileDataUrl: fileStr,
            applicationId: this.onlineApplication.id,
          })
          break;
        case 5:
        case 9:
          this.declarationForm.adverseActionFiles.push({
            id: resp.data.data.data,
            fileName: file.name,
            fileExtension: file.name,
            // fileDataUrl: fileStr,
            applicationId: this.onlineApplication.id,
          })
          break;
      }

      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  private fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result as string);
      };

      reader.onabort = () => {
        return reject();
      }

      reader.onerror = () => {
        return reject();
      }
    });
  }

  private deleteFile(id: number) {
    const _fileIdToDelete = id;
    const _confirm: any = this.$refs.confirmDialogue;
    _confirm.show({
      title: 'Delete File',
      message: 'Are you sure you want to delete this file?',
      okButton: 'Delete',
      cancelButton: 'Cancel',
    }).then((res: any) => {
      if (res) {
        this.deleteFileFromServer(_fileIdToDelete);
      }
    })
  }

  private async deleteFileFromServer(id: number): Promise<void> {
    let files: any[] = [];

    if (this.isPCHolder) {
      switch (this.currentStepIndex) {
        case 0:
          files = this.declarationForm.exemptionReasonFiles;
          break;
        case 1:
          files = this.declarationForm.extensionStatementFiles;
          break;
        case 2:
          files = this.declarationForm.adverseActionFiles;
          break;
      }
    } else {
      switch (this.currentStepIndex) {
        case 0:
          files = this.declarationForm.interstatePCFiles;
          break;
        case 1:
          files = this.declarationForm.interstateMembershipFiles;
          break;
        case 2:
          files = this.declarationForm.exemptionReasonFiles;
          break;
        case 3:
          files = this.declarationForm.extensionStatementFiles;
          break;
        case 4:
          files = this.declarationForm.adverseActionFiles;
          break;
      }
    }

    try {
      const resp: any = await this.apiService.FileApi?.apiV1FileDeleteFileFileIdDelete(id);
      for (let i = 0; i < files.length; i++) {
        if (files[i].id === id) {
          files.splice(i, 1);
        }
      }
      this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');

    } catch (err) {
      this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);

    } finally {
      this.canGoNextCheck();
    }
  }

  private setFormValidationStatus(status: any, index: number) {
    switch (index) {
      case 0:
        this.declarationFormValid1 = status;
        break;
      case 1:
        this.declarationFormValid2 = status;
        break;
      case 2:
        this.declarationFormValid3 = status;
        break;
      case 3:
        this.declarationFormValid4 = status;
        break;
      case 4:
        this.declarationFormValid5 = status;
        break;
    }
    this.steps[index].completed = status;
    this.canGoNextCheck();
  }

  get isValidStep(): boolean {
    switch (this.currentStepIndex) {
      case 0:
        return this.declarationFormValid1;
      case 1:
        return this.declarationFormValid2;
      case 2:
        return this.declarationFormValid3;
      case 3:
        return this.declarationFormValid4;
      case 4:
        return this.declarationFormValid5;
      default:
        return false;
    }
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

