import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_StdPageLayout = _resolveComponent("StdPageLayout")

  return (_openBlock(), _createBlock(_component_StdPageLayout, {
    showAccountControl: true,
    isExternal: true
  }, {
    "body-content": _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}