import { Options, Vue } from "vue-property-decorator";
import { IDLESessionManager } from "@/shared/modules/session/idle-session-manager";
import { RouteN } from "@/shared/constants/route-name";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "@/shared/store";
import { AuthorizedUser } from "@/shared/models/authorized-user";
import { AuthenticationStatusType } from "@/shared/modules/authentication/authentication-status";
import StdPageLayout from "@/shared/components/std-page-layout/index.vue";
import { EventEmitter } from "events";

Vue.registerHooks(['beforeRouteEnter']);

@Options({
  components: {
    StdPageLayout,
  }
})
export default class ExternalMain extends Vue {

  // @Inject({ from: 'apiService', default: new MARSApi() })
  // private apiService!: MARSApi;

  // @Provide({ to: 'fileType', reactive: true })
  // private fileTypes!: FileType[];

  private idleSessionMgr: IDLESessionManager = new IDLESessionManager();

  beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
    const c1: boolean = to.matched.some((record) => record.meta.requiresAuth);
    const c2: boolean = (store.getters.getUserAccount as AuthorizedUser)
      .authenticationStatus === AuthenticationStatusType.AppAccessPermitted;
    // Bypass login check
    // next();
    c1 && !c2 ? next({ name: RouteN.Mars.LoginPage.n, query: { redirect: to.fullPath } }) : next();

  }

  created(): void {
    document.title = "My Law Society";
    // this.loadDataType();
    this.idleSessionMgr.run(this.idleSessionTimeoutCallback);
  }

  mounted(): void {
    document.body.classList.remove('modal-open');
  }

  beforeUnmount(): void {
    this.idleSessionMgr.stop();
  }

  public idleSessionTimeoutCallback(): void {
    console.debug('***** idleSessionTimeoutCallback ***** ');
    store.commit('setPcrFormData', null);
    window.sessionStorage.clear;
    this.$router.push({ name: RouteN.Mars.LogoutPage.n });
  }

  get isFixHeader(): boolean {
    switch (this.$route.name) {
      case RouteN.Mars.Home.n:
        return false;
      default:
        return true;
    }
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

}

