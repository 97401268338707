import { Inject, Options, Vue } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { RouteN } from "@/shared/constants/route-name";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import LoadingSpinner from "@/shared/components/loading-spinner/index.vue";
import AppCard from "../app-card/index.vue";
import { Application, PractisingCertificate } from "@/shared/modules/typescript-api-client/models";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Section } from "@/shared/components/header-stepper/nav-step";
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';

@Options({
  components: {
    LoadingSpinner,
    LoadingIndicator,
    AppCard,
    ConfirmDialogue,
  }
})
export default class Home extends Vue {
  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private applicationStatus: any[] = [];
  private myApplications: Application[] = [];
  private myPractisingCertificate: PractisingCertificate[] = [];
  private isLoadingData = true;
  private downloading = false;
  private popovers: any[] = [];

  created() {
    this.getApplicationStatus();
  }

  mounted() {
    this.getMyApplications();
    store.dispatch('setAppRenew', {});
    store.dispatch('setOnlineApplication', {});
    window.sessionStorage.removeItem('pcrFormData');
    window.sessionStorage.removeItem('smAppFormData');
  }

  private async getApplicationStatus() {
    await this.apiService.CodeApi?.apiV1CodeGetApplicationStatusesGet()
      .then(res => {
        this.applicationStatus = res.data.data;
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to get applications status: ' + err);
        // this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get application status');
        this.showErrorMsg();
      })
  }

  private applyApplicationClicked(app: Application): void {
    store.dispatch('setAppRenew', app);
    store.dispatch('setOnlineApplication', app);
    switch (app.applicationType?.id) {
      case 1: // PC
        this.$router.push({ name: RouteN.Mars.PcRenewOverview.n, query: { appId: app.id } });
        break;
      case 2: // SM
        this.$router.push({ name: RouteN.Mars.SmApplication.n, query: { appId: app.id } });
        break;
      case 3: // SAR
        this.$router.push({ name: RouteN.Mars.SarApplication.n, query: { appId: app.id } });
        break;
      case 4: // AMN
        this.$router.push({ name: RouteN.Mars.AmnApplication.n, query: { appId: app.id } });
        break;
      case 5: // AMR
        this.$router.push({ name: RouteN.Mars.AmrApplication.n, query: { appId: app.id } });
        break;
      case 6: // STN
        this.$router.push({ name: RouteN.Mars.StnApplication.n, query: { appId: app.id } });
        break;
      case 7: // STR
        this.$router.push({ name: RouteN.Mars.StrApplication.n, query: { appId: app.id } });
        break;
      default:
        break;
    }
  }

  private async getMyApplications() {
    this.isLoadingData = true;
    try {

      const resp0: any = await this.apiService.ApplicationApi?.apiV1ApplicationGetMyApplicationsGet();
      const resp1: any = await this.apiService.ApplicationApi?.apiV1ApplicationGetMyPractisingCertificateGet();
      const resp2: any = await this.apiService.PageApi?.apiV1PageGetAllPageContentsGet();
      this.myApplications = resp0.data.data || [];
      this.myPractisingCertificate = resp1.data.data;
      // this.insertMockData();

      // store all popovers
      for (let p = 0; p < resp2.data.data.length; p++) {
        this.popovers.push({
          key: resp2.data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp2.data.data[p].content as string,
          isShow: resp2.data.data[p].isShow as boolean,
          title: resp2.data.data[p].title as string,
          pageId: resp2.data.data[p].pageId as number,
        })
      }

      store.dispatch('setPopovers', this.popovers);

      this.isLoadingData = false;
    } catch (err) {
      this.ai.trackException('Error, Unable to get my applications data: ' + err);
      // this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get my applications data');
      this.showErrorMsg();
      this.isLoadingData = false;
    } finally {
      this.isLoadingData = false;
    }

  }

  private insertMockData() {
    // Mock Data for SAR and AMN - Remove when data is ready
    this.myApplications.push(
      {
        "id": 1134,
        "key": "SARNEW",
        "applicationStatus": {
          "id": 1,
          "name": "New",
          "description": "New"
        },
        "applicationClass": {
          "id": 2,
          "name": "Renew",
          "description": "Renew"
        },
        "applicationType": {
          "id": 3,
          "name": "Specialist Accreditation Renewal",
          "description": "Specialist Accreditation Renewal"
        },
        "applicationYearName": "2023/24",
        "description": "Specialist Accreditation Renewal",
        "lsAppId": "1619755",
        "lsAppStatus": "new"
      },
      {
        "id": 134,
        "key": "AMN",
        "applicationStatus": {
          "id": 1,
          "name": "New",
          "description": "New"
        },
        "applicationClass": {
          "id": 1,
          "name": "New",
          "description": "New"
        },
        "applicationType": {
          "id": 4,
          "name": "Associate Membership",
          "description": "Associate Membership"
        },
        "applicationYearName": "2023/24",
        "description": "Associate Membership",
        "lsAppId": "1619733455",
        "lsAppStatus": "new"
      }
    );
  }

  private stringToBlob(bs: string) {
    const byteString = window.atob(bs);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  private downloadCertificate(pcId: number) {
    this.downloading = true;
    this.apiService.ApplicationApi?.apiV1ApplicationGetPractisingCertificateFilePcIdGet(pcId)
      .then(res => {
        const file = res.data.data.data;
        const blob = this.stringToBlob(file.base64AsString);
        const documentURL = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        const fileName = file.fileName;
        downloadLink.href = documentURL;
        downloadLink.download = fileName;
        downloadLink.click();
        this.downloading = false;
      })
      .catch(err => {
        this.downloading = false;
        console.log(err);
        this.ai.trackException('Error, Unable to get file for download: ' + err);
        // this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get file for download, PC ID: ' + pcId);
        this.showErrorMsg();
      })
  }

  get currentApp(): Application {
    return store.getters.getAppRenew;
  }

  private showErrorMsg() {
    const _msg = 'We\'re sorry, an error has occurred. Please try refreshing your browser to see if that resolves the issue. If you continue to see this message after refreshing, please wait for 5 minutes and try again. We apologise for any inconvenience this may cause.';
    this.eventCtrl.emit('show-snackbar', 'error', _msg);
  }
}

