import { Options, Vue } from "vue-property-decorator";
import Popover from "@/shared/components/popover/index.vue";
import { PopoverView } from "@/shared/components/popover/popover";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { PracticeDetails } from "@/shared/modules/typescript-api-client/models";

@Options({
  props: {
    index: Number,
    practiceRecord: Object,
    showNoChange: Boolean,
  },
  components: {
    Popover,
    AddressPreview,
  },
  mixins: [
    FormatDateTimeMixin
  ]
})
export default class PcrPracticeRecordCard extends Vue {
  private index!: number;
  private practiceRecord!: PracticeDetails;
  private showNoChange!: boolean

  private isPrincipalPlace = false;

  private popover: PopoverView = {
    key:'',
    content: 'Hello world',
    isShow: true,
    title: ''
  };

  private changeDetails(addNew: boolean) {
    this.$emit('changeDetails', this.index, this.practiceRecord, addNew);
  }

  private revertDetails() {
    this.$emit('revertDetails', this.index, this.practiceRecord);
  }

  private getSelectedAddressFromArray(addresses: any[]) {
    const _selectedAddress: any[] = addresses.filter(a => {
      return a.isSelected;
    });
    return _selectedAddress[0]?.address ? _selectedAddress[0].address : addresses[0].address;
  }

  private getSelectedPhoneNumberFromArray(addresses: any[], isFax: boolean) {
    const _selectedAddress = addresses.find(a => a.isSelected);
    const address = _selectedAddress || addresses[0];
    if(isFax) {
      return address.businessFax?.phoneNumber ? address.businessFax?.phoneAreaCode + ' ' + address.businessFax?.phoneNumber : '';
    } else {
      return address.businessPhone?.phoneNumber ? address.businessPhone?.phoneAreaCode + ' ' + address.businessPhone?.phoneNumber : '';
    }
  }

  get btnTextRevertDelete(): string {
    return this.practiceRecord.originalPracticeRecord?.name ? 'Revert details' : 'Delete details';
  }

  get sameEmploymentStatus() {
    return this.practiceRecord.originalPracticeRecord?.employmentStatus?.id == this.practiceRecord.newPracticeRecord?.employmentStatus?.id;
  }

}

