import { Module } from "vuex";

const amrAppFormDataStoreModule: Module<any, any> = {
  state: {
    amrAppFormData: {}
  },
  mutations: {
    setAmrAppFormData(state, fd) {
      state.amrAppFormData = fd;
      // window.sessionStorage.setItem('amrAppFormData', JSON.stringify(fd));
    }
  },
  actions: {
    setAmrAppFormData({ commit }, fd) {
      commit('setAmrAppFormData', fd);
    }
  },
  getters: {
    getAmrAppFormData: (state) => state.amrAppFormData
  }
}
export default amrAppFormDataStoreModule;