import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { Application, StudentRenewIndividualDetail } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import Popover from "@/shared/components/popover/index.vue";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import StrApplicantDetailsUpdateModal from "../str-applicant-details-update-modal/index.vue";


@Options({
  props: {
    strAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    AddressPreview,
    StrApplicantDetailsUpdateModal,
  },
  mixins: [FormatDateTimeMixin],
})

export default class StrApplicantDetails extends Vue {
  private strAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private loadingDataSet = false;

  private individualDetails: StudentRenewIndividualDetail = {};
  private origNames = {};

  private showUpdateDetailsModal = false;

  private disallowUpdate: any = {};

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  @Watch('individualDetails.isCurrentDetailCorrect')
  private radioButtonChange() {
    this.canGoNextCheck();
  }

  mounted() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.eventCtrl.emit('canGoNext', false);
    const _origName = this.strAppFormData.origNames;
    this.origNames = _origName;
    this.preloadData();
  }

  private async preloadData(): Promise<void> {
    const _isRenewing = this.strAppFormData.individualDetails?.studentRenewApplicationId > 0;
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.StudentRenewApi?.apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(this.onlineApplication.id, !_isRenewing)
      ]);
      this.individualDetails = resp[0].data.data;
      this.strAppFormData.individualDetails = this.individualDetails;
      if (!_isRenewing) {
        this.origNames = {
          firstName: this.individualDetails.firstName,
          otherName: this.individualDetails.otherName,
          surname: this.individualDetails.surname,
        };
        this.disallowUpdate = {
          birthDate: this.individualDetails.hasIndId && this.individualDetails.birthDate !== null,
        };
      }
      this.strAppFormData.origNames = this.origNames;
      this.strAppFormData.disallowUpdate = this.disallowUpdate;
      store.dispatch('setStrAppFormData', this.strAppFormData);
      this.canGoNextCheck();

    } catch (err) {
      this.ai.trackException('Error, Unable to load application details: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load application details');
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  get hasNameChanged() {
    const _firstNameCheck = this.individualDetails?.firstName?.trim() == this.strAppFormData.origNames?.firstName?.trim();
    const _otherNameCheck = this.individualDetails?.otherName?.trim() == this.strAppFormData.origNames?.otherName?.trim();
    const _surnameCheck = this.individualDetails?.surname?.trim() == this.strAppFormData.origNames?.surname?.trim();
    return !(_firstNameCheck && _otherNameCheck && _surnameCheck);
  }

  get missingSurname(): boolean { 
    return !this.individualDetails.surname;
  }

  get missingMobilePhone(): boolean {
    return !this.individualDetails?.mobilePhone?.phoneNumber;
  }

  get missingDOB(): boolean {
    return !this.individualDetails?.birthDate;
  }

  get missingEmail(): boolean {
    return !this.individualDetails.communicationEmail?.email;
  }

  get missingPostcode(): boolean {
    return !this.individualDetails?.postalAddress?.postcode;
  }

  get missingInstitution(): boolean {
    return !this.individualDetails?.qualificationInstitution;
  }

  get missingLawDegree(): boolean {
    return !this.individualDetails?.lawDegree;
  }

  get missingStudentNumber(): boolean {
    return !this.individualDetails?.studentNumber;
  }

  get missingProofOfEnrolment(): boolean {
    return this.individualDetails?.studentProofDocumentFiles?.length == 0;
  }

  get isValidForm(): boolean {
    return !this.missingSurname && !this.missingMobilePhone && !this.missingDOB && !this.missingEmail && !this.missingPostcode &&
    !this.missingInstitution && !this.missingLawDegree && !this.missingStudentNumber && !this.missingProofOfEnrolment;
  }

  private canGoNextCheck() {
    const correctInfo: boolean = this.strAppFormData.individualDetails?.isCurrentDetailCorrect ? true : false;
    this.eventCtrl.emit('canGoNext', this.isValidForm && correctInfo);
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  private updateCurrentDetails() {
    // this.individualDetails.workPhone = this.individualDetails.workPhone?.phoneNumber ? this.individualDetails.workPhone :
    //   { phoneNumber: '', phoneAreaCode: null };
    // this.individualDetails.businessFax = this.individualDetails.businessFax?.phoneNumber ? this.individualDetails.businessFax :
    //   { phoneNumber: '', phoneAreaCode: null };
    this.individualDetails.mobilePhone = this.individualDetails.mobilePhone?.phoneNumber ? this.individualDetails.mobilePhone :
      { phoneNumber: '', phoneAreaCode: null };
    // this.individualDetails.businessPhone = this.individualDetails.businessPhone?.phoneNumber ? this.individualDetails.businessPhone :
    //   { phoneNumber: '', phoneAreaCode: null };
    // this.individualDetails.birthPlace = this.individualDetails.birthPlace || '';
    this.showUpdateDetailsModal = true;
  }

  private async reloadCurrentDetails() {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.StudentRenewApi?.apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(this.onlineApplication.id, false)
      ]);
      this.individualDetails = resp[0].data.data;
      this.strAppFormData.individualDetails = this.individualDetails;
      store.dispatch('setStrAppFormData', this.strAppFormData);
      this.canGoNextCheck();
    } catch (err) {
      this.ai.trackException('Error, Unable to reload application details: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to reload application details');
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private toCurrency(value: any): string {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

}


