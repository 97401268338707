import { Module } from "vuex";

const snackBarStoreModule: Module<any, any> = {
  state: {
    snackbar: []
  },
  mutations: {
    setSnackbar(state, sb) {
      state.snackbar = sb;
    }
  },
  actions: {
    setSnackbar({ commit }, sb) {
      commit('setSnackbar', sb);
    }
  },
  getters: {
    getSnackbar: (state) => state.snackbar
  }
}
export default snackBarStoreModule;