import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bb92891"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dropdown-selection"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick", "innerHTML"]

export function render(_ctx, _cache) {
  return (_ctx.listItems.length > 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", {
          class: "text-box pl-3",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args))),
          title: _ctx.inputDisplay
        }, _toDisplayString(_ctx.inputDisplay), 9, _hoisted_2),
        (_ctx.dropdownOpen)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (l, idx) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: idx,
                  onClick: $event => (_ctx.clicked(idx)),
                  class: _normalizeClass(["dropdown-list", { selected: idx == _ctx.listIndex }]),
                  innerHTML: l.htmlText
                }, null, 10, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}