import { Options, Vue } from "vue-property-decorator";

@Options({
  components: {}
})
export default class ModalWindow extends Vue {

  private isClosing = false;

  private close() {
    this.isClosing = true;
    setTimeout(() => {
      this.$emit('close');
    }, 500);
  }

  mounted(): void {
    document.body.classList.add('modal-open');
  }

  beforeUnmount(): void {
    document.body.classList.remove('modal-open');
  }

}

