import {Options, Vue} from "vue-property-decorator";
import StdPageLayout from "@/shared/components/std-page-layout/index.vue";
import LoadingSpinner from "@/shared/components/loading-spinner/index.vue";
import { Authentication } from "@/shared/modules/authentication/authentication";
import { AuthorizedUser } from "@/shared/models/authorized-user";
import store from "@/shared/store";
import { AuthenticationStatus, AuthenticationStatusType } from "@/shared/modules/authentication/authentication-status";
import { RouteN } from "@/shared/constants/route-name";
import { SELF_URL } from "@/shared/config/config";
import { Url } from "@/shared/config/url";
import { AppInsights } from "@/mars-app/appInsights";

@Options({
  components: {
    StdPageLayout,
    LoadingSpinner,
  }
})
export default class AutoLogin extends Vue {

  private ai = new AppInsights;

  private authentication: Authentication = new Authentication(
    this.userAccount
  );

  mounted() {
    this.authentication.runAuthCheck(this.authCheckCallBack);
  }

  private authCheckCallBack(status: AuthenticationStatus = AuthenticationStatusType.NotLogin): void {
    this.ai.trackEvent('authCheckCallBack: ' + status );
    try {
      const redirect: string = this.$route.query.redirect as string;
      switch (status) {
        case AuthenticationStatusType.AppAccessPermitted:
          setTimeout(() => {
            this.$router.push(
              redirect ? redirect : { name: RouteN.Mars.Main.n }
            );
          }, 250);
          break;
        case AuthenticationStatusType.AppAccessDenied:
          setTimeout(() => { this.$router.push({ name: RouteN.Mars.AccessError.n }); }, 250);
          break;
        case AuthenticationStatusType.NotLogin:
          setTimeout(() => { document.location.href = SELF_URL + Url.Login; }, 250);
          break;
        default:
          setTimeout(() => { document.location.href = SELF_URL + Url.Logout; }, 250);
          break;
      }
    }
    catch(e) {
      this.ai.trackException('authCheckCallBack: ' + e );
      // console.log("authCheckCallBack: "+e);
      throw e;
    }
    
  }
  
  get userAccount(): AuthorizedUser {
    try {
      return store.getters.getUserAccount;
    }
    catch(e) {
      this.ai.trackException('get userAccount: ' + e );
      // console.log("get userAccount:"+e);
      throw e;
    }
  }

}

