// import { UserAccount, ClientTypeID, ClientType } from '../user/user-account';
import { AuthorizedUser } from '@/shared/models/authorized-user';
import AuthenticationService from './authentication.service';
import { AuthenticationStatusType, AuthenticationStatus } from './authentication-status';

export class Authentication {

  private authService: AuthenticationService;
  private loginCallback: (x: AuthenticationStatusType) => void;

  constructor(
    public authorizedUser: AuthorizedUser
  ) {
    this.authService = new AuthenticationService(this.authorizedUser);
    this.loginCallback = null as any;
  }

  public runAuthCheck(cb: () => void): void {
    this.loginCallback = cb;
    this.productionAuth();
  }

  public logout(cb: () => void): void {
    this.clearBrowserStorages();
    this.deleteAllCookies();

    if (typeof cb === 'function') {
      cb();
    }
  }

  private clearBrowserStorages(): void {
    try {
      localStorage.clear();
      sessionStorage.clear();
    } catch (err) {
      // console.log(err);
    }
  }


  private deleteAllCookies() {
    const cookies: string[] = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos: number = cookie.indexOf('=');
      const name: string = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }


  private productionAuth(): void {
    this.authService.checkAuthentication()
      .then((res: any) => {
        this.productionVerify();
      })
      .catch((err: any) => {
        this.productionVerify();
      });
  }

  private productionVerify(): void {
    // const v1: ClientType = this.userAccount.clientType;
    const v2: AuthenticationStatus = this.authorizedUser.authenticationStatus;
    // const c1: boolean = v1 === ClientTypeID.DesktopApp;
    const c2: boolean = v2 === AuthenticationStatusType.LoggedIn;
    const c3: boolean = v2 === AuthenticationStatusType.NotLogin;

    this.authorizedUser.authenticationStatus = c2 ? AuthenticationStatusType.AppAccessPermitted : AuthenticationStatusType.NotLogin;

    if (typeof this.loginCallback === 'function') {
      this.loginCallback(this.authorizedUser.authenticationStatus);
    }
  }
}
