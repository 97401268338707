/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddressTypeIEnumerableResponse } from '../models';
import { AdmissionPlaceIEnumerableResponse } from '../models';
import { AmNewCategoryIEnumerableResponse } from '../models';
import { AmNewFileTypeIEnumerableResponse } from '../models';
import { AmRenewFileTypeIEnumerableResponse } from '../models';
import { ApplicationStatusIEnumerableResponse } from '../models';
import { ApplicationTypeIEnumerableResponse } from '../models';
import { AreaOfInterestIEnumerableResponse } from '../models';
import { AreaOfLawIEnumerableResponse } from '../models';
import { AustralianJurisdictionIEnumerableResponse } from '../models';
import { BuildingTypeIEnumerableResponse } from '../models';
import { ContentTypeIEnumerableResponse } from '../models';
import { CountryIEnumerableResponse } from '../models';
import { CurrentStatusIEnumerableResponse } from '../models';
import { DeliveryTypeIEnumerableResponse } from '../models';
import { DxExchangeIEnumerableResponse } from '../models';
import { EmailPreferenceIEnumerableResponse } from '../models';
import { EmploymentStatusIEnumerableResponse } from '../models';
import { FirmNameSearchIEnumerableResponse } from '../models';
import { FirmTypeIEnumerableResponse } from '../models';
import { GenderIEnumerableResponse } from '../models';
import { NonRenewalReasonIEnumerableResponse } from '../models';
import { PcRenewAddressTypeIEnumerableResponse } from '../models';
import { PcRenewCpdAnswerTypeIEnumerableResponse } from '../models';
import { PcRenewFileTypeIEnumerableResponse } from '../models';
import { QualificationInstitutionIEnumerableResponse } from '../models';
import { RecordTypeIEnumerableResponse } from '../models';
import { Request } from '../models';
import { SarFileTypeIEnumerableResponse } from '../models';
import { StreetTypeIEnumerableResponse } from '../models';
import { StudentNewFileTypeIEnumerableResponse } from '../models';
import { StudentRenewFileTypeIEnumerableResponse } from '../models';
import { SuburbStatePostcodeIEnumerableResponse } from '../models';
import { TitleIEnumerableResponse } from '../models';
import { TransactionStatusIEnumerableResponse } from '../models';
/**
 * CodeApi - axios parameter creator
 * @export
 */
export const CodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary READER | Returns Address Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAddressTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAddressTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Admission Places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAdmissionPlacesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAdmissionPlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns AmNew Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmNewCategoriesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAmNewCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Am New File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmNewFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAmNewFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Am Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmRenewFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAmRenewFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Application Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetApplicationStatusesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetApplicationStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Application Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetApplicationTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetApplicationTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Area Of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAreaOfInterestsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAreaOfInterests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAreaOfLawsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAreaOfLaws`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Australian Jurisdictions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAustralianJurisdictionsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetAustralianJurisdictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Building Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetBuildingTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetBuildingTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Content Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetContentTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetContentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetCountriesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetCountries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Current Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetCurrentStatusesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetCurrentStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reader | Returns Delivery Types  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetDeliveryTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetDeliveryTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reader | Returns DX Exchange list  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetDxExchangeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetDxExchangeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Email Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmailPreferencesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetEmailPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet: async (firmId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            if (firmId === null || firmId === undefined) {
                throw new RequiredError('firmId','Required parameter firmId was null or undefined when calling apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet.');
            }
            const localVarPath = `/api/v1/Code/GetEmploymentStatusByFirmId/{firmId}`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmTypeId
         * @param {number} firmTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet: async (firmTypeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmTypeId' is not null or undefined
            if (firmTypeId === null || firmTypeId === undefined) {
                throw new RequiredError('firmTypeId','Required parameter firmTypeId was null or undefined when calling apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet.');
            }
            const localVarPath = `/api/v1/Code/GetEmploymentStatusByFirmTypeId/{firmTypeId}`
                .replace(`{${"firmTypeId"}}`, encodeURIComponent(String(firmTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Firm Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetFirmTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetFirmTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Genders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetGendersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetGenders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns GetNotRenewalReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetNonRenewalReasonGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetNonRenewalReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns PcRenewAddressType for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewAddressTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetPcRenewAddressTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns CpdAnswerTypes for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewCpdAnswerTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetPcRenewCpdAnswerTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Pc Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetPcRenewFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Qualification Institutions
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetQualificationInstitutionsByKeywordSearchPost: async (body?: Request, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetQualificationInstitutionsByKeywordSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Record Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetRecordTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetRecordTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Spec Acc File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSpecAccFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetSpecAccFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns SpecAcc Non Renewal Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSpecAccNonRenewalReasonsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetSpecAccNonRenewalReasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Street Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStreetTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetStreetTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns StudentNew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStudentNewFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetStudentNewFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns StudentRenew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStudentRenewFileTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetStudentRenewFileTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Suburbs, States, and Postcode
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSuburbStatePostcodePost: async (body?: Request, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetSuburbStatePostcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetTitlesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetTitles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary READER | Returns Transactions Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetTransactionStatusesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/GetTransactionStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeSearchFirmsPost: async (body?: Request, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Code/SearchFirms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeApi - functional programming interface
 * @export
 */
export const CodeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary READER | Returns Address Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAddressTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAddressTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Admission Places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAdmissionPlacesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmissionPlaceIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAdmissionPlacesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns AmNew Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAmNewCategoriesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewCategoryIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAmNewCategoriesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Am New File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAmNewFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmNewFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAmNewFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Am Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAmRenewFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmRenewFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAmRenewFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Application Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetApplicationStatusesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatusIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetApplicationStatusesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Application Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetApplicationTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetApplicationTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Area Of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAreaOfInterestsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOfInterestIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAreaOfInterestsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAreaOfLawsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOfLawIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAreaOfLawsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Australian Jurisdictions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetAustralianJurisdictionsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AustralianJurisdictionIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetAustralianJurisdictionsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Building Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetBuildingTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetBuildingTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Content Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetContentTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetContentTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetCountriesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetCountriesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Current Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetCurrentStatusesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentStatusIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetCurrentStatusesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reader | Returns Delivery Types  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetDeliveryTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetDeliveryTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reader | Returns DX Exchange list  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetDxExchangeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DxExchangeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetDxExchangeListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Email Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetEmailPreferencesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailPreferenceIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetEmailPreferencesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmploymentStatusIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmTypeId
         * @param {number} firmTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmploymentStatusIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Firm Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetFirmTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetFirmTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Genders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetGendersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenderIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetGendersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns GetNotRenewalReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetNonRenewalReasonGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonRenewalReasonIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetNonRenewalReasonGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns PcRenewAddressType for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetPcRenewAddressTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewAddressTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetPcRenewAddressTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns CpdAnswerTypes for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetPcRenewCpdAnswerTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewCpdAnswerTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetPcRenewCpdAnswerTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Pc Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetPcRenewFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetPcRenewFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Qualification Institutions
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body?: Request, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualificationInstitutionIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Record Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetRecordTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetRecordTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Spec Acc File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetSpecAccFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SarFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetSpecAccFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns SpecAcc Non Renewal Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetSpecAccNonRenewalReasonsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonRenewalReasonIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetSpecAccNonRenewalReasonsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Street Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetStreetTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreetTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetStreetTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns StudentNew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetStudentNewFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentNewFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetStudentNewFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns StudentRenew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetStudentRenewFileTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentRenewFileTypeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetStudentRenewFileTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Suburbs, States, and Postcode
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetSuburbStatePostcodePost(body?: Request, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuburbStatePostcodeIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetSuburbStatePostcodePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetTitlesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetTitlesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary READER | Returns Transactions Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeGetTransactionStatusesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionStatusIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeGetTransactionStatusesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CodeSearchFirmsPost(body?: Request, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmNameSearchIEnumerableResponse>> {
            const localVarAxiosArgs = await CodeApiAxiosParamCreator(configuration).apiV1CodeSearchFirmsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CodeApi - factory interface
 * @export
 */
export const CodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary READER | Returns Address Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAddressTypesGet(options?: any): AxiosPromise<AddressTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAddressTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Admission Places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAdmissionPlacesGet(options?: any): AxiosPromise<AdmissionPlaceIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAdmissionPlacesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns AmNew Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmNewCategoriesGet(options?: any): AxiosPromise<AmNewCategoryIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAmNewCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Am New File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmNewFileTypesGet(options?: any): AxiosPromise<AmNewFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAmNewFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Am Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAmRenewFileTypesGet(options?: any): AxiosPromise<AmRenewFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAmRenewFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Application Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetApplicationStatusesGet(options?: any): AxiosPromise<ApplicationStatusIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetApplicationStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Application Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetApplicationTypesGet(options?: any): AxiosPromise<ApplicationTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetApplicationTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Area Of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAreaOfInterestsGet(options?: any): AxiosPromise<AreaOfInterestIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAreaOfInterestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAreaOfLawsGet(options?: any): AxiosPromise<AreaOfLawIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAreaOfLawsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Australian Jurisdictions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetAustralianJurisdictionsGet(options?: any): AxiosPromise<AustralianJurisdictionIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetAustralianJurisdictionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Building Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetBuildingTypesGet(options?: any): AxiosPromise<BuildingTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetBuildingTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Content Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetContentTypesGet(options?: any): AxiosPromise<ContentTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetContentTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetCountriesGet(options?: any): AxiosPromise<CountryIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Current Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetCurrentStatusesGet(options?: any): AxiosPromise<CurrentStatusIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetCurrentStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reader | Returns Delivery Types  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetDeliveryTypesGet(options?: any): AxiosPromise<DeliveryTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetDeliveryTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reader | Returns DX Exchange list  (via LS API)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetDxExchangeListGet(options?: any): AxiosPromise<DxExchangeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetDxExchangeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Email Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmailPreferencesGet(options?: any): AxiosPromise<EmailPreferenceIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetEmailPreferencesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmId
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId: number, options?: any): AxiosPromise<EmploymentStatusIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Employment Status By FirmTypeId
         * @param {number} firmTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId: number, options?: any): AxiosPromise<EmploymentStatusIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Firm Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetFirmTypesGet(options?: any): AxiosPromise<FirmTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetFirmTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Genders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetGendersGet(options?: any): AxiosPromise<GenderIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetGendersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns GetNotRenewalReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetNonRenewalReasonGet(options?: any): AxiosPromise<NonRenewalReasonIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetNonRenewalReasonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns PcRenewAddressType for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewAddressTypesGet(options?: any): AxiosPromise<PcRenewAddressTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetPcRenewAddressTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns CpdAnswerTypes for PcRenew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewCpdAnswerTypesGet(options?: any): AxiosPromise<PcRenewCpdAnswerTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetPcRenewCpdAnswerTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Pc Renew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetPcRenewFileTypesGet(options?: any): AxiosPromise<PcRenewFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetPcRenewFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Qualification Institutions
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body?: Request, options?: any): AxiosPromise<QualificationInstitutionIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Record Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetRecordTypesGet(options?: any): AxiosPromise<RecordTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetRecordTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Spec Acc File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSpecAccFileTypesGet(options?: any): AxiosPromise<SarFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetSpecAccFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns SpecAcc Non Renewal Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSpecAccNonRenewalReasonsGet(options?: any): AxiosPromise<NonRenewalReasonIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetSpecAccNonRenewalReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Street Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStreetTypesGet(options?: any): AxiosPromise<StreetTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetStreetTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns StudentNew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStudentNewFileTypesGet(options?: any): AxiosPromise<StudentNewFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetStudentNewFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns StudentRenew File Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetStudentRenewFileTypesGet(options?: any): AxiosPromise<StudentRenewFileTypeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetStudentRenewFileTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Suburbs, States, and Postcode
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetSuburbStatePostcodePost(body?: Request, options?: any): AxiosPromise<SuburbStatePostcodeIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetSuburbStatePostcodePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetTitlesGet(options?: any): AxiosPromise<TitleIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetTitlesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary READER | Returns Transactions Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeGetTransactionStatusesGet(options?: any): AxiosPromise<TransactionStatusIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeGetTransactionStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Search Firms
         * @param {Request} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CodeSearchFirmsPost(body?: Request, options?: any): AxiosPromise<FirmNameSearchIEnumerableResponse> {
            return CodeApiFp(configuration).apiV1CodeSearchFirmsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeApi - object-oriented interface
 * @export
 * @class CodeApi
 * @extends {BaseAPI}
 */
export class CodeApi extends BaseAPI {
    /**
     * 
     * @summary READER | Returns Address Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAddressTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAddressTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Admission Places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAdmissionPlacesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAdmissionPlacesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns AmNew Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAmNewCategoriesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAmNewCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Am New File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAmNewFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAmNewFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Am Renew File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAmRenewFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAmRenewFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Application Statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetApplicationStatusesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetApplicationStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Application Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetApplicationTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetApplicationTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Area Of Interests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAreaOfInterestsGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAreaOfInterestsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAreaOfLawsGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAreaOfLawsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Australian Jurisdictions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetAustralianJurisdictionsGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetAustralianJurisdictionsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Building Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetBuildingTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetBuildingTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Content Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetContentTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetContentTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetCountriesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Current Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetCurrentStatusesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetCurrentStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Reader | Returns Delivery Types  (via LS API)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetDeliveryTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetDeliveryTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Reader | Returns DX Exchange list  (via LS API)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetDxExchangeListGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetDxExchangeListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Email Preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetEmailPreferencesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetEmailPreferencesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Employment Status By FirmId
     * @param {number} firmId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId: number, options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(firmId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Employment Status By FirmTypeId
     * @param {number} firmTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId: number, options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetEmploymentStatusByFirmTypeIdFirmTypeIdGet(firmTypeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Firm Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetFirmTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetFirmTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Genders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetGendersGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetGendersGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns GetNotRenewalReason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetNonRenewalReasonGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetNonRenewalReasonGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns PcRenewAddressType for PcRenew
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetPcRenewAddressTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetPcRenewAddressTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns CpdAnswerTypes for PcRenew
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetPcRenewCpdAnswerTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetPcRenewCpdAnswerTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Pc Renew File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetPcRenewFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetPcRenewFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Qualification Institutions
     * @param {Request} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body?: Request, options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetQualificationInstitutionsByKeywordSearchPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Record Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetRecordTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetRecordTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Spec Acc File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetSpecAccFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetSpecAccFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns SpecAcc Non Renewal Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetSpecAccNonRenewalReasonsGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetSpecAccNonRenewalReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Street Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetStreetTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetStreetTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns StudentNew File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetStudentNewFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetStudentNewFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns StudentRenew File Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetStudentRenewFileTypesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetStudentRenewFileTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Suburbs, States, and Postcode
     * @param {Request} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetSuburbStatePostcodePost(body?: Request, options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetSuburbStatePostcodePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetTitlesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetTitlesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary READER | Returns Transactions Statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeGetTransactionStatusesGet(options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeGetTransactionStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Search Firms
     * @param {Request} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public apiV1CodeSearchFirmsPost(body?: Request, options?: any) {
        return CodeApiFp(this.configuration).apiV1CodeSearchFirmsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
