
export interface RouteName {
  NoAccess: { n: string; p: string };
  Mars: MarsRouterName;
  Mac: MacRouterName;
}

export interface MarsRouterName {
  Home: { n: string; p: string };
  Main: { n: string; p: string };
  PcRenew: { n: string; p: string };
  PcRenewOverview: { n: string; p: string };
  PcNoRenew: { n: string; p: string };
  SmApplication: { n: string; p: string };
  SarApplication: { n: string; p: string };
  SaNrApplication: { n: string; p: string };
  AmnApplication: { n: string; p: string };
  StnApplication: { n: string; p: string };
  StrApplication: { n: string; p: string };
  AmrApplication: { n: string; p: string };
  LoginPage: { n: string; p: string };
  LogoutPage: { n: string; p: string };
  AccessError: { n: string; p: string };
}

export interface MacRouterName {
  Home: { n: string; p: string };
  ApplicationContent: { n: string; p: string };
  ApplicationPages: { n: string; p: string };
  PageContentList: { n: string; p: string };
  Main: { n: string; p: string };
  LoginPage: { n: string; p: string };
  LogoutPage: { n: string; p: string };
  AccessError: { n: string; p: string };
}

export const RouteN: RouteName = {
  NoAccess: { n: 'no-access', p: '/no-access' },
  Mars: {
    Main: { n: 'internal-main', p: '/' },
    Home: { n: 'Home', p: '/home' },
    PcRenew: { n: 'PC-Renew', p: '/pcr' },
    PcRenewOverview: { n: 'PC-Renew-Overview', p: 'pcr-overview' },
    PcNoRenew: { n: 'PC-NoRenew', p: '/pc-nor' },
    SmApplication: { n: 'SM-application', p: '/sm-application' },
    SarApplication: { n: 'SAR-application', p: '/sar-application' },
    SaNrApplication: { n: 'SA-NR-application', p: '/sa-nr-application' },
    AmnApplication: { n: 'AMN-application', p: '/amn-application' },
    StnApplication: { n: 'STN-application', p: '/stn-application' },
    StrApplication: { n: 'STR-application', p: '/str-application' },
    AmrApplication: { n: 'AMR-application', p: '/amr-application' },
    LoginPage: { n: 'login', p: '/check-auth' },
    LogoutPage: { n: 'logout', p: '/signout' },
    AccessError: { n: 'accessError', p: '/access-error' },
  },
  Mac: {
    Main: { n: 'internal-main', p: '/' },
    Home: { n: 'Home', p: '/home' },
    ApplicationContent: { n: 'application-content', p: '/application-content' },
    ApplicationPages: { n: 'application-pages', p: '/application-pages' },
    PageContentList: { n: 'page-content-list', p: '/page-content-list' },
    LoginPage: { n: 'login', p: '/check-auth' },
    LogoutPage: { n: 'logout', p: '/signout' },
    AccessError: { n: 'accessError', p: '/access-error' },
  }
};