// import { MOVED_PERMANENTLY } from "http-status-codes";
import moment from "moment";
import { PopoverView } from "../components/popover/popover";
import store from "../store";
import { AddressDetail } from "../modules/typescript-api-client/models";

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $formatDateTimeTypeA: (isoStr: string) => string,
    $formatDate: (isoStr: string) => string,
    formatMMYYYY: (isoStr: string) => string,
    $formatDateTimeTypeC: (isoStr: string) => string,
    $formatDateTimeTypeD: (isoStr: string) => string,
    $formatSecToHours: (seconds: number) => string,
    $getPopover: (pKey: string, pageId: number) => PopoverView,
    $addressCleanup: (a: AddressDetail) => AddressDetail,
  }
}

export default {
  methods: {
    $addressCleanup(a: AddressDetail): AddressDetail {
      const addressToSaved: AddressDetail = {};
      addressToSaved.id = a.id || 0;
      addressToSaved.countryId = a.countryId;
      addressToSaved.countryName = a.countryName;
      addressToSaved.lsAddressId = a.lsAddressId || null;
      addressToSaved.firmAddMapId = a.firmAddMapId || null;
      addressToSaved.addressTypeId = a.addressTypeId || 0;
      if (a.countryName !== 'AUSTRALIA') {
        addressToSaved.foreignAddress = a.foreignAddress;
      } else {
        switch (a.addressTypeId) {
          case 1:
            addressToSaved.buildingName = a.buildingName;
            addressToSaved.buildingNumber = a.buildingNumber;
            addressToSaved.buildingTypeId = a.buildingTypeId;
            addressToSaved.buildingTypeName = a.buildingTypeName;
            addressToSaved.level = a.level;
            addressToSaved.streetName = a.streetName;
            addressToSaved.streetNumber = a.streetNumber;
            addressToSaved.streetTypeId = a.streetTypeId;
            addressToSaved.streetTypeName = a.streetTypeName;

            addressToSaved.postcode = a.postcode;
            addressToSaved.postCodeSuburbId = a.postCodeSuburbId;
            addressToSaved.stateId = a.stateId;
            addressToSaved.state = a.state;
            addressToSaved.stateName = a.stateName;
            addressToSaved.suburbId = a.suburbId;
            addressToSaved.suburbName = a.suburbName;
            break;
          case 2:
            addressToSaved.deliveryTypeId = a.deliveryTypeId;
            addressToSaved.deliveryTypeName = a.deliveryTypeName;
            addressToSaved.poBox = a.poBox;
            addressToSaved.postcode = a.postcode;
            addressToSaved.postCodeSuburbId = a.postCodeSuburbId;
            addressToSaved.stateId = a.stateId;
            addressToSaved.state = a.state;
            addressToSaved.stateName = a.stateName;
            addressToSaved.suburbId = a.suburbId;
            addressToSaved.suburbName = a.suburbName;
            break;
          case 3:
            addressToSaved.dxExchangeTypeId = a.dxExchangeTypeId;
            addressToSaved.dxExchangeTypeName = a.dxExchangeTypeName;
            addressToSaved.dxNumber = a.dxNumber;
            break;
        }
      }

      return addressToSaved;
    },

    $getPopover(pKey: string, pageId?: number): PopoverView {
      try {
        const _popOver = store.getters.getPopovers.filter((p: { pageId: number; key: string; }) => {
          if (pageId && pageId > 0) {
            return p.key == pKey && p.pageId == pageId;
          } else {
            return p.key == pKey;
          }
        })[0];
        return _popOver;
      } catch (err) {
        return {} as PopoverView;
      }
    },

    $formatDateTimeTypeA(isoStr: string): string {
      if (isoStr) {
        return moment(isoStr).format('DD/MM/YYYY HH:mm');
      } else {
        return '';
      }
    },
    $formatDate(isoStr: string): string {
      return isoStr ? moment(isoStr).format('DD/MM/YYYY') : '';
    },
    $formatMMYYYY(isoStr: string): string {
      return isoStr ? moment(isoStr).format('MM/YYYY') : '';
    },
    $formatDateTimeTypeC(isoStr: string): string {
      return moment(isoStr).format('HH:mm');
    },
    $formatDateTimeTypeD(isoStr: string): string {
      return moment(isoStr).format('DD/MM/YYYY HH:mm');
    },
    $formatSecToHours(seconds: number): string {
      if (seconds > 1) {
        return seconds / 60 + ' hours';
      } else if (seconds == 60) {
        return seconds / 60 + ' hour';
      } else {
        return '';
      }
    }
  }
};
