import { Module } from "vuex";

const popOverStoreModule: Module<any, any> = {
  state: {
    popovers: []
  },
  mutations: {
    setPopovers(state, p) {
      state.popovers = p;
      // window.sessionStorage.setItem('popovers', JSON.stringify(p));
    }
  },
  actions: {
    setPopovers({ commit }, p) {
      commit('setPopovers', p);
    }
  },
  getters: {
    getPopovers: (state) => state.popovers
  }
}
export default popOverStoreModule;