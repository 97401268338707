import { Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AmRenewRecordDeclarationRequest, Application } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import DatePicker from 'vue-datepicker-next';

@Options({
  props: {
    amrAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    DatePicker
    // Popover
  },
  mixins: [FormatDateTimeMixin],
})
export default class AmrDeclarations extends Vue {
  private amrAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  private loadingDataSet = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.amrAppFormData.amrDeclarations = {};
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.updateAmrDeclaration();
  }

  get amrApp(): Application {
    return store.getters.getAppRenew;
  }

  private isValidForm(): boolean {
    return true;
  }

  private updateAmrDeclaration() {
    const _amrDeclaration: AmRenewRecordDeclarationRequest = {
      id: 0,
      amRenewApplicationId: this.amrAppFormData.individualDetails?.amRenewApplicationId,
    }
    this.amrAppFormData.amrDeclarations = _amrDeclaration;
    this.canGoNextCheck();
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

}

