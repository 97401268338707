import { Module } from "vuex";

const dxExchangeTypeListStoreModule: Module<any, any> = {
  state: {
    dxExchangeTypeList: null
  },
  mutations: {
    setDxExchangeTypeList(state, dx) {
      state.dxExchangeTypeList = dx;
      window.sessionStorage.setItem('dxExchangeTypeList', JSON.stringify(dx));
    }
  },
  actions: {
    setDxExchangeTypeList({ commit }, dx) {
      // console.log('setCountryList: ', cl)
      commit('setDxExchangeTypeList', JSON.stringify(dx));
    }
  },
  getters: {
    getDxExchangeTypeList: (state) => state.dxExchangeTypeList
  }
}
export default dxExchangeTypeListStoreModule;