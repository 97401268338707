/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanResponse } from '../models';
import { StudentRenewApplicationRequest } from '../models';
import { StudentRenewIndividualDetailRequest } from '../models';
import { StudentRenewIndividualDetailResponse } from '../models';
import { StudentRenewPreferenceDetailRequest } from '../models';
import { StudentRenewPreferenceDetailResponse } from '../models';
import { StudentRenewRecordDeclarationRequest } from '../models';
import { StudentRenewRecordDeclarationResponse } from '../models';
import { StudentRenewRecordEligibilityRequest } from '../models';
import { StudentRenewRecordEligibilityResponse } from '../models';
/**
 * StudentRenewApi - axios parameter creator
 * @export
 */
export const StudentRenewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/CancelStudentRenewApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/CheckStudentRenewConcurrentSession/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/GetStudentRenewPreferenceDetailData/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/GetStudentRenewRecordDeclaration/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Student Renew Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet: async (applicationId: number, isNewApplication: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet.');
            }
            // verify required parameter 'isNewApplication' is not null or undefined
            if (isNewApplication === null || isNewApplication === undefined) {
                throw new RequiredError('isNewApplication','Required parameter isNewApplication was null or undefined when calling apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/GetStudentRenewRecordEligibility/{applicationId}/{isNewApplication}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"isNewApplication"}}`, encodeURIComponent(String(isNewApplication)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/StudentRenew/GetStudentRenewStudentIndividualDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost: async (body?: StudentRenewPreferenceDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentRenew/SaveStudentRenewPreferenceDetailData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save StudentRenew Record Declaration
         * @param {StudentRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewRecordDeclarationPost: async (body?: StudentRenewRecordDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentRenew/SaveStudentRenewRecordDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Student Renew Record Eligibility
         * @param {StudentRenewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewRecordEligibilityPost: async (body?: StudentRenewRecordEligibilityRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentRenew/SaveStudentRenewRecordEligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update StudentRenewApplication Detail
         * @param {StudentRenewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewUpdateStudentRenewApplicationDetailPost: async (body?: StudentRenewApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentRenew/UpdateStudentRenewApplicationDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentRenewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewUpdateStudentRenewIndividualDetailPost: async (body?: StudentRenewIndividualDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/StudentRenew/UpdateStudentRenewIndividualDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentRenewApi - functional programming interface
 * @export
 */
export const StudentRenewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentRenewPreferenceDetailResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns StudentRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentRenewRecordDeclarationResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Student Renew Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentRenewRecordEligibilityResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentRenewIndividualDetailResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body?: StudentRenewPreferenceDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save StudentRenew Record Declaration
         * @param {StudentRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body?: StudentRenewRecordDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Student Renew Record Eligibility
         * @param {StudentRenewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body?: StudentRenewRecordEligibilityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update StudentRenewApplication Detail
         * @param {StudentRenewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body?: StudentRenewApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentRenewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body?: StudentRenewIndividualDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await StudentRenewApiAxiosParamCreator(configuration).apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentRenewApi - factory interface
 * @export
 */
export const StudentRenewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled StudentRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns StudentRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentRenewPreferenceDetailResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns StudentRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentRenewRecordDeclarationResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Student Renew Record Eligibility
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): AxiosPromise<StudentRenewRecordEligibilityResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<StudentRenewIndividualDetailResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {StudentRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body?: StudentRenewPreferenceDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save StudentRenew Record Declaration
         * @param {StudentRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body?: StudentRenewRecordDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Student Renew Record Eligibility
         * @param {StudentRenewRecordEligibilityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body?: StudentRenewRecordEligibilityRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update StudentRenewApplication Detail
         * @param {StudentRenewApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body?: StudentRenewApplicationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Individual Detail
         * @param {StudentRenewIndividualDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body?: StudentRenewIndividualDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return StudentRenewApiFp(configuration).apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentRenewApi - object-oriented interface
 * @export
 * @class StudentRenewApi
 * @extends {BaseAPI}
 */
export class StudentRenewApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Cancelled StudentRenewApplication
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId: number, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewCancelStudentRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns StudentRenew Concurrent Session
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewCheckStudentRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Preference Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewGetStudentRenewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns StudentRenew Record Declaration
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewGetStudentRenewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Student Renew Record Eligibility
     * @param {number} applicationId 
     * @param {boolean} isNewApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewGetStudentRenewRecordEligibilityApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Individual Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId: number, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewGetStudentRenewStudentIndividualDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Preference Details
     * @param {StudentRenewPreferenceDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body?: StudentRenewPreferenceDetailRequest, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewSaveStudentRenewPreferenceDetailDataPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save StudentRenew Record Declaration
     * @param {StudentRenewRecordDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body?: StudentRenewRecordDeclarationRequest, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewSaveStudentRenewRecordDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Student Renew Record Eligibility
     * @param {StudentRenewRecordEligibilityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body?: StudentRenewRecordEligibilityRequest, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewSaveStudentRenewRecordEligibilityPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update StudentRenewApplication Detail
     * @param {StudentRenewApplicationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body?: StudentRenewApplicationRequest, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewUpdateStudentRenewApplicationDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Individual Detail
     * @param {StudentRenewIndividualDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentRenewApi
     */
    public apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body?: StudentRenewIndividualDetailRequest, options?: any) {
        return StudentRenewApiFp(this.configuration).apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
