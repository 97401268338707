import { Options, Prop, Vue } from "vue-property-decorator";


@Options({})
export default class ErrorIndicator extends Vue {

  @Prop({default: true})
  public showErrorText!: boolean;

  @Prop({default: 'Error'})
  public errorText!: string;
}
