import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0558ac43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-info-panel" }
const _hoisted_2 = {
  key: 0,
  class: "user-info-preview"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "menuButton-icon" }
const _hoisted_6 = { class: "menuButton-text" }

export function render(_ctx, _cache) {
  const _component_ApplicationMenu = _resolveComponent("ApplicationMenu")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.appAccessPermitted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.infoTextRow0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.infoTextRow0), 1))
            : _createCommentVNode("", true),
          (_ctx.infoTextRow1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.infoTextRow1), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.onMenuClicked && _ctx.onMenuClicked(...args)), ["prevent","stop"])),
      ref: "menuButton",
      class: "menuButton"
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("i", {
          class: _normalizeClass(['far', 'fa-3x', _ctx.menuIcon, 'icon'])
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.menuButtonText), 1)
    ], 512),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.showMenu)
          ? (_openBlock(), _createBlock(_component_ApplicationMenu, {
              key: 0,
              ref: "dropDownMenu",
              show: _ctx.showMenu,
              isExternal: _ctx.isExternal
            }, null, 8, ["show", "isExternal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}