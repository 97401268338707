
import { Options, Vue } from "vue-property-decorator";
import { paywayStyle } from "./payway-style";
import { PUBLISHABLE_API_KEY, SELF_URL } from '../config/config';
import store from "@/shared/store";
import { EventEmitter } from "events";
import { AppInsights } from "@/mars-app/appInsights";

declare let payway: any;

@Options({
  props: {
    paymentToken: String,
    isCreditCardValid: Boolean,
  },
  emits: ['update:paymentToken', 'update:isCreditCardValid'],
  components: {}
})
export default class CreditCardPayment extends Vue {
  private paymentToken!: string;
  private isCreditCardValid!: boolean;

  private ai = new AppInsights;

  private paywayEl: HTMLElement | null = null;
  private paywayFrameGuid = '837494741234567895857494639628373';

  // For PayWay Lib Checking
  private numberOfRetry = 2;
  private timeout = 10;
  private numberOfTried = 0;
  private startTime = Math.round(new Date().getTime() / 1000);
  private endTime = this.startTime + this.timeout;
  private loadingLibStatus = -1;
  private paymentDeclined = false;

  private createFrameRetry = 5;
  private createFrameNumberOfTried = 0;
  private createPayWayFrameStatus = -1;

  private creditCardFrame: any = null;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private localDebug = false;

  created() {
    this.localDebug = SELF_URL.indexOf('localhost') >= 0;
    setTimeout(() => {
      this.loadPayWayLib();
      this.checkPayWayLib();
    }, 500);

  }

  mounted(): void {
    this.eventCtrl.on('process-payment-application', this.requestToProcessPayment);
  }

  beforeUnmount() {
    this.eventCtrl.removeListener('process-payment-application', this.requestToProcessPayment);
  }

  private loadPayWayLib() {
    this.paywayEl = document.createElement('script');
    this.paywayEl.setAttribute('src', 'https://api.payway.com.au/rest/v1/payway.js');
    document.head.appendChild(this.paywayEl);
    // console.log('PayWayLib loaded')
  }

  public isPayWayLibReady(): boolean {
    try {
      // console.log('PayWayLib Ready -- ', typeof payway.createCreditCardFrame);
      return typeof payway.createCreditCardFrame === 'function';
    } catch (err) {
      console.log('PayWayLib is not ready!!! ', err);
      return false;
    }
  }

  private checkPayWayLib() {
    if (!this.isPayWayLibReady() && this.numberOfTried < this.numberOfRetry) {

      if (Math.round(new Date().getTime() / 1000) > this.endTime) {
        this.numberOfTried++;
        this.startTime = Math.round(new Date().getTime() / 1000);
        this.endTime = this.startTime + this.timeout;
        this.removePayWayLib();
        setTimeout(() => {
          this.loadPayWayLib();
          this.checkPayWayLib();
        }, 500);

      } else if (this.numberOfTried < this.numberOfRetry) {
        setTimeout(this.checkPayWayLib, 500);
      }
    } else if (this.numberOfTried >= this.numberOfRetry) {
      this.loadingLibStatus = 0;
      this.numberOfTried = 0;
      this.startTime = 0;
      this.endTime = 0;
    } else if (this.isPayWayLibReady()) {
      this.loadingLibStatus = 1;
      this.createPayWayFrame();
    }
  }

  public removePayWayLib(): void {
    console.log('removing PayWayLib ...');
    try {
      document.body.removeChild(this.paywayEl as HTMLElement);
    } catch (err) {
      console.log(err);
    }
    try {
      this.paywayEl = null;
    } catch (err) {
      console.log(err);
    }
    try {
      payway = null;
    } catch (err) {
      console.log(err);
    }
  }


  public createPayWayFrame(): void {
    const myStyle = paywayStyle;
    const options = {
      // TODO: Replace {publishableApiKey} with your key
      publishableApiKey: PUBLISHABLE_API_KEY,
      tokenMode: 'callback',
      container: this.paywayFrameGuid,
      onValid: () => {
        this.$emit('update:isCreditCardValid', true);
        this.paymentDeclined = false;
      },
      onInvalid: () => {
        this.$emit('update:isCreditCardValid', false);
        this.paymentDeclined = false;
      },
      style: myStyle,
    };

    const createdCallback = (err: any, frame: any) => {
      if (err && this.createFrameNumberOfTried < this.createFrameRetry) {
        this.createFrameNumberOfTried++;
        this.removePayWayFrame();
        this.createPayWayFrame();
      } else if (err && this.createFrameNumberOfTried >= this.createFrameRetry) {
        this.createPayWayFrameStatus = 0;
        this.createFrameNumberOfTried = 0;
      } else {
        // Save the created frame for when we get the token
        this.creditCardFrame = frame;
        this.createPayWayFrameStatus = 1;
        this.$nextTick(() => {
          const elm: HTMLElement = document.getElementById(this.paywayFrameGuid) as HTMLElement;
          try {
            (elm.childNodes[0] as HTMLElement).setAttribute('width', '100%');
            (elm.childNodes[0] as HTMLElement).setAttribute('height', '315px');
          } catch (err) {
            console.log(err);
          }
        });
      }
    };

    payway.createCreditCardFrame(options, createdCallback);
  }

  public removePayWayFrame(): void {
    try {
      this.creditCardFrame.destroy();
    } catch (err) {
      console.log(err);
    }

    try {
      this.creditCardFrame = null;
    } catch (err) {
      console.log(err);
    }
  }

  public reloadPaymentForm(): void {
    this.removePayWayLib();
    this.removePayWayFrame();

    this.loadingLibStatus = -1;
    this.createPayWayFrameStatus = -1;

    this.startTime = Math.round(new Date().getTime() / 1000);
    this.endTime = this.startTime + this.timeout;

    this.loadPayWayLib();
    this.checkPayWayLib();
  }

  public requestToProcessPayment(cb: (token: string) => Promise<number> = null as any): void {
    console.log('requestToProcessPayment .............')
    this.paymentDeclined = false;
    const tokenCallback = (err: any, data: any) => {
      if (err) {
        this.paymentDeclined = true;
      } else if (data.singleUseTokenId) {
        this.$emit('update:paymentToken', data.singleUseTokenId);

        // complete the payment in cb - applyPayment()
        if (typeof cb === 'function') {
          // console.log('cb: ', cb)
          cb(data.singleUseTokenId)
            .then((res) => {
              // console.log('call back res: ', res);
              if (res === 3 || res === 5) {
                this.paymentDeclined = true;
              }
            }).catch((error) => {
              this.paymentDeclined = true;
              // console.log('Error in callback function: ', error);
              this.ai.trackException('Error submit payment: ' + JSON.stringify(error));
            });
        }
      }
    };

    this.creditCardFrame.getToken(tokenCallback);
  }

}

