import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import Popover from "@/shared/components/popover/index.vue";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import AddressPreview from "@/shared/components/address-preview/index.vue";
import { AddressDetail, PcRenewAddressType, RecordAddress, RecordPracticeAddress } from "@/shared/modules/typescript-api-client/models";
import { orderBy } from "lodash";
import DropdownSelection from "@/shared/components/dropdown-selection/index.vue";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import moment from "moment";
import PcrAddressDetailsUpdateModal from "../pcr-address-details-update-modal/index.vue";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    Popover,
    AddressPreview,
    DropdownSelection,
    LoadingIndicator,
    PcrAddressDetailsUpdateModal,
  },
  mixins: [
    FormatDateTimeMixin
  ]
})
export default class PcrStatutoryRegister extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private showStatutoryRegisterDetails = false;
  private statutoryRegisterDetails: any = {};
  private addressList: any[] = [];
  private selectedAddressId = 0;
  private registerAddressOption = 10;

  private showUpdateModal = false;
  private editAddressType = 0;
  private addressToEdit = {};

  private pcRenewAddressTypes: PcRenewAddressType[] = [];

  private recordPractices: AddressDetail[] = [];
  private isLoadingData = false;

  @Watch('registerAddressOption')
  private registerAddressOptionChange() {
    this.setRegisterAddress();
  }

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.isLoadingData);
  }

  get isTesting() {
    const _local = window.location.href.indexOf('https://localhost:44341/') == 0;
    const _dev = window.location.href.indexOf('fe-dev') > 0;
    const _sit = window.location.href.indexOf('fe-sit') > 0;
    const _uat = window.location.href.indexOf('fe-uat') > 0;
    return _local || _dev || _sit || _uat;
  }

  mounted(): void {
    this.loadStatutoryRegisterDetails();
    this.eventCtrl.emit('canGoNext', false);
  }

  private loadStatutoryRegisterDetails() {
    this.isLoadingData = true;
    // const lastApplicationYear = store.getters.getAppInfo.lastApplicationYear.year;
    this.apiService.CodeApi?.apiV1CodeGetPcRenewAddressTypesGet()
      .then(res => {
        this.pcRenewAddressTypes = res.data.data;
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to get PC renew address types: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get PC renew address types: ' + err);
      })

    this.apiService.PcRenewApi?.apiV1PcRenewGetStatutoryRegisterDetailsApplicationIdGet(this.appId)
      .then(res => {
        this.statutoryRegisterDetails = res.data.data;
        this.recordPractices = this.statutoryRegisterDetails.recordPractices.filter((p: { employmentStatus: { id: number; }; effectiveDate: moment.MomentInput; isPpp: any; }) => {
          return !p.isPpp && !p.isPpp;
        })
        this.statutoryRegisterDetails.recordStatutoryRegisterAddresses = orderBy(
          this.statutoryRegisterDetails.recordStatutoryRegisterAddresses,
          ['pcRenewAddressTypeId'], 'asc');
        // this.getAddressList();
        this.getAddressId();
        this.pcrFormData.statutoryRegisterDetails = this.statutoryRegisterDetails;
        this.isLoadingData = false;
        this.canGoNextCheck();
      })
      .catch(err => {
        this.isLoadingData = false;
        this.ai.trackException('Error, Unable to load Statutory register details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load Statutory register details: ' + err);
      })
  }


  get isAddressValid() {
    return this.registerAddressOption < 10;
  }

  private getPcRenewAddressType(id: number) {
    return this.pcRenewAddressTypes.filter(p => {
      return p.id === id;
    })[0].description;
  }

  private selectedOtherAddress(addresses: RecordPracticeAddress[]) {
    const _selectedAddress: any[] = addresses.filter(a => {
      return a.isSelected;
    });
    return _selectedAddress[0]?.address ? _selectedAddress[0].address : addresses[0].address;
  }

  private getAddressId() {
    for (let i = 0; i < this.statutoryRegisterDetails.recordStatutoryRegisterAddresses.length; i++) {
      if (this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[i].isSelected) {
        this.registerAddressOption = i;
        this.selectedAddressId = this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[i].id;
      }
    }
  }

  // private setAddressId(idx: number) {
  //   this.registerAddressOption = idx;
  //   this.statutoryRegisterDetails.recordStatutoryRegisterAddresses.forEach((a: { isSelected: boolean; }) => {
  //     a.isSelected = false;
  //   });
  //   this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[this.registerAddressOption].isSelected = true;
  //   this.canGoNextCheck();
  // }

  private getAddressInline(add: AddressDetail) {
    if (add == null) {
      return '';
    }
    let address: AddressDetail = add;
    const countryName: string = address.countryName ? address.countryName : '';
    let addressEncoded = '';
    address = JSON.parse(JSON.stringify(address).replace(/:null/gi, ':""'));  // replace all null value
    if (countryName !== '' && countryName.toLowerCase() == 'australia') {
      switch (address['addressTypeId']) {
        case 1: // Street
          addressEncoded = address['buildingName'] + ' ';
          addressEncoded += address['buildingTypeName'] + ' ' + address['buildingNumber'] + ' ' + address['level'] + ', ';
          addressEncoded += address['streetNumber'] + ' ' + address['streetName'] + ' ' + address['streetTypeName'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 2: // PO Box
          addressEncoded = address['deliveryTypeName'] + ' ' + address['poBox'] + ', ';
          addressEncoded += address['suburbName'] + ', ' + address['stateName'] + ' ' + address['postcode'];
          break;
        case 3: // DX address
          addressEncoded = 'DX ' + address['dxNumber'] + ', ';
          addressEncoded += address['dxExchangeTypeName'];
          break;
      }
    } else if (countryName !== '') {
      addressEncoded = address['foreignAddress'] + ', ' + address['countryName'];
    } else {
      addressEncoded = JSON.stringify(address).replaceAll('"', '');
    }
    return addressEncoded.replace(',', ' ').trim();
  }

  get appId(): number {
    return this.pcrFormData.appId;
  }

  get pcRenewApplicationId(): number {
    return this.pcrFormData.individualDetails.pcRenewApplicationId;
  }

  private canGoNextCheck() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const _validateEmail = this.statutoryRegisterDetails.publicationEmail.email == null || this.statutoryRegisterDetails.publicationEmail.email == '' || this.statutoryRegisterDetails.publicationEmail.email.match(
      emailRegex
    );
    // ignore showStatutoryRegisterDetails for now  #17042 PCR - Statutory Register Enhancements
    // const _validateAddress = this.showStatutoryRegisterDetails ? this.isAddressValid : true
    const _validateAddress = true;
    this.eventCtrl.emit('canGoNext', _validateAddress && _validateEmail);
  }

  private getSelectedAddressFromArray(addresses: any[]) {
    const _selectedAddress: any[] = addresses.filter(a => {
      return a.isSelected;
    });
    return _selectedAddress[0]?.address ? _selectedAddress[0].address : addresses[0].address;
  }

  private getAddressLabel(id: number): string {
    const _addressTypes: any[] = JSON.parse(store.getters.getAddressTypeList);
    return _addressTypes.filter((a: any) => {
      return a.id == id;
    })[0].description;
  }

  private getAddressDisplay(a: RecordAddress): string {
    const _label = this.getAddressLabel(a.pcRenewAddressTypeId);
    let _address = this.getAddressInline(a.address);
    if ( _address == '' && a.pcRenewAddressTypeId == 8) {
      _address = 'PPP does not exist';
    } else if (a.address?.deliveryTypeId == 8 && a.pcRenewAddressTypeId == 8) {
      _address = 'Address must be a street address (practice street is not disclosed)';
    }
    if (_address.length == 0) {
      _address = '(Address is blank. Select the <b>Edit</b> button to add new address)';
      this.pcrFormData.isStatutoryAddressBlank = true;
    }
    return _label + ' - ' + _address;

  }

  private setRegisterAddress() {
    this.statutoryRegisterDetails.recordStatutoryRegisterAddresses.forEach((a: { isSelected: boolean; }) => {
      a.isSelected = false;
    });
    if (this.registerAddressOption < this.statutoryRegisterDetails.recordStatutoryRegisterAddresses.length) {
      if (this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[this.registerAddressOption].address) {
        this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[this.registerAddressOption].isSelected = true;
      } else {
        this.registerAddressOption = 10;
      }
    }
    this.canGoNextCheck();
  }

  private openEdit(type: number, index: number) {
    this.editAddressType = type;
    // save current change before open modal
    this.saveStatutoryRegister();
    this.addressToEdit = { ...this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[index]?.address};
    this.addressToEdit = this.initAddress(this.addressToEdit);
    this.showUpdateModal = true;
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.lsAddressId = a.lsAddressId || 0;
    a.firmAddMapId = a.firmAddMapId || 0;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }

  private reload(editAddressType: number, isRevertible: boolean) {
    this.pcrFormData.isRevertibleStatutory = isRevertible;
    this.getSelectedAddresses();
    this.loadStatutoryRegisterDetails();
  }

  private getSelectedAddresses() {
    for (let i = 0; i < this.statutoryRegisterDetails.recordStatutoryRegisterAddresses.length; i++) {
      if (this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[i].isSelected) {
        this.registerAddressOption = i;
      }
    }
  }

  private revertAddress(type: number, index: number) {
    const addressDetailId = this.statutoryRegisterDetails.recordStatutoryRegisterAddresses[index]?.address.id;
    this.apiService.PcRenewApi?.apiV1PcRenewRevertStatutoryRegisterOtherAddressPcRenewApplicationIdAddressDetailIdPut(this.pcRenewApplicationId, addressDetailId)
      .then(res => {
        this.reload(type, false);
        this.eventCtrl.emit('show-snackbar', 'success', 'Address details reverted');
      })
      .catch(err => {
        this.ai.trackException('Error, Unable to revert address details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to revert address details' + err);
      })
  }

  private saveStatutoryRegister() {
    // save current change before open modal
    this.apiService.PcRenewApi?.apiV1PcRenewSaveStatutoryRegisterDetailsPost(this.pcrFormData.statutoryRegisterDetails)
      .then(res => {
        // this.eventCtrl.emit('show-snackbar', 'success', 'Statutory Register updated');
      })
      .catch(err => {
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save data: ' + err);
        this.ai.trackException('Error, Unable to save data: ' + err);
      })
  }

}

