import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import LoadingIndicator from "@/shared/components/loading-indicator/loading-indicator";
import DatePicker from 'vue-datepicker-next';
import { MARSApi } from "@/shared/modules/mars-api";
import { Application, StudentNewRecordDeclarationRequest } from "@/shared/modules/typescript-api-client/models";
import store from "@/shared/store";
import { EventEmitter } from "events";
import { AppInsights } from "@/mars-app/appInsights";

@Options({
  props: {
    stnAppFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    DatePicker,
  }
})
export default class StnDeclaration extends Vue {
  private stnAppFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  private loadingDataSet = false;

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  mounted() {
    this.stnAppFormData.stnDeclarations = {};
    this.$emit('update:isSectionLoading', this.loadingDataSet);
    window.scrollTo(0, 0);
    this.updateStnDeclaration(); 
  }

  private updateStnDeclaration() {
    const _stnDeclaration: StudentNewRecordDeclarationRequest = {
      id: 0,
      studentNewApplicationId: this.stnAppFormData.studentRecord?.studentNewApplicationId,
    }
    this.stnAppFormData.stnDeclarations = _stnDeclaration;
    this.canGoNextCheck();
  }

  private isValidForm(): boolean {
    return true;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }
}


