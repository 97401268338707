import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import PcrPracticeRecordPppCard from "../pcr-practice-record-ppp-card/index.vue";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { PracticeDetails } from "@/shared/modules/typescript-api-client/models";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";

@Options({
  props: {
    practiceRecords: Object,
  },
  components: {
    PcrPracticeRecordPppCard,
    Popover,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrPracticeRecordPppPopup extends Vue {
  private practiceRecords!: PracticeDetails[];

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  isVisible = false;

  title = '';
  message = '';// Main text content
  okButton = 'OK'; // Text for confirm button; leave it empty because we don't know what we're using it for
  cancelButton = 'Cancel'; // text for cancel button

  // Private variables
  private resolvePromise: any;
  private rejectPromise: any;

  private newRecords: any[] = [];

  @Watch('practiceRecords')
  private onPracticeRecordsChange(){
    this.getPractices();
  }


  private getPractices() {
    this.newRecords = [];
    for (let r = 0; r < this.practiceRecords.length; r++) {
      if (this.practiceRecords[r].newPracticeRecord?.name) {
        this.newRecords.push(this.practiceRecords[r].newPracticeRecord);
      } else {
        this.newRecords.push(this.practiceRecords[r].originalPracticeRecord);
        this.newRecords[r].hasEmploymentStatusChanged = this.sameEmploymentStatus(this.practiceRecords[r])
      }
      this.newRecords[r].effectiveDate = this.newRecords[r].effectiveDate ? new Date(this.newRecords[r].effectiveDate) : null;
    }
  }

  private sameEmploymentStatus(practiceRecord: any ): boolean {
    return practiceRecord.originalPracticeRecord?.employmentStatus?.id == practiceRecord.newRecord?.employmentStatus?.id;
  }

  private open() {
    setTimeout(() => {
      // delay add class to allow other popup to close
      document.body.classList.add('modal-open');
    }, 250);
    this.isVisible = true;

  }

  private close() {
    this.isVisible = false;
    document.body.classList.remove('modal-open');
  }

  public show(opts: any = {}) {
    this.title = opts?.title || 'CANCEL ALERT';
    this.message = opts?.message || 'Are you sure?';
    this.okButton = opts?.okButton || 'OK';
    this.cancelButton = opts?.cancelButton || 'Cancel';
    this.getPractices();
    this.open();
    // Return promise so the caller can get results
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve
      this.rejectPromise = reject
    })
  }

  private pppSelected(index: number) {
    this.newRecords.forEach((p: { isPpp: boolean; }) => {
      p.isPpp = false;
    });
    this.newRecords[index].isPpp = true;
  }

  _confirm() {
    this.updatePrincipalPlacePractice();
  }

  get pppCheck(): boolean {
    const _isPpp = this.newRecords.filter(p=>{
      return p.isPpp && !p.isCeased;
    });
    return _isPpp.length == 1;
  }

  private async updatePrincipalPlacePractice() {
    const _ppp = this.newRecords.filter((r: { isPpp: boolean; }) => {
      return r.isPpp
    })[0];
    if (_ppp?.id && _ppp.id > 0) {
      await this.apiService.PcRenewApi?.apiV1PcRenewUpdatePrincipalPlacePracticePcRenewApplicationIdRecordPracticeIdPut(
        this.pcRenewApplicationId, // pcRenewApplicationId
        _ppp.id         // recordPracticeId
      )
        .then(() => {
          this.eventCtrl.emit('show-snackbar', 'success', 'PPP record updated');
          this.close()
          this.resolvePromise(true)
        })
        .catch(err => {
          this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update PPP record: ' + err);
          this.ai.trackException('Error, Unable to update PPP record: ' + err);
        })
    } else {
      this.close()
      this.resolvePromise(true)
    }

  }

  _cancel() {
    this.close();
    this.resolvePromise(false)
    // Or you can throw an error
    // this.rejectPromise(new Error('User cancelled the dialogue'))
  }

  get pcRenewApplicationId() {
    return store.getters.getPcrFormData.individualDetails.pcRenewApplicationId;
  }

}

