/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 1.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AmRenewIndividualDetailResponse } from '../models';
import { AmRenewPersonalDetail } from '../models';
import { AmRenewPreferenceDetailRequest } from '../models';
import { AmRenewPreferenceDetailResponse } from '../models';
import { AmRenewRecordDeclarationRequest } from '../models';
import { AmRenewRecordDeclarationResponse } from '../models';
import { BooleanResponse } from '../models';
import { PaymentDetailsRequest } from '../models';
import { PaymentDetailsResponse } from '../models';
import { TransactionResponse } from '../models';
/**
 * AmRenewApi - axios parameter creator
 * @export
 */
export const AmRenewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewCancelAmRenewApplicationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewCancelAmRenewApplicationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmRenew/CancelAmRenewApplication/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmRenew/CheckAmRenewConcurrentSession/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmRenew/GetAmRenewPreferenceDetailData/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmRenew/GetAmRenewRecordDeclaration/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet: async (applicationId: number, isNewApplication: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            // verify required parameter 'isNewApplication' is not null or undefined
            if (isNewApplication === null || isNewApplication === undefined) {
                throw new RequiredError('isNewApplication','Required parameter isNewApplication was null or undefined when calling apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet.');
            }
            const localVarPath = `/api/v1/AmRenew/GetIndividualDetails/{applicationId}/{isNewApplication}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"isNewApplication"}}`, encodeURIComponent(String(isNewApplication)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetPaymentDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV1AmRenewGetPaymentDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v1/AmRenew/GetPaymentDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSaveAmRenewPreferenceDetailDataPost: async (body?: AmRenewPreferenceDetailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmRenew/SaveAmRenewPreferenceDetailData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save AmRenew Record Declaration
         * @param {AmRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSaveAmRenewRecordDeclarationPost: async (body?: AmRenewRecordDeclarationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmRenew/SaveAmRenewRecordDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSavePaymentDetailsPost: async (body?: PaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmRenew/SavePaymentDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {AmRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewUpdatePersonalDetailPost: async (body?: AmRenewPersonalDetail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AmRenew/UpdatePersonalDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmRenewApi - functional programming interface
 * @export
 */
export const AmRenewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmRenewPreferenceDetailResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns AmRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmRenewRecordDeclarationResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmRenewIndividualDetailResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetailsResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body?: AmRenewPreferenceDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save AmRenew Record Declaration
         * @param {AmRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewSaveAmRenewRecordDeclarationPost(body?: AmRenewRecordDeclarationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewSaveAmRenewRecordDeclarationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewSavePaymentDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {AmRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AmRenewUpdatePersonalDetailPost(body?: AmRenewPersonalDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await AmRenewApiAxiosParamCreator(configuration).apiV1AmRenewUpdatePersonalDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AmRenewApi - factory interface
 * @export
 */
export const AmRenewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Returns Cancelled AmRenewApplication
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmRenew Concurrent Session
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any): AxiosPromise<BooleanResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Preference Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmRenewPreferenceDetailResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns AmRenew Record Declaration
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any): AxiosPromise<AmRenewRecordDeclarationResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Returns Individual Details
         * @param {number} applicationId 
         * @param {boolean} isNewApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any): AxiosPromise<AmRenewIndividualDetailResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PaymentDetailsResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Preference Details
         * @param {AmRenewPreferenceDetailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body?: AmRenewPreferenceDetailRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save AmRenew Record Declaration
         * @param {AmRenewRecordDeclarationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSaveAmRenewRecordDeclarationPost(body?: AmRenewRecordDeclarationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewSaveAmRenewRecordDeclarationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any): AxiosPromise<TransactionResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewSavePaymentDetailsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Update Personal Detail
         * @param {AmRenewPersonalDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AmRenewUpdatePersonalDetailPost(body?: AmRenewPersonalDetail, options?: any): AxiosPromise<BooleanResponse> {
            return AmRenewApiFp(configuration).apiV1AmRenewUpdatePersonalDetailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmRenewApi - object-oriented interface
 * @export
 * @class AmRenewApi
 * @extends {BaseAPI}
 */
export class AmRenewApi extends BaseAPI {
    /**
     * 
     * @summary External | Returns Cancelled AmRenewApplication
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId: number, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewCancelAmRenewApplicationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmRenew Concurrent Session
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId: number, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewCheckAmRenewConcurrentSessionApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Preference Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId: number, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewGetAmRenewPreferenceDetailDataApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns AmRenew Record Declaration
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId: number, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewGetAmRenewRecordDeclarationApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Returns Individual Details
     * @param {number} applicationId 
     * @param {boolean} isNewApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId: number, isNewApplication: boolean, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(applicationId, isNewApplication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Get Payment Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Preference Details
     * @param {AmRenewPreferenceDetailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body?: AmRenewPreferenceDetailRequest, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewSaveAmRenewPreferenceDetailDataPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save AmRenew Record Declaration
     * @param {AmRenewRecordDeclarationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewSaveAmRenewRecordDeclarationPost(body?: AmRenewRecordDeclarationRequest, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewSaveAmRenewRecordDeclarationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details
     * @param {PaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewSavePaymentDetailsPost(body?: PaymentDetailsRequest, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewSavePaymentDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Personal Detail
     * @param {AmRenewPersonalDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmRenewApi
     */
    public apiV1AmRenewUpdatePersonalDetailPost(body?: AmRenewPersonalDetail, options?: any) {
        return AmRenewApiFp(this.configuration).apiV1AmRenewUpdatePersonalDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
